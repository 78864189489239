import { authenticatedApiFetch } from '../utils'

export const createQCMService = async (qcm) => {
	const res = await authenticatedApiFetch('/qcms/', { method: 'POST', body: JSON.stringify(qcm) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const allQCMsForClassService = async (classId) => {
	const res = await authenticatedApiFetch(`/qcms/?classroom=${classId}&all`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const getQCMService = async (id) => {
	const res = await authenticatedApiFetch(`/qcms/${id}/`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const updateQCMService = async (id, qcm) => {
	const res = await authenticatedApiFetch(`/qcms/${id}/`, { method: 'PATCH', body: JSON.stringify(qcm) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}




