<template>
	<pop-up-wrapper>
		<div class="pop-up-preview-file">
			<div class="img-container" ref="imgContainer"></div>
		</div>
	</pop-up-wrapper>
</template>


<script>
import { authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		file: Object
	},
	data() {
		return {
			name: ''
		}
	},

	methods: {
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		async downloadItem({ url, label }) {
			const response = await authenticatedImageFetch(url, { method: 'GET', responseType: "blob" })
				.then((response) => response.blob())

			const blob = new Blob([response])
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = label
			link.click()
			URL.revokeObjectURL(link.href)
		},

	},


	created() {
		let regex = /(?:\.([^.]+))?$/
		const extensions = ['png', 'jpg', 'gif']
		if (extensions.includes(regex.exec(this.file.file_upload)[1])) {

			this.getImage(this.file.file_upload)
		}
		else {
			this.downloadItem({
				url: this.file.file_upload,
				label: this.file.name,
			})
			this.$emit('close')
		}
	},

}
</script>

<style>
</style>
