<template>
	<h1>Ajouter une question de fiche d'exercice</h1>
	<section class="form-container-latex">
		<form
			@submit.prevent="submit"
			class="form-container"
			enctype="multipart/form-data"
		>
			<div class="input-container">
				<label for="Title">Title</label>
				<input
					type="text"
					class="form-control"
					v-model="form.title"
					id="Title"
					placeholder="Title"
				/>
			</div>
			<div class="input-container">
				<label for="Description">Texte </label>
				<textarea
					rows="10"
					type="text"
					class="form-control md-editor"
					v-model="latex"
					id="Description"
					placeholder="Description"
				>
				</textarea>
			</div>
			<div class="input-container">
				<label for="Description">Correction </label>
				<textarea
					rows="10"
					type="text"
					class="form-control md-editor"
					v-model="latex2"
					id="Description"
					placeholder="Correction"
				>
				</textarea>
			</div>
			<div class="input-container">
				<label for="Image">Image</label>
				<input
					type="file"
					v-on:change="changeImg"
					class="form-control"
					id="Image"
					placeholder="Image"
				/>
			</div>

			<div class="input-container">
				<label for="niveaux">Niveau</label>
				<select id="niveaux" v-model="selectedLevel" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="chapter">Chapter</label>
				<select id="chapter" v-model="form.chapter">
					<option
						v-for="chapter in getChapter()"
						:key="chapter"
						:value="chapter.id"
					>
						{{ chapter.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="type">Type</label>
				<select id="type" v-model="form.type" required>
					<option
						v-for="type in this.$store.state.exerciseQuestionTypes"
						:key="type"
						:value="type.id"
					>
						{{ type.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Theme</label>
				<select id="theme" v-model="form.theme" required>
					<option
						v-for="theme in this.$store.state
							.exerciseQuestionThemes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Difficulty</label>
				<select id="theme" v-model="form.difficulty" required>
					<option
						v-for="difficulty in this.$store.state.difficulties"
						:key="difficulty"
						:value="difficulty.id"
					>
						{{ difficulty.name }}
					</option>
				</select>
			</div>
			<p v-if="status !== ''" class="status">{{ status }}</p>
			<p class="errors">{{ errors }}</p>
			<input class="submit dark" type="submit" value="Valider" />
		</form>
		<div class="latex-wrapper">
			<div class="latex-container">
				<p :key="latex">{{ latex }}</p>
				<img :src="url" alt="" />
			</div>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			latex: '',
			latex2: '',
			form: {
				title: '',
				description: '',
				correction: '',
				image: '',
				chapter: '',
				type: '',
				theme: '',
				difficulty: '',
			},
			levels: [],
			errors: '',
			status: '',
			selectedLevel: null,
			teacher: localStorage.userId,
			url: ''
		}
	},
	methods: {
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},
		submit() {
			authenticatedApiFetch('/exercise-questions/', {
				method: 'POST',
				body: this.getFormDataValue(),
				headers: {}
			})
				.then(() => {
					this.status = 'Enregistré avec succès'
					this.form.title = ""
					this.form.description = ""

					setTimeout(() => {
						this.status = ''
					}, 2000)
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.selectedLevel == null) {
					return this.$store.state.chapters
				}
			}
			else {
				return ''
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', this.latex)
			data.append('correction', this.latex2)
			data.append('image_url_1', this.form.image)
			data.append('chapter', this.form.chapter)
			data.append('level', this.getLevel(this.form.chapter))
			data.append('type', this.form.type)
			data.append('theme', this.form.theme)
			data.append('difficulty', this.form.difficulty)
			data.append('teacher', this.teacher)

			return data
		},
		getLevel(e) {
			let chapter = this.$store.state.chapters.find(x => x.id == e).sequence
			let sequence = this.$store.state.sequences.find(x => x.id == chapter).level
			let level = this.$store.state.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg(e) {
			this.form.image = e.target.files[0]
			this.url = URL.createObjectURL(this.form.image)
		},
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		latex2: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
	}
}
</script>

<style>
</style>
