<template>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component class="main-component" :is="Component"></component>
		</transition>
	</router-view>
</template>

<script>
export default {

}
</script>

<style>
</style>
