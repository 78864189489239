<template>
	<pop-up-wrapper>
		<div class="pop-up-new-widget">
			<div class="wrapper">
				<p class="title">Ajouter un outil</p>
				<div class="tools-container">
					<label
						:class="
							checkIfAlreadyExist('NamePicker')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper"
					>
						<input v-model="tool" type="radio" value="NamePicker" />
						<span></span>
						<img src="/img/tools/name-picker.svg" alt="" />
						<p class="tool-name">Tirage au sort</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('Timer') ? 'not-clickable' : ''
						"
						class="tools-wrapper"
					>
						<input v-model="tool" type="radio" value="Timer" />
						<span></span>
						<img src="/img/tools/timer.svg" alt="" />
						<p class="tool-name">Chrono</p>
					</label>
				</div>
				<div class="btn-container">
					<button @click="this.$emit('close')" class="cancel">
						Annuler
					</button>
					<button
						@click="submit"
						:class="tool ? 'active' : ''"
						class="add"
					>
						Valider
					</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { allWidgetsFilterService, createWidgetService } from '../../../services/widgetsService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	data() {
		return {
			tool: null,
			tools: []
		}
	},

	methods: {
		checkIfAlreadyExist(name) {
			let filteredTools = this.tools.filter(x => x.widget_name == name)
			if (filteredTools.length > 0) {
				return true
			}
			else {
				return false
			}
		},
		async submit() {
			const data = {
				teacher_id: this.$store.state.user.user,
				widget_name: this.tool,
				widget_type: 'tool',
			}
			await createWidgetService(data)
				.then(() => {
					this.$emit('refreshTool')
					this.$emit('close')
				})
		},
		async getTools() {
			const filter = `?teacher=${this.$store.state.user.user}&type=tool`
			this.tools = await allWidgetsFilterService(filter)

		}
	},

	created() {
		this.getTools()
	},
}
</script>

<style>
</style>
