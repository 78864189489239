import { authenticatedApiFetch } from '../utils'



export const getTileGameThemeService = async () => {
    const res = await authenticatedApiFetch('/tile-game-theme/')
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allTileGameThemesService = async () => {
    const res = await authenticatedApiFetch('/tile-game-each-theme/')
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


