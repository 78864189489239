<template>
	<pop-up-wrapper>
		<div class="pop-up-configure-qcm-sheet">
			<img
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
				class="close"
			/>
			<h2>Personnalisation</h2>
			<div class="pop-up-content-container">
				<div class="field-container">
					<div class="input-container">
						<label for="name">Titre *</label>
						<input
							class="form-control required"
							id="name"
							v-model="qcm.title"
							placeholder="Nom de la ressource"
							type="text"
							@change="updateError"
						/>
						<small class="error">{{ errors.title }}</small>
					</div>
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							@change="updateErrorClasse"
							v-model="qcm.classroom"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe.id"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select v-model="qcm.currency" id="date">
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
					</div>
					<div class="input-container">
						<label for="duration">Durée (en minute)</label>
						<input v-model="qcm.duration" type="number" min="1">
					</div>
				</div>
				<div class="exo-container">
					<p>Questions</p>
					<div class="exo-wrapper">
						<draggable
							v-if="organizedQuestions.length > 0"
							:list="organizedQuestions"
							:disabled="!enabled"
							item-key="name"
							class="exo"
							ghost-class="ghost"
							@start="dragging = true"
							@end="dragging = false"
						>
							<template #item="{ element }">
								<div
									class="drag-el img-container"
									:class="{ 'not-draggable': !enabled }"
								>
									<div
										class="img-arrow-container"
									>
										<p>{{ element.question }}</p>
										<p v-for="answer in element.answers" :key="answer.id">
										<span :style="`color: ${answer.is_correct ? '#47cf52' : '#C22A29'} `">	{{ answer.answer }}</span></p>
										
										<img
											@click="removeQuestion(element.id)"
											class="remove"
											src="/img/blue-cross.svg"
											alt=""
										/>
									</div>
								</div>
							</template>
						</draggable>
					</div>
				</div>
			</div>
			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
		<Teleport to="body">
			<full-page-loader v-if="loading" />
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import { formateAndOrderDate } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
import draggable from "vuedraggable"
import { allActivityEventsFilterService } from '../../../services/activityEventsService'
import {  getQCMService, updateQCMService } from '../../../services/qcmServices'
import { createQCMQuestionOrderService,deleteQCMQuestionOrderService, filteredQCMQuestionOrdersService } from '../../../services/qcmQuestionsOrderServices'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'

export default {
	components: {
		draggable,
		PopUpWrapper,
		FullPageLoader,
	},
	props: {
		id: {
			type: Number,
			required: true
		},
	},
	data() {
		return {
			isExtended: null,
			url: Config.backend_url,
			dragging: false,
			enabled: true,
			qcm: {
				title: '',
				classroom: [],
				currency: null,
				duration: 10
			},
			classes: [],
			errors: {},
			courses: [],
			organizedQuestions: [],
			loading: false
		}
	},

	methods: {
		removeQuestion(e) {
			this.$emit('removeQuestion', e)
			this.organizedQuestions = this.organizedQuestions.filter(x => x.id !== e)

		},
		extend(id) {
			if (this.isExtended == id) {
				this.isExtended = null
			} else {
				this.isExtended = id
			}
		},
		async allCourses() {
			let classes = []

			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}
			if (this.$store.state.selectedOccurrence) {
				this.qcm.classroom = this.$store.state.selectedOccurrence.classId
				const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
				this.qcm.currency = occurrenceId
			}
			let occurrence = this.courses.find(e => {
				return e.qcms.find(x => x == this.id)
			})
			if (occurrence) {
				this.qcm.currency = occurrence.id
			}

		},
		updateInput() {
			this.errors.title = null
		},
		updateSelect() {
			this.errors.classe = null
		},
		close() {
			this.$emit('closed')
		},
		checkErrors() {
			let errors = false
			if (this.qcm.title == '') {
				this.errors.title = 'Veuillez ajouter un nom à votre ressource'
				errors = true
			}
			if (this.qcm.classroom.length == 0) {
				this.errors.classe = 'Veuillez ajouter une classe à votre ressource'
				errors = true
			}
			return errors
		},
		async submit() {
			if(this.checkErrors()) return 
			this.loading = true
			const res = await updateQCMService(this.id,this.qcm)

			const filter = `qcm=${this.id}`
			const qcmQuestionsOrder = await filteredQCMQuestionOrdersService(filter)

			for(const question of qcmQuestionsOrder){
				await deleteQCMQuestionOrderService(question.id)
			}

			for (const question of this.organizedQuestions) {
				const data = {
					question: question.id,
					qcm: res.id,
					order: this.organizedQuestions.indexOf(question)
				}
				await createQCMQuestionOrderService(data)
			}
			if (this.qcm.currency == undefined) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.qcm.currency)
				occurrence.qcms.push(this.id)

				await updateOccurenceService(this.qcm.currency, occurrence)

				let patchedOccurence = this.courses.find(e => {
					return e.qcms.find(x => x == this.id)
				})
				if (patchedOccurence && this.oldCurrency !== this.qcm.currency) {
					patchedOccurence.qcms = patchedOccurence.qcms.filter(x => x !== this.id)
					await updateOccurenceService(patchedOccurence.id, patchedOccurence)
				}
			}
			this.loading = false
			this.$emit('refreshActivities')
			this.$emit('close')

			
		},
		
		async getQcm(id){
			const res = await getQCMService(id)
			this.qcm = res
			this.organizedQuestions = res.questions
			
		}
		
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.qcm.classroom)
			return courses
		}
	},

	async created() {
		await this.getQcm(this.id)
		await this.allCourses()
	},


}
</script>

<style>
</style>
