<template>
	<section
		:class="theme"
		class="display-activity-container display-activity-container-hand-made"
	>
		<div
			v-if="questions.length !== 0"
			:style="`transform:translateX(-${progression}%)`"
			class="activity-container"
		>
			<div class="slide" :class="theme">
				<div class="question-container">
					<h1>Question flash</h1>
					<h2>{{ activity.title }}</h2>

					<button class="begin">Commencer</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import VueResizeText from 'vue3-resize-text'
import { authenticatedImageFetch } from '../../../utils'
import { getQuestionInstanceService } from '../../../services/questionInstancesService'
import { getQuestionFlashPackageService } from '../../../services/questionFlashPackagesServices'
export default {
	props: {
		id: Number,
		disableSliding: {
			type: Boolean,
			default: false
		}
	},
	directives: {
		ResizeText: VueResizeText.ResizeText
	},
	data() {
		return {
			activity: {},
			questions: [],
			theme: "",
			progression: 0,
			rituals: [],
			classe: null,
			classe_id: null,
			key: {},
			images: []
		}
	},
	methods: {
		getImage(file, i) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[`imgContainer${i}`].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},

		async getActivity() {
			this.activity = await getQuestionFlashPackageService(this.$route.params.id || this.id)
			if (!this.activity.template) {
				this.activity.template = 1
			}
			await this.getQuestions()
		},


		
		async getQuestions() {
			for (const question of this.activity.questions) {
				const res = await getQuestionInstanceService(question)
				this.questions.push(res)
			
				this.getTemplate()
			}
		},
		
		getTemplate() {
			this.reRender()
			let template = this.activity.template
			this.theme = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seven'][template]
		},

		dashboard() {
			if (this.$route.name == 'Key') {
				this.$emit('close')
			}
			else {
				this.$router.push({ name: 'ClassResources', params: { id: this.classe_id }, })
			}
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.getActivity()
	},
	mounted() {
		this.reRender()
	},
}
</script>

<style>
</style>
