<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">
				Pour enregistrer votre activité, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container first">
				<label for="classes">Classe</label>
				<select id="classes" v-model="form.classes">
					<option
						selected="selected"
						v-if="exercise.classes"
						:value="exercise.classes"
					>
						{{ getClasse(exercise.classes) }}
					</option>
					<option
						v-for="classe in $store.state.classes"
						:key="classe.name"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select
					v-if="form.classes.length !== 0"
					v-model="form.currency"
					id="date"
				>
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>
			<button @click="submit" class="submit dark">Attribuer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { createActivityService } from '../../services/activitiesService'
import { allActivityEventsFilterService } from '../../services/activityEventsService'
import { getOccurenceByIdService, updateOccurenceService } from '../../services/occurrencesService'
import { getQuestionFlashPackageService } from '../../services/questionFlashPackagesServices'
import { createQuestionInstanceService } from '../../services/questionInstancesService'
import { getQuestionService } from '../../services/questionsService'
import { createRitualActivityService } from '../../services/ritualActivitiesService'
import { formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		id: Number,
	},
	data() {
		return {
			form: {
				name: '',
				classes: [],
				currency: ''
			},
			activity: {},
			selectedClasse: null,
			index: 0,
			exercise: {},
			classes: [],
			questionsList: [],
			rituals: [],
			courses: []
		}
	},

	methods: {
		close() {
			this.$emit('close')
		},
		async submit() {
			let classArray = this.form.classes
			const body = {
				name: this.flashPackage.title,
				classes: [classArray],
				template: this.flashPackage.template,
				type: 'PACKAGE'
			}
			const res = await createActivityService(body)
			this.flashPackageId = res.id
			for (const [index, question] of this.questionsList.entries()) {
				const questionInstance = await createQuestionInstanceService(question)
				const data = {
					activity: questionInstance.id,
					ritual: this.flashPackageId,
					order: index
				}
				await createRitualActivityService(data)
			}


			if (this.form.currency == '') {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.form.currency)
				occurrence.activities.push(this.flashPackageId)
				await updateOccurenceService(this.form.currency, occurrence)
			}
			this.$router.push({ name: 'ClassResources', params: { id: classArray } })
		},
		async getFlashPackage() {
			this.flashPackage = await getQuestionFlashPackageService(this.id)
			for (const question of this.flashPackage.questions) {
				const res = await getQuestionService(question)
				this.questionsList.push(res)
			}
		},
		getClasse(e) {
			if (this.classes.length !== 0) {
				let classe = this.classes.find(x => x.id == e)
				return classe.name
			}
		},
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}

			if (this.$store.state.selectedOccurrence) {
				this.form.classes = this.$store.state.selectedOccurrence.classId
				const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
				this.form.currency = occurrenceId
			}
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},

	created() {
		this.allCourses()
		this.getFlashPackage()
	},
}
</script>

<style>
</style>
