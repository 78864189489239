<template>
	<div class="slider">
		<div id="left" class="arrow-container" @click="prev">
			<span class="arrow"></span>
		</div>
		<div id="right" class="arrow-container" @click="next">
			<span class="arrow"></span>
		</div>
		<div class="slide" v-for="i in [currentSlide]" :key="i">
			<img style="" :src="currentImg" alt="Image calendrier" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentSlide: 0,
			themes: [
				{
					theme: 'MOUNTAIN',
					img: '/img/game/calendar/demo/mountain.jpg',
				},
				{
					theme: 'SNOWY',
					img: '/img/game/calendar/demo/snowy.jpg',
				},
				{
					theme: 'VILLAGE',
					img: '/img/game/calendar/demo/village.jpg',
				},
				{
					theme: 'STARS',
					img: '/img/game/calendar/demo/stars.jpg',
				},
				{
					theme: 'SNOWMAN',
					img: '/img/game/calendar/demo/snowman.jpg',
				},
				{
					theme: 'FOREST',
					img: '/img/game/calendar/demo/forest.jpg',
				},
				{
					theme: 'HOPPER',
					img: '/img/game/calendar/demo/hopper.jpg',
				},
			],
		}
	},
	methods: {

		next() {
			if (this.currentSlide == this.themes.length - 1) {
				this.currentSlide = 0
			}
			else {
				this.currentSlide += 1
			}
			this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
		},
		prev() {
			if (this.currentSlide == 0) {
				this.currentSlide = this.themes.length - 1
			}
			else {
				this.currentSlide -= 1
			}
			this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
		},

	},
	computed: {
		currentImg() {
			return this.themes[Math.abs(this.currentSlide) % this.themes.length].img
		}
	},
	created() {
		this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
	},

}
</script>
