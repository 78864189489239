<template>
	<div class="day-column">
		<calendar-tile
			v-for="hour in hours"
			:key="hour"
			:hour="getHour(hour)"
		/>

		<calendar-indicator v-if="indicator" />
		<event v-for="event in events" :key="event" :event="event" />
	</div>
</template>

<script>
import CalendarIndicator from './CalendarIndicator.vue'
import CalendarTile from './CalendarTile.vue'
import Event from './Event.vue'
export default {
	components: { CalendarTile, Event, CalendarIndicator },
	props: {
		events: Array,
		day: Object,
		indicator: Boolean
	},

	data() {
		return {
			hours: 13
		}
	},

	methods: {
		getHour(e) {
			return `${e + 6}:00`
		}
	},
}
</script>

<style>
</style>
