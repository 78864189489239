<template>
	<section>
		<h1>
			<img src="/img/icons/classico-icon.svg" alt="" />Exercices Classico
		</h1>
		<p class="filter-paragraph">
			Ici, nous vous proposons de créer une fiche d'exercices ou une
			évaluation en 5min.
			<br />
			Choisissez un niveau de classe puis les chapitres qui vous
			intéressent. <br />
		</p>
		<p class="beta">
			Cette fonctionnalité est en cours de développement, merci de votre
			patience ... et de votre bienveillance face aux bugs ou désagréments
			!
		</p>
		<div>
			<filter-wrapper
				@setFilterVisible="setFilterVisible"
				@updateFilter="updateFilter"
			/>
		</div>

		<div class="activite-container activite-container-exercise">
			<exercise-container
				@addQuestion="addQuestion"
				@addQuestionRandom="addQuestionRandom"
				@removeQuestionRandom="removeQuestionRandom"
				v-for="individualQuestion in individualQuestions"
				:added="isAdded(individualQuestion.id)"
				:amount="checkAmount(individualQuestion.id)"
				:question="individualQuestion"
				:key="individualQuestion.id"
				:type="'exercice'"
			/>
		</div>
		<no-content
			v-if="individualQuestions.length == 0 && filter.level !== null"
			:level="filter.level"
			:chapter="filter.chapter"
			:type="'EXERCISE_SHEET'"
		/>
		<pagination-component
			v-if="pages !== null && pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="changeFilter"
			class="question-paginate"
		/>

		<pop-up-configure-exercise-sheet
			:questionLength="questions.length"
			:questions="questions"
			@organizeQuestions="organizeQuestion"
			@submit="submit"
			@removeQuestion="removeQuestion"
			@close="this.isVisible2 = !this.isVisible2"
			v-if="isVisible2"
		/>
		<div>
			<filter-component
				:style="
					isFilterVisible
						? 'transform: translateX(0);'
						: 'transform: translateX(100vw);'
				"
				:class="isFilterVisible ? 'active' : ''"
				@changeFilter="changeFilter"
				@close="setFilterVisible"
			/>
		</div>
		<div
			:class="questions.length > 0 ? 'active' : ''"
			class="footer-validation"
		>
			<button @click="resetQuestions">Reinitialiser</button>
			<button @click="showPopUp">
				Enregistrer
				<span
					>&nbsp; ( {{ questions.length }} question<span
						v-if="questions.length > 1"
						>s
					</span>
					)
				</span>
			</button>
		</div>
	</section>
</template>

<script>
import { checkRightFor } from '../../../../utils'
import FilterComponent from '../Filter.vue'
import PaginationComponent from '../../../Utils/PaginationComponent.vue'
import NoContent from '../NoContent.vue'
import FilterWrapper from '../FilterWrapper.vue'
import ExerciseContainer from './ExerciseContainer.vue'
import PopUpConfigureExerciseSheet from '../../../PopUp/ExerciseSheet/PopUpConfigureExerciseSheet.vue'
import { allExerciseQuestionsFilterService, allExerciseQuestionsService } from '../../../../services/exerciseQuestionsService'
import { createExerciseQuestionInstanceService } from '../../../../services/exerciseQuestionInstancesService'
import { createExerciseSheetService } from '../../../../services/exerciseSheetsService'

import { createExerciseQuestionOrderService } from '../../../../services/exerciseQuestionOrdersService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../../services/occurrencesService'

export default {
	components: {
		FilterComponent,
		PaginationComponent,
		NoContent,
		FilterWrapper,
		ExerciseContainer,
		PopUpConfigureExerciseSheet
	},
	data() {
		return {
			levelsColor: [
				{
					bg: '#FFE58E',
					color: '#8D4305'
				},
				{
					bg: '#B2E9FF',
					color: '#005272'
				},
				{
					bg: '#F8DAE9',
					color: '#D63384'
				},
				{
					bg: '#CEFFEA',
					color: '#00864D'
				},
				{
					bg: '#DCCBFF',
					color: '#200060'
				},
				{
					bg: '#E8FFC3',
					color: '#3E6200'
				},
				{
					bg: '#A7AAFF',
					color: '#000246'
				},
			],
			isFilterVisible: false,
			isSequenceVisible: true,
			isChapitreVisible: false,
			isVisible: false,
			isVisible2: false,
			individualQuestions: [],
			name: '',
			classLevel: [],
			questions: [],
			questionInstances: [],
			chapters: [],
			types: [],
			themes: [],
			difficulties: [],
			classes: [],
			levels: [],
			sequences: [],
			teacher: localStorage.userId,
			order: 0,
			questionsSelected: [],
			actualFilter: '',
			pages: null,
			count: null,
			currentPage: 0,
			filter: {
				chapter: [],
				level: null,
				type: [],
				theme: [],
				difficulty: [],
				offset: 0,
			},
			isEvaluation: false
		}
	},
	methods: {
		organizeQuestion(e) {
			this.questions = e
		},
		updateFilter(data) {
			this.filter.level = data.level
			this.filter.chapter = data.chapter
			this.filter.search = data.search
			this.changeFilter()
		},
		checkAmount(e) {
			let questions = this.questions.filter(x => x.id == e)
			return questions.length
		},
		setFilterVisible() {
			this.isFilterVisible = !this.isFilterVisible
			if (this.isFilterVisible) {
				document.body.style.overflow = 'hidden'
			}
			else {
				document.body.style.overflow = 'visible'
			}
		},
		isAdded(e) {
			let question = this.questions.find(x => x.id == e)
			if (question)
				question = true
			else
				question = false
			return question
		},
		sequenceChapters(e) {
			return this.$store.state.chapters.filter(x => x.sequence == e)
		},
		resetQuestions() {
			this.questions = []
		},
		changeLevel() {
			this.filter.chapter = []
			this.changeFilter()
		},
		changeFilter: function (e) {
			if (typeof e == 'number') {
				this.filter.offset = 20 * e
				this.currentPage = e
			}
			else {
				this.currentPage = 0
				this.filter.offset = 0
			}

			if (e !== undefined) {
				if (Object.prototype.hasOwnProperty.call(e, 'types')) {
					this.filter.type = e.types
				}
				if (Object.prototype.hasOwnProperty.call(e, 'themes')) {
					this.filter.theme = e.themes
				}
				if (Object.prototype.hasOwnProperty.call(e, 'difficulties')) {
					this.filter.difficulty = e.difficulties
				}
			}
			const search = this.filter.search ? `&search=${this.filter.search}` : '&search='

			const chapter = this.filter.chapter ? `chapter=${this.filter.chapter}` : ''
			const level = this.filter.level ? `&level=${this.filter.level}` : '&level='
			const type = this.filter.type ? `&type=${this.filter.type}` : '&type='
			const theme = this.filter.theme ? `&theme=${this.filter.theme}` : '&theme='
			const difficulty = this.filter.difficulty ? `&difficulty=${this.filter.difficulty}` : '&difficulty='
			this.actualFilter = `?${chapter}${level}&offset=${this.filter.offset}${type}${theme}${difficulty}${search}`
			this.refreshExercises(this.actualFilter)
		},
		async allExerciseQuestions() {
			const res = await allExerciseQuestionsService()
			this.individualQuestions = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
		},
		async refreshExercises(filter) {
			const res = await allExerciseQuestionsFilterService(filter)
			this.individualQuestions = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			if (this.pages < this.currentPage) {
				this.currentPage = 0
			}
		},
		submit: async function (e) {
			const uniqueLevels = Array.from(new Set(this.questions.map(item => item.level)))
			if (!checkRightFor('Exercices Classico', uniqueLevels)) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			let classArray = e.classes
			for await (const question of this.questions) {
				const res = await createExerciseQuestionInstanceService(question)
				this.questionInstances.push(res)
			}
			const data = {
				title: e.name,
				teacher: this.$store.state.user.user,
				classes: [classArray],
				template: e.index,
				double_column: e.double_column,
				level: this.questionLevel,
				chapter: this.questionChapter,
				type: this.isEvaluation ? "EVALUATION" : "EXERCISE_SHEET"
			}
			let activity = await createExerciseSheetService(data)
			let i = 0
			for await (const questionInstance of this.questionInstances) {
				let body = {
					exercise: questionInstance.id,
					exercise_sheet: activity.id,
					order: i
				}
				i++
				await createExerciseQuestionOrderService(body)
			}
			if (e.currency == undefined) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(e.currency)

				occurrence.exercise_sheets.push(activity.id)
				await updateOccurenceService(e.currency, occurrence)

			}
			this.$router.push({ name: 'ClassResources', params: { id: classArray } })
		},

		addQuestion(i) {
			let question = this.questions.find(x => x.id == i)
			let individualQuestion = this.individualQuestions.find(x => x.id == i)
			let newDescription = individualQuestion.description
			if (question) {
				this.questions.splice(this.questions.findIndex(x => x.id == i), 1)
			}
			else {
				this.questions.push({
					"id": individualQuestion.id,
					"title": individualQuestion.title,
					"description": newDescription,
					"description_img": individualQuestion.description_img,
					"correction": individualQuestion.correction,
					"correction_img": individualQuestion.correction_img,
					"level": individualQuestion.level,
					"type": individualQuestion.type,
					"theme": individualQuestion.theme,
					"difficulty": individualQuestion.difficulty
				})
			}
			this.questionLevel = individualQuestion.level
			this.questionChapter = individualQuestion.chapter
		},
		addQuestionRandom(question) {
			let questionRandomized = { ...question }
			questionRandomized.random_values = {}
			for (const value in question.random_values) {
				questionRandomized.random_values[value] = question.random_values[value]
			}
			this.questions.push(questionRandomized)

		},
		removeQuestionRandom(i) {
			let index = this.questions.findIndex(x => x.id == i.id)
			this.questions.splice(index, 1)
		},
		removeQuestion(i) {
			let index = this.questions.findIndex(x => x.id == i.id)
			this.questions.splice(index, 1)
		},
		showPopUp() {
			this.isVisible2 = !this.isVisible2
		},
		showPopUp2(e, isEvaluation) {
			this.questions = e
			this.isEvaluation = isEvaluation
			this.isVisible2 = !this.isVisible2
		}
	},
	computed: {
		filteredSequences() {
			let sequences = this.$store.state.sequences.filter(x => x.level == this.filter.level)
			return sequences
		}
	},
	created() {
		this.allExerciseQuestions()
	},
}
</script>

<style>
</style>
