<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-qcm-soluce">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p v-if="!isLoading && qcm.title" class="title">{{ qcm.title }}</p>
			<div class="btn-container">
				<button class="submit dark" @click="download">Télécharger</button>
				<button v-if="!heritedQuestions" class="submit white" @click="isPopUpShareVisible = true">Partager</button>
			</div>
			<table v-if="!isLoading">
				<thead>
					<tr>
						<th>Questions</th>
						<th>Reponses</th>
					</tr>
				</thead>
				<tbody id="table">
					<tr v-for="question in questions" :key="question.id">
						<td v-html="question.question"></td>
						<td>
							<ul style="flex-direction: column;">
								<li v-for="answer in question.answers" :key="answer.id">
									<input type="checkbox" :checked="answer.is_correct"/> <span v-html="answer.answer" ></span>
								</li>
							</ul>
						</td>
						
					</tr>
				</tbody>	
			</table>
			<Loader v-if="isLoading" />
		</div>
		<Teleport to="body">
			<PopUpShareResource v-if="isPopUpShareVisible" @close="isPopUpShareVisible = false" :id="id" :type="'corrected_qcm_id'"/>
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import { getQCMQuestionService } from '../../../services/qcmQuestionsServices'
import { getQCMService } from '../../../services/qcmServices'
import Loader from '../../Loader.vue'
import PopUpWrapper from '../PopUpWrapper.vue'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import PopUpShareResource from '../PopUpShareResource.vue'
export default {
	components: { PopUpWrapper,Loader,PopUpShareResource },
	emits: ['close'],
	props: {
		id: {
			type:Number,
			required: false
		},
		heritedQuestions: {
			type: Array,
			required: false
		}
	},
	data(){
		return {
			qcm: {},
			questions: [],
			isLoading: false,
			isPopUpShareVisible: false
		}
	},

	methods: {
		
		async download() {
			const content = document.querySelector('#correction')
			const HTML_Width = content.clientWidth
			const HTML_Height = content.clientHeight
			const PDF_Width = HTML_Width
			const PDF_Height = (PDF_Width * 1.5)
			const canvas_image_width = HTML_Width
			const canvas_image_height = HTML_Height

			const totalPDFPages = Math.ceil(HTML_Height / PDF_Height)
			await html2canvas(content, {
				allowTaint: false, quality: 1,
				scale: 2,
			}).then(function (canvas) {
				
				canvas.getContext('2d')

				let imgData = canvas.toDataURL("image/jpg", 1.0)
				let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height], true)
				pdf.addImage(imgData, 'JPG', 0, 0, canvas_image_width, canvas_image_height, 'SLOW')


				for (let i = 1; i < totalPDFPages; i++) {
					pdf.addPage([PDF_Width, PDF_Height])
					pdf.addImage(imgData, 'JPG', 0, -(PDF_Height * i) + 0, canvas_image_width, canvas_image_height)
				}
				pdf.save(`correction`)
			})
		},
		async getQcm(){
			this.isLoading = true
			const res = await getQCMService(this.id)
			this.qcm = res
			for(const question of this.qcm.questions){
				const res = await getQCMQuestionService(question.id)
				this.questions.push(res)
			}
			this.isLoading = false
		},
		
	},
	created(){
		if(this.heritedQuestions){
			this.questions = this.heritedQuestions
		}
		else{
			this.getQcm()
		}
	}
	

}
</script>

<style>
</style>
