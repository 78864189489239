<template>
	<h1>Types d'Evaluation</h1>
	<table>
		<tr>
			<th></th>
			<th>Nom</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr
			v-for="(evaluationType, index) in this.$store.state.evaluationTypes"
			:key="index"
		>
			<td>{{ index + 1 }}</td>
			<td>{{ evaluationType.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditEvaluationType',
						params: { id: evaluationType.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteEvaluationType(evaluationType.id)">
					supprimer
				</button>
			</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			evaluationTypes: [],
		}
	},
	methods: {
		deleteEvaluationType(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/evaluation-types/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.allEvaluationTypes()
					})
			}
		},
		allEvaluationTypes: function () {
			authenticatedApiFetch(`/evaluation-types/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.evaluationTypes = res
				})
		},
	},
	created() {
		this.allEvaluationTypes()
	},
}
</script>

<style>
</style>
