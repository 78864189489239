<template>
	<pop-up-wrapper>
		<div class="pop-up tile-pop-up tile-rule">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">LE PRINCIPE</p>

			<p class="subtitle">
				Il s'agit d'un jeu où les élèves s'affrontent par équipe sur
				différents types de questions
			</p>
			<div class="img-text-container">
				<img src="/img/game/tiles/timer.svg" alt="" />
				<div class="text-container">
					<p class="flash">FLASH</p>
					<p class="explication">
						Épreuve de rapidité où seule la 1ère équipe à répondre
						remporte les points.
					</p>
				</div>
			</div>
			<div class="img-text-container">
				<img src="/img/game/tiles/puzzle.svg" alt="" />
				<div class="text-container">
					<p class="enigma">ENIGMA</p>
					<p class="explication">
						Enigme ou Question de logique.
					</p>
				</div>
			</div>
			<div class="img-text-container">
				<img src="/img/game/tiles/target.svg" alt="" />
				<div class="text-container">
					<p class="oneshot">ONESHOT</p>
					<p class="explication">
						Un temps limité est laissé aux élèves. Ils doivent alors
						donner une unique réponse à la question posée. Ils
						remportent les points si leur réponse est juste.
					</p>
				</div>
			</div>
			<div class="img-text-container">
				<img src="/img/game/tiles/interogation.svg" alt="" />
				<div class="text-container">
					<p class="chance">CHANCE</p>
					<p class="explication">
						Un peu comme au Monopoly, l'équipe met son destin entre
						les mains du hasard. Elle peut gagner ou perdre des
						points, se voir faire une action, …
					</p>
				</div>
			</div>
			<div class="img-text-container">
				<img src="/img/game/tiles/boomerang.svg" alt="" />
				<div class="text-container">
					<p class="boomerang">BOOMERANG</p>
					<p class="explication">
						Chaque équipe joue tour à tour en donnant un nom de la
						liste demandée. L'équipe qui répète un nom, donne un
						faux nom ou ne trouve pas de nom est éliminée. L'équipe
						restante emporte les points.
					</p>
				</div>
			</div>
			<img
				@click="this.$emit('next')"
				class="next"
				src="/img/next.svg"
				alt=""
			/>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
