<template>
	<section class="filter-menu-container">
		<div @click="this.$emit('close')" class="fake-menu-filter"></div>
		<div class="filter-div-container">
			<img
				@click="this.$emit('close')"
				class="close"
				src="/img/close.svg"
				alt=""
			/>
			<div>
				<h1>Filtrer votre recherche</h1>
				<p class="subtitle">Type de question</p>
				<label
					class="input-container-question"
					v-for="type in questionType()"
					:key="type"
				>
					<input
						type="checkbox"
						v-model="filter.types"
						:value="type.id"
						@change="changeFilter()"
					/>
					<span class="checkmark"> </span>
					<p>{{ type.name }}</p>
				</label>
				<p
					class="subtitle"
					v-if="$route.path.includes('/questions-individuelles')"
				>
					Thématique
				</p>
				<label
					class="input-container-question"
					v-for="theme in questionThemes()"
					:key="theme"
				>
					<input
						type="checkbox"
						v-model="filter.themes"
						:value="theme.id"
						@change="changeFilter()"
					/>
					<span class="checkmark"> </span>
					<p>{{ theme.name }}</p>
				</label>
				<p class="subtitle">Difficulté</p>
				<label
					class="input-container-question"
					v-for="difficulty in this.$store.state.difficulties"
					:key="difficulty"
				>
					<input
						type="checkbox"
						v-model="filter.difficulties"
						:value="difficulty.id"
						@change="changeFilter()"
					/>
					<span class="checkmark"> </span>
					<p>{{ difficulty.name }}</p>
				</label>
			</div>
			<div class="setting-filter-container">
				<!-- <button
					class="apply-filter-btn submit dark"
					@click="applyFilter"
				>
					Valider la sélection
				</button> -->
				<button class="reset-filter-btn" @click="resetFilter">
					Réinitialiser les filtres
				</button>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	data() {
		return {
			filter: {
				types: [],
				themes: [],
				difficulties: [],
			}
		}
	},
	methods: {

		softReset() {
			this.filter = {
				types: [],
				themes: [],
				difficulties: [],
			}
		},

		resetFilter() {
			this.filter = {
				types: [],
				themes: [],
				difficulties: [],
			}
			this.applyFilter()
		},
		applyFilter() {
			this.$emit('changeFilter', this.filter)
			this.$emit('close')
		},
		changeFilter() {
			this.$emit('changeFilter', this.filter)
		},

		questionType() {
			if (this.$route.path.includes('/questions-individuelles')) {
				return this.$store.state.questionTypes
			}
			else {
				return this.$store.state.exerciseQuestionTypes
			}
		},

		questionThemes() {
			if (this.$route.path.includes('/questions-individuelles')) {
				return this.$store.state.questionThemes
			}
			else {
				return null
			}
		},
	},

}
</script>

<style>
</style>
