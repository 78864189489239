<template>
	<section>
		<div class="add-class">
			<h1 class="classname">Mon Compte</h1>
		</div>

		<div class="parameter-container">
			<profile-router />
		</div>
	</section>
</template>

<script>

import ProfileRouter from './ProfileRouter.vue'

export default {
	components: {
		ProfileRouter,
	},
};
</script>

<style>
</style>
