<template>
	<table v-if="getClassLength !== 0 && ressources.length !== 0 && !isLoading">
		<tr>
			<th>Nom</th>
			<th>Type</th>
			<th>Action</th>
			<th>Correction</th>
			<th>Date du cours</th>
			<th>
				Partager <br />
				à la classe
			</th>
			<th></th>
		</tr>
		<tr v-for="data in getRessources" :key="data">
			<resource-name :name="data.name || data.title || data.game_name" />

			<data-type
				:isNoted="data.isNoted"
				:type="data.type"
				:isKey="data.key"
			/>
			<resource-actions
				:type="data.type"
				:id="data.id"
				:finished="data.finished"
				:name="data.name || data.title"
				:file_upload="data.file_upload"
			/>
			<notice-column :data="data" />
			<td>
				<span class="date" v-if="data.day"> {{ data.day }}</span>
			</td>

			<share-to-class-column :data="data" />

			<td class="td-center">
				<img
					@click="showPopUp2(data.id, data.type, data.customId)"
					src="/img/three-dots.png"
				/>
			</td>
		</tr>
	</table>
	<div v-else-if="isLoading">
		<loader />
	</div>
	<div v-else>
		<h2>Aucune ressource enregistrée</h2>
	</div>

	<pagination-component
		ref="paginate"
		v-if="pages !== null && pages > 1 && !isLoading"
		:totalPages="pages"
		:perPage="20"
		:currentPage="currentPage"
		@pageChanged="changeCurrentPage"
	/>

	<div v-if="showPopUpMenu">
		<pop-up-menu
			:id="popUpId"
			:x="x"
			:y="y"
			:type="type"
			@showPopUpDuplicate="showPopUpDuplicate"
			@showPopUpRemove="showPopUpRemove"
			@showPopUpEdit="showPopUpEdit"
			@closed="timeToClose2"
		/>
	</div>

	<div v-if="isVisibleRemove">
		<pop-up-remove
			@closed="showPopUpRemove"
			:titre="'Êtes-vous sûr de vouloir supprimer cette ressource?'"
			:text="'La ressource sera supprimée de vos ressources enregistrées ou attribuées.'"
			:btn="'Supprimer'"
			:path="type"
			:id="popUpId"
			:customId="customId"
			:redirect="'Ressource'"
			@refreshActivities="allActivities"
			@remove="remove"
		/>
	</div>
	<div v-if="isVisibleEdit">
		<pop-up-edit
			v-if="type == 'activities'"
			@close="showPopUpEdit"
			:id="popUpId"
			@refreshActivities="allActivities"
		/>
		<pop-up-edit-key
			v-if="type == 'keys'"
			@close="showPopUpEdit"
			:id="popUpId"
			@refreshActivities="allActivities"
		/>
		<pop-up-configure-exercise-sheet-edit
			v-if="type == 'exercise-sheets'"
			@close="showPopUpEdit"
			:id="popUpId"
			@refreshActivities="allActivities"
		/>
		<PopUpConfigureQCMEdit v-if="type == 'qcms'" :id="popUpId" @close="showPopUpEdit" @refreshActivities="allActivities"/>
	</div>
	<div v-if="isVisibleDuplicate">
		<pop-up-duplicate
			v-if="type == 'activities'"
			@close="showPopUpDuplicate"
			@refreshActivities="allActivities"
			:titre="'Êtes-vous sûr de vouloir Dupliquer cette ressource?'"
			:text="'Dupliquer une ressource vous permet de modifier les contenus de celle-ci et de l\'attribuer de nouveau'"
			:id="popUpId"
			:btn="'Dupliquer'"
		/>
		<pop-up-duplicate-key
			v-if="type == 'keys'"
			@close="showPopUpDuplicate"
			@refreshActivities="allActivities"
			:titre="'Êtes-vous sûr de vouloir Dupliquer cette ressource?'"
			:text="'Dupliquer une ressource vous permet de modifier les contenus de celle-ci et de l\'attribuer de nouveau'"
			:id="popUpId"
			:btn="'Dupliquer'"
			:path="type"
		/>

		<pop-up-duplicate-exercise-sheet
			v-if="type == 'exercise-sheets'"
			@close="showPopUpDuplicate"
			@refreshActivities="allActivities"
			:id="popUpId"
			:btn="'Dupliquer'"
			:path="type"
		/>
		<PopUpDuplicateQCM v-if="type == 'qcms'" @close="showPopUpDuplicate" @refreshActivities="allActivities" :id="popUpId" :btn="'Dupliquer'" />
	</div>
</template>

<script>
import { getTileGameInfo } from '../../../../utils'
import { allClassActivityByIdService } from '../../../../services/activitiesService'
import { getKeyByIdService } from '../../../../services/keysService'
import { allLessonsForClassService } from '../../../../services/lessonsService'
import { allExerciseSheetsForClassService } from '../../../../services/exerciseSheetsService'
import { allEvaluationsForClassService } from '../../../../services/evaluationsService'
import { allGamesForClassService } from '../../../../services/gamesService'
import { allChristmasCalendarsForClassService } from '../../../../services/christmasCalendarsService'
import { allTileGamesForClassService } from '../../../../services/tileGamesService'
import { allQCMsForClassService } from '../../../../services/qcmServices'
import Loader from '../../../Loader.vue'
import PaginationComponent from '../../../Utils/PaginationComponent.vue'
import PopUpMenu from "../../../PopUp/PopUpMenu.vue"
import PopUpRemove from "../../../PopUp/PopUpRemove.vue"
import PopUpDuplicate from "../../../PopUp/PopUpDuplicate.vue"
import PopUpEdit from "../../../PopUp/PopUpEdit.vue"
import PopUpDuplicateKey from '../../../PopUp/PopUpDuplicateKey.vue'
import PopUpEditKey from '../../../PopUp/PopUpEditKey.vue'
import PopUpDuplicateExerciseSheet from '../../../PopUp/PopUpDuplicateExerciseSheet.vue'
import Config from '../../../../config'
import ResourceName from './ResourceName.vue'
import DataType from './DataType.vue'
import ResourceActions from './ResourceActions.vue'
import PopUpConfigureExerciseSheetEdit from '../../../PopUp/ExerciseSheet/PopUpConfigureExerciseSheetEdit.vue'
import NoticeColumn from './NoticeColumn.vue'
import ShareToClassColumn from './ShareToClassColumn.vue'
import { allOccurencesForClassAndTypeService } from '../../../../services/occurrencesService'
import PopUpDuplicateQCM from '../../../PopUp/QCM/PopUpDuplicateQCM.vue'
import PopUpConfigureQCMEdit from '../../../PopUp/QCM/PopUpEditQCM.vue'
export default {
	components: {
		PaginationComponent,
		Loader,
		PopUpMenu,
		PopUpRemove,
		PopUpDuplicate,
		PopUpEdit,
		PopUpDuplicateKey,
		PopUpEditKey,
		PopUpDuplicateExerciseSheet,
		ResourceName,
		DataType,
		ResourceActions,
		PopUpConfigureExerciseSheetEdit,
		NoticeColumn,
		ShareToClassColumn,
		PopUpDuplicateQCM,
		PopUpConfigureQCMEdit
	},

	data() {
		return {
			ressources: [],
			activities: [],
			evaluations: [],
			exerciseSheets: [],
			games: [],
			agendas: [],
			calendars: [],
			lessons: [],
			tileGames: [],
			qcms: [],
			pages: 0,
			currentPage: 0,
			classIndex: 0,
			type: '',
			showPopUpMenu: false,
			isVisibleRemove: false,
			isVisibleDuplicate: false,
			isVisibleEdit: false,
			popUpId: null,
			popUpName: null,
			selectedRessourceId: null,
			selectedRessourceType: null,
			x: 0,
			y: 0,
			isLoading: false,
			isMounted: false,
			landing_url: Config.landing_url,
			calendarId: null,
			tileGameId: null
		}
	},

	methods: {
		getTileGameInfo: getTileGameInfo,
		async allActivities() {
			if (this.$route.params.id) {
				this.isLoading = true
				this.ressources = []
				this.activities = await allClassActivityByIdService(this.$route.params.id)
				for (const activity of this.activities) {
					if (activity.key !== null) {
						const key = await getKeyByIdService(activity.key)
						activity.notice = key.notice
					}
					if (activity.activities2.length > 0) {
						activity.isNoted = true
					}
				}

				this.ressources.push(...this.activities)

				await this.allLessons()
				await this.allExerciseSheets()
				await this.allGames()
				await this.christmasCalendar()
				await this.allTileGames()
				await this.allQCMs()
				for (const [index, ressource] of this.ressources.entries()) {
					ressource.customId = index
				}

				this.ressources.sort((a, b) => {
					if (a.created_at && b.created_at) {
						return  new Date(b.created_at)  - new Date(a.created_at)
					}
					return 0
				})
				
				this.pages = Math.ceil(this.ressources.length / 20)
				this.allAgendas()
				this.isLoading = false
			}

		},
		async allQCMs() {
			this.qcms = await allQCMsForClassService(this.$route.params.id)
			for (const qcm of this.qcms) {
				qcm.type = 'QCM'
			}
			this.ressources.push(...this.qcms)
		},
		async allAgendas() {

			let occurrences = []
			const contents = {
				activity: [],
				exercise_sheet: [],
				lesson: [],
				tile_game: [],
				qcms: []
			}
			for (const activity of this.ressources) {
				let type
				switch (activity.type) {
					case 'Activité rituelle':
						type = 'activity'
						break
					case 'PACKAGE':
						type = 'activity'
						break
					case 'Évaluation':
					case 'ExerciseSheet':
					case 'EXERCISE_SHEET':
					case 'EVALUATION':
						type = 'exercise_sheet'
						break
					case 'TileGame':
						type = 'tile_game'
						break
					case 'QCM':
						type = 'qcms'
						break
					case 'Calendrier de l\'Avent':
					case 'Game':
						continue

					default:
						continue

				}
				contents[type].push(activity.id)
			}
			for (const type in contents) {
				const typeArray = contents[type]

				if (typeArray.length > 0) {
					const filterString = typeArray.join(',')
					occurrences = [...occurrences, ...await allOccurencesForClassAndTypeService(this.$route.params.id, type, filterString)]

				}
			}
			this.setDate(this.activities, occurrences, 'activities')
			this.setDate(this.exerciseSheets, occurrences, 'exercise_sheets')
			this.setDate(this.tileGames, occurrences, 'tile_games')
			this.setDate(this.qcms, occurrences, 'qcms')
			for (let content of this.exerciseSheets) {

				const occurrenceExist = occurrences.find(x => x.exercise_sheets.includes(content.id))
				if (occurrenceExist) {
					content.day = new Date(occurrenceExist.day).toLocaleDateString('fr-fr', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
				}
			}
		},
		setDate(datas, occurrences, type) {
			for (let content of datas) {
				const occurrenceExist = occurrences.find(x => x[type].includes(content.id))
				if (occurrenceExist) {
					content.day = new Date(occurrenceExist.day).toLocaleDateString('fr-fr', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
				}
			}
		},
		
		async allEvaluations() {
			const response = await allEvaluationsForClassService(this.$route.params.id)
			this.evaluations = response
			this.ressources.push(...this.evaluations)
		},
		async allExerciseSheets() {
			const response = await allExerciseSheetsForClassService(this.$route.params.id)
			this.exerciseSheets = response
			this.ressources.push(...this.exerciseSheets)
		},
		async allGames() {
			this.games = await allGamesForClassService(this.$route.params.id)
			for (const game of this.games) {
				game.type = 'Game'
			}
			this.ressources.push(...this.games)
		},
		async christmasCalendar() {
			this.calendars = await allChristmasCalendarsForClassService(this.$route.params.id)

			for (const calendar of this.calendars) {
				calendar.type = "Calendrier de l'Avent"
			}
			this.ressources.push(...this.calendars)
		},

		async allTileGames() {

			this.tileGames = await allTileGamesForClassService(this.$route.params.id)

			for (const tileGame of this.tileGames) {
				tileGame.type = "TileGame"
				tileGame.name = getTileGameInfo(tileGame.theme).name
			}
			this.ressources.push(...this.tileGames)
		},

		async allLessons() {
			this.lessons = await allLessonsForClassService(this.$route.params.id)

			for (const lesson of this.lessons) {
				lesson.type = "Leçon"
			}
			this.ressources.push(...this.lessons)
		},


		showPopUp2(id, type, customId) {
			this.popUpId = id
			this.customId = customId

			if (type === "Activité rituelle" || type === "ACTIVITY") {
				this.type = 'activities'
			}
			if (type === "PACKAGE" || type === "Clé en main") {
				this.type = 'keys'
			}
			if (type == "Game") {
				this.type = 'games'
			}
			if (type == "Évaluation" || type == "ExerciseSheet" || type == "EVALUATION" || type == "EXERCISE_SHEET") {
				this.type = 'exercise-sheets'
			}
			if (type == "Calendrier de l'Avent") {
				this.type = 'christmas-calendar'
			}
			if (type == "TileGame") {
				this.type = 'tile-game'
			}

			if (type == "Leçon") {
				this.type = 'imported-lessons'
			}
			if (type == "QCM") {
				this.type = 'qcms'
			}

			this.showPopUpMenu = true
		},
		timeToClose2() {
			this.showPopUpMenu = false
		},

		showPopUpRemove() {
			this.isVisibleRemove = !this.isVisibleRemove
		},
		showPopUpEdit() {
			this.isVisibleEdit = !this.isVisibleEdit
		},
		showPopUpDuplicate() {
			this.isVisibleDuplicate = !this.isVisibleDuplicate
		},

		changeCurrentPage(e) {
			this.currentPage = e
		},

		remove(e) {
			this.ressources = this.ressources.filter(x => x.customId !== e)
		},
	},
	computed: {
		getClassLength() {
			if (this.$store.state.classes) {
				return this.$store.state.classes.length
			}
			else {
				return null
			}
		},
		getRessources() {
			let resources = this.ressources.slice(this.currentPage * 20, this.currentPage * 20 + 20)
			return resources
		}
	},

	created() {
		window.addEventListener('mousemove', (e) => {

			this.x = e.clientX
			this.y = e.clientY
		})
	},
	async mounted() {
		await this.allActivities()
		this.isMounted = true
	},
	watch: {
		$route() {
			if (this.$route.name == "ClassResources" && this.isMounted) {
				this.currentPage = 0
				this.allActivities()
			}
		}
	},
}
</script>

<style>
</style>
