<template>
	<propose-key class="admin-wrapper" />
</template>

<script>
import ProposeKey from "../../../../SuperAdmin/Key/ProposeKey.vue"

export default {
	components: { ProposeKey },
}
</script>

<style>
</style>
