import { authenticatedApiFetch } from '../utils'

export const getSubscriptionService = async () => {
    const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
        .then(response => response.json())
        .then(response => {
            return response[0]
        })
        .catch(error => console.error(error))
    return res
}
