import { authenticatedApiFetch } from '../utils'


export const createSharedResourceService = async (sharedResource) => {
	const res = await authenticatedApiFetch(`/shared-resources/`, { method: 'POST', body: JSON.stringify(sharedResource) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const getSharedResourceByIdService = async (id) => {
	const res = await authenticatedApiFetch(`/shared-resources/${id}/`, { method: 'GET' })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const filteredSharedResourcesService = async (filter) => {
	const res = await authenticatedApiFetch(`/shared-resources/?${filter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const updateSharedResourceService = async (id, sharedResource) => {
	const res = await authenticatedApiFetch(`/shared-resources/${id}/`, { method: 'PUT', body: JSON.stringify(sharedResource) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}
