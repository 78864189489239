import { createStore } from 'vuex'
import VuexPersist from 'vuex-persist'
import state from './state'
import mutations from './mutations'
import actions from './actions'
import getters from './getters'

const vuexPersist = new VuexPersist({
    key: 'my-app',
    storage: window.localStorage
})

const store = createStore({
    state() {
        state
    },
    mutations: mutations,
    actions: actions,
    getters: getters,
    plugins: [vuexPersist.plugin]
}
)

export default store
