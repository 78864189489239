<template>
	<div></div>
</template>
<script>
import { decodeAndStoreToken } from '../utils'
import Config from '../config'
export default {
	async created() {
		if (this.$route.query.token) {
			let token = decodeAndStoreToken(this.$route.query.token)
			let url = this.$route.query.name
			let action = this.$route.query.action
			await this.$store.dispatch('setUserInfo', {
				token: token["token"],
				userId: token["userId"],
				profileId: token["profileId"],
			})
			if (action == 'FreeTrial') {
				this.$store.state.isPopUpNotAllowedVisible = true
			}
			if (action == 'GetClassPlan') {
				await this.$store.dispatch('getClasses', token["userId"])
				if (this.$store.state.classes.length !== 0) {
					const classe = this.$store.state.classes[0]
					this.$router.push({ name: 'ClassStudent', params: { id: classe.id } })
					return
				}
				else {
					this.$router.push({ name: 'Dashboard' })
					return
				}
			}
			if (action == 'GetClassResources') {
				await this.$store.dispatch('getClasses', token["userId"])
				if (this.$store.state.classes.length !== 0) {
					const classe = this.$store.state.classes[0]
					this.$router.push({ name: 'ClassResources', params: { id: classe.id } })
					return
				}
				else {
					this.$router.push({ name: 'Dashboard' })
					return
				}
			}
			if (action == 'GetClassDrive') {
				await this.$store.dispatch('getClasses', token["userId"])
				if (this.$store.state.classes.length !== 0) {
					const classe = this.$store.state.classes[0]
					this.$router.push({ name: 'ClassDrive', params: { id: classe.id } })
					return
				}
				else {
					this.$router.push({ name: 'Dashboard' })
					return
				}
			}
			if (url) {
				this.$router.push({ name: url })
			}
			else {
				this.$router.push({ name: 'Dashboard' })
			}
		} else {
			location.replace(`${Config.landing_url}/auth/login`)
		}
	}
}
</script>
