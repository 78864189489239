<template>
	<pop-up-wrapper @click="this.$emit('close')">
		<div class="pop-up-flash">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>

			<div class="all-guide-container">
				<div class="guide-wrapper">
					<div class="video-text-wrapper">
						<iframe
							src="https://www.youtube.com/embed/HNwIFuNjNKw"
							frameborder="0"
						></iframe>
						<div class="text-container">
							<p class="title">
								Comment créer un diaporama d'entraînement ?
							</p>
							<p>
								Vous pouvez créer ici des diaporamas de
								questions flash à vidéoprojeter en classe.
								L'objectif est de faire travailler les élèves
								sur les automatismes.
							</p>
						</div>
					</div>
				</div>
				<div class="guide-wrapper">
					<div class="video-text-wrapper">
						<div class="text-container">
							<p class="title">
								Comment créer un diaporama noté ?
							</p>
							<p>
								Vous pouvez créer ici des diaporamas notés de
								questions flash à vidéoprojeter en classe.
								L'objectif est d'évaluer les élèves après 4 ou 5
								entraînements.
							</p>
						</div>
						<iframe
							src="https://www.youtube.com/embed/PmDoGUaUD8Y"
							frameborder="0"
						></iframe>
					</div>
				</div>
			</div>
			<p class="see-more">
				Pour en savoir plus sur la mise en place en classe, leurs
				intérêts…
				<br />
				<a target="_blank" :href="`${landing}/faq`">Consulter la FAQ</a
				>😉
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../config'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	emits: ['close'],
	data() {
		return {
			landing: Config.landing_url
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style>
</style>
