<template>
	<pop-up-wrapper>
		<div class="pop-up-configure-exercise-sheet">
			<img
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
				class="close"
			/>
			<h2>Personnalisation</h2>
			<div class="pop-up-content-container">
				<div class="field-container">
					<div class="input-container">
						<label for="name">Titre *</label>
						<input
							class="form-control required"
							id="name"
							v-model="form.name"
							placeholder="Nom de la ressource"
							type="text"
							@change="updateError"
						/>
						<small class="error">{{ errors.name }}</small>
					</div>
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							@change="updateErrorClasse"
							v-model="form.classes"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe.id"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select v-model="form.currency" id="date">
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
					</div>
					<p>Cartouche</p>
					<slider-evaluation @index="checkIndex" />
				</div>
				<div class="exo-container">
					<p>Exercices</p>
					<div class="exo-wrapper">
						<draggable
							v-if="organizedQuestions.length > 0"
							:list="organizedQuestions"
							:disabled="!enabled"
							item-key="name"
							class="exo"
							ghost-class="ghost"
							@start="dragging = true"
							@end="dragging = false"
						>
							<template #item="{ element }">
								<div
									class="drag-el img-container"
									:class="{ 'not-draggable': !enabled }"
								>
									<div
										class="img-arrow-container"
										:class="{
											extended:
												isExtended == element.id
													? true
													: false,
										}"
									>
										<img
											:src="`${url}/media/${element.description_img}`"
											alt=""
										/>
										<img
											@click="extend(element.id)"
											class="arrow"
											src="/img/pop-up/down-arrow.svg"
											alt=""
										/>
										<img
											@click="removeQuestion(element.id)"
											class="remove"
											src="/img/blue-cross.svg"
											alt=""
										/>
									</div>
								</div>
							</template>
						</draggable>
					</div>
				</div>
			</div>
			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import { formateAndOrderDate } from '../../../utils'
import SliderEvaluation from '../../Admin/SliderEvaluation.vue'
import PopUpWrapper from '../PopUpWrapper.vue'
import draggable from "vuedraggable"
import { allActivityEventsFilterService } from '../../../services/activityEventsService'

export default {
	components: {
		draggable,
		PopUpWrapper,
		SliderEvaluation
	},
	props: {
		questionLength: Number,
		questions: Array
	},
	data() {
		return {
			isExtended: null,
			url: Config.backend_url,
			dragging: false,
			enabled: true,
			form: {
				name: '',
				classes: [],
				index: 0,
				currency: null,
				double_column: false
			},
			classes: [],
			errors: {},
			courses: [],
			organizedQuestions: []
		}
	},

	methods: {
		removeQuestion(e) {
			this.$emit('removeQuestion', e)
			this.organizedQuestions = this.organizedQuestions.filter(x => x.id !== e)

		},
		extend(id) {
			if (this.isExtended == id) {
				this.isExtended = null
			} else {
				this.isExtended = id
			}
		},
		async allCourses() {
			let classes = []

			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}
			if (this.$store.state.selectedOccurrence) {
				this.form.classes = this.$store.state.selectedOccurrence.classId
				const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
				this.form.currency = occurrenceId
			}

		},
		updateInput() {
			this.errors.name = null
		},
		updateSelect() {
			this.errors.classe = null
		},
		close() {
			this.$emit('closed')
		},
		submit() {
			if (this.form.name == '') {
				this.errors.name = 'Veuillez ajouter un nom à votre ressource'
			}
			if (this.form.classes.length == 0) {
				this.errors.classe = 'Veuillez ajouter une classe à votre ressource'
			}
			if (this.form.name !== '' && this.form.classes.length !== 0) {
				this.$emit('organizeQuestions', this.organizedQuestions)
				this.$emit('submit', this.form)
			}
		},
		checkIndex(e) {
			this.form.index = e
		},
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},

	created() {
		this.allCourses()
		this.organizedQuestions = [...this.questions]
	},


}
</script>

<style>
</style>
