<template>
	<section class="tile-game-container" :class="tileGame.theme">
		<div class="logo-container">
			<router-link
				v-if="tileGame.class_id"
				class="logo"
				:to="{
					name: 'ClassResources',
					params: { id: tileGame.class_id },
				}"
			>
				<img src="/img/game/tiles/tile-game-logo.svg" alt="" />
			</router-link>
		</div>
		<div class="establishment-container">
			<p v-if="exos[0]" class="logo">
				{{ tileGame.class_name }}
			</p>
		</div>
		<router-link :to="{ name: 'TileRules' }">
			<img
				class="info-rock"
				:class="tileGame.theme"
				:src="getRockImg()"
				alt=""
			/>
		</router-link>
		<router-link
			:to="{
				name: 'TileGameLeaderBoard',
				params: { id: this.$route.params.id },
			}"
		>
			<img :class="tileGame.theme" class="cup" :src="getCupImg()" alt=""
		/></router-link>

		<div class="grid-container">
			<router-link
				v-for="item in 25"
				:key="item"
				class="hexagon-container"
				:class="tileGame.theme"
				:to="checkIfQuestion(item)"
			>
				<img
					:class="[getImg(item + 5), tileGame.theme]"
					:src="randomImg(item + 5)"
					alt=""
				/>
				<div class="hexagone">
					<div class="top">
						<div :class="tileGame.theme" class="face"></div>
						<div :class="tileGame.theme" class="face"></div>
						<div :class="tileGame.theme" class="face"></div>
					</div>

					<div class="side-faces">
						<div :class="tileGame.theme" class="side"></div>
						<div :class="tileGame.theme" class="side"></div>
						<div :class="tileGame.theme" class="side"></div>
					</div>
				</div>
			</router-link>
		</div>
		<div
			@click="isPopUpCreateTeamVisible = !isPopUpCreateTeamVisible"
			class="team-indicator-container"
		>
			<div
				:style="`--color:${team.color}`"
				class="team"
				v-for="team in teams"
				:key="team.id"
			>
				<p>{{ team.points }}</p>
			</div>
			<div class="team plus">
				<p>+</p>
			</div>
		</div>
		<pop-up-create-teams
			v-if="isPopUpCreateTeamVisible"
			@close="isPopUpCreateTeamVisible = !isPopUpCreateTeamVisible"
			@deleteTeam="deleteTeam"
			@createTeam="createTeam"
			@getTeams="getTeams"
			:teams="teams"
		/>
	</section>
</template>

<script>
import PopUpCreateTeams from '../../../../PopUp/SeriousGame/TileGame/PopUpCreateTeams.vue'
import { allTileGameContentOrderFilterService } from '../../../../../services/tileGameContentsOrderService'
import { getTileGameByIdService, updateTileGameService } from '../../../../../services/tileGamesService'
import { allTileGameTeamsFilterService } from '../../../../../services/tileGameTeamsService'

export default {
	components: { PopUpCreateTeams },
	data() {
		return {
			tileGame: {},
			exos: [],
			theme: {},
			imgs: [
				'timer.svg',
				'boomerang.svg',
				'interogation.svg',
				'puzzle.svg',
				'target.svg',
			],
			fullScreenVisible: false,
			teams: [],
			initialTeamLength: 0,
			teamsLoaded: false,
			isPopUpCreateTeamVisible: false,
		}
	},
	methods: {
		checkIfQuestion(item) {
			const id = this.getId(item + 5)
			if (id) {
				return {
					name: 'TileGameQuestion',
					params: { id: id }
				}
			}
			else {
				return ''
			}
		},
		deleteTeam(id) {
			this.teams = this.teams.filter(x => x.id !== id)
		},
		createTeam(team) {
			this.teams.push(team)
		},
		getId(e) {
			let exo = this.exos.find(x => x.pos == e)
			if (exo) {

				return exo.id
			}
		},
		getRockImg() {
			let img = `/img/game/tiles/theme/${this.tileGame.theme}/info-rock.png`
			return img
		},
		getCupImg() {

			let img = `/img/game/tiles/theme/${this.tileGame.theme}/cup.png`
			return img
		},
		getImg(e) {
			let exo = this.exos.find(x => x.pos == e)
			if (exo) {
				let img
				switch (exo.tile_game_type_name) {
					case 'Flash':
						img = 'flash'
						break
					case 'OneShot':
						img = 'one-shot'
						break
					case 'Enigma':
						img = 'enigma'
						break
					case 'Boomerang':
						img = 'boomerang'
						break
					case 'HitOrMiss':
						img = 'hit-or-miss'
						break
				}
				return img
			}
			else {
				return
			}
		},
		randomImg(e) {
			let exo = this.exos.find(x => x.pos == e)
			if (exo) {
				let img = `/img/game/tiles/theme/${this.tileGame.theme}/`
				switch (exo.tile_game_type_name) {
					case 'Flash':
						img += this.imgs[0]
						break
					case 'OneShot':
						img += this.imgs[4]
						break
					case 'HitOrMiss':
						img += this.imgs[2]
						break
					case 'Boomerang':
						img += this.imgs[1]
						break
					case 'Enigma':
						img += this.imgs[3]
						break
				}
				return img
			}
			else {
				return
			}
		},
		async getTileGameContentOrder() {
			const filter = `?tile_game_id=${this.$route.params.id}`
			this.exos = await allTileGameContentOrderFilterService(filter)
			if (this.exos.length == 0) {
				this.setFinished()
			}

		},
		async getTileGame() {
			this.tileGame = await getTileGameByIdService(this.$route.params.id)
		},
		async getTeams() {

			const filter = `?tile_game_id=${this.$route.params.id}`
			this.teams = await allTileGameTeamsFilterService(filter)
			this.teams = this.teams.sort((a, b) => (a.points < b.points ? 1 : -1))
			if (this.teams.length == 0 && !this.$store.state.tileGameTeamsDisabled) {
				this.isPopUpCreateTeamVisible = true
			}
		},

		setFinished: async function () {
			this.tileGame.finished = true
			await updateTileGameService(this.$route.params.id, this.tileGame)
			this.$router.push({
				name: 'TileGameLeaderBoard',
				params: { id: this.$route.params.id }
			})
		},
	},

	async mounted() {
		await this.getTileGame()
		await this.getTeams()
		await this.getTileGameContentOrder()
		const now = new Date()
		const current = String(now.getHours())
		if (localStorage.time !== current) {
			this.fullScreenVisible = true
			localStorage.time = current
		}
	},
}
</script>

<style>
</style>
