<template>
	<pop-up-wrapper @close="close()">
		<div class="pop-up-explaination">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="intro">
				Ces activités sont à vidéo projeter à la classe. L'objectif est
				de donner du sens à des concepts mathématiques abstraits.
			</p>
			<p class="subtitle">Activité d'introduction (clé en main)</p>
			<p>
				Présentée à l'entrée en classe, ne doit pas excéder 10-12 min
				(brève introduction du chapitre, repères historiques, utilités,
				application diagnostique rapide)(en général entre 5 et 10
				slides)
			</p>
			<p class="subtitle">Activité de motivation (clé en main)</p>
			<p>
				Présentée à l'entrée en classe généralement, ne doit pas excéder
				10-12 min (entre 3 et 5 questions de difficulté croissante sur
				le chapitre étudié, avec une même thématique pour toutes les
				questions)(en générale entre 4 et 6 slides).
			</p>
			<p>
				Attention à ne pas surcharger les slides, les textes doivent
				être courts, les questions d'application aussi. Ne pas
				s'attacher à un trop grand formalisme au risque de complexifier
				pour l'élève. Au besoin, l'enseignant pourra détailler oralement
				à la classe. Concernant les figures, joindre le fichier au
				format compressé jpeg. Vous pouvez laisser des remarques pour
				les designers (idées de visuel, ...)
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	methods: {
		close() {
			this.$emit('close')
		},
	}
}
</script>

<style>
</style>
