
<template>
	<div class="slide-container">
		<title-container
			@remove-slide="removeSlide"
			:title="`Page ${index + 1}`"
			:editable="editable"
		/>
		<div class="form-container">
			<div class="title-img-container">
				<div class="input-container">
					<label for="">Titre diapo</label>
					<input type="text" v-model="form.title" />
				</div>
				<div class="input-container">
					<label for="">Texte</label>
					<textarea
						v-model="form.text"
						name=""
						id=""
						cols="30"
						rows="10"
					></textarea>
				</div>
				<div v-if="editable && form.images" class="img-container">
					<p>Ajouter des images</p>
					<div class="img-wrapper">
						<div class="generated-img-container">
							<div
								class="img-delete-wrapper"
								v-for="(image, index) in form.images"
								:key="image"
							>
								<img
									class="uploaded-img"
									:src="image.img"
									alt="Uploaded Image"
								/>
								<div
									class="delete"
									@click="removeImg(index, image.index)"
								>
									<img src="/img/trash.png" alt="" />
								</div>
							</div>
						</div>
						<label
							v-if="form.images.length < 3"
							class="plus-container"
							:for="`file${index}`"
						>
							<img src="/img/super-admin/plus.svg" alt="" />
						</label>

						<input
							v-if="form.images.length < 3"
							@change="handleImageUpload"
							:id="`file${index}`"
							type="file"
							name=""
						/>
					</div>
				</div>

				<div v-else class="img-container">
					<div class="img-wrapper">
						<div
							class="generated-img-container preview"
							:ref="`imgContainer${index}`"
						></div>
					</div>
				</div>
			</div>
			<div class="text-designer-container">
				<div class="input-container">
					<label for="">Notes pour le designer</label>
					<textarea
						v-model="form.designer_note"
						name=""
						id=""
						cols="30"
						rows="7"
					></textarea>
				</div>
				<div class="input-container">
					<label for="">Notes pour l'enseignant</label>
					<textarea
						v-model="form.teacher_note"
						name=""
						id=""
						cols="30"
						rows="7"
					></textarea>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { authenticatedApiFetch, authenticatedImageFetch } from '../../../utils'
import TitleContainer from './TitleContainer.vue'
export default {
	components: {
		TitleContainer,
	},
	props: {
		index: {
			type: Number,
			required: true
		},
		slide: {
			type: Object,
		},
		editable: {
			type: Boolean,
			default: false
		},
		creating: {
			type: Boolean,
			default: false
		},

	},
	data() {
		return {
			form: {
				title: '',
				text: '',
				designer_note: '',
				teacher_note: '',
				images: [

				]
			},

		}
	},
	methods: {
		async removeImg(index, imgIndex = null) {
			const answer = window.confirm("Attention, supprimer l'image est definitif !")
			if (answer) {
				this.form.images.splice(index, 1)


				if (this.editable) {
					await authenticatedApiFetch(`/proposed-key-slides/${this.slide.id}/removeImg/?field=image_url_${imgIndex}`, { method: 'GET' })
						.then(res => res.json())
				}
			}
		},
		handleImageUpload(event) {
			const file = event.target.files[0]
			const possibility = [1, 2, 3]
			let alreadyFilled = []
			this.form.images.forEach(img => {
				alreadyFilled.push(img.index)
			})



			let index = possibility.find(n => !alreadyFilled.includes(n))

			if (file) {
				this.form.images.push(
					{
						file: file,
						img: URL.createObjectURL(file),
						index: index
					}
				)
			}
		},
		async generateImg(imgUrl, ref) {
			await authenticatedImageFetch(imgUrl, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						const img = new Image()
						img.src = e.target.result
						img.className = 'uploaded-img'
						ref.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		async generateImg2(imgUrl, index) {
			await authenticatedImageFetch(imgUrl, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						const img = new Image()

						img.src = e.target.result
						// img.className = 'uploaded-img'
						// ref.append(img)
						this.form.images.push(
							{
								file: 'aled',
								img: img.src,
								index: index
							}
						)
					}
					reader.readAsDataURL(blob)
				})
		},
		async removeSlide() {
			if (this.creating) {
				this.$emit('removeSlide', this.slide.id)
			}
			else if (this.editable) {
				await authenticatedApiFetch(`/proposed-key-slides/${this.form.id}/`, { method: 'DELETE' })
				this.$emit('removeSlide', this.form.id)
			}
		}
	},

	mounted() {
		if (this.slide) {
			this.form = this.slide
			this.form.images = []

			for (let i = 1; i < 4; i++) {
				if (this.slide[`image_url_${i}`]) {
					if (!this.editable) {
						this.generateImg(this.slide[`image_url_${i}`], this.$refs[`imgContainer${this.index}`])
					}
					else {
						this.generateImg2(this.slide[`image_url_${i}`], i)
					}
				}
			}


		}
	},
}
</script>

<style>
</style>
