<template>
	<section>
		<h1>
			<img src="/img/payment/expresso-icon.svg" alt="" />Activités
			Expresso<img
				class="info"
				@click="showGuide"
				src="/img/info-bulle.svg"
				alt=""
			/>
		</h1>

		<filter-wrapper
			@setFilterVisible="setFilterVisible"
			@updateFilter="updateFilter"
			:showFilter="false"
		/>

		<p class="select-level" v-if="filter.level == ''">
			Veuillez sélectionner un niveau
		</p>

		<div class="activite-container">
			<clef-container
				v-for="key in this.activities"
				:key="key.id"
				:index="key.id"
				:title="key.title"
				:description="key.description"
				:image1="key.image1"
				:image2="key.image2"
				:image3="key.image3"
				:image4="key.image4"
				:image5="key.image5"
				:image6="key.image6"
				:image7="key.image7"
				:image8="key.image8"
				:image9="key.image9"
				:image10="key.image10"
				:guest_visible="key.guest_visible"
			/>

			<no-content
				v-if="activities.length == 0 && filter.level !== null"
				:level="filter.level"
				:chapter="filter.chapter"
				:type="'PACKAGE'"
			/>
		</div>
		<pagination-component
			ref="paginate"
			v-if="pages !== null && pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="changeFilter"
		/>

		<pop-up-guide
			v-if="this.$store.state.showKey"
			:text="'Vous pouvez sélectionner ici des activités clés en main.<br /> Il s\'agit d\'activités d\'introduction ou de motivation directement vidéo-projetables à votre classe. '"
			:type="'key'"
			@close="this.$store.state.showKey = !this.$store.state.showKey"
		/>
	</section>
</template>

<script>
import { allkeysFilterService } from '../../../services/keysService'
import PopUpGuide from '../../PopUp/Guide/PopUpGuide.vue'
import PaginationComponent from '../../Utils/PaginationComponent.vue'
// import FilterComponent from './Filter.vue'
import FilterWrapper from './FilterWrapper.vue'
import ClefContainer from './KeyContainer.vue'
import NoContent from './NoContent.vue'

export default {
	components: { ClefContainer, PopUpGuide, NoContent, PaginationComponent, FilterWrapper },
	data() {
		return {
			isFilterVisible: false,

			expanded: false,
			opened: null,
			type: '',
			keys: [],
			activities: {
			},
			profiles: [],
			chapters: [],
			levels: [],
			questions: [],
			sequences: [],
			chapterSelected: null,
			levelsColor: [
				{
					bg: '#FFE58E',
					color: '#8D4305'
				},
				{
					bg: '#B2E9FF',
					color: '#005272'
				},
				{
					bg: '#F8DAE9',
					color: '#D63384'
				},
				{
					bg: '#CEFFEA',
					color: '#00864D'
				},
				{
					bg: '#DCCBFF',
					color: '#200060'
				},
				{
					bg: '#E8FFC3',
					color: '#3E6200'
				},
				{
					bg: '#A7AAFF',
					color: '#000246'
				},
			],
			filter: {
				chapter: null,
				level: null,
				search: '',
				offset: 0,
				subject: null,
			},
			currentPage: 0,
			pages: null,
			count: null,
			levelSelected: null,
			selectedChapter: null,
			guideVisible: false,
			guideVisibleOnClick: false,
			actualFilter: ''
		}
	},


	created() {
		this.$store.state.showKey = false
	},
	methods: {
		setFilterVisible() {
			this.isFilterVisible = !this.isFilterVisible
			if (this.isFilterVisible) {
				document.body.style.overflow = 'hidden'
			}
			else {
				document.body.style.overflow = 'visible'
			}
		},
		updateFilter(data) {
			this.filter.level = data.level
			this.filter.chapter = data.chapter
			this.filter.type = data.type
			this.filter.search = data.search
			this.filter.subject = data.subject
			this.changeFilter()
		},
		closePopUpGuide(e) {
			this.$store.commit({
				type: 'updateDontShowAgain',
				guideType: 'key',
				value: e
			})
			this.guideVisible = false
			this.guideVisibleOnClick = false
		},
		showGuide() {
			this.$store.state.showKey = !this.$store.state.showKey
		},
		changeType() {
			this.currentPage = 0
			this.changeFilter()
		},
		changeChapter(id) {
			if (id == this.chapterSelected) {
				this.filter.chapter = null
				this.chapterSelected = null
				this.changeFilter()
			}
			else {
				this.filter.chapter = id
				this.changeFilter()
			}
		},

		changeLevel() {
			this.filter.chapter = ''
			this.changeFilter()
		},
		changePage() {

		},

		changeFilter(e) {
			const search = this.filter.search ? `&search=${this.filter.search}` : '&search='
			const chapter = this.filter.chapter ? `chapter=${this.filter.chapter}` : ''
			const level = this.filter.level ? `&level=${this.filter.level}` : '&level='
			const type = this.filter.type ? `&type=${this.filter.type}` : '&type='
			const subject = this.filter.subject ? `&subject=${this.filter.subject}` : '&subject='
			if (typeof e == 'number') {

				this.filter.offset = 20 * e
				this.currentPage = e
			}
			else {
				this.currentPage = 0
				this.filter.offset = 0
			}

			this.actualFilter = `?${chapter}${level}${type}${subject}&offset=${this.filter.offset}${search}`
			this.getKeys()
		},
		sequenceChapters(e) {
			return this.$store.state.chapters.filter(x => x.sequence == e)
		},

		openChapter(e) {
			if (this.opened == e) {
				this.opened = null
			}
			else {

				this.opened = e
			}

		},
		getDifficulty(e) {
			if (this.$store.state.difficulties.length !== 0) {
				return this.$store.state.difficulties.find(x => x.id == e).name
			}
		},
		getType(e) {
			if (this.$store.state.types.length !== 0) {
				return this.$store.state.types.find(x => x.id == e).name
			}
		},
		getChapter(e) {
			if (this.$store.state.chapters.length !== 0) {
				return this.$store.state.chapters.find(x => x.id == e).name
			}
		},
		async getKeys() {
			const res = await allkeysFilterService(this.actualFilter)

			this.activities = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			if (this.pages < this.currentPage) {
				this.currentPage = 0
			}

		},

		activitiesFiltered(id) {
			let filtered = []
			filtered = this.activities.Activity.filter(x => x.chapter == id)
			return filtered
		},
		keysFiltered(id) {
			let filtered = []
			filtered = this.activities.Key.filter(x => x.chapter == id)
			return filtered
		},
	},
	computed: {
		filteredSequences() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.filter.level)
				return sequences
			}
			else {
				return ''
			}
		}
	},
}
</script>

<style>
</style>
