import { authenticatedApiFetch } from '../utils'


export const allFoldersForClassService = async (classId, parentFolder = '') => {
    const res = await authenticatedApiFetch(`/folders/?classes=${classId}&parent_folder_id=${parentFolder}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const getFolderService = async (id) => {
    const res = await authenticatedApiFetch(`/folders/${id}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
