<template>
	<h1>Modifier un chapitre</h1>
	<form @submit.prevent="submit" class="form-container">
		<div class="input-container">
			<label for="Difficulty">Nom</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Name"
				placeholder="Name"
			/>
		</div>
		<div class="input-container">
			<label for="Difficulty">Sequence</label>
			<select id="classes" v-model="form.sequence">
				<option
					v-for="sequence in this.$store.state.sequences"
					:key="sequence"
					:value="sequence.id"
				>
					{{ sequence.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Order">Order</label>
			<input
				type="number"
				v-model="form.order"
				id="Order"
				placeholder="{{ order }}"
			/>
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			form: {
				name: '',
				sequence: '',
				order: 0,
			},
			sequences: [],
			errors: '',
			status: ''
		}
	},
	methods: {
		submit() {
			this.$store.dispatch('updateChapter', this.form)
				.then(() => {
					this.$router.push({ name: 'SuperAdminAllChapters' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			authenticatedApiFetch(`/chapters/${this.$route.params.id}/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.form = res
				})
		},
	},
	created() {
		this.getChapter()
	},
}
</script>

<style>
</style>
