<template>
	<pop-up-wrapper>
		<div class="pop-up-remove">
			<p class="title">{{ titre }}</p>
			<p class="paragraph">{{ text }}</p>
			<button @click="deleteThing" class="submit delete">
				{{ btn }}
			</button>
			<button @click="close" class="submit white">Annuler</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	props: {
		id: Number,
		redirect: String,
		titre: String,
		text: String,
		btn: String,
		path: String,
		delete: Boolean,
		customId: Number,
	},
	data() {
		return {
			user: ''
		}
	},
	methods: {
		close() {
			this.$emit('closed')
		},
		deleteThing() {
			let path = this.path
			if (this.path == 'keys') {
				path = 'activities'
			}
			authenticatedApiFetch(`/${path}/${this.id}/`, { method: 'DELETE' })
				.then(() => {
					this.$emit('closed')
					this.$emit('remove', this.customId)
				})
				.catch((error) => {
					console.error(error)
				})
		},
	},
}
</script>

<style>
</style>
