<template>
	<section class="tile-game-leaderboard-container">
		<h1>TABLEAU DES SCORES</h1>
		<div class="tile-game-leaderboard-wrapper">
			<div v-if="teams.length !== 0" class="score-wrapper">
				<div
					class="score"
					v-for="(team, index) in teams"
					:key="team.id"
				>
					<div class="leaderboard">
						<img :src="getImg(index + 1)" />
						<p>{{ index + 1 > 3 ? index + 1 : "" }}</p>
					</div>
					<div class="indicator-name-container">
						<span
							:style="`--color:${team.color}`"
							class="indicator"
						></span>
						<p class="name">{{ team.name }}</p>
						<p class="points">{{ team.points }}</p>
					</div>
				</div>
			</div>
			<div class="score-wrapper" v-else-if="isLoading">
				<loader />
			</div>
			<div v-else class="no-teams">
				<p>Aucune équipe enregistrée</p>
				<button
					@click="
						isPopUpCreateTeamVisible = !isPopUpCreateTeamVisible
					"
					class="btn add-team"
				>
					Ajouter une équipe
				</button>
			</div>
			<button class="go-back" @click="redirect">Retour</button>
			<div class="img-container">
				<img src="/img/game/tiles/leaderboard.svg" alt="" />
			</div>
		</div>
		<pop-up-create-teams
			v-if="isPopUpCreateTeamVisible"
			@close="isPopUpCreateTeamVisible = !isPopUpCreateTeamVisible"
			@deleteTeam="deleteTeam"
			@createTeam="createTeam"
			@getTeams="getTeams"
			:teams="teams"
		/>
	</section>
</template>

<script>
import { getTileGameByIdService } from '../../../../../services/tileGamesService'
import { allTileGameTeamsFilterService } from '../../../../../services/tileGameTeamsService'
import Loader from '../../../../Loader.vue'
import PopUpCreateTeams from '../../../../PopUp/SeriousGame/TileGame/PopUpCreateTeams.vue'

export default {
	components: {
		Loader,
		PopUpCreateTeams,
	},
	data() {
		return {
			teams: [],
			tileGame: {},
			isLoading: false,
			isPopUpCreateTeamVisible: false
		}
	},

	methods: {
		getImg(index) {
			const basePath = '/img/game/tiles/'
			let img
			switch (index) {
				case 1:
					img = basePath + 'first-place.svg'
					break
				case 2:
					img = basePath + 'second-place.svg'
					break
				case 3:
					img = basePath + 'third-place.svg'
					break
				default:
					img = basePath + 'other-place.svg'
					break
			}
			return img
		},
		deleteTeam(id) {
			this.teams = this.teams.filter(x => x.id !== id)
		},
		createTeam(team) {
			this.teams.push(team)
		},
		redirect() {
			if (this.tileGame.finished) {
				this.$router.push({ name: 'ClassResources', params: { id: this.tileGame.class_id } })
			}
			else {
				this.$router.go(-1)
			}
		},
		async getTileGame() {
			this.tileGame = await getTileGameByIdService(this.$route.params.id)
		},
		async getTeams() {
			const filter = `?tile_game_id=${this.$route.params.id}`
			const res = await allTileGameTeamsFilterService(filter)
			this.teams = res.sort((a, b) => (a.points < b.points ? 1 : -1))
		},
	},

	async created() {
		this.isLoading = true
		await this.getTileGame()
		await this.getTeams()
		this.isLoading = false
	},
}
</script>

<style>
</style>
