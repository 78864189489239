<template>
	<div class="widget-wrapper">
		<h2>Mes liens</h2>
		<div class="tools-wrapper link">
			<div class="widget" v-for="link in links" :key="link.id">
				<img
					@click="deleteLink(link.id)"
					class="delete"
					src="/img/close.svg"
					alt=""
				/>
				<a class="link" target="_blank" :href="link.link">
					<img
						class="favicon"
						:src="getFavicon(link.favicon)"
						alt=""
					/>{{ link.title }}</a
				>
			</div>
			<button
				@click="isPopUpVisible = !isPopUpVisible"
				class="add-widget"
			>
				+
			</button>
		</div>
	</div>
	<pop-up-new-link
		@close="isPopUpVisible = !isPopUpVisible"
		@refreshLink="getLinks"
		v-if="isPopUpVisible"
	/>
</template>

<script>
import { getWebsiteIcon } from '../../../../utils'
import { allLinksForUserService, deleteLinkService } from '../../../../services/linksService'
import PopUpNewLink from '../../../PopUp/Dashboard/PopUpNewLink.vue'

export default {
	components: { PopUpNewLink },
	data() {
		return {
			isPopUpVisible: false,
			links: []
		}
	},

	methods: {
		getWebsiteIcon(link) {
			return getWebsiteIcon(link)
		},
		getFavicon(link) {
			const result = this.getWebsiteIcon(link)
			return result
		},
		async getLinks() {
			this.links = await allLinksForUserService(this.$store.state.user.user)

		},
		async deleteLink(id) {
			if (confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
				await deleteLinkService(id)
				this.links = this.links.filter(x => x.id !== id)
			}
		},
	},

	created() {
		this.getLinks()
	},

}
</script>

<style>
</style>
