<template>
	<pop-up-wrapper>
		<div :style="pos" class="pop-up-choose-resource">
			<router-link :to="{ name: 'MyResourcesAddQuestion' }">
				Questions Flash</router-link
			>
			<router-link :to="{ name: 'MyResourcesAddExpresso' }"
				>Activités Expresso</router-link
			>
			<!-- <router-link :to="{ name: 'MyResourcesAddExercise' }"
				>Exercices Classico</router-link
			> -->
			<router-link :to="{ name: 'MyResourcesAddQcm' }"
				>QCM</router-link
			>
		</div>
	</pop-up-wrapper>
</template>


<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		x: Number,
		y: Number
	},
	data() {
		return {
			pos: '',
		}
	},
	created() {
		let style = `left: calc(${this.x}px - 15vw); top: ${this.y}px;`
		this.pos = style
	},
}
</script>

<style>
</style>
