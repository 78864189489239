import { authenticatedApiFetch } from '../utils'


export const getClassesService = async (teacherId) => {
    const res = await authenticatedApiFetch(`/classes/?teacher=${teacherId}`, { method: "GET" })
        .then((response) => response.json())
        .catch((error) => console.error(error))
    return res
}

export const getClassService = async (id) => {
    const res = await authenticatedApiFetch(`/classes/${id}/`, { method: "GET" })
        .then((response) => response.json())
        .catch((error) => console.error(error))
    return res
}

export const createClassesService = async (data) => {
    const res = await authenticatedApiFetch(`/classes/`, { method: 'POST', body: JSON.stringify(data) })
        .then((response) => response.json())
        .catch((error) => console.error(error))
    return res
}

export const updateClassesService = async (data) => {
    const res = await authenticatedApiFetch(`/classes/${data.id}/`, {
        method: 'PUT',
        body: JSON.stringify(data)
    })
        .then((response) => response.json())
        .catch((error) => console.error(error))
    return res
}


export const deleteClassesService = async (id) => {
    const res = await authenticatedApiFetch(`/classes/${id}/`, { method: "DELETE" })
        .catch((error) => console.error(error))
    return res
}
