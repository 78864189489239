<template>
	<section class="verification-email"></section>
</template>

<script>
import Config from '../config'
export default {
	data() {
		return {
			text: 'Déconnexion en cours...'
		}
	},

	methods: {
		async logout() {
			this.$store.dispatch('logout')

			location.replace(`${Config.landing_url}/auth/reset-token`)
		},
	},


	created() {
		this.logout()
	},
}
</script>

<style>
</style>
