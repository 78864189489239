import { authenticatedApiFetch } from '../utils'



export const allWidgetsByUserService = async (userId, type) => {
    const res = await authenticatedApiFetch(`/widgets/?teacher=${userId}&type=${type}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allWidgetsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/widgets/${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
export const createWidgetService = async (data) => {
    const res = await authenticatedApiFetch(`/widgets/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteWidgetService = async (id) => {
    const res = await authenticatedApiFetch(`/widgets/${id}/`, { method: 'DELETE' })
    return res
}
