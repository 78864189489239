<template>
	<section class="class-stats">
		<h1>Activités clés en main</h1>

		<div v-if="isLoading">
			<loader-component />
		</div>
		<div class="table-container" v-else>
			<table>
				<tr>
					<th>Titre</th>
					<th>Chapitre</th>
					<th>Sequence</th>
					<th>Niveau</th>
					<th>Nombre Activités</th>
				</tr>
				<tr v-for="key in this.keys" :key="key.id">
					<td>
						{{ key.title }}
					</td>
					<td>
						<p>
							{{ key.chapter_name }}
						</p>
					</td>
					<td>
						<p>
							{{ key.sequence_name }}
						</p>
					</td>
					<td>
						<p>
							{{ key.level_name }}
						</p>
					</td>
					<td>
						<p>
							{{ key.activities_count }}
						</p>
					</td>
				</tr>
			</table>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import LoaderComponent from '../../Loader.vue'
export default {
	components: { LoaderComponent },


	data() {
		return {
			activities: [],
			keys: [],
			isLoading: true,
		}
	},

	methods: {

		async keyStats() {
			this.isLoading = true
			const res = await authenticatedApiFetch('/stat-key/?ordering=-activities_count')
				.then(res => res.json())
			this.keys = res
			this.isLoading = false
		},

	},
	created() {
		this.keyStats()
	},
}
</script>

<style>
</style>
