<template>
	<section class="student-place-flash-question-container">
		<div class="title-container">
			<h1>Questions Flash</h1>
		</div>

		<div class="flash-question-wrapper">
			<key-question-container 
				v-for="key in flashQuestions"
				:key="key.id"
				:id="key.id"
				:activities="key.activities"
				:name="key.name"
			/>
			
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt } from '../../utils'
import KeyQuestionContainer from './KeyQuestionContainer.vue'
export default {
	components: { KeyQuestionContainer },
	data() {
		return {
			flashQuestions: [],
			classId: null
		}
	},
	methods: {
		async getLessons() {
			const res = await axios.get(`${Config.backend_url}/api/v1/activities/?classes=${this.classId}&visible_for_student=true`)
			this.flashQuestions = res.data.results
			this.flashQuestions = this.flashQuestions.filter(x => x.type == 'Activité rituelle' || x.type == 'PACKAGE')
		}
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getLessons()

	},
}
</script>

<style>
</style>
