<template>
	<section class="student-place-exercise-sheet-container">
		<div class="title-container">
			<h1>Evaluations</h1>
		</div>

		<div class="exercise-sheet-wrapper">
			<button
				class="exercise-sheet"
				v-for="exerciseSheet in exerciseSheets"
				:key="exerciseSheet.id"
				@click="showPopUpPdf(exerciseSheet.id, exerciseSheet.type)"
			>
				{{ exerciseSheet.title }}
			</button>
			<key-question-container
				v-for="key in 0"
				:key="key.id"
				:id="key.id"
				:activities="key.activities"
				:name="key.name"
			/>
		</div>
		<div class="qcms-container">
			<QCMContainer v-for="qcm in qcms" :key="qcm.id" :qcm="qcm" :is-corrected="checkIfCorrected(qcm.id)" @showSoluce="showPopUpQcmSoluce2(qcm.id)" />
		</div>
		<PopUpQCMSoluce :herited-questions="heritedQuestions" v-if="isPopUpQcmSoluceVisible" @close="isPopUpQcmSoluceVisible = !isPopUpQcmSoluceVisible" />
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { authenticatedStudentApiFetch, decrypt } from '../../utils'
import KeyQuestionContainer from './KeyQuestionContainer.vue'
import PopUpQCMSoluce from '../PopUp/QCM/PopUpQCMSoluce.vue'
import QCMContainer from './QCMContainer.vue'
import { filteredSharedResourcesService } from '../../services/sharedResourcesService'
import { getQCMService } from '../../services/qcmServices'
export default {
	components: { KeyQuestionContainer,PopUpQCMSoluce,QCMContainer },
	data() {
		return {
			exerciseSheets: [],
			qcms: [],
			classId: null,
			isPopUpPdfVisible: false,
			selectedRessourceId: null,
			selectedRessourceType: null,
			correctedQcms:[],
			heritedQuestions:[],
			isPopUpQcmSoluceVisible: false,
		}
	},
	methods: {
		checkIfCorrected(qcmId){
			return this.correctedQcms.some(x => x.corrected_qcm_id == qcmId)
		},
		showPopUpQcmSoluce2(qcmId){
			this.heritedQuestions = this.correctedQcms.find(x => x.corrected_qcm_id == qcmId).questions_with_answers
			console.log(this.heritedQuestions)
			
			this.isPopUpQcmSoluceVisible = !this.isPopUpQcmSoluceVisible
		},
		showPopUpQcmSoluce(questions){
			this.heritedQuestions = questions
			console.log(this.heritedQuestions)
			this.isPopUpQcmSoluceVisible = !this.isPopUpQcmSoluceVisible
		},
		async getEvaluationSheets() {
			const res = await axios.get(`${Config.backend_url}/api/v1/exercise-sheets/?classes=${this.classId}&visible_for_student=true`)
			this.exerciseSheets = res.data.results
			this.exerciseSheets = this.exerciseSheets.filter(x => x.type == 'EVALUATION')
		},
		async qcmForTeacher(){
			const filter = `class_id=${this.classId}&visible_for_student=true`
			const sharedResource = await filteredSharedResourcesService(filter)

			for(const resource of sharedResource){
				if(resource.qcm_id){
					const res  = await getQCMService(resource.qcm_id)
					
					this.qcms.push(res)
				}
				else if(resource.corrected_qcm_id){
					
					this.correctedQcms.push(resource)
				}	
			}
		},

		async qcmForStudent(){
			const sharedResource = await authenticatedStudentApiFetch('/shared-resources-student/')
				.then(res => res.json())

			for(const resource of sharedResource){
				if(resource.qcm_id){
					const res = await authenticatedStudentApiFetch( `/qcm-students/${resource.qcm_id}/`, {method: 'GET'})
						.then(res => res.json())
					this.qcms.push(res)
				}
				else if(resource.corrected_qcm_id){
					
					this.correctedQcms.push(resource)
				}
			}

			for(const qcm of this.qcms){
				const res = await authenticatedStudentApiFetch(`/qcm-student-answers/?qcm=${qcm.id}`, {method: 'GET'})
				.then(res => res.json())

				if (res.results.length > 0){
					qcm.isDone = true
				}
			}
		},
		async getQcm(){
			if(localStorage.getItem('userId') !== null){	
				this.qcmForTeacher()
				return
			}
			this.qcmForStudent()	
		},
		showPopUpPdf(id, type) {
			this.isPopUpPdfVisible = !this.isPopUpPdfVisible
			this.selectedRessourceId = id
			this.selectedRessourceType = type
		},
	},

	async created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getEvaluationSheets()
		this.getQcm()
	},
}
</script>

<style>
</style>
