<template>
	<!-- fix la requete qui se relance et le faire proprement -->

	<pop-up-wrapper>
		<form class="pop-up-class-created" @submit.prevent="submit">
			<div class="wrapper">
				<div class="input-container">
					<label for="">Titre</label>
					<input v-model="form.title" type="text" />
				</div>
				<div class="input-container">
					<label for="">Description</label>
					<textarea v-model="form.description" rows="10" />
				</div>
				<div class="input-container">
					<label for=""
						>Attribué à (Maintenez la touche Ctrl pour sélectionner
						plusieurs classes)
					</label>
					<select multiple v-model="form.classes" name="" id="">
						<option
							v-for="classe in this.$store.state.classes"
							:key="classe.id"
							:value="classe.id"
						>
							{{ classe.name }}
						</option>
					</select>
				</div>
				<div class="btn-container">
					<p @click="this.$emit('close')" class="cancel">
						Abandonner
					</p>
					<button class="add">Valider</button>
				</div>
			</div>
		</form>
	</pop-up-wrapper>
</template>

<script>
import { updateKanbanService } from '../../../services/kanbansService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		task: Object
	},



	data() {
		return {
			form: {

			}
		}
	},

	methods: {
		async submit() {
			await updateKanbanService(this.task.id, this.form)
				.then(() => {
					this.$emit('refreshKanban')
					this.$emit('close')
				})
		}
	},
	created() {
		this.form = { ...this.task }
	},
}
</script>

<style>
</style>
