<template>
	<div>
		<p class="student">
			<span> {{ student.name }}</span>
			<img
				v-if="placed"
				@click="showPopUpEditStudent(student)"
				src="/img/class/option.svg"
				alt=""
			/>
			<img
				v-else
				@click="showPopUpEditStudent(student)"
				src="/img/class/option-white.svg"
				alt=""
			/>
		</p>
		<pop-up-student-edit
			@close="isPopUpEditStudentVisible = false"
			@refreshStudent="this.$emit('allStudent')"
			:student="student"
			v-if="isPopUpEditStudentVisible"
		/>
	</div>
</template>

<script>
import PopUpStudentEdit from '../../../PopUp/Class/PopUpStudentEdit.vue'
export default {
	components: { PopUpStudentEdit },
	props: {
		student: Object,
		placed: Boolean
	},
	data() {
		return {
			isPopUpEditStudentVisible: false,
		}
	},

	methods: {
		showPopUpEditStudent() {
			this.isPopUpEditStudentVisible = true
		},

	},
}
</script>

<style>
</style>
