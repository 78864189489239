<template>
	<section>
		<h1>Agenda</h1>
		<div class="classe-container agenda">
			<div class="edit-course-btn-container">
				<button
					@click="isPopUpEditCourseVisible = true"
					class="submit dark"
				>
					Modifier l'emploi du temps
				</button>
			</div>
			<calendar />
		</div>

		<pop-up-edit-course
			v-if="isPopUpEditCourseVisible"
			@close="isPopUpEditCourseVisible = !isPopUpEditCourseVisible"
		/>
	</section>
</template>

<script>
import { allActivitiesService } from '../../../services/activitiesService'
import { allExerciseSheetsService } from '../../../services/exerciseSheetsService'
import { allAgendasService } from '../../../services/agendasService'

import Calendar from './Calendar.vue'
import PopUpEditCourse from '../../PopUp/Agenda/PopUpEditCourse.vue'

export default {
	components: {
		Calendar,
		PopUpEditCourse,
	},
	data() {
		return {
			agendas: [],
			events: [],
			activities: [],
			evaluations: [],
			exerciseSheets: [],
			activityId: null,
			activityType: null,
			isPreviewVisible: false,
			isVisibleGuest: false,
			isPopUpEditCourseVisible: false
		}
	},
	created() {
		this.allActivities()
		this.allExerciseSheets()
		this.allAgendas()
	},
	methods: {
		showActivity(event, e) {
			this.activityId = event.activity || event.evaluation
			this.activityType = event.activity ? 'activity' : 'evaluation'
			this.isPreviewVisible = true
			e.stopPropagation()
		},
		hideActivity() {
			this.activityId = null
			this.isPreviewVisible = false
		},
		async allAgendas() {
			this.agendas = await allAgendasService()
			for (let agenda of this.agendas) {
				let data = {}
				let activity = this.activities.find(x => x.id == agenda.activity) || ''
				let evaluation = this.evaluations.find(x => x.id == agenda.evaluation) || ''
				let exerciseSheet = this.exerciseSheets.find(x => x.id == agenda.exercise_sheet) || ''
				data = {
					activity: agenda.activity,
					evaluation: agenda.evaluation,
					exerciseSheet: agenda.exercise_sheet,
					start: `${agenda.day} ${agenda.debut_hour}`,
					end: `${agenda.day} ${agenda.end_hour}`,
					title: activity.name || evaluation.title || exerciseSheet.title,
					class: 'activity-agenda'
				}
				this.events.push(data)
			}

		},
		async allActivities() {
			this.activities = await allActivitiesService()
		},

		async allExerciseSheets() {
			this.exerciseSheets = await allExerciseSheetsService()
		},

	},


}
</script>

<style>
ol,
li {
	padding: 0;
	margin: 0;
	list-style: none;
}

.calendar-month-header {
	display: flex;
	justify-content: space-between;
	background-color: #fff;
	padding: 10px;
}
</style>
