<template>
	<pop-up-wrapper>
		<form class="pop-up-class-created" @submit.prevent="submit">
			<div class="wrapper">
				<p class="title">Nouveau lien</p>
				<div class="input-container">
					<label for="">Titre</label>
					<input
						list="title"
						placeholder="Titre"
						v-model="form.title"
						@keyup="getData"
						type="text"
					/>
					<datalist id="title">
						<option value="Pronote"></option>
						<option value="Ecole Direct"></option>
						<option value="Kahoot"></option>
						<option value="Genialy"></option>
					</datalist>
				</div>
				<div class="input-container">
					<label for="">Lien</label>
					<input placeholder="Lien" type="text" v-model="form.link" />
				</div>

				<div class="btn-container">
					<p @click="this.$emit('close')" class="cancel">
						Abandonner
					</p>
					<button class="add">Valider</button>
				</div>
			</div>
		</form>
	</pop-up-wrapper>
</template>

<script>
import { createLinkService } from '../../../services/linksService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	data() {
		return {
			form: {
				title: '',
				link: '',
				teacher_id: this.$store.state.user.user,
			}

		}
	},
	methods: {
		getData(e) {
			if (!e.key) {
				const title = e.target.value
				switch (title) {
					case 'Pronote':
						this.form.link = 'https://www.index-education.com/fr/logiciel-gestion-vie-scolaire.php'
						break
					case 'Ecole Direct':
						this.form.link = 'https://www.ecoledirecte.com/'
						break
					case 'Kahoot':
						this.form.link = 'https://kahoot.it/'
						break
					case 'Genialy':
						this.form.link = 'https://app.genial.ly/'
						break
				}
			}
		},
		async submit() {
			if (!this.form.link.includes('https://')) {
				this.form.link = 'https://' + this.form.link
			}
			const data = {
				teacher_id: this.$store.state.user.user,
				title: this.form.title,
				link: this.form.link,
				favicon: this.getFavicon()
			}
			await createLinkService(data)
				.then(() => {
					this.$emit('close')
					this.$emit('refreshLink')
				})
		},
		getFavicon() {
			return `https://s2.googleusercontent.com/s2/favicons?domain_url=${this.form.link}&size=128`
		}
	},
}
</script>

<style>
</style>
