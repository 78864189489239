<template>
	<div class="pop-up-observation-student detailled">
		<table id="detailledPdf">
			<p class="classe-name">{{ getClassName() }}</p>
			<thead>
				<tr>
					<th></th>
					<th class="red">Travail non fait</th>
					<th class="red">Comportement</th>
					<th class="orange">Oubli de matériel</th>
					<th class="green">Participation</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="student in students" :key="student.id">
					<td>
						<p class="student-name">
							{{ student.name }}
						</p>
					</td>
					<td>
						<div class="observation-container">
							<div
								class="observation-wrapper"
								v-for="observation in filteredObservations(
									student.id,
									'Travail non fait'
								)"
								:key="observation.id"
							>
								<p class="date" :style="`color:#FF0000`">
									{{ observation.date }}
								</p>
								<p>{{ observation.comment }}</p>
							</div>
						</div>
					</td>
					<td>
						<div class="observation-container">
							<div
								class="observation-wrapper"
								v-for="observation in filteredObservations(
									student.id,
									'Comportement'
								)"
								:key="observation.id"
							>
								<p :style="`color:#FF0000`">
									{{ observation.date }}
								</p>
								<p>{{ observation.comment }}</p>
							</div>
						</div>
					</td>
					<td>
						<div class="observation-container">
							<div
								class="observation-wrapper"
								v-for="observation in filteredObservations(
									student.id,
									'Oubli de matériel'
								)"
								:key="observation.id"
							>
								<p :style="`color:#FFBA00`">
									{{ observation.date }}
								</p>
								<p>{{ observation.comment }}</p>
							</div>
						</div>
					</td>
					<td>
						<div class="observation-container">
							<div
								class="observation-wrapper"
								v-for="observation in filteredObservations(
									student.id,
									'Participation'
								)"
								:key="observation.id"
							>
								<p :style="`color:#07AC00`">
									{{ observation.date }}
								</p>
								<p>{{ observation.comment }}</p>
							</div>
						</div>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { allObservationsFilterService } from '../../../services/observationsService'
import { allStudentsFilterService } from '../../../services/studentsService'

export default {
	data() {
		return {
			students: [],
			observations: [],
			isLoading: false
		}
	},
	methods: {
		getClassName() {
			return this.$store.getters.class(this.$route.params.id)
		},



		filteredObservations(id, motifName) {
			const motif = this.$store.state.motifs.find(x => x.name == motifName)
			const observations = this.observations.filter(x => x.student_id == id)

			const filteredObservations = observations.filter(x => x.motif == motif.id)
			return filteredObservations
		},

		async getObservations() {
			this.isLoading = true
			const filter = `?classe=${this.$route.params.id}`
			this.observations = await allObservationsFilterService(filter)
			this.isLoading = false
		},
		async allStudent() {
			let classId = this.$route.params.id
			const filter = `?classes=${classId}`
			this.students = await allStudentsFilterService(filter)
		},
	},
	created() {
		this.allStudent()
		this.getObservations()
	},
}
</script>

<style>
</style>
