<template>
	<div class="slider">
		<div id="left" class="arrow-container" @click="prev">
			<span class="arrow"></span>
		</div>
		<div id="right" class="arrow-container" @click="next">
			<span class="arrow"></span>
		</div>
		<div class="slide" v-for="i in [currentSlide]" :key="i">
			<img
				style="width: 100%; object-fit: contain"
				:src="currentImg"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		index: Number
	},
	data() {
		return {
			currentSlide: 0,
			images: [
				'/img/evaluation/cartouche1.jpg',
				'/img/evaluation/cartouche2.jpg',
			],
		}
	},
	methods: {
		next() {
			this.currentSlide += 1
			this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
		},
		prev() {
			this.currentSlide -= 1
			this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
		},
	},
	computed: {
		currentImg() {
			return this.images[Math.abs(this.currentSlide) % this.images.length]
		}
	},
	created() {
		if (this.index !== undefined) {
			this.currentSlide = this.index
		}
		else {
			this.currentSlide = 0
		}
		this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
	},
}
</script>
