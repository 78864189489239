<template>
	<div @click="showPopUpSlide = !showPopUpSlide" class="actu-wrapper">
		<div ref="imgContainer" class="img-container"></div>
		<p>{{ keyValue.title }}</p>
	</div>
	<slider-key
		@addKey="addKey"
		@close="showPopUpSlide = !showPopUpSlide"
		v-if="showPopUpSlide"
		:image1="keyValue.image1"
		:image2="keyValue.image2"
		:image3="keyValue.image3"
		:image4="keyValue.image4"
		:image5="keyValue.image5"
		:image6="keyValue.image6"
		:image7="keyValue.image7"
		:image8="keyValue.image8"
		:image9="keyValue.image9"
		:image10="keyValue.image10"
	/>
	<pop-up-key v-if="isPopUpVisible" :id="keyValue.id" @close="popUp" />
</template>

<script>
import { authenticatedImageFetch } from '../../../utils'
import PopUpKey from '../../PopUp/PopUpKey.vue'
import SliderKey from '../Search/SliderKey.vue'
export default {
	components: { SliderKey, PopUpKey },
	props: {
		keyValue: Object
	},
	data() {
		return {
			showPopUpSlide: false,
			isPopUpVisible: false
		}
	},
	methods: {
		popUp() {
			if (this.$store.state.user.role !== 'GUEST') {
				this.isPopUpVisible = !this.isPopUpVisible
			}
		},
		addKey() {
			this.showPopUpSlide = false
			this.isPopUpVisible = true
		},
		async getImage() {
			await authenticatedImageFetch(this.keyValue.image1, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[`imgContainer`].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
	},
	created() {
		this.getImage()
	},
}
</script>

<style>
</style>
