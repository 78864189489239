<template>
	<pop-up-wrapper>
		<div class="slider-pop-up">
			<span @click="prev" id="left" class="btn">
				<span></span>
			</span>
			<span @click="next" id="right" class="btn">
				<span></span>
			</span>
			<div class="slide" v-for="i in [currentSlide]" :key="i">
				<img
					class="close"
					@click="this.$emit('close')"
					src="/img/close-white.png"
					alt=""
				/>
				<div class="fake-img"></div>
				<img :src="currentImg" alt="" />
			</div>
			<button @click="$emit('addKey')" class="submit dark">
				Enregistrer
			</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../../PopUp/PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		image1: String,
		image2: String,
		image3: String,
		image4: String,
		image5: String,
		image6: String,
		image7: String,
		image8: String,
		image9: String,
		image10: String,
	},
	data() {
		return {
			currentSlide: 0,
			images: [
			],
		}
	},
	methods: {
		next() {
			this.currentSlide += 1
			this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
		},
		prev() {
			this.currentSlide -= 1
			this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
		},
		getImage(file, index) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.images[index] = img.src
					}
					reader.readAsDataURL(blob)
				})
		},
		addIndex: function (e) {
			if (e.code == 'ArrowRight' || e.code == 'Space') {
				this.next()
			}
			if (e.code == 'ArrowLeft' || e === false) {
				this.prev()
			}


		},
	},
	computed: {
		currentImg() {
			return this.images[Math.abs(this.currentSlide) % this.images.length]
		}
	},
	mounted() {
		this.images = [
			this.image1, this.image2, this.image3, this.image4, this.image5, this.image6, this.image7, this.image8, this.image9, this.image10
		]
		this.images = this.images.filter((el) => {
			return el !== null && typeof el !== 'undefined'
		})
		this.images.forEach((e, index) => {
			this.getImage(e, index)
		})


		window.addEventListener('keyup', (e) => this.addIndex(e))
	},
}
</script>
