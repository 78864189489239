<template>
	<div class="all-filter-container">
		<div class="all-filter-wrapper">
			<div v-if="this.$store.state.user.subjects.length > 1" class="filter-wrapper">
				<label for="subject">Matière</label>
				<select
					@change="updateLevel"
					v-model="subject"
					name="subject"
					id="subject"
				>
					<option value="">-----</option>
					<option
						v-for="subject in getUserSubjects()"
						:key="subject.id"
						:value="subject.id"
					>
						{{ subject.name }}
					</option>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="level">Niveau</label>
				<select
					@change="updateLevel"
					v-model="level"
					name="level"
					id="level"
				>
					<option value="">-----</option>
					<option
						v-for="level in getUserLevels()"
						:key="level.id"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			
			<div v-if="showChapter" class="filter-wrapper">
				<label for="chapter">Chapitre</label>
				<select
					class="filter-chapter"
					@change="updateFilter"
					v-model="chapter"
					name="chapter"
					id="chapter"
				>
					<option value="">-----</option>

					<optgroup
						v-for="sequence in getSequence()"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<form
				v-if="showSearch"
				@submit.prevent="updateFilter"
				class="filter-desc-title"
			>
				<img
					class="magnifying-glass"
					src="/img/magnifying-glass.svg"
					alt=""
				/>

				<input
					class="search-input"
					v-model="search"
					type="text"
					placeholder="Rechercher par mots clefs"
				/>
				
			</form>
			<div v-if="$route.name == 'QCM' || $route.name == 'Question'" class="filter-wrapper my-content">
				<label for="myquestions">
					<input id="myquestions" v-model="myQuestionsOnly" type="checkbox">
					Mes questions
				</label>
			</div>
			<div v-if="checkIfKeyPage()" class="filter-wrapper">
				<label for="level">Type</label>
				<select
					@change="updateFilter"
					v-model="type"
					name="type"
					id="type"
				>
					<option value="">-----</option>
					<option value="INTRODUCTION">Introduction</option>
					<option value="MOTIVATION">Motivation</option>
				</select>
			</div>
		</div>
		
	</div>
</template>

<script>
export default {
	props: {
		showChapter: {
			type: Boolean,
			default: true
		},
		showFilter: {
			type: Boolean,
			default: true
		},
		showSearch: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			level: null,
			subject: null,
			chapter: null,
			type: null,
			search: '',
			myQuestionsOnly: false
		}
	},

	methods: {
		getUserLevels() {
			const levels = this.$store.getters.levels(this.$store.state.user.levels)
			return levels
		},
		getUserSubjects() {
			const subjects = this.$store.getters.subjects(this.$store.state.user.subjects)
			return subjects
		},
		getSequence(){
			if (this.$store.state.user.subjects.length == 1 ){
				this.subject = this.$store.state.user.subjects[0]
			}
			return this.$store.getters.sequencesByLevelAndSubject(this.level, this.subject)
		},
		checkIfKeyPage() {
			const page = this.$route.name
			return page == 'Key'
		},
		updateLevel() {
			this.chapter = null
			this.updateFilter()
		},
		updateFilter() {
			const data = {
				level: this.level,
				subject: this.subject,
				chapter: this.chapter,
				type: this.type,
				search: this.search,
				teacher: this.myQuestionsOnly ? this.$store.state.user.user : null
			}
			this.$emit('updateFilter', data)
		}
	},
	created() {
		this.updateFilter()
		
	},
	watch: {
		myQuestionsOnly() {
			this.updateFilter()
		}
	}



}
</script>

<style>
</style>
