<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-new-course">
			<h3><img src="/img/class/plus.svg" alt="" /> Nouveau Cours</h3>
			<div class="input-wrapper">
				<div class="input-container">
					<label for="">Date</label>
					<input v-model="form.day" type="date" />
				</div>
				<div class="input-container">
					<label class="custom-label" for="start-hour"
						>Heure de début</label
					>
					<input
						v-model="form.debut_hour"
						id="start-hour"
						type="time"
					/>
				</div>
				<div class="input-container">
					<label class="custom-label" for="end-hour"
						>Heure de fin</label
					>
					<input v-model="form.end_hour" id="end-hour" type="time" />
				</div>
				<div class="input-container">
					<label for="currency">Fréquence</label>
					<select
						class="days"
						v-model="form.currency"
						name="currency"
						id="currency"
					>
						<option value="OCCASIONAL">Occasionnel</option>
						<option value="WEEKLY">Toutes les semaines</option>
						<option value="BIWEEKLY">Toutes les 2 semaines</option>
					</select>
				</div>
				<div class="input-container">
					<label for="">Salle de classe</label>
					<input
						placeholder="Ajouter une salle"
						v-model="form.room_number"
						type="text"
					/>
				</div>

				<p class="error">{{ this.error }}</p>

				<div class="btn-container">
					<button @click="close" class="cancel">Annuler</button>
					<button @click="submit" class="add">Ajouter</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		studentId: Number
	},
	emits: ["close"],
	data() {
		return {

			form: {
				day: null,
				debut_hour: null,
				end_hour: null,
				currency: null,
				classroom: this.$route.params.id,
				room_number: ''
			},
			error: ''
		}
	},


	methods: {
		close() {
			this.$emit('close')
		},
		submit() {
			if (this.validateForm()) {
				authenticatedApiFetch(`/agendas/`, { method: 'POST', body: JSON.stringify(this.form) })
					.then(() => {
						this.status = 'Enregistré avec succès'
						this.$emit('close')
						this.$emit('refresh')
					})
					.catch((error) => {
						this.errors = error
					})
			}
		},
		validateForm() {
			if (!this.form.day) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez entrer la date'
				}
				return false
			}
			if (!this.form.debut_hour) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez entrer l\'heure de début'
				}
				return false
			}
			if (!this.form.end_hour) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez entrer l\'heure de fin'
				}
				return false
			}
			if (!this.form.currency) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez choisir la fréquence'
				}
				return false
			}
			// Check if debut_hour is before end_hour
			const startTime = new Date(`2000-01-01 ${this.form.debut_hour}`)
			const endTime = new Date(`2000-01-01 ${this.form.end_hour}`)

			if (startTime >= endTime) {
				this.$store.state.toast = {
					status: 'error',
					text: 'L\'heure de début doit être avant l\'heure de fin'
				}
				return false
			}
			this.error = ''
			return true
		}
	},



}
</script>
