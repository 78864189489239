<template>
	<add-exercise class="admin-wrapper" />
</template>

<script>
import AddExercise from '../../../../SuperAdmin/Exercise/AddExercise.vue'

export default {
	components: { AddExercise },
}
</script>

<style>
</style>
