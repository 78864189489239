import { authenticatedApiFetch } from '../utils'



export const allTileGameTeamsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/tile-game-team/${filter}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateTileGameTeamService = async (id, data) => {
    const res = await authenticatedApiFetch(`/tile-game-team/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const createTileGameTeamService = async (data) => {
    const res = await authenticatedApiFetch(`/tile-game-team/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}



export const deleteTileGameTeamService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game-team/${id}/`, { method: 'DELETE' })
    return res
}
