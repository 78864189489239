<template>
	<h1>Exercices En Attente - calendrier de l'avent</h1>
	<router-link
		class="submit dark"
		:to="{ name: 'SuperAdminAllCalendarExercises' }"
		>Exercices Validés
	</router-link>
	<nav class="paginate">
		<ul v-if="count !== null">
			<li v-for="(i, index) in paginate()" :key="index">
				<button :class="index == currentPage ? 'active' : ''">
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Titre</th>
			<th>Username</th>
			<th>Voir</th>
			<th>Passer en public</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(exercise, index) in exercises" :key="index">
			<td>{{ index + 1 }}</td>
			<td>{{ exercise.title }}</td>
			<td>{{ exercise.teacher_name }}</td>
			<td>
				<button @click="showPopUp(exercise, exercise.tile_game_theme)">
					Voir
				</button>
			</td>

			<td>
				<button @click="setToPublic(exercise)">Passer en public</button>
			</td>

			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditCalendarExercise',
						params: { id: exercise.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteExercise(exercise.id)">supprimer</button>
			</td>
		</tr>
		<Teleport to="body">
			<pop-up-exo-calendar
				v-if="isPopUpVisible"
				:id="selectedContent.id"
				@close="isPopUpVisible = !isPopUpVisible"
			/>
		</Teleport>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import PopUpExoCalendar from '../../PopUp/SeriousGame/Calendar/PopUpExoCalendar.vue'

export default {
	components: { PopUpExoCalendar },
	data() {
		return {
			exercises: [],
			count: null,
			currentPage: 0,
			isPopUpVisible: false,
			selectedContent: {},
			selectedContentTheme: '',
			themes: []
		}
	},
	methods: {
		showPopUp(e) {
			this.selectedContent = e
			this.isPopUpVisible = !this.isPopUpVisible
		},
		async deleteExercise(id) {
			if (confirm('Voulez vous vraiment supprimer le contenu ?')) {
				await authenticatedApiFetch(`/christmas-calendar-exercises/${id}`, { method: 'DELETE' })
				this.allCalendarContents()
			}
		},
		async allCalendarContents() {
			const res = await authenticatedApiFetch('/christmas-calendar-exercises/?status=PENDING', { method: 'GET' })
				.then(res => res.json())

			this.exercises = res
		},
		async allCalendarThemes() {
			const res = await authenticatedApiFetch('/tile-game-each-theme/', { method: 'GET' })
				.then(res => res.json())

			this.themes = res
		},
		async setToPublic(exercise) {
			if (confirm('Voulez vous vraiment approuver ce contenu ?')) {
				let data = {}

				data.tile_game_theme = exercise.tile_game_theme
				data.status = 'PUBLIC'
				await authenticatedApiFetch(`/christmas-calendar-exercises/${exercise.id}/`, {
					method: 'PUT',
					body: JSON.stringify(data)
				})
				await this.allCalendarContents()
			}
		},
		getTheme(e) {
			const theme = this.themes.find(x => x.id == e)
			return theme.name
		}
	},

	created() {
		this.allCalendarThemes()
		this.allCalendarContents()
	},


}
</script>

<style>
</style>
