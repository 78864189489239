import { authenticatedApiFetch } from '../utils'

export const allTileGameTypesService = async () => {
    const res = await authenticatedApiFetch(`/tile-game-type/`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


