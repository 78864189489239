export default {
    updateClasses(state, classes) {
        this.state.classes = classes
    },

    updateDontShowAgain(state, payload) {

        switch (payload.guideType) {
            case 'individual':
                state.dontShowAgainIndividual = payload.value
                break
            case 'key':
                state.dontShowAgainKey = payload.value
                break
            case 'share':
                state.dontShowAgainShare = payload.value
                break
        }


    },
    setTileGameTeamsDisabled(state, value) {
        state.tileGameTeamsDisabled = value
    }
}
