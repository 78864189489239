<template>
	<section class="student-place-lesson-container">
		<div class="title-container">
			<h1>Cours</h1>
		</div>

		<section class="lesson-folder-container">
			<lesson-container
				v-for="lesson in lessons"
				:key="lesson.id"
				:lesson="lesson"
				@setActive="setActive"
				:active="lessonSelected == lesson.id ? true : false"
			/>
		</section>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt } from '../../utils'
import LessonContainer from './LessonContainer.vue'
export default {
	components: { LessonContainer },
	data() {
		return {
			lessons: [],
			classId: null,
			lessonSelected: null
		}
	},
	methods: {
		async getLessons() {
			const res = await axios.get(`${Config.backend_url}/api/v1/imported-lessons/?class_id=${this.classId}&visible_for_student=true`)
			this.lessons = res.data

		},
		setActive(e) {
			this.lessonSelected = e
		},
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getLessons()

	},
}
</script>

<style>
</style>
