<template>
	<section class="side-bar">
		<h1>Élèves</h1>
		<div class="student-wrapper">
			<div v-for="student in students" :key="student">
				<p>{{ student.name }}</p>
			</div>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		students: Array
	},

}
</script>

<style>
</style>

