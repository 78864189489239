<template>
	<div
		v-if="actualObservations.length !== 0"
		class="pop-up-show-observations"
	>
		<div
			:class="getMotif(observation.motif)"
			v-for="observation in actualObservations"
			:key="observation.id"
			class="observation-container"
		>
			<h5>{{ observation.date }}</h5>
			<p>{{ observation.comment }}</p>
			<img
				@click="deleteObservation(observation.id)"
				src="/img/close.svg"
				alt=""
			/>
		</div>
	</div>
	<div v-else class="pop-up-show-observations no-observation">
		<h2>Aucune observation</h2>
	</div>
</template>
<script>
import { deleteObservationService } from '../../../services/observationsService'
export default {
	props: {
		observations: Array
	},
	data() {
		return {
			actualObservations: []
		}
	},
	emits: ["close"],
	methods: {
		async deleteObservation(e) {
			await deleteObservationService(e)
			this.actualObservations = this.actualObservations.filter(x => x.id !== e)
			this.$emit('refresh')
		},
		close() {
			this.$emit('close')
		},

		getMotif(e) {
			switch (e) {
				case 1:
					return 'red'

				case 2:
					return 'red'

				case 3:
					return 'yellow'

				case 4:
					return 'green'
			}
		}

	},
	watch: {
		observations: function (newVal) {
			this.actualObservations = newVal
		}
	},
	created() {
		this.actualObservations = [...this.observations]
	},
}
</script>

<style>
</style>
