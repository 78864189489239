<template>
	<h1>Exercices En Attente - Tiles Game</h1>
	<router-link
		class="submit dark"
		:to="{ name: 'SuperAdminAllTileGameContent' }"
		>Exercices Validés
	</router-link>
	<nav class="paginate">
		<ul v-if="count !== null">
			<li v-for="(i, index) in paginate()" :key="index">
				<button :class="index == currentPage ? 'active' : ''">
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Titre</th>
			<th>Username</th>
			<th>Voir</th>
			<th>Theme</th>
			<th>Passer en public</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(exercise, index) in exercises" :key="index">
			<td>{{ index + 1 }}</td>
			<td>{{ exercise.title }}</td>
			<td>{{ exercise.teacher_name }}</td>
			<td>
				<button @click="showPopUp(exercise, exercise.tile_game_theme)">
					Voir
				</button>
			</td>
			<td>
				<select
					v-model="exercise.tile_game_theme"
					multiple
					name=""
					id=""
				>
					<option
						v-for="theme in themes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</td>
			<td>
				<button @click="setToPublic(exercise)">Passer en public</button>
			</td>

			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditTileGameContent',
						params: { id: exercise.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteExercise(exercise.id)">supprimer</button>
			</td>
		</tr>
		<Teleport to="body">
			<pop-up-exo
				v-if="isPopUpVisible"
				@showPopUp="showPopUp(selectedContent, selectedContentTheme)"
				:question="selectedContent"
				:theme="selectedContentTheme"
				@close="isPopUpVisible = !isPopUpVisible"
			/>
		</Teleport>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import PopUpExo from '../../PopUp/SeriousGame/TileGame/PopUpExo.vue'

export default {
	components: { PopUpExo },
	data() {
		return {
			exercises: [],
			count: null,
			currentPage: 0,
			isPopUpVisible: false,
			selectedContent: {},
			selectedContentTheme: '',
			themes: []
		}
	},
	methods: {
		showPopUp(e, theme) {
			this.selectedContent = e
			const themeName = this.themes.find(x => x.id == theme[0]).name
			this.selectedContentTheme = themeName
			this.isPopUpVisible = !this.isPopUpVisible
		},
		async deleteExercise(id) {
			await authenticatedApiFetch(`/tile-game-content/${id}`, { method: 'DELETE' })
			this.allTileGameContents()
		},
		async allTileGameContents() {
			const res = await authenticatedApiFetch('/tile-game-content/?status=PENDING', { method: 'GET' })
				.then(res => res.json())

			this.exercises = res
		},
		async allTileGameThemes() {
			const res = await authenticatedApiFetch('/tile-game-each-theme/', { method: 'GET' })
				.then(res => res.json())

			this.themes = res
		},
		async setToPublic(exercise) {
			let data = {}

			data.tile_game_theme = exercise.tile_game_theme
			data.status = 'PUBLIC'
			await authenticatedApiFetch(`/tile-game-content/${exercise.id}/`, {
				method: 'PATCH',
				body: JSON.stringify(data)
			})
			await this.allTileGameContents()
		},
		getTheme(e) {
			const theme = this.themes.find(x => x.id == e)
			return theme.name
		}
	},

	created() {
		this.allTileGameThemes()
		this.allTileGameContents()
	},


}
</script>

<style>
</style>
