<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Duppliquer votre ressource</p>
			<p>
				Pour enregistrer votre ressource, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container">
				<label for="classes">Classe</label>
				<select
					v-if="this.$store.state.classes.length !== 0"
					id="classes"
					v-model="classe"
				>
					<option
						v-for="classe in this.$store.state.classes"
						:key="classe.name"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select v-if="classe" v-model="currency" id="date">
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>

			<button @click="duplicateThing" class="submit dark">
				Dupliquer
			</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { allActivityEventsFilterService } from '../../services/activityEventsService'
import { createExerciseQuestionOrderService } from '../../services/exerciseQuestionOrdersService'
import { createExerciseSheetService, getExerciseSheetByIdService } from '../../services/exerciseSheetsService'
import { getOccurenceByIdService, updateOccurenceService } from '../../services/occurrencesService'
import { formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	props: {
		id: Number,
		redirect: String,
		titre: String,
		text: String,
		btn: String,
		path: String,
		delete: Boolean,
	},
	data() {
		return {
			newActivityId: null,
			classe: null,
			courses: [],
			currency: null
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}

		},
		async duplicateThing() {
			let activity = await getExerciseSheetByIdService(this.id)

			const body = {
				classes: [this.classe],
				template: activity.template,
				teacher: activity.teacher,
				title: activity.title + '(1)',
				status: false,
				type: activity.type
			}
			const res = await createExerciseSheetService(body)

			this.newActivityId = res.id
			for (const [index, activity] of activity.exercises.entries()) {
				const body2 = {
					exercise: activity,
					exercise_sheet: res.id,
					order: index,
				}
				await createExerciseQuestionOrderService(body2)
			}
			if (!this.currency) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.currency)
				occurrence.exercise_sheets.push(this.newActivityId)
				await updateOccurenceService(this.currency, occurrence)
			}
			this.$emit('refreshActivities')
			this.$emit('close')
		},
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.classe)
			return courses
		}
	},
	created() {
		this.allCourses()
	},
}
</script>

<style>
</style>
