<template>
	<section class="class-stats">
		<h1>Stats de Question Flash</h1>

		<div v-if="isLoading">
			<loader-component />
		</div>
		<div class="table-container" v-else>
			<table>
				<tr>
					<th>Chapitre</th>
					<th>Sequence</th>
					<th>Nombre Activités</th>
				</tr>
				<tr v-for="activity in this.activities" :key="activity.id">
					<td>
						{{ activity.name }}
					</td>
					<td>
						<p>
							{{ activity.sequence_name }}
						</p>
					</td>
					<td>
						<p>
							{{ activity.activities_count }}
						</p>
					</td>
				</tr>
			</table>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import LoaderComponent from '../../Loader.vue'
export default {
	components: { LoaderComponent },


	data() {
		return {
			activities: [],
			keys: [],
			isLoading: true,
		}
	},

	methods: {

		async activityStats() {
			this.isLoading = true
			const res = await authenticatedApiFetch('/stat-flash/?ordering=-activities_count')
				.then(res => res.json())
			this.activities = res
			this.isLoading = false
		},

	},
	created() {
		this.activityStats()
	},
}
</script>

<style>
</style>
