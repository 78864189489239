<template>
	<section class="kanban-container">
		<h2>Mes Tâches</h2>
		<div class="kanban-wrapper">
			<div
				class="column"
				@drop="onDrop($event, 'TODO')"
				@dragover.prevent
				@dragenter.prevent
			>
				<h3>À faire</h3>
				<div class="scrollable">
					<task
						v-for="(task, index) in todo"
						:key="task"
						:task="task"
						draggable="true"
						@dragstart="startDrag($event, task.id)"
						@delete="deleteTask"
						class="task-container loading-animation"
						@showPopUpEdit="showPopUpEdit"
						:style="`--delay: ${index}`"
					/>
				</div>
			</div>

			<div
				class="column"
				@drop="onDrop($event, 'PROGRESS')"
				@dragenter.prevent
				@dragover.prevent
			>
				<h3>En cours</h3>
				<div class="scrollable">
					<task
						v-for="(task, index) in progress"
						:key="task"
						:task="task"
						draggable="true"
						@dragstart="startDrag($event, task.id)"
						@delete="deleteTask"
						class="task-container loading-animation"
						@showPopUpEdit="showPopUpEdit"
						:style="`--delay: ${index}`"
					/>
				</div>
			</div>
			<div
				class="column"
				@drop="onDrop($event, 'DONE')"
				@dragover.prevent
				@dragenter.prevent
			>
				<h3>Fait</h3>
				<div class="scrollable">
					<task
						v-for="(task, index) in done"
						:key="task"
						:task="task"
						draggable="true"
						@dragstart="startDrag($event, task.id)"
						@delete="deleteTask"
						class="task-container loading-animation"
						@showPopUpEdit="showPopUpEdit"
						:style="`--delay: ${index}`"
					/>
				</div>
			</div>
			<button class="add-task" @click="showPopUpCreate">
				Ajouter une tâche
			</button>
			<button class="remove-tasks" @click="removeTasks">
				Vider les taches
			</button>
		</div>

		<pop-up-new-kaban-task
			@refreshKanban="getKanbans"
			@close="showPopUpCreate"
			v-if="isPopUpCreateVisible"
		/>
		<pop-up-edit-kanban-task
			v-if="isPopUpEditVisible"
			@refreshKanban="getKanbans"
			@close="showPopUpEdit"
			:task="selectedTask"
		/>
	</section>
</template>

<script>
import { allKanbansForUserService, deleteKanbanService, updateKanbanService } from '../../../../services/kanbansService'
import PopUpEditKanbanTask from '../../../PopUp/Dashboard/PopUpEditKanbanTask.vue'
import PopUpNewKabanTask from '../../../PopUp/Dashboard/PopUpNewKabanTask.vue'
import Task from './Task.vue'
export default {
	components: { PopUpNewKabanTask, Task, PopUpEditKanbanTask },
	data() {
		return {
			selectedTask: {},
			kanbans: [],

			isPopUpCreateVisible: false,
			isPopUpEditVisible: false
		}
	},

	methods: {

		startDrag(evt, item) {
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.dataTransfer.setData('itemID', item)

		},
		onDrop(evt, state) {
			const kanbanID = evt.dataTransfer.getData('itemID')
			const kanban = this.kanbans.find(x => x.id == kanbanID)
			kanban.state = state
			this.updateKanban(kanban)
		},

		async removeTasks() {
			for (const task of this.done) {
				await deleteKanbanService(task.id)
				this.kanbans.splice(this.kanbans.findIndex(function (x) {
					return x.id === task.id
				}), 1)
			}

		},

		showPopUpCreate() {
			this.isPopUpCreateVisible = !this.isPopUpCreateVisible
		},
		showPopUpEdit(e) {
			this.selectedTask = e
			this.isPopUpEditVisible = !this.isPopUpEditVisible
		},

		async getKanbans() {
			this.kanbans = await allKanbansForUserService(this.$store.state.user.user)
		},
		async updateKanban(kanban) {
			await updateKanbanService(kanban.id, kanban)
		},
		deleteTask(e) {
			this.kanbans.splice(this.kanbans.findIndex(function (x) {
				return x.id === e
			}), 1)
		}
	},

	computed: {
		todo() {
			return this.kanbans.filter(x => x.state == 'TODO')
		},
		progress() {
			return this.kanbans.filter(x => x.state == 'PROGRESS')
		},
		done() {
			return this.kanbans.filter(x => x.state == 'DONE')
		}
	},

	created() {
		this.getKanbans()
	},
}
</script>

<style>
</style>
