<template>
	<div ref="imgContainer" class="img-container"></div>
</template>

<script>
import { authenticatedImageFetch } from '../../../../utils'
export default {

	methods: {
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
	},
	created() {
		let regex = /(?:\.([^.]+))?$/
		const extensions = ['png', 'jpg', 'gif']
		if (extensions.includes(regex.exec(this.$route.params.fileName)[1])) {
			this.getImage(this.$route.params.fileName)
		}
	},
}
</script>

<style>
</style>
