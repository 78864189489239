<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-course">
			<img
				@click="$emit('close')"
				class="close"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Choisissez votre classe</p>
			<form @submit.prevent="redirect">
				<select v-model="classe">
					<option
						v-for="classe in $store.state.classes"
						:key="classe.id"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
				<button class="submit dark">Valider</button>
			</form>
		</div>
	</pop-up-wrapper>
</template>


<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	data() {
		return {
			classe: this.$store.state.classes[0].id
		}
	},
	methods: {
		redirect() {
			this.$router.push({ name: 'EditCourse', params: { id: this.classe } })
		}
	},
}
</script>

<style>
</style>
