import { authenticatedApiFetch } from '../utils'


export const allAgendasService = async () => {
    const res = await authenticatedApiFetch(`/agendas/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allAgendasForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/agendas/?classes=${classId}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteAgendaService = async (id) => {
    const res = await authenticatedApiFetch(`/agendas/${id}/`, { method: 'DELETE' })
    return res
}

export const udpateAgendaService = async (id, data) => {
    const res = await authenticatedApiFetch(`/agendas/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getAgendaService = async (id) => {
    const res = await authenticatedApiFetch(`/agendas/${id}/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
