<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-edit">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Modifier votre ressource</p>
			<div class="pop-up-edit-wrapper">
				<div class="container">
					<div class="input-container">
						<label for="name">Nom *</label>
						<input
							class="form-control required"
							id="name"
							v-model="activity.name"
							placeholder="Nom de la ressource"
							type="text"
							@change="updateError"
						/>
						<small class="error">{{ errors.name }}</small>
					</div>
					<p class="label">Modèle</p>
					<slider
						v-if="
							activity.template !== undefined &&
							activity.activities2.length == 0
						"
						:index="activity.template"
						@index="checkIndex"
					/>
					<img
						v-else
						src="/img/template/template-interrogation-preview.jpg"
						alt=""
					/>
				</div>
				<div class="container">
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							v-model="selectedClasse"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select
							v-if="selectedClasse"
							v-model="activity.currency"
							id="date"
						>
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
					</div>
				</div>
			</div>
			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Slider from '../Admin/Slider.vue'
import { formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'
import { getActivityByIdService, updateActivityService } from '../../services/activitiesService'
import { getOccurenceByIdService, updateOccurenceService } from '../../services/occurrencesService'
import { allActivityEventsFilterService } from '../../services/activityEventsService'
import { allRitualActivitiesFilterService } from '../../services/ritualActivitiesService'
import { getQuestionInstanceService } from '../../services/questionInstancesService'

export default {
	components: {
		Slider,
		PopUpWrapper
	},
	emits: ["close"],
	props: {
		id: Number,
	},
	data() {
		return {
			form: {
				name: '',
				classes: [],
			},
			selectedClasse: [],
			index: 0,
			activity: {},
			questionsList: [],
			dragging: false,
			enabled: true,
			teacher: this.$store.state.user.user,
			rituals: [],
			courses: [],
			agenda: {},
			errors: {},
			oldCurrency: null
		}
	},
	created() {
		this.getActivity()
		this.allCourses()
	},
	methods: {
		updateInput() {
			this.errors.name = null
		},
		updateSelect() {
			this.errors.classe = null
		},
		close() {
			this.$emit('close')
		},
		async submit() {
			if (this.activity.name == '') {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter un nom à votre ressource'
				}
			}
			if (this.selectedClasse == '' || this.selectedClasse == undefined) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter une classe à votre ressource'
				}
			}
			if (this.activity.name !== '' && this.selectedClasse.length !== 0) {
				let classArray = [this.selectedClasse]

				const body = {
					name: this.activity.name,
					teacher: this.teacher,
					classes: classArray,
					template: this.index
				}
				await updateActivityService(this.id, body)
				if (this.activity.currency == undefined) {
					console.log('no agenda')
				}
				else {
					let occurrence = await getOccurenceByIdService(this.activity.currency)
					occurrence.activities.push(this.id)
					await updateOccurenceService(this.activity.currency, occurrence)
					// If already have an occurrence (remove old occurrence)
					let patchedOccurence = this.courses.find(e => {
						return e.activities.find(x => x == this.id)
					})
					if (patchedOccurence && this.oldCurrency !== this.activity.currency) {
						patchedOccurence.activities = patchedOccurence.activities.filter(x => x !== this.id)
						await updateOccurenceService(patchedOccurence.id, patchedOccurence)
					}

				}
				this.$emit('refreshActivities')
				this.$emit('close')
			}
		},
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}

			let courseActivity = this.courses.find(e => {
				return e.activities.find(x => x == this.id)
			})
			if (courseActivity) {
				this.activity.currency = courseActivity.id
				this.oldCurrency = courseActivity.id
			}

		},
		async getActivity() {
			const res = await getActivityByIdService(this.id)
			this.activity = res
			this.selectedClasse = this.activity.classes[0]
			await this.getRituals()

		},
		async getRituals() {
			const filter = `ritual=${this.activity.id}`
			this.rituals = await allRitualActivitiesFilterService(filter)
			this.rituals.sort((a, b) => (a.order > b.order ? 1 : -1))
			await this.getQuestions()
		},
		async getQuestions() {
			for await (const ritual of this.rituals) {
				const res = await getQuestionInstanceService(ritual.activity)
				this.questionsList.push(res)
			}
			this.sortQuestionList()

		},
		sortQuestionList() {
			let test = []
			for (let i = 0; i < this.rituals.length; i++) {
				test.push(this.questionsList.find(x => x.id == this.rituals[i].activity))
			}
			this.questionsList = test
		},
		remove(e) {
			this.questionsList = this.questionsList.filter(x => x.id != e.id)
		},
		checkIndex(e) {
			this.index = e
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.selectedClasse)
			return courses
		}
	},
}
</script>

<style>
</style>
