<template>
	<div class="game-container">
		<div style="cursor: unset" class="img-container">
			<img :src="game.img" alt="" />
		</div>
		<div class="add-container add-container-game">
			<div>
				<p>{{ game.title }}</p>
			</div>
			<a v-if="game.url" :href="game.url" class="submit add">
				<span>Ouvrir</span>
			</a>
			<router-link
				class="submit add"
				v-if="game.componentName"
				:to="{ name: game.componentName }"
			>
				<span>Ouvrir</span>
			</router-link>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		game: {
			type: Object,
			required: true
		}
	},
}
</script>

<style>
</style>
