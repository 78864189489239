<template>
	<div :class="isAdded ? 'active' : ''" class="fake-container">
		<div @click="checkType" class="activite individuelle exercise">
			<div class="exo" ref="imgContainer">
				<p class="custom-id">{{ customId() }}</p>
				<div class="difficulty-indicator">
					<div
						:style="color(question.difficulty_name)"
						class="indicator"
					></div>
				</div>

				<div
					:class="readyToDisplay ? 'visible' : ''"
					class="calcul"
					ref="exerciseImgContainer"
				></div>
			</div>
		</div>
		<div class="add-container question">
			<div>
				<p>{{ question.title }}</p>
				<p :style="getType(question.type_name)">
					{{ question.type_name }}
				</p>
			</div>
			<button
				v-if="question.random_values == null"
				@click="add"
				class="submit add"
			>
				<span v-if="isAdded"> - Supprimer</span>
				<span v-else> + Ajouter</span>
			</button>
			<button v-else class="submit add">
				<span @click="addRandom" v-if="amount == 0">+ Ajouter</span>
				<span class="amount-container" v-else>
					<span @click="removeRandom">-</span>
					<span>{{ amount }}</span>
					<span @click="addRandom">+</span>
				</span>
			</button>
		</div>
	</div>
	<pop-up-preview-question
		@close="isVisibleQuestionPreview = !isVisibleQuestionPreview"
		v-if="isVisibleQuestionPreview"
		:question="question"
	/>
	<pop-up-preview-exercise
		@close="isVisibleExercisePreview = !isVisibleExercisePreview"
		v-if="isVisibleExercisePreview"
		:description_img="question.description_img"
		:correction_img="question.correction_img"
		:title="question.title"
	/>
</template>

<script>
import { authenticatedImageFetch } from '../../../../utils'
import PopUpPreviewExercise from '../../../PopUp/PopUpPreviewExercise.vue'
import PopUpPreviewQuestion from '../../../PopUp/PopUpPreviewQuestion.vue'

export default {
	components: {
		PopUpPreviewQuestion,
		PopUpPreviewExercise,
	},
	props: {
		added: Boolean,
		question: Object,
		amount: {
			type: Number,
			default: 0
		},
		type: {
			type: String,
			default: 'question'
		}
	},
	emits: ['addQuestion', 'addQuestionRandom', 'removeQuestionRandom'],
	data() {
		return {
			copiedQuestion: {},
			isAdded: false,
			readyToDisplay: false,
			isVisibleGuest: false,
			isVisibleQuestionPreview: false,
			isVisibleExercisePreview: false,
			imageData: '',
			copiedAmount: 0
		}
	},
	methods: {
		add() {
			if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
				return false
			}
			this.isAdded = !this.isAdded
			this.$emit('addQuestion', this.question.id)
		},


		checkType() {
			if (this.type == 'question') {
				this.isVisibleQuestionPreview = !this.isVisibleQuestionPreview
			}
			else {
				this.isVisibleExercisePreview = !this.isVisibleExercisePreview
			}
		},
		customId() {
			let id = this.question.level_name.charAt(0) + this.question.sequence_name.charAt(0) + this.question.chapter_name.charAt(0) + this.question.difficulty_name.charAt(0) + this.question.id
			return id
		},
		getType(e) {
			let color
			switch (e) {
				case 'Application':
					color = '#FFCE00'
					break
				case 'Entraînement':
					color = '#02FF63'
					break
				case 'Approfondissement':
					color = '#53d6ff'
					break
				case 'Annale':
					color = '#EEAFFC'
					break


			}
			return `color:${color}`
		},
		color(e) {
			let color
			let width
			switch (e) {
				case 'Facile':
					color = '#09E300'
					width = '33%'
					break
				case 'Moyen':
					color = '#FF9300'
					width = '66%'
					break
				case 'Difficile':
					color = '#FF0000'
					width = '100%'
					break

				default:
					break
			}
			return `background-color: ${color};width: ${width};`
		},

		timeToCloseGuest() {
			this.isVisibleGuest = false
		},

		async generateImg() {
			await authenticatedImageFetch(this.copiedQuestion.description_img, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.exerciseImgContainer.append(img)
					}
					reader.readAsDataURL(blob)

				})
		}
	},
	created() {
		this.copiedQuestion = { ...this.question }
		this.generateImg()

	},
	mounted() {
		this.isAdded = this.added
	},
	watch: {
		added() {
			this.isAdded = this.added
		}
	},
}
</script>

<style>
</style>
