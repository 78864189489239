<template>
	<div
		draggable="true"
		@dragstart="startDrag($event, file.id)"
		class="file-wrapper"
		:class="selected ? 'selected' : ''"
		@click="this.$refs.input.focus()"
	>
		<input
			class="fake-input"
			ref="input"
			type="text"
			@keyup.delete.prevent="deleteFile"
		/>
		<p @click="oneClick" class="file-name">
			<img src="/img/class/file.svg" alt="" />
			{{ file.name }}
		</p>
		<div class="info-container">
			<p>{{ getDate(file.uploaded_at) }}</p>

			<button @click="isPopUpVisible = !isPopUpVisible">
				<img src="/img/class/menu.svg" alt="" />
			</button>
		</div>
		<Teleport to="body">
			<pop-up-menu-file
				v-if="isPopUpVisible"
				:x="x"
				:y="y"
				@close="isPopUpVisible = !isPopUpVisible"
				@remove="removeFile"
				@showPopUpEditFile="
					isPopUpEditFileVisible = !isPopUpEditFileVisible
				"
				:file="newFile"
			/>
		</Teleport>
		<Teleport to="body">
			<pop-up-edit-file
				v-if="isPopUpEditFileVisible"
				@close="isPopUpEditFileVisible = !isPopUpEditFileVisible"
				:file="newFile"
				@getFiles="changeFileName"
			/>
		</Teleport>
		<Teleport to="body">
			<pop-up-preview-file
				v-if="isPopUpPreviewFileVisible"
				@close="isPopUpPreviewFileVisible = !isPopUpPreviewFileVisible"
				:file="newFile"
			/>
		</Teleport>
	</div>
</template>

<script>
import { deleteFileService } from '../../../../services/filesService'
import PopUpEditFile from '../../../PopUp/Class/PopUpEditFile.vue'
import PopUpMenuFile from '../../../PopUp/Class/PopUpMenuFile.vue'
import PopUpPreviewFile from '../../../PopUp/Class/PopUpPreviewFile.vue'
export default {
	components: { PopUpMenuFile, PopUpEditFile, PopUpPreviewFile },
	props: {
		file: Object,
		selected: Boolean,
		id: Number,
		selectedFile: Number
	},
	data() {
		return {
			newFile: {},
			isPopUpVisible: false,
			isPopUpEditFileVisible: false,
			isPopUpPreviewFileVisible: false,
			x: null,
			y: null,
			delay: 700,
			clicks: 0,
		}
	},
	methods: {
		oneClick() {
			this.clicks++
			this.$emit('select', this.file.id)
			if (this.clicks === 1) {
				this.timer = setTimeout(() => {
					this.clicks = 0
				}, this.delay)
			} else {
				clearTimeout(this.timer)
				let regex = /(?:\.([^.]+))?$/
				const extensions = ['png', 'jpg', 'gif']
				if (extensions.includes(regex.exec(this.file.file_upload)[1])) {
					let routeData = this.$router.resolve({ name: 'ShowImg', params: { fileName: this.file.file_upload } })
					window.open(routeData.href, '_blank')
				}
				else {
					this.isPopUpPreviewFileVisible = !this.isPopUpPreviewFileVisible

				}
				this.clicks = 0
			}
		},
		startDrag(evt, item) {
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.dataTransfer.setData('itemID', item)
		},
		changeFileName(e) {
			this.newFile.name = e
		},
		getDate(e) {
			return e.slice(0, 10)
		},
		removeFile(e) {
			this.$emit('remove', e)
		},
		deleteFile: async function () {
			let result = confirm('Etes vous sur de vouloir supprimer ?')
			if (result) {
				await deleteFileService(this.id)
				this.$emit('remove', this.id)
				this.newFile = {}
			}
		},
	},

	created() {
		this.newFile = this.file
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY
		})
	},
}

</script>

<style>
</style>
