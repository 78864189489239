<template>
	<section
		:class="theme"
		class="display-activity-container display-activity-container-hand-made"
		style="cursor: unset"
	>
		<div
			v-if="questions.length !== 0"
			:style="`transform:translateX(-${progression}%)`"
			class="activity-container"
		>
			<div class="slide" :class="theme">
				<div class="question-container">
					<h1>Question flash</h1>
					<h2>{{ activity.name }}</h2>

					<button style="cursor: unset" class="begin">
						Commencer
					</button>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import VueResizeText from 'vue3-resize-text'
import Config from '../../config'

export default {
	props: {
		id: Number,
	},
	directives: {
		ResizeText: VueResizeText.ResizeText
	},
	data() {
		return {
			activity: {},
			questions: [],
			theme: "",
			progression: 0,
			rituals: [],
			classe: null,
			classe_id: null,
			key: {},
			images: []
		}
	},
	methods: {

		
		async getActivity() {
			await axios.get(`${Config.backend_url}/api/v1/activities/${this.id}/`)
				.then((res) => {
					this.activity = res.data
				})
			await this.getRituals()
			if (this.activity.key) {
				await this.getKey()
			}
		},

		async getKey() {
			await axios.get(`${Config.backend_url}/api/v1/keys/${this.activity.key}/`)
				.then((res) => {
					this.key = res.data
					let key = this.key
					this.images = [
						key.image1,
						key.image2,
						key.image3,
						key.image4,
						key.image5,
						key.image6,
						key.image7,
						key.image8,
						key.image9,
						key.image10,
					]
					this.images = this.images.filter((el) => {
						return el !== null && typeof el !== 'undefined'
					})
					
				})
		},
		async getRituals() {
			await axios.get(`${Config.backend_url}/api/v1/ritual-activities/`)
				.then((response) => {
					let data = response.data
					this.rituals = data.filter(
						(x) => x.ritual == this.activity.id
					)
					this.rituals.sort((a, b) => (a.order > b.order ? 1 : -1))
					this.getQuestions()
				})
				.catch((error) => console.error(error))
		},
		async getQuestions() {
			this.activity.activities.forEach((element) => {
				axios.get(`${Config.backend_url}/api/v1/questions-instances/${element}/`)
					.then((res) => {
						this.questions.push(res.data)
						if (this.questions.length == this.rituals.length) {
							this.sortQuestionList()
						}
						this.getTemplate()
					})
			})
		},
		sortQuestionList() {
			let array = []
			for (let i = 0; i < this.rituals.length; i++) {
				array.push(
					this.questions.find((x) => x.id == this.rituals[i].activity)
				)
			}
			this.questions = array
			
		},
		getTemplate() {
			this.reRender()
			let template = this.activity.template
			this.theme = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seven'][template] || 'second'
		},
		addIndex(e) {
			if (e.code == 'ArrowRight' || e.code == 'Space') {
				if (this.progression < this.questions.length * 100 || this.progression < this.images.length * 100 - 100) {
					this.progression += 100
				}
			}
			if (e.code == 'ArrowLeft' || e === false) {
				if (this.progression >= 100) {
					this.progression -= 100
				}
			}
			if (e.code == 'Escape') {
				this.$router.push({ name: 'ClassResources', params: { id: this.classe_id }, })
			}
			if (e === true) {
				this.progression += 100
			}

		},
		dashboard() {
			if (this.$route.name == 'Key') {
				this.$emit('close')
			}
			else {
				this.$router.push({ name: 'ClassResources', params: { id: this.classe_id }, })
			}
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.getActivity()
	},
	mounted() {
		this.reRender()
		window.addEventListener('keyup', (e) => this.addIndex(e))
	},
};
</script>

<style>
</style>
