<template>
	<div class="card-wrapper">
		<div class="card">
			<div class="img-container">
				<img :src="`/img/super-admin/stats/${imgName}.svg`" alt="" />
			</div>
			<p class="title">{{ title }}</p>
			<p>{{ done }} Faits</p>
			<p>{{ total }} Total</p>
			<p>{{ calculatedPercentage }} %</p>
		</div>
		<p class="coef">Coef {{ coef }}</p>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		done: Number,
		total: Number,
		calculatedPercentage: Number,
		coef: Number,
		imgName: String
	},

}
</script>

<style>
</style>
