<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-calendar principe">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Principe</p>
			<p>
				Il s'agit d'un calendrier de l'Avent personnalisable où vous
				pourrez choisir une activité à réaliser avec les élèves pour
				chaque case du calendrier. Différentes capsules vous sont
				proposées :
			</p>
			<p>
				- Exercices : des exercices de révision sur
				différents chapitres du niveau choisi <br />
				- Énigmes : des questions de logique<br />
				- Défis : des problèmes à réaliser à 2 ou plus.<br />
				- Vidéos : des situations concrètes en rapport avec la discipline<br />
			</p>

			<p>
				Ces capsules sont regroupées en 2 catégories : Cases Cours et
				Cases A Distance. Ce sont uniquement des conseils, au regard de
				la durée ou de la difficulté des activités.
			</p>
			<p>
				Vous pouvez débuter chaque séance de cours par la découverte de
				la case du jour, en vidéo-projetant le calendrier. Chaque case
				est conçue pour durer 10 min maximum. Pour motiver les élèves,
				n'hésitez-pas à ajouter certaines règles ou un système de
				récompenses (petits cadeaux symboliques, friandises, …).
				Concernant les séances où les élèves n'ont pas cours avec vous,
				ce peut être donné comme travail obligatoire ou facultatif, à
				vous de voir! <br />
				Chaque case sera accessible uniquement le jour J.
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
