<template>
	<h1>Add chapter</h1>
	<form @submit.prevent="submit" class="form-container">
		<div class="input-container">
			<label for="Difficulty">Name</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Name"
				placeholder="Name"
			/>
		</div>
		<div class="input-container">
			<label for="">Level</label>
			<select v-model="level" id="level">
				<option value="" selected disabled hidden>
					Choose a level
				</option>
				<option
					v-for="level in this.$store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="">Matière</label>
			<select v-model="subject" id="subject">
				<option value="" selected disabled hidden>
					Choisir une matière
				</option>
				<option
					v-for="subject in this.$store.state.subjects"
					:key="subject"
					:value="subject.id"
				>
					{{ subject.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Difficulty">Sequence</label>
			<select id="classes" v-model="form.sequence">
				<option value="" selected disabled hidden>
					Choisir une séquence
				</option>
				<option
					v-for="sequence in getSequences()"
					:key="sequence"
					:value="sequence.id"
				>
					{{ sequence.name }}, {{ getLevel(sequence.level) }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Order">Order</label>
			<input type="number" v-model="form.order" id="Order" />
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
export default {
	data() {
		return {
			form: {
				name: '',
				sequence: '',
				order: 0
			},
			level: null,
			subject: null,
			errors: '',
			status: ''
		}
	},
	methods: {
		submit() {
			this.$store.dispatch('addChapter', this.form)
				.then(() => {
					this.status = 'Enregistré avec succès'
					this.form.name = ""
					setTimeout(() => {
						this.status = ''	
					}, 2000)
				})
		},
		getLevel: function (e) {
			let level = this.$store.state.levels.find(x => x.id == e)
			return level.name
		},
		getSequences() {
			if (!this.subject || !this.level) return []
			return this.$store.state.sequences.filter(x => x.subject == this.subject && x.level == this.level)
		}
	},
}
</script>

<style>
</style>
