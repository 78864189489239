<template>
	<div class="class-resource-container">
		<table-wrapper />
	</div>
</template>

<script>
import { crypt } from '../../../../utils'
import TableWrapper from './TableWrapper.vue'
export default {
	components: {
		TableWrapper
	},
	methods: {
		crypt: crypt,
	},
}
</script>

<style>
</style>
