<template>
	<td
		class="td-center resource-action"
		v-if="type == 'Activité rituelle' || type == 'PACKAGE'"
	>
		<router-link
			:to="{
				name: 'DisplayActivity',
				params: { id: id },
			}"
			><img  src="/img/play.svg" />
			</router-link
		>
	</td>
	<td
		class="td-center resource-action"
		v-if="type == `Calendrier de l'Avent`"
	>
		<router-link
			:to="{
				name: 'DisplayCalendar',
				params: { classId: crypt($route.params.id), calendarId: id },
			}"
			><img  src="/img/play.svg" />
			</router-link
		>
	</td>
	<td class="td-center resource-action" v-else-if="type == `TileGame`">
		<router-link
			:to="{
				name: finished ? 'TileGameLeaderBoard' : 'TileGameHome',
				params: { id: id },
			}"
			><img src="/img/play.svg" /></router-link
		>
	</td>
	<td
		class="td-center download resource-action"
		v-else-if="
			type == 'Évaluation' ||
			type == 'ExerciseSheet' ||
			type == 'EVALUATION' ||
			type == 'EXERCISE_SHEET'
		"
		@click="showPopUpPdf(id, type)"
	>
		<img src="/img/class/download-icon.svg" alt="" />
		Télécharger
	</td>
	<td
		class="td-center download resource-action"
		v-else-if="type == 'Leçon'"
		@click="
			downloadLesson({
				url: file_upload,
				label: name,
			})
		"
	>
		<img src="/img/class/download-icon.svg" alt="" />
		Télécharger
	</td>
	<td class="td-center download resource-action"  v-else-if="type == 'QCM'"> 
		<div style="display: flex;">
			<router-link :to="{name:'ViewQcm', params: {id:id}}">
				<img  src="/img/play.svg" />
			</router-link> 
			<router-link :to="{name:'ViewQcmNote', params: {id:id}}">
				<img  src="/img/class/stats.png" />
			</router-link> 
		</div>
	</td>
	<Teleport to="body">
		<pop-up-latex-pdf
			v-if="isPopUpPdfVisible"
			@closed="isPopUpPdfVisible = !isPopUpPdfVisible"
			:exerciseId="selectedRessourceId"
			:name="name"
			:type="selectedRessourceType"
		/>
	</Teleport>
</template>

<script>
import { authenticatedImageFetch, crypt } from '../../../../utils'
import PopUpLatexPdf from '../../../PopUp/ExerciseSheet/PopUpLatexPdf.vue'
export default {
	components: { PopUpLatexPdf },
	props: {
		type: String,
		id: Number,
		finished: Boolean,
		name: String,
		file_upload: String
	},
	data() {
		return {
			selectedRessourceId: null,
			selectedRessourceType: null,
			isPopUpPdfVisible: false
		}
	},
	methods: {
		crypt: crypt,

		showPopUpPdf(id, type) {
			this.isPopUpPdfVisible = !this.isPopUpPdfVisible
			this.selectedRessourceId = id
			this.selectedRessourceType = type
		},
		async downloadLesson({ url, label }) {
			let re = /(?:\.([^.]+))?$/
			let ext = re.exec(url)[1]
			const response = await authenticatedImageFetch(url, { method: 'GET', responseType: "blob" })
				.then((response) => response.blob())

			const blob = new Blob([response])
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = label + '.' + ext
			link.click()
			URL.revokeObjectURL(link.href)
		},
	},
}
</script>

<style>
</style>
