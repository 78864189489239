<template>
	<section class="pop-up-container">
		<div class="fake-container-pop-up" @click="this.$emit('close')"></div>
		<div class="pop-up not-white">
			<slot></slot>
		</div>
	</section>
</template>

<script>
export default {

}
</script>

<style>
</style>
