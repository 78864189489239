<template>
	<h1>Leçon</h1>
	<nav class="paginate">
		<ul v-if="pages !== null">
			<li v-for="(i, index) in pages" :key="index">
				<button
					:class="index == currentPage ? 'active' : ''"
					@click="getNewQuestions(index)"
				>
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Title</th>
			<th>Niveau</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(key, index) in lessons" :key="index">
			<td>{{ index + 1 * currentPage * 20 + 1 }}</td>
			<td>{{ key.name }}</td>
			<td>
				{{ this.$store.getters.level(key.level_id).name }}
			</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditLesson',
						params: { id: key.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteKey(key.id)">supprimer</button>
			</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			lessons: [],
			chapters: [],
			sequences: [],
			levels: [],
			count: null,
			currentPage: 0,
			pages: null
		}
	},
	methods: {
		deleteKey(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/lessons/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.allLessons()
					})
			}
		},

		allLessons: function () {
			authenticatedApiFetch(`/lessons/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.lessons = res
				})
		},
	},
	created() {
		this.allLessons()
	},
}
</script>

<style>
</style>
