<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-new-course">
			<h3><img src="/img/class/plus.svg" alt="" /> Modifier un Cours</h3>
			<div class="input-wrapper">
				<div class="input-container">
					<label class="custom-label" for="start-hour"
						>Heure de début</label
					>
					<input
						v-model="agenda.debut_hour"
						id="start-hour"
						type="time"
					/>
				</div>
				<div class="input-container">
					<label class="custom-label" for="end-hour"
						>Heure de fin</label
					>
					<input
						v-model="agenda.end_hour"
						id="end-hour"
						type="time"
					/>
				</div>

				<div class="input-container">
					<label for="">Salle de classe</label>
					<input
						placeholder="Ajouter une salle"
						v-model="agenda.room_number"
						type="text"
					/>
				</div>

				<div class="btn-container">
					<button @click="close" class="cancel">Annuler</button>
					<button @click="submit" class="add">Ajouter</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { getAgendaService, udpateAgendaService } from '../../../services/agendasService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		id: Number
	},
	emits: ["close", "refresh"],
	data() {
		return {

			agenda: {
			},
		}
	},


	methods: {
		close() {
			this.$emit('close')
		},
		async submit() {
			await udpateAgendaService(this.id, this.agenda)
			this.$emit('close')
			this.$emit('refresh')
		},
		getCourse: async function () {
			this.agenda = await getAgendaService(this.id)
		},
	},
	created() {
		this.getCourse()
	},
}
</script>
