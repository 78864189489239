<template>
	<div class="widget-wrapper">
		<h2>Mes outils</h2>
		<div class="tools-wrapper tool">
			<div class="widget" v-for="tool in tools" :key="tool.id">
				<img
					@click="deleteTool(tool.id)"
					class="delete"
					src="/img/close.svg"
					alt=""
				/>
				<router-link
					class="link-widget"
					:to="{ name: tool.widget_name }"
				>
					<img
						class="tool-img"
						:src="getImg(tool.widget_name)"
						alt=""
					/>
					<p>{{ getTranslatedToolName(tool.widget_name) }}</p>
				</router-link>
			</div>
			<button
				@click="isPopUpVisible = !isPopUpVisible"
				class="add-widget"
			>
				+
			</button>
		</div>
	</div>
	<pop-up-new-tool
		@close="isPopUpVisible = !isPopUpVisible"
		@refreshTool="getTools"
		v-if="isPopUpVisible"
	/>
</template>

<script>
import { allWidgetsByUserService, deleteWidgetService } from '../../../../services/widgetsService'
import PopUpNewTool from "../../../PopUp/Dashboard/PopUpNewTool.vue"

export default {
	components: { PopUpNewTool },
	data() {
		return {
			isPopUpVisible: false,
			tools: []
		}
	},

	methods: {
		getTranslatedToolName(name) {
			switch (name) {
				case 'NamePicker':
					return 'Tirage au sort'
				case 'Timer':
					return 'Chrono'
				default:
					return name
			}
		},
		getImg(e) {
			let img

			switch (e) {
				case 'NamePicker':
					img = '/img/tools/name-picker.svg'
					break
				case 'Timer':
					img = '/img/tools/timer.svg'
					break
			}

			return img
		},
		async deleteTool(id) {
			if (confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
				await deleteWidgetService(id)
				this.tools = this.tools.filter(x => x.id !== id)
			}
		},

		async getTools() {
			this.tools = await allWidgetsByUserService(this.$store.state.user.user, 'tool')
		}
	},

	created() {
		this.getTools()
	},

}
</script>

<style>
</style>
