
<template>
	<section class="propose-key-container">
		<h1>
			Créer une Activité Expresso
			<img @click="isPopUp = !isPopUp" src="/img/info-bulle.svg" alt="" />
		</h1>
		<div class="title-container">
			<hr />
			<h2>Page 1</h2>
			<hr />
		</div>

		<div class="basic-info-container">
			<div class="input-container">
				<label for="">Titre de l'activité</label>
				<input v-model="form.title" type="text" />
			</div>
			<div class="input-container">
				<label for="">Niveau</label>
				<select id="niveaux" v-model="form.level" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="">Chapitre</label>
				<select v-model="form.chapter" name="chapter" id="chapter">
					<optgroup
						v-for="sequence in this.$store.getters.sequences(
							form.level
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
		</div>
		<slide
			:ref="`slide${index}`"
			v-for="(slide, index) in this.slides"
			:key="slide.id"
			:index="index + 1"
			:slide="slide"
			:editable="true"
			:creating="true"
			@removeSlide="removeSlide"
		/>
		<div class="btn-container">
			<button @click="addSlide">Ajouter une diapo</button>
			<button @click="submit">Valider</button>
		</div>
		<pop-up-explaination @close="isPopUp = false" v-if="isPopUp" />
	</section>
</template>
<script>
import { createProposedKeyService } from '../../../services/proposedKeysService'
import { authenticatedApiFetch } from '../../../utils'
import PopUpExplaination from '../../PopUp/SuperAdmin/PopUpExplaination.vue'
import Slide from './Slide.vue'
export default {
	components: { Slide, PopUpExplaination },
	data() {
		return {
			form: {
				title: '',
				level: '',
				chapter: '',
				teacher: ''
			},
			slideAmount: 1,
			slides: [
				{
					id: 1,
					title: '',
					text: '',
					designer_note: '',
					images: [

					]
				}
			],
			isPopUp: false,
		}
	},
	methods: {
		addSlide() {
			this.slides.push(
				{
					id: this.slides.length + 1,
					title: '',
					text: '',
					designer_note: '',
					teacher_note: '',
					images: [

					]
				}
			)
		},
		removeSlide(id) {
			this.slides = this.slides.filter(x => x.id !== id)

		},
		checkForm() {
			if (!this.form.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ titre',
					status: 'error'
				}
				return false
			}
			if (!this.form.level) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ niveau',
					status: 'error'
				}
				return false
			}
			if (!this.form.chapter) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ chapitre',
					status: 'error'
				}
				return false
			}
			return true
		},
		async submit() {
			if (this.checkForm()) {
				this.form.teacher = this.$store.state.user.user
				const res = await createProposedKeyService(this.form)

				const proposedKeyId = res.id

				for (let i = 0; i < this.slides.length; i++) {

					const object = this.$refs[`slide${i}`].form
					const data = new FormData()

					data.append('title', object.title)
					data.append('text', object.text)
					data.append('designer_note', object.designer_note)
					data.append('teacher_note', object.teacher_note)
					object.images.forEach((img, index) => {
						data.append(`image_url_${index + 1}`, img.file)
					})
					data.append('proposed_key', proposedKeyId)


					await authenticatedApiFetch('/proposed-key-slides/', {
						method: 'POST',
						body: data,
						headers: {}
					})
						.then(res => res.json())
				}
				this.$router.go(-1)
			}
		}


	},
}
</script>
