<template>
	<section class="student-place-container">
		<div
			v-if="
				checkPage &&
				$route.name !== 'DisplayCalendar' &&
				$route.name !== 'CalendarExercise' &&
				$route.name !== 'StudentPlaceQcm' &&
				$route.name !== 'StudentPlaceLogin'
			"
			class="classe-container"
		>
			<router-link
				v-if="$route.name !== 'StudentPlaceHome'"
				:to="{ name: 'StudentPlaceHome' }"
				>Retour</router-link
			>
			<router-link
				v-else-if="$store.state.user"
				:to="{ name: 'ClassResources', params: { id: classId } }"
				><img src="/img/logo-white.svg" alt=""
			/></router-link>
		</div>
		<div
			v-if="
				checkPage &&
				$route.name !== 'DisplayCalendar' &&
				$route.name !== 'CalendarExercise'&&
				$route.name !== 'StudentPlaceQcm' &&
				$route.name !== 'StudentPlaceLogin'

			"
			class="return-container"
		>
			<p>{{ classe.name }}</p>
		</div>
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="main-component" :is="Component"></component>
			</transition>
		</router-view>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt } from '../../utils'
export default {
	data() {
		return {
			classId: null,
			classe: {}
		}
	},
	methods: {

		async getClasse() {
			const res = await axios.get(`${Config.backend_url}/api/v1/classes/${this.classId}`)
			this.classe = res.data
		},
	},
	computed: {
		checkPage() {
			if (this.$route.name == "StudentPlaceDisplayActivity")
				return false
			else {
				return true
			}
		}
	},
	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getClasse()
	},
}
</script>

<style>
</style>
