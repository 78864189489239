<template>
	<h1>Suggestions</h1>
	<router-link :to="{ name: 'SuggestionDetails' }">Détails</router-link>
	<router-link :to="{ name: 'SuggestionStats' }">Stat</router-link>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component class="main-component" :is="Component"></component>
		</transition>
	</router-view>
</template>

<script>

export default {
	
	
}
</script>

<style>
</style>
