<template>
	<table>
		<tr>
			<th></th>
			<th>Nom</th>
			<th>Niveau</th>
			<th>Chapitre</th>
			<th>Type</th>
			<th>Date</th>
		</tr>
		<tr v-for="(suggestion, index) in suggestions" :key="index">
			<td>{{ index + 1 }}</td>
			<td>{{ suggestion.username }}</td>
			<td>{{ suggestion.level_name }}</td>
			<td>{{ suggestion.chapter_name }}</td>
			<td>{{ getRealTypeName(suggestion.type) }}</td>
			<td>{{ formatedDate(suggestion.created_at) }}</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'

export default {
	data() {
		return {
			suggestions: [],
			count: null,
			currentPage: 0,
		}
	},
	methods: {
		formatedDate(date) {
			if (date) {
				return date.slice(0, 10)
			}
		},
		getRealTypeName(type) {
			switch (type) {
				case 'ACTIVITY':
					return 'Question flash'
				case 'Activité rituelle':
					return 'Question flash à la carte'
				case 'PACKAGE':
					return 'Activité Expresso'
				case 'ExerciseSheet':
					return 'Exercice Classico'
				case 'EXERCISE_SHEET':
					return 'Exercice Classico'
				case 'EVALUATION':
					return 'Exercice Classico'
				case 'Evaluation':
					return 'Exercice Classico'
				case 'Game':
					return 'Jeu Sérieux'
				case `Calendrier de l'Avent`:
					return 'Jeu Sérieux'
				case 'TileGame':
					return 'Jeu Sérieux'
				default:
					return type
			}
		},
		async allSuggestions() {
			const res = await authenticatedApiFetch('/suggestions/', { method: 'GET' })
				.then(res => res.json())
			this.suggestions = res


		}
	},
	created() {
		this.allSuggestions()
	},
}
</script>

<style></style>
