<template>
	<section class="calendar-header">
		<div class="day-wrapper" v-for="day in week" :key="day">
			<p>{{ day.day }}</p>
			<p>{{ day.number }} {{ day.month }}</p>
		</div>
	</section>
</template>

<script>
export default {
	props: {
		week: Array
	}
}
</script>

<style>
</style>
