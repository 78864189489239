<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-guide">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Comment ça marche ?</p>
			<p class="intro-key">
				Vous pouvez créer ici des activités clés en main.<br />
				Ce sont des diaporamas non modifiables à vidéo-projeter en
				classe.
			</p>
			<br />
			<ul class="intro-key list">
				<li>
					<b> Activité d"introduction</b> (brève introduction du
					chapitre, repères historiques, utilité, prérequis )
				</li>
				<li>
					<b>Activité de motivation </b> (sur une thématique
					particulière, 3 à 5 questions de difficulté croissante)
				</li>
			</ul>

			<iframe
				allowfullscreen
				class="video"
				:src="url"
				frameborder="0"
			></iframe>

			<p class="paragraph">
				Pour en savoir plus sur la mise en place en classe, leurs
				intérêts... <br />
				<a target="_blank" :href="`${landing}/faq`">Consulter la FAQ</a>
				😉
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	emits: ["close"],
	data() {
		return {
			dontShow: false,
			landing: Config.landing_url,
			url: 'https://www.youtube.com/embed/BudBlTwpI40',
			type: 'key',
		}
	},
}

</script>

<style>
</style>
