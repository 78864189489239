<template>
	<div class="each-content" :class="getClasses()">
		<img :src="getImg(product.img)" alt="" />
		<div class="text-price-container">
			<div class="title-text-container">
				<p class="title" :style="getColor()">
					{{ product.title }}
				</p>
				<p>{{ product.text }}</p>
			</div>
			<div>
				<p
					v-if="isAlreadyOwned && !cancelled"
					:style="getColor()"
					class="small"
				>
					Renouvellement automatique le {{ subscriptionEnd }}
				</p>
				<p v-else-if="cancelled" :style="getColor()" class="small">
					Fin de l'abonnement le {{ subscriptionEnd }}
				</p>
				<p v-else-if="isFreeTrial" :style="getColor()" class="small">
					Fin de la version d'essai le
					{{
						formatedDate(this.$store.state.freeTrial.free_trial_end)
					}}
				</p>
				<button
					v-if="isAlreadyOwned && !cancelled"
					@click="showPopUpCancelSubscription"
					class="cancel"
				>
					Résilier
				</button>
			</div>
		</div>
		<Teleport to="body">
			<pop-up-cancel-subscription
				@close="
					isPopUpCancelSubscriptionVisible =
						!isPopUpCancelSubscriptionVisible
				"
				v-if="isPopUpCancelSubscriptionVisible"
				:product="product.title"
				:subscriptionEnd="subscriptionEnd"
				@refreshProducts="$emit('refreshProducts')"
			/>
		</Teleport>
	</div>
</template>

<script>
import { formatedDate } from '../../../utils'
import PopUpCancelSubscription from '../../PopUp/Profile/PopUpCancelSubscription.vue'
export default {
	components: { PopUpCancelSubscription },
	props: {
		product: Object,
		isAlreadyOwned: Boolean,
		subscriptionEnd: String,
		isFreeTrial: Boolean,
		cancelled: {
			type: Boolean,
			default: false
		}
	},
	data() {
		return {
			isPopUpCancelSubscriptionVisible: false
		}
	},
	methods: {
		formatedDate: formatedDate,
		showPopUpCancelSubscription() {
			this.isPopUpCancelSubscriptionVisible = !this.isPopUpCancelSubscriptionVisible
		},
		getImg(name) {
			if (this.isFreeTrial) {
				return name
			}
			if (!this.isAlreadyOwned) {
				name = name.replace(/\.svg$/, '-grey.svg')
			}
			return name
		},
		getColor() {
			if (this.isFreeTrial || this.isAlreadyOwned) {
				return `color:${this.product.color}`
			}
			return `color:#A5A5A5`
		},
		getClasses() {
			if (this.isFreeTrial || this.isAlreadyOwned) {
				return 'already_owned'
			}
			return ''
		}
	},
}
</script>

<style>
</style>
