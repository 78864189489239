<template>
	<div class="widget-wrapper">
		<h2>Ma calculatrice</h2>
		<div class="tools-wrapper calculator">
			<div
				class="widget"
				v-for="calculator in calculators"
				:key="calculator.id"
			>
				<img
					@click="deleteTool(calculator.id)"
					class="delete"
					src="/img/close.svg"
					alt=""
				/>
				<a
					class="link"
					target="_blank"
					:href="getLink(calculator.widget_name)"
				>
					<img
						class="favicon"
						:src="`/img/tools/calculators/${getImg(
							calculator.widget_name
						)}`"
						alt=""
					/>
					{{ calculator.widget_name }}
				</a>
			</div>
			<button
				@click="isPopUpVisible = !isPopUpVisible"
				class="add-widget"
			>
				+
			</button>
		</div>
	</div>
	<pop-up-new-calculator
		@close="isPopUpVisible = !isPopUpVisible"
		@refreshTool="getTools"
		v-if="isPopUpVisible"
	/>
</template>

<script>
import { allWidgetsByUserService, deleteWidgetService } from '../../../../services/widgetsService'
import PopUpNewCalculator from "../../../PopUp/Dashboard/PopUpNewCalculator.vue"

export default {
	components: { PopUpNewCalculator },
	data() {
		return {
			isPopUpVisible: false,
			calculators: []
		}
	},

	methods: {
		getLink(e) {
			let link

			switch (e) {
				case 'Numworks':
					link = "https://www.numworks.com/fr/simulateur/"
					break
				case 'TI-83 CE':
					link = "https://maclasseti.fr/calculatrice"
					break
			}

			return link
		},
		getImg(e) {
			let link

			switch (e) {
				case 'Numworks':
					link = "numworks.png"
					break
				case 'Casio 35+ E':
					link = "casio-35.png"
					break
				case 'TI-83 CE':
					link = "ti-83.png"
					break
				case 'TI-Nspire':
					link = "ti-inspire.png"
					break
				case 'Casio FX-92':
					link = "casio-fx.png"
					break
				case 'TI-Collège Plus':
					link = "ti-college.png"
					break
			}
			return link
		},
		async deleteTool(id) {
			if (confirm("Êtes-vous sûr de vouloir supprimer cet élément ?")) {
				await deleteWidgetService(id)
				this.calculators = this.calculators.filter(x => x.id !== id)
			}
		},
		async getTools() {
			this.calculators = await allWidgetsByUserService(this.$store.state.user.user, 'calculator')
		}
	},

	created() {
		this.getTools()
	},

}
</script>

<style>
</style>
