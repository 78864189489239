<template>
	<h1>Départements</h1>
	<table>
		<tr>
			<th></th>
			<th>Name</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr
			v-for="(county, index) in this.$store.state.county"
			:key="index"
		>
			<td>{{ index + 1 }}</td>
			<td>{{ county.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditCounty',
						params: { id: county.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteCounty(county.id)">
					supprimer
				</button>
			</td>
		</tr>
	</table>
</template>

<script>

export default {
	data() {
		return {
			county: [],
		}
	},
	methods: {
		deleteCounty(e) {
			this.$store.dispatch('deleteCounty', e)
		},
	},
}
</script>

<style>
</style>
