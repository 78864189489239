import { authenticatedApiFetch } from '../utils'


export const allFilesForClassService = async (classId, parentFolder = '') => {
    const res = await authenticatedApiFetch(`/files/?classes=${classId}&parent_folder_id=${parentFolder}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createFileService = async (data) => {
    const res = await authenticatedApiFetch('/files/', {
        method: 'POST',
        body: data,
        headers: {}
    })
        .catch(error => console.error(error))
    return res
}


export const deleteFileService = async (id) => {
    const res = await authenticatedApiFetch(`/files/${id}/`, { method: 'DELETE' })
        .catch(error => console.error(error))
    return res
}

export const updateFileService = async (id, data) => {
    const res = await authenticatedApiFetch(`/files/${id}/`, {
        method: 'PATCH',
        body: JSON.stringify(data),
    })
        .catch(error => console.error(error))
    return res
}
