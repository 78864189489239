<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Modifier votre ressource</p>
			<p>
				Pour enregistrer votre ressource, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container">
				<label for="classes">Classe</label>
				<select
					class="required"
					v-if="selectedClasse !== null"
					id="classes"
					v-model="selectedClasse"
					@change="updateSelect"
				>
					<option
						v-for="classe in $store.state.classes"
						:key="classe.id"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
				<small class="error">{{ errors.classe }}</small>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select
					v-if="selectedClasse"
					v-model="activity.currency"
					id="date"
				>
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>
			<button @click="submit" class="submit dark">Attribuer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { getActivityByIdService, updateActivityService } from '../../services/activitiesService'
import { allActivityEventsFilterService } from '../../services/activityEventsService'
import { getOccurenceByIdService, updateOccurenceService } from '../../services/occurrencesService'
import { formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		id: Number,
	},
	data() {
		return {
			activity: {},
			selectedClasse: null,
			index: 0,
			classes: [],
			teacher: localStorage.userId,
			rituals: [],
			courses: [],
			agenda: {
			},
			errors: {}
		}
	},
	created() {
		this.getActivity()
		this.allCourses()
	},
	methods: {
		close() {
			this.$emit('close')
		},
		async submit() {
			if (!this.selectedClasse) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter une classe à votre ressource'
				}
			}
			else {
				let classArray = this.selectedClasse
				const body = {
					name: this.activity.name,
					classes: [classArray],
				}
				await updateActivityService(this.id, body)

				if (this.activity.currency == undefined) {
					console.log('no agenda')
				}
				else {
					let occurrence = await getOccurenceByIdService(this.activity.currency)
					occurrence.activities.push(this.id)
					await updateOccurenceService(this.activity.currency, occurrence)

					let patchedOccurence = this.courses.find(e => {
						return e.activities.find(x => x == this.id)
					})

					if (patchedOccurence) {
						patchedOccurence.activities = patchedOccurence.activities.filter(x => x !== this.id)
					}
					if (patchedOccurence && this.oldCurrency !== this.activity.currency) {
						await updateOccurenceService(patchedOccurence.id, patchedOccurence)
					}
				}
				this.$emit('refreshActivities')
				this.$emit('close')
			}
		},

		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}
			let courseActivity = this.courses.find(e => {
				return e.activities.find(x => x == this.id)
			})
			if (courseActivity) {
				this.activity.currency = courseActivity.id
				this.oldCurrency = courseActivity.id
			}

		},
		async getActivity() {
			const res = await getActivityByIdService(this.id)
			this.activity = res
			this.selectedClasse = this.activity.classes[0]
		},

		getClasse(e) {
			if (this.classes.length !== 0) {
				let classe = this.classes.find(x => x.id == e)
				return classe.name
			}
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.selectedClasse)
			return courses
		}
	},
}
</script>

<style>
</style>
