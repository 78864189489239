import { authenticatedApiFetch } from '../utils'

export const getChristmasCalendarExerciseService = async (id) => {
    const res = await authenticatedApiFetch(`/christmas-calendar-exercises/${id}/`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
export const createChristmasCalendarExerciseService = async (data) => {
    const res = await authenticatedApiFetch(`/christmas-calendar-exercises/`, { method: 'POST', body: data, headers: {} })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const allChristmasCalendarExercisesFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/christmas-calendar-exercises/?${filter}`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}



