<script setup>
import { ref, onMounted, defineEmits } from 'vue'
import PopUpWrapper from '../../PopUpWrapper.vue'

const latex = ref('')

const emit = defineEmits(['insert-latex', 'close'])

onMounted(() => {
    const mathField = document.getElementById('latex')
    mathField.focus()
    mathField.addEventListener("input", () => {
        latex.value = mathField.value
    })
})

const insertLatex = () => {
    emit('insert-latex', latex.value)
}

const close = () => {
    emit('close')
}

</script>
<template>
    <PopUpWrapper @close="close">
        <div class="pop-up-insert-latex">
            <img class="close" @click="close()" src="/img/close.svg" alt="" />
            <p class="title">Ajouter une formule mathématique</p>
            <math-field id="latex" virtualKeyboardMode="manual">
            </math-field>
            <button class="submit dark" @click="insertLatex()">Valider</button>
        </div>
    </PopUpWrapper>
</template>
<style lang="scss" scoped>
.pop-up-insert-latex {
    width: 30vw;
    min-height: 30vh;
    display: grid;
    place-items: center;
    position: relative;
	padding: 4rem;

    math-field {
        width: 100%;
        border: 1px solid black;
        font-size: 2rem;
    }
}
</style>
