import { authenticatedApiFetch } from "../utils"

export const allQuestionsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/questions/${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getQuestionService = async (id) => {
    const res = await authenticatedApiFetch(`/questions/${id}/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const createQuestionService = async (question) => {
    const res = await authenticatedApiFetch(`/questions/`, { method: 'POST', body: question, headers: {} })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
export const updateQuestionService = async (id, question) => {
    const res = await authenticatedApiFetch(`/all-questions/${id}/`, {
        method: 'PUT',
        body: question,
        headers: {}
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
