<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-free-trial">
			<img class="close" @click="close" src="/img/close.svg" alt="" />

			<div class="text-container">
				<p>
					L'essai gratuit à la plateforme donne accès à l'ensemble des
					fonctionnalités et à l'ensemble des contenus.
				</p>
				<br />
				<p>
					Cet essai est non renouvelable, il commence immédiatement et
					prend fin au bout d'un mois.
				</p>
			</div>
			<button v-if="checkDate()" @click="freeTrial" class="submit light">
				Commencer mon essai gratuit
			</button>
			<button v-else @click="showError" class="submit dark disabled">
				Commencer l'essai gratuit
			</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { getSubscriptionService } from '../../../services/subscriptionsService'
import { authenticatedApiFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	data() {
		return {
			subscription: {},
			error: {
				text: '',
				status: ''
			}
		}
	},

	methods: {
		showError() {

			const date = new Date(this.$store.state.freeTrial.free_trial_end)

			const options = { year: 'numeric', month: 'long', day: 'numeric' }
			const dateFormat = new Intl.DateTimeFormat('fr-FR', options)

			const formattedDate = dateFormat.format(date)
			this.error.status = 'error'
			this.error.text = `Votre essai gratuit a pris fin le ${formattedDate}`
		},
		checkDate() {
			if (this.$store.state.freeTrial.free_trial_end) {
				const freeTrialEndDate = new Date(this.$store.state.freeTrial.free_trial_end)

				// Obtenir la date actuelle
				const currentDate = new Date()

				// Comparer les dates
				if (freeTrialEndDate > currentDate) {
					return true
				}
			}
			else {
				return true
			}
		},
		resetError() {
			this.error = {
				text: '',
				status: ''
			}
		},
		close() {
			this.$emit('close')
		},
		async freeTrial() {
			if (this.$store.state.freeTrial.free_trial_start == null) {
				const res = await authenticatedApiFetch('/get-free-trials/', { method: 'GET' })
					.then(res => res.json())
					.then(response => {
						return response[0]
					})
					.catch(err => {
						console.error(err)
					})
				this.$store.dispatch('getSubscription')
				this.subscription = res
				this.error.status = 'success'
				this.error.text = `Votre essai gratuit est activé. Il prendra fin le ${this.subscription.free_trial_end}`
				this.$emit('showPopUp', this.error)
				this.close()
			}
			else {
				this.error.status = 'error'
				this.error.text = `Tu as déjà utilisé ton essai gratuit`
				this.$emit('showPopUp', this.error)
			}
		},
		async checkSubscription() {
			this.subscription = await getSubscriptionService()
		}
	},

	created() {
		this.checkSubscription()
	},
}
</script>

<style>
</style>
