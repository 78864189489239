<template>
	<add-question class="admin-wrapper" />
</template>

<script>
import AddQuestion from "../../../../SuperAdmin/Flash/AddQuestion.vue"

export default {
	components: { AddQuestion },
}
</script>

<style>
</style>
