<template>
	<h1>Niveaux</h1>
	<nav class="paginate">
		<ul v-if="count !== null">
			<li v-for="(i, index) in paginate()" :key="index">
				<button
					:class="index == currentPage ? 'active' : ''"
					@click="getNewLevels(index)"
				>
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Nom</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(level, index) in this.$store.state.levels" :key="index">
			<td>{{ index + 1 }}</td>
			<td>{{ level.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditLevel',
						params: { id: level.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td><button @click="deleteLevel(level.id)">supprimer</button></td>
		</tr>
	</table>
</template>

<script>

export default {
	data() {
		return {
			levels: [],
			count: null,
			currentPage: 0
		}
	},
	methods: {
		deleteLevel(e) {
			this.$store.dispatch('deleteLevel', e)
		},
	},
}
</script>

<style>
</style>
