import { authenticatedApiFetch } from '../utils'


export const allObservationsForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/observations/?classe=${classId}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allObservationsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/observations/${filter}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteObservationService = async (id) => {
    const res = await authenticatedApiFetch(`/observations/${id}/`, { method: 'DELETE' })
    return res
}


export const createObservationService = async (data) => {
    const res = await authenticatedApiFetch(`/observations/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
