<template>
	<pop-up-wrapper>
		<div class="pop-up-share-student-credentials">
			<img @click="close" class="close" src="/img/close.svg" alt="" />
			<p class="title">Identifiants élèves</p>
			<table>
				<thead>
					<tr>
						<th>Nom</th>
						<th>Identifiant</th>
						<th>Code</th>
					</tr>
				</thead>

				<tbody>
					<Row v-for="student in students" :key="student.id" :student="student"/>
				</tbody>
				
			</table>
				
		</div>
	</pop-up-wrapper>
</template>
<script>
import { allStudentsInClasseService } from '../../../services/studentsService'
import PopUpWrapper from '../PopUpWrapper.vue'
import Row from './PopUpShareStudentCredentials/Row.vue'
export default{
	components:{ PopUpWrapper, Row },
	props:{
		id: String
	},
	data(){
		return{
			students: []
		}
	},
	methods:{
		close(){ 
			this.$emit('close')
		},
		async getStudents(){
			const res = await allStudentsInClasseService(this.id)
			this.students = res
		}


	},	
	created(){
		this.getStudents()
	}
}
</script>
<style lang="scss" scoped>
@import '../../../assets/scss/pop-up/pop-up-share-student-credentials.scss';
</style>
