<template>
	<pop-up-wrapper-without-background>
		<div class="pop-up tile-pop-up create-team">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Équipes</p>
			<div class="input-container">
				<input
					v-model="team"
					@keydown.enter="createTeam"
					type="text"
					placeholder="Équipe (8 max)"
				/>
				<button
					@click="createTeam"
					:class="team.length !== 0 ? 'active' : ''"
					class="btn"
				>
					Ajouter
				</button>
			</div>

			<div class="teams-container">
				<div class="team" v-for="team in copiedTeams" :key="team">
					<div class="indicator-wrapper">
						<span
							class="indicator"
							:style="`--color:${team.color}`"
						></span>
						<p>{{ team.name }}</p>
					</div>
					<div class="score-delete-container">
						<input
							@change="updateScore(team.id, $event)"
							:value="team.points"
							type="number"
							min="0"
						/>

						<img
							class="delete-class"
							@click="deleteTeam(team.id)"
							src="/img/close.svg"
							alt=""
						/>
					</div>
				</div>
				<div v-if="copiedTeams.length == 0"> <p class="no-team">Aucune équipe créée</p></div>
			</div>
			<div class="btn-container">
				<button
					@click="validatePoints"
					class="btn-team"
					v-if="copiedTeams.length !== 0"
				>
					Valider
				</button>
				<button @click="disableTeams" class="btn-team" v-else>
					Continuer sans équipe
				</button>
			</div>
		</div>
	</pop-up-wrapper-without-background>
</template>

<script>
import { createTileGameTeamService, deleteTileGameTeamService, updateTileGameTeamService } from '../../../../services/tileGameTeamsService'
import PopUpWrapperWithoutBackground from '../../PopUpWrapperWithoutBackground.vue'
export default {
	components: { PopUpWrapperWithoutBackground },
	props: {
		teams: Array
	},
	data() {
		return {
			copiedTeams: [],
			team: '',
			colors: [
				'#827DFF',
				'#7dffa0',
				'#FF7DC2',
				'#FFC27D',
				'#C77DFF',
				'#f0ff6b',
				'#20b56a',
				'#1febf2',
			]
		}
	},

	methods: {
		async validatePoints() {
			for (const team of this.copiedTeams) {
				await updateTileGameTeamService(team.id, team)
			}
			this.$emit('getTeams')
			this.$emit('close')
		},
		updateScore(id, event) {
			let team = this.copiedTeams.find(x => x.id == id)
			team.points = parseInt(event.target.value)
		},
		disableTeams() {
			this.$store.commit('setTileGameTeamsDisabled', true)
			this.$emit('close')
		},
		async createTeam() {
			if (this.copiedTeams.length >= 8 || !this.team) {
				return
			}
			const length = this.copiedTeams.length
			let color = this.colors[length]
			const data = {
				tile_game_id: this.$route.params.id,
				name: this.team,
				points: 0,
				color: color
			}
			const res = await createTileGameTeamService(data)
			this.$store.commit('setTileGameTeamsDisabled', false)
			this.$emit('createTeam', res)
			this.copiedTeams = [...this.teams]
			this.team = ''
		},
		async deleteTeam(id) {
			await deleteTileGameTeamService(id)
			this.$emit('deleteTeam', id)
		}
	},
	mounted() {
		this.copiedTeams = JSON.parse(JSON.stringify(this.teams))
	},
	watch: {
		teams(newVal) {
			this.copiedTeams = newVal
		}
	},
}
</script>

<style>
</style>
