<template>
	<section class="question">
		<h1><img src="/img/payment/game-icon.svg" alt="" />Jeux Sérieux</h1>
		<p class="intro-key">
			Nous vous proposons ici un ensemble de jeux personnalisables pour
			vos classes.
			<br />
			Les jeux sont adaptés à des niveaux de classe et parfois à des
			chapitres particuliers.
		</p>
		<div class="game-wrapper">
			<game-container v-for="game in games" :key="game" :game="game" />
		</div>
	</section>
</template>

<script>
import Config from '../../../../config'
import { getTileGameInfo } from '../../../../utils'
import GameContainer from './GameContainer.vue'

export default {
	components: {
		GameContainer
	},
	data() {
		return {
			games: [
				{
					title: 'Unlock',
					pdf: '/pdf/a.pdf',
					img: '/img/game/unlock.jpg',
					url: `${Config.landing_url}/unlock`
				},
				{
					title: 'Math La Plage',
					pdf: '/pdf/a.pdf',
					img: '/img/game/demo-tile-game-winter.jpg',
					componentName: `TileGameCreate`
				},
				{
					title: "Calendrier de l'Avent",
					pdf: '/pdf/a.pdf',
					img: '/img/game/calendar/demo/snowy.jpg',
					componentName: `CalendarCreate`
				},


			]

		}
	},
	mounted() {
		this.games[1].title = 'Wander Game'
		this.games[1].img = getTileGameInfo(this.$store.state.tileGameTheme.theme).img
	},

}
</script>

<style>
</style>
