<template>
	<div class="slider">
		<span @click="prev" id="left" class="btn"></span>
		<span @click="next" id="right" class="btn"></span>
		<div class="slide" v-for="i in [currentSlide]" :key="i">
			<img
				style="width: 100%; aspect-ratio: 16/9"
				:src="currentImg"
				alt=""
			/>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		index: Number
	},
	data() {
		return {
			currentSlide: 0,
			images: [
				'/img/template/template1.jpg',
				'/img/template/template2.jpg',
			],
		}
	},
	methods: {
		next() {
			this.currentSlide += 1
			if (this.currentSlide == this.images.length) {
				this.currentSlide = 0
			}
			this.$emit('index', this.currentSlide)
		},
		prev() {
			this.currentSlide -= 1
			if (this.currentSlide < 0) {
				this.currentSlide = this.images.length - 1
			}
			this.$emit('index', this.currentSlide)
		},
	},
	computed: {
		currentImg() {
			return this.images[Math.abs(this.currentSlide) % this.images.length]
		}
	},
	created() {
		if (this.index !== undefined) {
			this.currentSlide = this.index
		}
		else {
			this.currentSlide = 1
		}
		this.$emit('index', Math.abs(this.currentSlide) % this.images.length)
	},
}
</script>
