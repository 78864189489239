<template>
	<Teleport to="body">
		<pop-up-wrapper @close="$emit('close')">
			<div class="pop-up-edit-student">
				<p class="title">Modifier Élève</p>
				<input type="text" v-model="name" />

				<div class="btn-container">
					<button @click="$emit('close')" class="cancel">
						Annuler
					</button>
					<div class="submit-container">
						<button class="delete" @click="deleteStudent">
							Supprimer
						</button>
						<button class="validate" @click="submit">
							Valider
						</button>
					</div>
				</div>
			</div>
		</pop-up-wrapper>
	</Teleport>
</template>


<script>
import PopUpWrapper from '../PopUpWrapper.vue'
import { deleteStudentService, updateStudentService } from '../../../services/studentsService'
export default {
	components: { PopUpWrapper },
	props: {
		student: Object
	},
	data() {
		return {
			name: ''
		}
	},

	methods: {
		async submit() {
			const data = {
				name: this.name,
				pos: this.student.pos
			}
			await updateStudentService(this.student.id, data)
				.then(() => {
					this.$emit('close')
					this.$emit('refreshStudent')
				})
		},
		async deleteStudent() {
			await deleteStudentService(this.student.id)
				.then(() => {
					this.$emit('close')
					this.$emit('refreshStudent')
				})
		}
	},

	created() {
		this.name = this.student.name
	},

}
</script>

<style>
</style>
