<template>
	<h1>Matières</h1>
	<nav class="paginate">
		<ul v-if="count !== null">
			<li v-for="(i, index) in paginate()" :key="index">
				<button
					:class="index == currentPage ? 'active' : ''"
					@click="getNewSubjects(index)"
				>
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Nom</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(subject, index) in this.$store.state.subjects" :key="index">
			<td>{{ index + 1 }}</td>
			<td>{{ subject.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditSubject',
						params: { id: subject.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteSubject(subject.id)">supprimer</button>
			</td>
		</tr>
	</table>
</template>

<script>

export default {
	data() {
		return {
			subjects: [],
			count: null,
			currentPage: 0
		}
	},
	methods: {
		paginate: function () {
			return Math.ceil(this.count / 20)
		},
		deleteSubject(e) {
			this.$store.dispatch('deleteSubject', e)
		},
	},
}
</script>

<style>
</style>
