<template>
	<section>
		<div v-if="checkRoute" class="check-phone">
			<a class="logo-container" :href="url"><img src="/img/logo.svg" alt="" /></a>
			<h1>La plateforme est accessible uniquement depuis un PC</h1>
			<a class="go-landing" :href="url">Retour</a>
		</div>
		<router-view v-if="loaded"></router-view>
		<loader v-else />
	</section>
</template>

<script>
import Config from "../config"
import { updateLoginCountService } from "../services/profilesService"
import Loader from "./Loader.vue"

export default {
	components: { Loader },
	data() {
		return {
			userId: null,
			loaded: false,
			url: Config.landing_url,
		}
	},
	methods: {

		checkIfLogged: async function () {
			if (localStorage.userId && localStorage.userId !== 'null') {
				await this.$store.dispatch('getProfile', localStorage.profileId)
				await this.$store.dispatch('getClasses')
				await this.$store.dispatch('getNextCourses')
				await this.$store.dispatch('getTileGameTheme')

				this.$store.dispatch('getLevels')
				this.$store.dispatch('getChapters')
				this.$store.dispatch('getSequences')
				this.$store.dispatch('getDifficulties')
				this.$store.dispatch('getSubjects')
				this.$store.dispatch('getMotifs')
				this.$store.dispatch('getExerciseQuestionTypes')
				this.$store.dispatch('getQuestionTypes')
				this.$store.dispatch('getQuestionThemes')
				this.$store.dispatch('getExerciseQuestionThemes')
				this.$store.dispatch('getAcademies')
				this.$store.dispatch('getCounty')
				this.loaded = true
			}
			else {
				location.replace(`${Config.landing_url}/auth/login`)
			}
		},
		checkRoute() {
			if (this.$route.path.includes('/logout')) {
				return false
			}
			else {
				return true
			}
		}
	},
	created() {
		this.checkIfLogged()
		updateLoginCountService()
	},
}
</script>

<style></style>
