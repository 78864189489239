<template>
	<pop-up-wrapper>
		<div class="pop-up-preview">
			<div class="pop-up-text-container">
				<div ref="imgContainer">
					<h2 class="question-order-title">{{ question.title }}</h2>
					<p
						ref="question"
						class="calcul"
						v-if="question.random_values !== null"
						:key="question.description"
						v-html="
							image(
								random(
									question.description,
									question.random_values
								),
								imageData
							)
						"
					/>
					<p class="calcul" v-else v-html="question.description" />
				</div>
				<div>
					<p class="calcul" >
						<b>Correction</b> <br>
						<i v-html="question.correction"></i>
					</p>
				</div>
				<button @click="this.$emit('close')" class="submit">
					Fermer
				</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedImageFetch, random, image } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	props: {
		question: Object
	},
	data() {
		return {
			imageData: ''
		}
	},
	methods: {
		image: image,
		random: random,
		close() {
			this.$emit('closed')
		},

		checkIndex(e) {
			this.form.index = e
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
	},
	created() {
		this.reRender()
		if (this.question.image_url_1) {
			this.getImage(this.question.image_url_1)
		}
	},
	mounted() {
		this.reRender()
		if (this.question.random_values) {
			let question = this.$refs['question']
			question.style.marginTop = `-${Object.keys(this.question.random_values).length * 2.5 - 1.6}rem`
		}
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		}
	}
}
</script>

<style>
</style>
