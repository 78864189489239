<template>
	<section class="check-horizontal-display-activity-wrapper">
		<div class="check-horizontal">
			<p>Pour afficher l'activité merci de tourner votre écran...</p>
			<img src="/img/rotating-phone.gif" alt="" />
			<p>Pensez à déverrouiller la rotation de votre écran !</p>
		</div>
		<display-activity />
	</section>
</template>

<script>
import DisplayActivity from '../Admin/DisplayActivity.vue'
export default {
	components: {
		DisplayActivity
	}
}
</script>

<style>
</style>
