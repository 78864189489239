<template>
	<pop-up-wrapper>
		<div class="pop-up fill-input-question">
			<img class="close" @click="this.$emit('close')" src="/img/close.svg" alt="" />
			<p class="title">Proposer une question</p>

			<div>
				<label for="">Template</label>
				<slide-maker ref="slideMaker" :type="type" :theme="theme" :template="template" />
				<div class="input-container">
					<label for="correction">Correction</label>
					<div class="latex-container">
						<button class="add-latex" @click="isPopUpLatexVisible = !isPopUpLatexVisible">f(x)</button>

						<div class="fake-textarea" id="correction" contenteditable></div>
					</div>
					<!-- <textarea v-model="correction" id="correction" name="" cols="30" rows="10"></textarea> -->
				</div>

				<button @click="emitSubmit" class="submit dark">Valider</button>
			</div>
		</div>
		<Teleport to="body">
			<PopUpInsertLatex @close="isPopUpLatexVisible = !isPopUpLatexVisible" @insert-latex="insertLatex"
				v-if="isPopUpLatexVisible" />
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
import PopUpInsertLatex from './PopUpInsertLatex.vue'
import SlideMaker from './SlideMaker.vue'
export default {
	components: { PopUpWrapper, SlideMaker, PopUpInsertLatex },
	props: {
		type: String,
		theme: String,
		template: Number,
		tileGameTypes: Array
	},
	data() {
		return {
			correction: '',
			carretPosition: 0,
			isPopUpLatexVisible: false
		}
	},
	methods: {
		checkErrors(data) {
			if (!data.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ Titre',
					status: 'error'
				}
				return false
			}
			if (!data.text) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ Texte',
					status: 'error'
				}
				return false
			}
			if (this.$refs.slideMaker.decorative_image.size > 1000000) {
				this.$store.state.toast = {
					text: "La taille de l'image ne doit pas peser plus de 1 Mo",
					status: 'error'
				}
				return false
			}
			return true
		},
		async emitSubmit() {
			const slideMaker = this.$refs.slideMaker
			this.correction = document.getElementById('correction').innerHTML
			const data = {
				title: slideMaker.title,
				text: slideMaker.text,
				image: slideMaker.image,
				decorative_image: slideMaker.decorative_image,
				correction: this.correction
			}
			if (this.checkErrors(data)) {
				this.$emit('submit', data)
			}
		},
		insertLatex(latex) {
			const position = this.carretPosition
			const mathField = document.createElement('math-field')
			mathField.setAttribute('readonly', '')
			mathField.setAttribute('class', 'mathfield')
			mathField.setAttribute('contenteditable', false)

			mathField.textContent = latex
			mathField.style.cursor = 'pointer'
			const correction = document.getElementById('correction')
			correction.innerHTML = correction.innerHTML.replaceAll('&nbsp;', ' ')
			correction.innerHTML = correction.innerHTML.slice(0, position) + mathField.outerHTML + correction.innerHTML.slice(position) + '&nbsp'
			this.correction = correction.innerHTML

			this.isPopUpLatexVisible = false
		},
		updateCarretPosition() {
			return new Promise((resolve) => {
				setTimeout(() => {
					const selection = window.getSelection()
					if (selection.rangeCount > 0) {
						const range = selection.getRangeAt(0)
						this.carretPosition = range.startOffset
					}
					resolve()
				}, 200)
			})

		},
	},
	async mounted() {
		this.$nextTick(() => {
			const correction = document.getElementById('correction')
			correction.addEventListener('input', async () => {
				await this.updateCarretPosition()
				this.text = correction.innerHTML
			})
			correction.addEventListener('click', async () => {
				await this.updateCarretPosition()
			})
		})
	},
}
</script>

<style></style>
