import { authenticatedApiFetch } from '../utils'



export const createRitualActivityInterrogationService = async (ritualActivity) => {
    const res = await authenticatedApiFetch(`/ritual-activities-interrogations/`, { method: 'POST', body: JSON.stringify(ritualActivity) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
