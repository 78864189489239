<template>
	<section class="course-container">
		<h1>Modifier l'emploi du temps</h1>
		<p class="title">Entrez la date de votre prochain cours :</p>
		<button
			class="add-course dark submit"
			@click="popUpNewCourseVisible = true"
		>
			+ Ajouter un horaire
		</button>

		<day-wrapper
			@refreshCourses="getCourses"
			v-for="(day, index) in days"
			:key="index"
			:day="day"
			:courses="filteredCourse(index)"
		/>
		<pop-up-new-course
			@refresh="getCourses"
			@close="popUpNewCourseVisible = false"
			v-if="popUpNewCourseVisible"
		/>
	</section>
</template>

<script>
import { allAgendasForClassService } from '../../../../services/agendasService'
import PopUpNewCourse from '../../../PopUp/Class/PopUpNewCourse.vue'
import DayWrapper from './DayWrapper.vue'
export default {
	components: {
		DayWrapper,
		PopUpNewCourse
	},
	data() {
		return {
			courses: [],
			days: [
				'Lundi',
				'Mardi',
				'Mercredi',
				'Jeudi',
				'Vendredi',
				'Samedi',
			],
			popUpNewCourseVisible: false
		}
	},

	methods: {
		getCourses: async function () {
			this.courses = await allAgendasForClassService(this.$route.params.id)
			for (const course of this.courses) {
				course.day = new Date(course.day).getDay() - 1
			}
		},
		filteredCourse(e) {
			let courses = this.courses.filter(x => x.day == e)
			return courses
		}
	},


	created() {
		this.getCourses()
	},
}
</script>

<style>
</style>
