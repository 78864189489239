<template>
	<section :class="theme" class="display-activity-container">
		<Teleport to="body">
			<loader style="background: white" v-if="isLoading" />
		</Teleport>
		<div v-if="questions.length !== 0" :style="`transform:translateX(-${progression}%)`" class="activity-container">
			<div class="slide" :class="[
				theme,
				questions2.length !== 0 ? 'interrogation-first' : '',
			]">
				<div class="question-container">
					<h1>Questions flash</h1>
					<h2>{{ activity.name }}</h2>

					<div v-if="!isStudent" class="info-container">
						<p class="classe">
							{{ this.$store.state.user.first_name }}
							{{ this.$store.state.user.last_name }}
						</p>
						<p class="school">
							{{ this.$store.state.user.school }}
						</p>
					</div>

					<h3>{{ classe }}</h3>
				</div>
			</div>
			<div v-for="(question, index) in questions" :key="question" :style="`left:${index * 100 + 100}%;`"
				class="slide" :class="[
					theme,
					question.image_url_1 !== null ? 'img' : '',
					questions2.length !== 0 ? 'interrogation' : '',
				]">
				<div class="question-container" :class="question.image_url_1 !== null ? 'img' : ''">
					<h4 v-if="questions2.length !== 0">A</h4>
					<div v-if="question.image_url_1 !== null" :ref="`imgContainer${index}`" class="img-container"></div>
					<div class="text-container">
						<p class="question-indicator">
							<img src="/img/template/logo-transparent.svg" alt="" />
							Question n°{{ index + 1 }}
						</p>
						<div :ref="`question${index}`" :key="question.description" class="question calcul">
							<p :ref="`calcul${index}`" @click="reRender" class="calcul" v-html="question.description">
							</p>
						</div>
					</div>
				</div>
				<div class="fake-border" v-if="questions2.length !== 0"></div>
				<div v-if="questions2[index]" class="question-container"
					:class="questions2[index].image_url_1 !== null ? 'img' : ''">
					<h4 v-if="questions2.length !== 0">B</h4>
					<div v-if="questions2[index].image_url_1" :ref="`imgContainerInterrogation${index}`"
						class="img-container"></div>
					<div class="text-container">
						<p class="question-indicator">
							<img src="/img/template/logo-black.svg" alt="" />
							Question n°{{ index + 1 }}
						</p>
						<div  class="question calcul">
							<p :ref="`calculInterrogation${index}`" :key="questions2[index].description"
							class="calcul" v-html="questions2[index].description" />
						</div>
					</div>
				</div>
			</div>
		</div>
		<div v-else class="activity-container" :style="`transform:translateX(-${progression}vw)`">
			<div v-for="(image, index) in images" :key="image" :style="`left:${index * 100}%;`" class="slide key"
				:class="theme">
				<div class="key-container" :ref="`imgContainer${index}`"></div>
			</div>
		</div>
		<button v-if="progression == 0" @click="addIndex(true)" class="begin">
			Commencer
		</button>
		<img v-if="progression !== 0" class="prev" @click="addIndex(false)" src="/img/left-arrow.svg" alt="" />
		<img v-if="progression !== getDataLength() * 100 && progression !== 0" class="next" @click="addIndex(true)"
			src="/img/right-arrow.svg" alt="" />
		<img v-if="
			progression == getDataLength() * 100 &&
			this.$route.name !== 'QuestionKey'
		" class="next return" @click="dashboard" src="/img/cross.svg" alt="" />
	</section>
</template>

<script>
import { image } from "../../utils"
import { authenticatedImageFetch } from '../../utils'
import VueResizeText from 'vue3-resize-text'
import Loader from '../Loader.vue'
import axios from 'axios'
import Config from '../../config'
import { getQuestionFlashPackageService } from "../../services/questionFlashPackagesServices"
import { getQuestionService } from "../../services/questionsService"

export default {
	props: {
		id: Number,
		resourceType: {
			type: String,
			default: 'activities'
		}
	},
	components: {
		Loader
	},
	directives: {
		ResizeText: VueResizeText.ResizeText
	},
	data() {
		return {
			activity: {},
			questions: [],
			questions2: [],
			theme: "",
			progression: 0,
			rituals: [],
			interrogationRituals: [],
			classe: null,
			classe_id: null,
			key: {},
			images: [],
			isLeavingPage: false,
			isStudent: null,
			url: `${Config.backend_url}/media/`,
			isLoading: false
		}
	},
	methods: {
		getDataLength() {
			if (this.images.length !== 0) {
				return this.images.length - 1
			}
			else {
				return this.questions.length
			}
		},
		image(str) {
			image(str)
		},
		getImage(file, i, ref) {
			axios.get(`${this.url}${file}`, {
				responseType: "blob",
			})
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[`${ref}${i}`].append(img)
					}
					reader.readAsDataURL(blob.data)
				})
		},
		getInsideImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						this.imageData = e.target.result
					}
					reader.readAsDataURL(blob)
				})
		},

		async getActivity() {
			await axios.get(`${Config.backend_url}/api/v1/activities/${this.$route.params.id || this.id}/`)

				.then((res) => {
					this.activity = res.data
				})

		},
		async getClasse() {
			await axios.get(`${Config.backend_url}/api/v1/classes/${this.activity.classes[0]}/`)
				.then((res) => {
					this.classe = res.data.name
					this.classe_id = res.data.id
				})
		},
		async getKey() {
			await axios.get(`${Config.backend_url}/api/v1/keys/${this.activity.key}/`)
				.then((res) => {
					this.key = res.data
					let key = this.key
					this.images = [
						key.image1,
						key.image2,
						key.image3,
						key.image4,
						key.image5,
						key.image6,
						key.image7,
						key.image8,
						key.image9,
						key.image10,
					]
					this.images = this.images.filter((el) => {
						return el !== null && typeof el !== 'undefined'
					})
					this.images.forEach((element, i) => {
						this.getImage(element, i, 'imgContainer')
					})
				})
		},
		async getRituals() {
			await axios.get(`${Config.backend_url}/api/v1/ritual-activities/?ritual=${this.activity.id}`)

				.then((response) => {
					let data = response.data
					this.rituals = data
					this.rituals.sort((a, b) => (a.order > b.order ? 1 : -1))

				})
				.catch((error) => console.error(error))
		},
		async getInterrogationRituals() {

			await axios.get(`${Config.backend_url}/api/v1/ritual-activities-interrogations/?ritual=${this.activity.id}`)
				.then((response) => {
					let data = response.data
					this.interrogationRituals = data
					this.interrogationRituals.sort((a, b) => (a.order > b.order ? 1 : -1))
				})
				.catch((error) => console.error(error))
		},
		async getQuestions() {
			for await (const element of this.activity.activities)

				await axios.get(`${Config.backend_url}/api/v1/questions-instances/${element}/`)
					.then((res) => {
						let question = res.data

						question.randomValuesAmount = (question.description.match(/randint/g) || []).length + (question.description.match(/randfloat/g) || []).length

						this.questions.push(question)

					})
			this.sortQuestionList()

			for await (const element of this.activity.activities2)

				await axios.get(`${Config.backend_url}/api/v1/questions-interrogation-instances/${element}/`)
					.then((res) => {
						let question2 = res.data
						question2.randomValuesAmount = (question2.description.match(/randint/g) || []).length + (question2.description.match(/randfloat/g) || []).length
						this.questions2.push(res.data)
					})
			this.sortQuestion2List()

		},
		sortQuestionList() {
			let test = []
			for (let i = 0; i < this.rituals.length; i++) {
				test.push(
					this.questions.find((x) => x.id == this.rituals[i].activity)
				)
			}
			this.questions = test
			this.questions.forEach((element, i) => {
				if (element.image_url_1 !== null) {
					this.getImage(element.image_url_1, i, 'imgContainer')
				}
				if (element.image_url_2 !== null) {
					this.getInsideImage(element.image_url_2, i, 'imgContainerInterrogation')
				}
			})
		},
		sortQuestion2List() {
			let test = []
			for (let i = 0; i < this.interrogationRituals.length; i++) {
				test.push(
					this.questions2.find((x) => x.id == this.interrogationRituals[i].activity)
				)
			}
			this.questions2 = test
			this.questions2.forEach((element, i) => {
				if (element.image_url_1 !== null) {
					this.getImage(element.image_url_1, i, 'imgContainerInterrogation')
				}
			})
		},
		getTemplate() {
			let template = this.activity.template
			if (!template) {
				template = 1
			}
			this.theme = ['first', 'second', 'third', 'fourth', 'fifth', 'sixth', 'seven'][template]
		},
		addIndex(e) {

			if (e.code == 'ArrowRight' || e.code == 'Space') {
				if (this.progression < this.questions.length * 100 || this.progression < this.images.length * 100 - 100) {
					this.progression += 100
				}
			}
			if (e.code == 'ArrowLeft' || e === false) {
				if (this.progression >= 100) {
					this.progression -= 100
				}
			}
			if (e.code == 'Escape') {
				if (!this.isLeavingPage) {
					this.$router.back()
					this.isLeavingPage = true
				}
			}
			if (e === true) {
				this.progression += 100
			}

		},
		dashboard() {
			if (this.$route.name == 'Key' || this.$route.name == 'QuestionKey') {
				this.$emit('close')
			}
			else {
				this.$router.back()
			}
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	watch: {

		progression() {

			if (this.progression > 0 && this.images.length == 0) {
				let margin = ''
				if (this.questions2.length == 0) {
					if (this.questions[(this.progression - 100) / 100].image_url_1) {
						margin = '-6rem'
					}
					else {
						margin = '-6.5rem'
					}
				}
				else {
					margin = '-4.5rem'
				}

				let question = this.$refs[`question${(this.progression - 100) / 100}`]
				let questionHtml = question.getElementsByClassName("mjx-chtml MJXc-display")
				let questionHtml2 = question.getElementsByClassName("MathJax_Preview")




				for (let j = 0; j < this.questions[(this.progression - 100) / 100].randomValuesAmount; j++) {
					questionHtml[j].style.marginTop = margin
					questionHtml[j].style.marginBottom = `0`
					questionHtml2[j].style.marginTop = margin
					questionHtml2[j].style.marginBottom = `0`
				}


				if (this.questions2.length !== 0) {
					let calculInterrogation = this.$refs[`calculInterrogation${(this.progression - 100) / 100}`]
					let questionHtmlInterrogation = calculInterrogation.getElementsByClassName("mjx-chtml MJXc-display")
					let questionHtml2Interrogation = calculInterrogation.getElementsByClassName("MathJax_Preview")

					for (let j = 0; j < this.questions2[(this.progression - 100) / 100].randomValuesAmount; j++) {
						questionHtmlInterrogation[j].style.marginTop = margin
						questionHtmlInterrogation[j].style.marginBottom = `0`
						questionHtml2Interrogation[j].style.marginTop = margin
						questionHtml2Interrogation[j].style.marginBottom = `0`
					}
				}
				// this.reRender()
			}
		}
	},
	async created() {
		this.isLoading = true
		if (localStorage.userId && localStorage.userId !== 'null') {
			this.isStudent = false
		}
		else {
			this.isStudent = true
		}
		if (this.resourceType == 'activities') {
			await this.getActivity()
			await this.getRituals()
			if (this.activity.activities2.length !== 0) {
				this.getInterrogationRituals()
			}
			await this.getQuestions()

			if (this.activity.key) {
				await this.getKey()
			}
		}
		if(this.resourceType == 'question-flash-packages'){
			const flashPackage = await getQuestionFlashPackageService(this.id)
			this.activity.name = flashPackage.title
			for await (const activity of flashPackage.questions) {
				const res = await getQuestionService(activity)
				this.questions.push(res)
				if (res.image_url_1 !== null) {
					this.getImage(res.image_url_1, this.questions.length - 1, 'imgContainer')
				}
			}
		}
		if (this.$route.name !== 'Key' && this.$route.name !== 'QuestionKey') {
			await this.getClasse()
		}
		this.getTemplate()


		this.questions.forEach((element, index) => {
			const calcul = this.$refs[`calcul${index}`]
			let newWidth = `${calcul.offsetWidth + 100}px`
			calcul.style.width = newWidth
			if (this.resourceType == 'activities' &&  this.activity.activities2.length !== 0){
				const calculInterrogation = this.$refs[`calculInterrogation${index}`]
				let newWidthInterrogation = `${calculInterrogation.offsetWidth + 100}px`
				calculInterrogation.style.width = newWidthInterrogation
			}

		})
		this.reRender()
		this.isLoading = false

	},

	async mounted() {
		window.addEventListener('keyup', (e) => this.addIndex(e))
	},


}
</script>

<style></style>
