<template>
	<pop-up-wrapper>
		<div class="slider-pop-up">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close-white.png"
				alt=""
			/>

			<section class="display-activity-container">
				<display-activity @close="this.$emit('close')" :id="id" :resource-type="resourceType" />
			</section>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUp/PopUpWrapper.vue'
import DisplayActivity from '../DisplayActivity.vue'
export default {
	components: { DisplayActivity, PopUpWrapper },
	props: {
		id: Number,
		resourceType: {
			type: String,
			default: 'activities'
		}
	},
}
</script>
