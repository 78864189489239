<template>
	<pop-up-wrapper>
		<section class="pop-up-container-winner">
			<div class="pop-up">
				<h2>Résultat du tirage au sort</h2>
				<p>
					Félicitations {{ winner }}!<br />
					Tu as gagné le droit d'aller au tableau!
				</p>
				<button
					:style="`background-color: ${color}`"
					@click="stopClap"
					class="submit"
				>
					Bonne chance {{ winner }}
				</button>
			</div>
		</section>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from './PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	setup() {
	},
	props: {
		winner: String,
		color: String
	},
	data() {
		return {
			audio: new Audio('./sounds/claps.mp3'),
		}
	},

	methods: {
		stopClap() {
			this.audio.pause()
			this.audio.currentTime = 0
			this.$emit('close')
		}
	},

	mounted() {
		this.audio.play()
	},
	unmounted() {
		this.audio.pause()
	},
}
</script>
