<template>
	<div class="timer-container">
		<!-- SVG -->
		<router-link class="go-back" :to="{ name: 'Dashboard' }"
			>Retour</router-link
		>
		<div class="timer-face">
			<img class="bg-timer" src="/img/tools/timer-bg.svg" alt="" />
			<svg class="timer-ring" :height="`${size}px`" :width="`${size}px`">
				<circle
					class="timer-ring__bgline"
					stroke="lightgray"
					stroke-width="20"
					fill="transparent"
					:r="`${size / 2 - 20}px`"
					:cx="`${size / 2}px`"
					:cy="`${size / 2}px`"
				/>
				<circle
					class="timer-ring__line"
					:style="{
						stroke: started ? '#5D58D6' : '#5D58D6',
						strokeDasharray: `${strokeDasharray}px`,
					}"
					stroke-width="40"
					fill="transparent"
					:r="`${size / 2 - 20}px`"
					:cx="`${size / 2}px`"
					:cy="`${size / 2}px`"
					:stroke-dashoffset="setProgress"
				/>
			</svg>
			<p class="text">{{ minutes }}:{{ seconds }}</p>
		</div>

		<!-- SECONDS -->
		<div class="timer-seconds">
			<div class="timer-option-container">
				<div class="timer-input-container">
					<label>Heures</label>
					<input type="number" min="0" max="24" v-model="hoursTime" />
				</div>
				<div class="timer-input-container">
					<label>Minutes</label>
					<input
						type="number"
						min="0"
						max="60"
						v-model="minutesTime"
					/>
				</div>
				<div class="timer-input-container">
					<label>Secondes</label>
					<input
						type="number"
						min="0"
						max="60"
						v-model="secondsTime"
					/>
				</div>
			</div>
			<!-- BUTTONS -->
			<div class="button-container">
				<button
					class="button start"
					v-if="!started"
					@click="startTimer()"
				>
					<p>Démarrer</p>
				</button>
				<button
					class="button pause"
					v-if="started"
					@click="pauseTimer()"
				>
					<p>Pause</p>
				</button>
				<button class="button reset" @click="resetTimer">
					<p>Arrêter</p>
				</button>
			</div>
		</div>
	</div>
</template>

<script>



export default {
	data() {
		return {
			timer: null,
			time: 10,
			startTime: 10,
			started: false,
			radioVal: 10,
			size: 250,
			strokeDasharray: 0,
			option: 1,
			hoursTime: 0,
			minutesTime: 0,
			secondsTime: 10
		}
	},
	methods: {
		startTimer() {
			this.started = true
			this.timer = setInterval(this.countdown, 1000)
		},
		pauseTimer() {
			this.started = false
			clearInterval(this.timer)
		},
		resetTimer() {
			this.time = this.hoursTime * 3600 + this.minutesTime * 60 + this.secondsTime
			clearInterval(this.timer)
			this.started = false
		},
		countdown() {
			if (this.time > 0) {
				this.time--
			} else {
				this.resetTimer()
			}
		},
		formatTime(time) {
			return (time < 10 ? '0' : '') + time
		},
		setTime() {
			this.time = this.hoursTime * 3600 + this.minutesTime * 60 + this.secondsTime
			this.startTime = this.hoursTime * 3600 + this.minutesTime * 60 + this.secondsTime
			this.resetTimer()
		}
	},
	watch: {
		hoursTime() {
			this.setTime()
		},
		minutesTime() {
			this.setTime()
		},
		secondsTime() {
			this.setTime()
		},

	},
	computed: {
		setProgress() {
			const offset = this.strokeDasharray - this.time / this.startTime * this.strokeDasharray
			return -offset
		},
		minutes() {
			const minutes = Math.floor(this.time / 60)
			return this.formatTime(minutes)
		},
		seconds() {
			const seconds = this.time - (60 * this.minutes)
			return this.formatTime(seconds)
		}
	},
	created() {
		this.strokeDasharray = 2 * Math.PI * (this.size / 2 - 20)
	},
}
</script>

<style>
</style>
