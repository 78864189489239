import { authenticatedApiFetch } from '../utils'


export const createQuestionInstanceService = async (question) => {
    const res = await authenticatedApiFetch(`/questions-instances/`, { method: 'POST', body: JSON.stringify(question) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const getQuestionInstanceService = async (id) => {
    const res = await authenticatedApiFetch(`/questions-instances/${id}/`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
