<template>
	<div
		v-if="questions.length !== 0"
		class="fake-container"
	>
		<display-hand-made-key
			@click="checkRights()"
			@close="this.$emit('close')"
			:id="id"
			:disableSliding="true"
		/>
		<div class="add-container">
			<div>
				<p>{{ name }}</p>
			</div>
			<button @click="popUp" class="submit add">
				<span>Enregistrer</span>
			</button>
		</div>
		<Teleport to="body">
			<pop-up-flash-package v-if="isPopUpVisible" :id="id" @close="popUp"
		/></Teleport>
		<Teleport to="body">
			<slider-key-questions
				@close="showPopUpSlide = !showPopUpSlide"
				v-if="showPopUpSlide"
				:id="id"
				:resource-type="'question-flash-packages'"
		/></Teleport>
	</div>
</template>

<script>
import { getQuestionService } from '../../../services/questionsService'
import { authenticatedImageFetch, checkRightFor } from '../../../utils'
import PopUpFlashPackage from '../../PopUp/PopUpFlashPackage.vue'
import DisplayHandMadeKey from './DisplayHandMadeKey.vue'
import SliderKeyQuestions from './SliderKeyQuestions.vue'
export default {
	components: { PopUpFlashPackage, SliderKeyQuestions, DisplayHandMadeKey },
	props: {
		id: Number,
		activities: Array,
		name: String,
	},
	data() {
		return {
			added: false,
			readyToDisplay: false,
			questions: [],
			isPopUpVisible: false,
			chapters: [],
			showPopUpSlide: false,
		}
	},
	methods: {
		checkRights() {
			if(!checkRightFor('Questions Flash', [])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			this.showPopUpSlide = !this.showPopUpSlide
			
		},
		

		popUp() {
			if(!checkRightFor('Questions Flash', [])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			this.isPopUpVisible = !this.isPopUpVisible
		},
		async getQuestions() {
			for (const activity of this.activities) {
				const res = await getQuestionService(activity)
				this.questions.push(res)
				
			}

		},
		color(e) {
			let color
			switch (e) {
				case 'Facile':
					color = 'green'
					break
				case 'Moyen':
					color = 'orange'
					break
				case 'Difficile':
					color = 'red'
					break

				default:
					break
			}
			return color
		},
		getImage: async function () {
			let image = this.questions[0].image_url_1
			if (image !== null) {
				await authenticatedImageFetch(image, {
					method: "GET",
				})
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader()
						const component = this
						reader.onload = function () {
							const img = new Image()
							img.src = this.result
							component.$refs[`imgContainer`].append(img)
						}
						reader.readAsDataURL(blob)
					})
			}
		},
		add() {
			this.added = !this.added
			this.$emit('addQuestion')
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.reRender()
		this.getQuestions()
	},
	mounted() {
		this.reRender()
		setTimeout(() => {
			this.readyToDisplay = true
		}, 1000)
	},
}
</script>

<style>
</style>
