// En cas de modification de BACKEND_URL, ne pas oublier de mettre à jour le Dockerfile
const BACKEND_URL = "https://api-prod.lecoinduprof.fr"
const LANDING_URL = "https://www.lecoinduprof.fr"
// const LANDING_URL = "http://127.0.0.1:9000"
const SECRETKEY = "temalencryptagedefoulocarigolepaspapapa"

// const PUBLISHABLE_KEY = "pk_test_51Mia5EBfJRotU3XNizFfmqe44JecgGGjH4kSlP9cT4Z4wuutTw6Ja1e5bNh0ITOc8BDAW0NuFsn3rdHmZLM0x5MF001qfe706O"

const PUBLISHABLE_KEY = "pk_live_51Jcrr7F7607RN5SkivqNqCSh8vLfetR8c8G3eh83IUHr1VUd3sfUOqISnKSsubCpHpQQ5u7uXCd3CUCVhFX3Mck500L4RpqDzP"

const Config = {
    backend_url: BACKEND_URL,
    admin_url: `${BACKEND_URL}/admin/backend`,
    landing_url: LANDING_URL,
    secret_key: SECRETKEY,
    publishable_key: PUBLISHABLE_KEY
}

export default Config
