import { authenticatedApiFetch } from '../utils'



export const allStudentsInClasseService = async (classId) => {
    const res = await authenticatedApiFetch(`/students/?classes=${classId}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allStudentsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/students/${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getStudentService = async (id) => {
    const res = await authenticatedApiFetch(`/students/${id}/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createStudentService = async (data) => {
    const res = await authenticatedApiFetch(`/students/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateStudentService = async (id, data) => {
    const res = await authenticatedApiFetch(`/students/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const deleteStudentService = async (id) => {
    const res = await authenticatedApiFetch(`/students/${id}/`, { method: 'DELETE' })
    return res
}
