<template>
	<section
		class="calendar-display-container"
		:class="'calendar-display-container-' + calendar.template"
	>
		<div
			class="custom-calendar-wrapper"
			:class="'custom-calendar-wrapper-' + calendar.template"
		>
			<router-link
				v-for="(day, index) in days"
				:key="index"
				class="calendar-day"
				:to="checkDay(index)"
				:class="isOpen(`2023-12-${index + 1}`)"
			>
			</router-link>
		</div>
		<button
			v-if="!isStudent"
			class="btn share"
			@click="shareVisible = !shareVisible"
		>
			Partager à ma classe
		</button>
		<button class="btn" @click="goBack">Retour</button>
		<pop-up-share @close="closePopUp" v-if="shareVisible" />
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../../../../config'
import PopUpShare from '../../../../PopUp/SeriousGame/Calendar/PopUpShare.vue'
export default {
	components: { PopUpShare },
	data() {
		return {
			date: '',
			classe: {},
			calendar: {},
			exercises: [],
			exercisesOrder: [],
			template: '',
			days: 25,
			shareVisible: false,
			shareVisibleOnClick: false,
			exoDay: [

			],
			exoId: [

			],
			isStudent: true
		}
	},

	methods: {
		closePopUp(e) {
			this.$store.commit({
				type: 'updateDontShowAgain',
				guideType: 'share',
				value: e
			})
			this.shareVisible = false
			this.shareVisibleOnClick = false
		},
		goBack() {
			this.$router.go(-1)
		},
		checkDate() {
			this.date = new Date().toISOString().split('T')[0]
		},
		isOpen(calendarDate) {
			let caseDate
			if (calendarDate.length == 9) {
				caseDate = calendarDate.substring(0, 8) + "0" + calendarDate.substring(8, 11)
			}
			else {
				caseDate = calendarDate.substring(0, 11)
			}
			if (this.date.toString() < caseDate.toString() && this.isStudent) {
				return 'closed'
			}
			else {
				return 'open'
			}
		},


		getData: async function () {
			const res = await axios.get(`${Config.backend_url}/api/v1/christmas-calendar/${this.$route.params.calendarId}`)
			this.calendar = res.data

			this.getExercisesOrder()
		},
		async getExercisesOrder() {
			const res = await axios.get(`${Config.backend_url}/api/v1/christmas-calendar-exercise-orders/?christmas_calendar_id=${this.calendar.id}`)
			this.exercisesOrder = res.data
			this.getExercises()

		},
		async getExercises() {
			for (const exo of this.exercisesOrder) {
				const res = await axios.get(`${Config.backend_url}/api/v1/christmas-calendar-exercises/${exo.christmas_calendar_exercise_id}/`)
				let exercise = res.data
				exercise.order = exo.order
				this.exercises.push(exercise)
			}
		},

		checkDay(e) {
			const day = this.exercises.find(x => x.order == e + 1)
			if (day) {
				return { name: 'CalendarExercise', params: { id: day.id, template: this.calendar.template } }
			}
			else {
				return ''
			}
		},
	},

	created() {
		this.checkDate()
		this.getData()
		if (localStorage.userId && localStorage.userId !== 'null') {
			this.isStudent = false
		}
	},





}
</script>

<style>
</style>
