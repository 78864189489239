<template>
	<section class="thank-you-container">
		<router-link class="logo" :to="{ name: 'Dashboard' }">
			<img src="/img/logo.svg" alt="" />
		</router-link>
		<p class="title">
			MERCI ! <br />
			<br />
			Vous avez à présent accès aux fonctionnalités suivantes :
		</p>
		<div class="buyed-product-container">
			<buyed-item
				v-for="(product, index) in products"
				:key="index"
				:product="product"
				:isBuyed="checkIfAlreadyOwned(product.title)"
			/>
		</div>
		<router-link class="submit dark" :to="{ name: 'Dashboard' }"
			>Retourner sur la plateforme</router-link
		>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import BuyedItem from './BuyedItem.vue'
export default {
	components: { BuyedItem },
	data() {
		return {
			alreadyOwnedProducts: [],
			products: [
				{
					img: '/img/payment/flash-icon.svg',
					title: 'Questions Flash',
					text: 'Préparez votre diaporama Flash en 3 min montre en main!',
					color: '#FFCC24'
				},
				{
					img: '/img/payment/expresso-icon.svg',
					title: 'Activités Expresso',
					text: 'Motivez vos élèves avec nos activités clés en main au design léché.',
					color: '#D11AFC'
				},
				{
					img: '/img/payment/game-icon.svg',
					title: 'Jeux Sérieux',
					text: 'A la fin d\'un cours ou avant les vacances, captivez vos élèves et transmettez de façon ludique et pédagogique !',
					color: '#59CA24'
				},
				// {
				// 	img: '/img/payment/classico-icon.svg',
				// 	title: 'Exercices Classico',
				// 	text: 'A la fin d\'un cours ou avant les vacances, captivez vos élèves et transmettez de façon ludique et pédagogique !',
				// 	color: '#31C3D6'
				// },
				{
					img: '/img/payment/qcm-icon.svg',
					title: 'QCM',
					text: 'Créer en un rien de temps des quiz interactifs pour tester les connaissances de vos élèves. Réalisables en classe ou à la maison.',
					color: '#FF0303'
				},
			],
		}
	},
	methods: {
		checkIfAlreadyOwned(productName) {
			const isOwned = this.alreadyOwnedProducts.some(x => x.resourceType == productName)
			return isOwned
		},
		async getAlreadyOwnedProducts() {
			const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
				.then(response => response.json())
				.then(response => {
					return response[0]
				})


			if (res) {
				for await (const subscriptionResource of res.subscription_resource_level) {
					const res = await authenticatedApiFetch(`/subscription-resource-levels/${subscriptionResource}/`, { method: 'GET' })
						.then(response => response.json())
						.then(response => {
							return response
						})
					this.alreadyOwnedProducts.push({
						resourceType: res.resource_name,
						levels: res.levels_id,
						subscription_start: res.subscription_start,
						subscription_end: res.subscription_end,
					})
				}
			}
		},
	},
	created() {
		this.getAlreadyOwnedProducts()
	},
}
</script>

<style>
</style>
