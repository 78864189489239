<template>
	<div class="lesson-container">
		<div class="lesson-img-container">
			<img :src="url + lesson.photo" alt="" />
		</div>
		<div class="lesson-text-container">
			<p>{{ lesson.name }}</p>
			<button
				v-if="lesson.downloadable"
				@click="download"
				class="submit add btn"
			>
				<span>Télécharger</span>
			</button>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
export default {
	props: {
		lesson: Object,
		active: Boolean
	},
	data() {
		return {
			delay: 700,
			clicks: 0,
			timer: null,
			showPopUp: false,

			url: `${Config.backend_url}/media/`
		}
	},

	methods: {
		async download() {
			let re = /(?:\.([^.]+))?$/
			let ext = re.exec(this.lesson.file_upload)[1]

			const response = await axios.get(`${this.url}${this.lesson.file_upload}`, { responseType: "blob" })

			const blob = new Blob([response.data])

			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = this.lesson.name + '.' + ext
			link.click()
			URL.revokeObjectURL(link.href)
		}
	},

}
</script>

<style>
</style>
