import { authenticatedApiFetch } from '../utils'



export const allChristmasCalendarExerciseOrdersFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/christmas-calendar-exercise-orders/${filter}`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const createChristmasCalendarExerciseOrderService = async (data) => {
    const res = await authenticatedApiFetch(`/christmas-calendar-exercise-orders/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}



