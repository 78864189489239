<template>
	<section class="edit-class">
		<h1>Modifier une classe</h1>
		<form v-if="form !== undefined" @submit.prevent="editClass">
			<div class="input-container">
				<input
					id="name"
					v-model="form.name"
					name="name"
					type="text"
					placeholder=" "
				/>
				<label class="custom-label" for="name">Nom de la classe</label>
			</div>
			<div class="input-container">
				<select
					class="days"
					v-model="form.level"
					name="level"
					id="level"
				>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="error-container">
				<p v-if="errors !== ''">{{ errors }}</p>
			</div>
		</form>

		<button @click="editClass" class="submit dark" type="submit">
			Valider
		</button>
		<router-link
			class="submit dark edit-course"
			:to="{ name: 'EditCourse', params: { id: $route.params.id } }"
			>Modifier l'emploi du temps</router-link
		>
		<button
			class="submit delete"
			@click="deleteClass(this.$route.params.id)"
		>
			Supprimer la classe
		</button>
	</section>
</template>

<script>
import { getClassService } from '../../../services/classesService'

export default {

	data() {
		return {
			form: {
				name: '',
				level: '',
				teacher: this.$store.state.user.user
			},
			importedAgenda: null,
			levels: [],
			errors: '',

			isFirstTime: true
		}
	},
	methods: {
		updateFile(e) {
			this.importedAgenda = e.target.files[0]
		},

		async deleteClass(item) {
			if (confirm(`Êtes-vous sûr de vouloir supprimer cette classe ?`)) {
				await this.$store.dispatch('deleteClass', item)
				this.$router.push({ name: 'Dashboard' })
			}
		},
		async getClasse() {
			this.form = await getClassService(this.$route.params.id)
		},

		editClass: function () {
			if (this.form.name == '' || this.form.level == '') {
				this.errors = 'Veuillez remplir les champs manquants'
			}
			else {
				// PUT request to update class.
				this.$store.dispatch('updateClass', this.form)
					.then(() => {
						this.$router.push({ name: 'ClassResources' })
					})
			}
		},
	},

	created() {
		this.getClasse()
	}
}
</script>

<style>
</style>
