<template>
	<pop-up-wrapper>
		<div class="pop-up-print">
			<img @click="close" class="close" src="/img/close.svg" alt="" />
			<p class="title">Imprimer</p>
			<div class="checkbox-container">
				<label for="simple">
					<input
						v-model="choice"
						id="simple"
						type="radio"
						value="simple"
					/>Liste élèves (simplifiée)
				</label>
				<label for="detailled">
					<input
						v-model="choice"
						id="detailled"
						type="radio"
						value="detailled"
					/>Liste élèves (détaillée)
				</label>
			</div>
			<button @click="print" class="submit dark">Imprimer</button>
		</div>
		<pop-up-observation-simple />
		<pop-up-observation-detailled />
		<Teleport to="body">
			<full-page-loader v-if="isLoading" />
		</Teleport>
	</pop-up-wrapper>
</template>
<script>
import jsPDF from 'jspdf'
import PopUpWrapper from '../PopUpWrapper.vue'
import PopUpObservationSimple from './PopUpObservationSimple.vue'
import html2canvas from 'html2canvas'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
import PopUpObservationDetailled from './PopUpObservationDetailled.vue'
export default {
	components: { PopUpWrapper, PopUpObservationSimple, FullPageLoader, PopUpObservationDetailled },

	data() {
		return {
			choice: null,
			isLoading: false
		}
	},
	methods: {
		print() {
			if (this.choice == 'simple') {
				this.download("#simplePdf")
			}
			else {
				this.download("#detailledPdf")
			}
		},
		async download(selector) {
			this.isLoading = true
			const HTML_Width = document.querySelector(selector).offsetWidth
			const HTML_Height = document.querySelector(selector).offsetHeight
			const top_left_margin = 5
			const PDF_Width = HTML_Width + top_left_margin * 2
			const PDF_Height = (PDF_Width * 1.5) + top_left_margin * 2
			const canvas_image_width = HTML_Width
			const canvas_image_height = HTML_Height

			const totalPDFPages = Math.ceil(HTML_Height / PDF_Height) - 1

			await html2canvas(document.querySelector(selector), { allowTaint: true, scale: 2 }).then(function (canvas) {
				canvas.getContext('2d')


				const imgData = canvas.toDataURL("image/jpeg", 1.0)
				const pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height])
				pdf.addImage(imgData, 'JPEG', top_left_margin, top_left_margin, canvas_image_width, canvas_image_height)

				for (let i = 1; i <= totalPDFPages; i++) {
					pdf.addPage(PDF_Width.toString(), PDF_Height.toString())
					pdf.addImage(imgData, 'JPEG', top_left_margin, -(PDF_Height * i) + (top_left_margin * 4), canvas_image_width, canvas_image_height)
				}

				pdf.save("observation.pdf")
			})
			this.isLoading = false

		},

		close() {
			this.$emit('close')
		},
	},
}
</script>

<style>
</style>
