<template>
	<h1>Modifier une leçon</h1>
	<form
		@submit.prevent="submit"
		class="form-container"
		enctype="multipart/form-data"
	>
		<div class="input-container">
			<label for="Title">Titre</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div ref="imgContainer" class="input-container">
			<label for="Image">Photo</label>
			<input
				type="file"
				v-on:change="changePhoto"
				class="form-control"
				placeholder="Photo"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Fichier</label>
			<input
				type="file"
				v-on:change="changeFile"
				class="form-control"
				placeholder="Fichier"
			/>
		</div>
		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select id="niveaux" v-model="form.level_id">
				<option
					v-for="level in $store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Image2">Téléchargeable</label>
			<input
				type="checkbox"
				class="form-control"
				v-model="form.downloadable"
			/>
		</div>

		<div class="input-container">
			<label for="Order">Order</label>
			<input type="number" v-model="form.order" id="Order" />
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch, authenticatedImageFetch } from '../../utils'

export default {
	data() {
		return {
			form: {
				name: '',
				photo: '',
				file_upload: '',
				level_id: '',
				downloadable: false,
				order: 0,
			},

			levels: [],
			errors: '',
			status: '',

		}
	},
	methods: {

		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		submit() {
			authenticatedApiFetch(`/lessons/${this.$route.params.id}/`, {
				method: 'PATCH',
				body: this.getFormDataValue(),
				headers: {}
			})
				.then(() => {
					this.status = 'Enregistré avec succès'
					this.form.title = ""
					this.form.description = ""
					this.$router.push({ name: 'SuperAdminAllLessons' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			let sequences = this.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
			if (sequences.length !== 0) {
				let chapterArray = []
				sequences.forEach(element => {
					let chapters = this.chapters.filter(x => x.sequence == element)
					chapterArray.push(...chapters)
				})
				return chapterArray
			}
			if (this.selectedLevel == null) {
				return this.chapters
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('name', this.form.name)
			data.append('level_id', this.form.level_id)
			if (typeof this.form.photo !== 'string' && this.form.photo !== null) {
				data.append('photo', this.form.photo)
			}
			if (typeof this.form.file_upload !== 'string' && this.form.file_upload !== null) {
				data.append('file_upload', this.form.file_upload)
			}
			data.append('downloadable', this.form.downloadable)
			data.append('order', this.form.order)
			return data
		},

		changePhoto(e) {
			this.form.photo = e.target.files[0]
		},
		changeFile(e) {
			this.form.file_upload = e.target.files[0]
		},

		getLesson() {
			authenticatedApiFetch(`/lessons/${this.$route.params.id}/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.form = res
					this.getImage(this.form.photo)
				})
		},
	},
	created() {
		this.getLesson()
	},
}
</script>

<style>
</style>
