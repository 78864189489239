<template>
	<section class="resources-page">
		<h1>Ressources Le Coin du Prof</h1>
		<div class="resource-container">
			<each-resource v-for="(resource, index) in filteredResources" :key="index" :resource="resource" />
		</div>
		<div class="my-resource-container">
			
			<router-link :to="{ name: 'MyResourcesQuestionFlash' }" class="submit light">Accéder à mes
				ressources</router-link>
		</div>
		
	</section>
</template>

<script>
import EachResource from './EachResource.vue'
export default {
	components: { EachResource },
	data() {
		return {
			resources: [
				{
					img: '/img/payment/flash-icon.svg',
					name: 'Questions Flash',
					text: 'Créez des diaporamas de questions Flash à vidéoprojeter en classe. Il suffit de séléctionner vos questions dans la base de données. Cela se fait en quelques clics !',
					color: '#FFCC24',
					componentName: 'Question'
				},
				{
					img: '/img/payment/expresso-icon.svg',
					name: 'Activités Expresso',
					text: "Des activités qui ont le goût d'une pause café ! Dynamisez vos cours de Mathématiques avec ces activités clés en main concrètes et stimulantes !",
					color: '#D11AFC',
					componentName: 'Key'
				},
				{
					img: '/img/payment/classico-icon.svg',
					name: 'Exercices Classico',
					text: "Générez une fiche d'exercices ou un sujet de devoir en un rien de temps. Il suffit de sélectionner vos exercices dans la base de données.",
					color: '#31C3D6',
					componentName: 'ExerciseSheet'
				},
				{
					img: '/img/payment/qcm-icon.svg',
					name: 'QCM',
					text: "Créer en un rien de temps des quiz interactifs pour tester les connaissances de vos élèves. Réalisables en classe ou à la maison.",
					color: '#FF0303',
					componentName: 'QCM'
				},
				{
					img: '/img/payment/game-icon.svg',
					name: 'Jeux Sérieux',
					text: "En fin de semaine ou avant les vacances, motivez vos élèves avec nos jeux sérieux. Ils sont personnalisables et adaptable au niveau de votre classe.",
					color: '#59CA24',
					componentName: 'JeuSerieux'
				},
				{
					img: '/img/payment/share-space-icon.svg',
					name: 'Espace de partage',
					text: "Vous trouverez dans cet espace des ressources partagées par la communauté d'enseignant. Vous pouvez également partager vos propres ressources.",
					color: '#31C3D6',
					componentName: 'ShareSpace'
				},
			],
			x: 0,
			y: 0,
			isPopUpChooseResourceVisible: false
		}
	},
	methods: {
		
	},
	computed: {
		filteredResources(){
			const userProperties = this.$store.state.user
			const userSubjects = userProperties.subjects
			if (!this.$store.state.subjects) return []
			const subjectsName = userSubjects.map(subject => this.$store.state.subjects.find(s => s.id === subject).name)
			let resources = []

			resources.push(this.resources.find(resource => resource.componentName === 'Question'))
			if (subjectsName.includes('Maths') || subjectsName.includes('Maths Complémentaires') || subjectsName.includes('Maths Expertes')) {
				
				resources.push(this.resources.find(resource => resource.componentName === 'Key'))
			}
			resources.push(this.resources.find(resource => resource.componentName === 'JeuSerieux'))
			resources.push(this.resources.find(resource => resource.componentName === 'QCM'))
			resources.push(this.resources.find(resource => resource.componentName === 'ShareSpace'))

			return resources
			

		}
	},
	created() {
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY - 70
		})
	},
}
</script>

<style></style>
