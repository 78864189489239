<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-observation">
			<h3>
				<img src="/img/class/plus.svg" alt="" /> Ajouter une observation
			</h3>
			<div class="motif-wrapper">
				<div class="motif-container">
					<h4><img src="/img/class/motif.svg" alt="" /> Motif</h4>
					<div
						class="label-input-wrapper"
						v-for="motif in this.$store.state.motifs"
						:key="motif"
					>
						<input
							class="checkbox"
							:id="motif.id"
							type="radio"
							:value="motif.id"
							v-model="selectedMotif"
						/>
						<label class="label-checkbox" :for="motif.id">
							{{ motif.name }}</label
						>
					</div>
				</div>
				<div class="date-container">
					<h4><img src="/img/class/date.svg" />Date</h4>
					<input v-model="date" type="date" />
				</div>
				<div class="comment-container">
					<h4><img src="/img/class/comment.svg" />Commentaire</h4>
					<textarea
						v-model="comment"
						placeholder="Commentaire"
						name=""
						id=""
						rows="5"
					></textarea>
				</div>

				<p class="error">{{ this.error }}</p>

				<div class="btn-container">
					<button @click="close" class="cancel">Annuler</button>
					<button @click="submit" class="add">Ajouter</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { createObservationService } from '../../../services/observationsService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		studentId: Number
	},
	emits: ["close"],
	data() {
		return {
			selectedMotif: null,
			date: null,
			comment: '',
			error: ''
		}
	},


	methods: {
		close() {
			this.$emit('close')
		},
		async submit() {
			const data = {
				student_id: this.studentId,
				motif: this.selectedMotif,
				date: this.date,
				comment: this.comment,
				teacher: this.$store.state.user.user
			}
			if (data.motif && data.date) {

				await createObservationService(data)
					.then(() => {
						this.close()
					})
					.catch(err => {
						console.error(err)
					})
			}
			else {
				this.error = 'Merci de remplir les champs manquant'
			}


		}
	},

	created() {
		this.date = new Date().toISOString().substring(0, 10)
	},



}
</script>
