<template>
	<h1>Add sequence</h1>
	<form @submit.prevent="submit" class="form-container">
		<div class="input-container">
			<label for="Name">Name</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Name"
				placeholder="Name"
			/>
		</div>
		<div class="input-container">
			<label for="Order">Order</label>
			<input
				type="number"
				v-model="form.order"
				id="Order"
			>
		</div>
		<div class="input-container">
			<label for="Subject">Subject</label>
			<select id="classes" v-model="form.subject">
				<option
					v-for="subject in this.$store.state.subjects"
					:key="subject"
					:value="subject.id"
				>
					{{ subject.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Level">Level</label>
			<select id="classes" v-model="form.level">
				<option
					v-for="level in this.$store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			form: {
				name: '',
				order: '',
				subject: '',
				level: '',
			},

			errors: '',
			status: ''
		}
	},
	methods: {
		submit() {
			authenticatedApiFetch(`/sequences/`, { method: 'POST', body: JSON.stringify(this.form) })
				.then(() => {
					this.status = 'Enregistré avec succès'
					this.form.name = ""
					setTimeout(() => {
						this.status = ''
					}, 2000)
				})
				.catch((error) => {
					this.errors = error
				})
		},
	},
}
</script>

<style>
</style>
