import { authenticatedApiFetch } from '../utils'


export const createExerciseQuestionOrderService = async (data) => {
    const res = await authenticatedApiFetch(`/exercise-question-orders/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const getExerciseQuestionOrderService = async (id) => {
    const res = await authenticatedApiFetch(`/exercise-question-orders/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
export const allExerciseQuestionOrdersFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/exercise-question-orders/${filter}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const deleteExerciseQuestionOrderService = async (id) => {
    const res = await authenticatedApiFetch(`/exercise-question-orders/${id}/`, { method: 'DELETE' })
    return res
}
