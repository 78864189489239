<template>
	<div>
		<div class="text-container" @click="this.$emit('showPopUpEdit', task)">
			<p class="title">{{ task.title }}</p>

			<p
				v-for="classe in task.classes"
				:key="classe"
				class="class"
				:class="getColor(classe)"
			>
				{{ getClass(classe) }}
			</p>
		</div>
		<img @click="deleteTask" src="/img/close.svg" alt="" />
	</div>
</template>

<script>
import { deleteKanbanService } from '../../../../services/kanbansService'
export default {
	props: {
		task: Object
	},
	methods: {
		getClass(id) {
			let classe = this.$store.state.classes.find(x => x.id == id)
			return classe.name
		},
		deleteTask: async function () {
			await deleteKanbanService(this.task.id)
			this.$emit('delete', this.task.id)
		},
		getColor(id) {
			let color = this.$store.state.classes.find(x => x.id == id).level_name
			switch (color) {
				case '6ème':
					return 'sixth'

				case '5ème':
					return 'fifth'

				case '4ème':
					return 'fourth'

				case '3ème':
					return 'third'

				case '2nde':
					return 'second'

				case '1ère Spé':
					return 'first'

				case 'Term Spé':
					return 'term'

				case 'Term Maths Expertes':
					return 'term'

				case 'Term Maths Comp':
					return 'term'

				default:
					return 'aled'
			}
		}
	},
}
</script>

<style>
</style>
