
<template>
	<section class="propose-key-container">
		<h1>Modifier une Activité Expresso</h1>

		<div class="basic-info-container">
			<div class="input-container">
				<label for="">Titre</label>
				<input v-model="form.title" type="text" />
			</div>
			<div class="input-container">
				<label for="">Niveau</label>
				<select id="niveaux" v-model="form.level" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="">Chapitre</label>
				<select v-model="form.chapter" name="chapter" id="chapter">
					<optgroup
						v-for="sequence in this.$store.getters.sequences(
							form.level
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
		</div>
		<slide
			:ref="`slide${index + 1}`"
			v-for="(slide, index) in slides"
			:key="slide.id"
			:index="index + 1"
			:slide="slide"
			:editable="true"
			@removeSlide="removeSlide"
		/>
		<div class="btn-container">
			<button @click="addSlide">Ajouter une diapo</button>
			<button @click="submit">Valider</button>
		</div>
	</section>
</template>
<script>
import { getProposedKeyService, updateProposedKeyService } from '../../../services/proposedKeysService'
import { allProposedKeySlidesFilterService, createProposedKeySlideService, updateProposedKeySlideService } from '../../../services/proposedKeySlidesService'
import Slide from './Slide.vue'
export default {
	components: { Slide },
	data() {
		return {
			form: {
				title: '',
				level: '',
				chapter: '',
				teacher: ''
			},
			slides: []
		}
	},
	methods: {
		addSlide() {
			this.slides.push(
				{
					id: this.slides.length + 1,
					title: '',
					text: '',
					designer_note: '',
					teacher_note: '',
					images: [

					],
					new: true
				}
			)
		},
		removeSlide(id) {
			this.slides = this.slides.filter(x => x.id !== id)
		},
		async submit() {
			const res = await updateProposedKeyService(this.$route.params.id, this.form)
			const proposedKeyId = res.id

			for (let i = 0; i < this.slides.length; i++) {
				const object = this.$refs[`slide${i + 1}`].form
				const data = new FormData()
				data.append('title', object.title)
				data.append('text', object.text)
				data.append('designer_note', object.designer_note)
				data.append('teacher_note', object.teacher_note)
				object.images.forEach((img) => {
					if (typeof img.file == 'object') {
						data.append(`image_url_${img.index}`, img.file)
					}
				})
				data.append('proposed_key', proposedKeyId)
				if (!object.new) {
					await updateProposedKeySlideService(object.id, data)
				}
				else {
					delete object["id"]
					await createProposedKeySlideService(data)
				}
			}
			this.$router.go(-1)
		},
		async getProposedKey() {
			this.form = await getProposedKeyService(this.$route.params.id)
		},
		async getProposedKeySlides() {
			const filter = `?proposed_key=${this.$route.params.id}`
			const res = await allProposedKeySlidesFilterService(filter)
			this.slides = res.results
		}
	},
	created() {
		this.getProposedKey()
		this.getProposedKeySlides()
	},
}
</script>
