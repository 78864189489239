import { authenticatedApiFetch } from '../utils'


export const createExerciseQuestionInstanceService = async (question) => {
    const res = await authenticatedApiFetch(`/exercise-question-instances/`, { method: 'POST', body: JSON.stringify(question) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const getExerciseQuestionInstanceService = async (id) => {
    const res = await authenticatedApiFetch(`/exercise-question-instances/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
