<template>
	<pop-up-wrapper>
		<form class="pop-up-class-created" @submit.prevent="submit">
			<div class="wrapper">
				<p class="title">Nouveau lien</p>

				<div class="input-container">
					<label for="">Titre</label>
					<input
						list="title"
						placeholder="Titre"
						v-model="form.title"
						type="text"
						@keyup="getData"
					/>
					<datalist id="title">
						<option value="Pronote"></option>
						<option value="Ecole Direct"></option>
						<option value="Kahoot"></option>
						<option value="Genialy"></option>
					</datalist>
				</div>
				<div class="input-container">
					<label for="">Lien</label>
					<input placeholder="Lien" type="text" v-model="form.link" />
				</div>

				<div class="input-container">
					<label for="date">Créneau</label>
					<select v-model="form.currency" id="date">
						<option
							v-for="course in courses"
							:key="course.id"
							:value="course.id"
						>
							{{ course.formatedDay }} {{ course.debut_hour }} -
							{{ course.end_hour }}
						</option>
					</select>
				</div>

				<div class="btn-container">
					<p @click="this.$emit('close')" class="cancel">
						Abandonner
					</p>
					<button class="add">Valider</button>
				</div>
			</div>
		</form>
	</pop-up-wrapper>
</template>

<script>
import { allActivityEventsFilterService } from '../../../services/activityEventsService'
import { createClassLinksService } from '../../../services/classLinkService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'
import { formateAndOrderDate } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	data() {
		return {
			form: {
				title: '',
				link: '',
				teacher_id: this.$store.state.user.user,
				currency: null
			},
			courses: []

		}
	},
	methods: {
		getData(e) {
			if (!e.key) {
				const title = e.target.value
				switch (title) {
					case 'Pronote':
						this.form.link = 'https://www.index-education.com/fr/logiciel-gestion-vie-scolaire.php'
						break
					case 'Ecole Direct':
						this.form.link = 'https://www.ecoledirecte.com/'
						break
					case 'Kahoot':
						this.form.link = 'https://kahoot.it/'
						break
					case 'Genialy':
						this.form.link = 'https://app.genial.ly/'
						break
				}
			}
		},
		async submit() {
			if (!this.form.link.includes('https://')) {
				this.form.link = 'https://' + this.form.link
			}
			const data = {
				teacher_id: this.$store.state.user.user,
				class_id: this.$route.params.id,
				title: this.form.title,
				link: this.form.link,
				favicon: this.getFavicon()
			}
			const res = await createClassLinksService(data)

			if (this.form.currency) {
				let occurrence = await getOccurenceByIdService(this.form.currency)
				occurrence.class_links.push(res.id)

				await updateOccurenceService(this.form.currency, occurrence)

					.catch((error) => {
						this.errors = error.response.data.errors
					})
			}
			this.$emit('refreshLinks')
			this.$emit('close')
		},
		getFavicon() {
			return `https://s2.googleusercontent.com/s2/favicons?domain_url=${this.form.link}&size=128`
		},
		async getCourses() {
			const filter = `?classes=${this.$route.params.id}`
			const res = await allActivityEventsFilterService(filter)
			this.courses = formateAndOrderDate(res)
			if (this.$store.state.selectedOccurrence) {
				this.form.classes = this.$store.state.selectedOccurrence.classId
				const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
				this.form.currency = occurrenceId
			}
		},
	},

	created() {
		this.getCourses()
	},
}
</script>

<style>
</style>
