<template>
	<section class="student-place-flash-question-container">
		<div class="title-container">
			<h1>Activités Expresso</h1>
		</div>

		<div class="flash-question-wrapper">
			<key-container
				v-for="key in keys"
				:key="key.id"
				:index="key.id"
				:activityId="key.activityId"
				:title="key.title"
				:description="key.description"
				:image1="key.image1"
				:image2="key.image2"
				:image3="key.image3"
				:image4="key.image4"
				:image5="key.image5"
				:image6="key.image6"
				:image7="key.image7"
				:image8="key.image8"
				:image9="key.image9"
				:image10="key.image10"
				:guest_visible="key.guest_visible"
			/>
			<key-question-container
				v-for="key in this.activities"
				:key="key.id"
				:id="key.id"
				:activities="key.activities"
				:name="key.name"
			/>
		</div>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt } from '../../utils'
import KeyContainer from './KeyContainer.vue'
import KeyQuestionContainer from './KeyQuestionContainer.vue'
export default {
	components: { KeyContainer, KeyQuestionContainer },
data() {
	return {
		resources: [],
		keys: [],
		activities: [],
		classId: null
	}
},
methods: {
		async getResources() {
		const res = await axios.get(`${Config.backend_url}/api/v1/activities/?classes=${this.classId}&visible_for_student=true`)
		this.resources = res.data.results
		this.resources = this.resources.filter(function (x) {
			if (x.type == 'PACKAGE' || x.type == 'Clé en main') {
				return true
			}
			return false
		})
		this.getKeys()
	},
		async getKeys() {
		for (const resource of this.resources) {
			if (resource.key !== null) {
				let res = await axios.get(`${Config.backend_url}/api/v1/keys/${resource.key}`)
				res.data.activityId = resource.id
				this.keys.push(res.data)
			}
			
		}
	}
},

created() {
	this.classId = decrypt(this.$route.params.classId)
	this.getResources()

},
}
</script>

<style>
</style>
