<template>
	<td>
		<span class="expresso" v-if="isKey">
			<img
				class="icon"
				src="/img/class/resources/expresso-icon.svg"
				alt=""
			/>
			Activité Expresso
		</span>
		<span
			class="flash"
			v-else-if="
				type == 'ACTIVITY' ||
				type == 'Activité rituelle' ||
				type == 'PACKAGE'
			"
		>
			<img
				class="icon"
				src="/img/class/resources/flash-icon.svg"
				alt=""
			/>
			Question flash - <span v-if="isNoted"> Notée</span
			><span v-else-if="type == 'PACKAGE'"> Clé en main</span
			><span v-else> A la carte</span>
		</span>

		<span
			class="classico"
			v-else-if="type == 'ExerciseSheet' || type == 'EXERCISE_SHEET'"
		>
			<img
				class="icon"
				src="/img/class/resources/classico-icon.svg"
				alt=""
			/>Exercice Classico
		</span>
		<span
			class="classico"
			v-else-if="type == 'EVALUATION' || type == 'Evaluation'"
		>
			<img
				class="icon"
				src="/img/class/resources/classico-icon.svg"
				alt=""
			/>
			Exercice Classico - Evaluation
		</span>
		<span
			class="game"
			v-else-if="
				type == 'Game' ||
				type == `Calendrier de l'Avent` ||
				type == 'TileGame'
			"
		>
			<img
				class="icon"
				src="/img/class/resources/game-icon.svg"
				alt=""
			/>Jeu Sérieux
		</span>
		<span class="flash" v-else>
			{{ type }}
		</span>
	</td>
</template>

<script>
export default {
	props: {
		type: String,
		isNoted: Boolean,
		isKey: Number
	},
	methods: {
		changeColor(e) {
			if (e == 'flash') {
				return ('flash')
			}
			if (e == "Jeux sérieux") {
				return ('jeux')
			}
			else {
				return ('evaluations')
			}
		},
	},
}
</script>

<style>
</style>
