<template>
	<h1>Ajouter un Exercices calendrier de l'avent</h1>
	<form
		@submit.prevent="submit"
		class="form-container"
		enctype="multipart/form-data"
	>
		<div class="input-container">
			<label for="Title">Titre</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div class="input-container">
			<label for="Image">Photo</label>
			<input
				type="file"
				v-on:change="changePhoto"
				class="form-control"
				placeholder="Photo"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Vidéo url</label>
			<input
				type="text"
				v-model="form.video_url"
				class="form-control"
				placeholder="Vidéo url"
			/>
		</div>
		<div class="input-container">
			<label for="Correction"
				>Correction (attention, pas plus de 200 caractères)</label
			>
			<input
				type="text"
				class="form-control"
				v-model="form.correction"
				id="Correction"
				placeholder="Correction"
			/>
		</div>
		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select id="niveaux" v-model="form.level_id">
				<option
					v-for="level in $store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="niveaux">Type</label>
			<select id="niveaux" v-model="form.type">
				<option value="Exercices">
					Exercices
				</option>
				<option value="Enigmes">Enigmes</option>
				<option value="Défis par équipe">Défis par équipe</option>
				<option value="Constructions géométriques">
					Constructions géométriques
				</option>
				<option value="Enigmes à distance">Enigmes à distance</option>
				<option value="Messages pour le 25 Décembre">
					Messages pour le 25 Décembre
				</option>
				<option value="Vidéos à distance">Vidéos à distance</option>
			</select>
		</div>

		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { createChristmasCalendarExerciseService } from '../../../services/christmasCalendarExercisesService'

export default {
	data() {
		return {
			form: {
				title: '',
				image_url: '',
				video_url: '',
				correction: '',
				level_id: '',
				type: '',
				teacher: this.$store.state.user.user,
			},

			levels: [],
			errors: '',
			status: '',

		}
	},
	methods: {
		checkForm() {
			if (!this.form.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ titre',
					status: 'error'
				}
				return false
			}
			if (!this.form.image_url) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ image',
					status: 'error'
				}
				return false
			}
		},
		async submit() {
			if (!this.checkForm()) {
				return
			}
			await createChristmasCalendarExerciseService(this.getFormDataValue())

			this.$store.state.toast = {
				status: 'success',
				text: 'Enregistré avec succès'
			}
			this.form.title = ""
			this.form.description = ""

		},
		getChapter() {
			let sequences = this.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
			if (sequences.length !== 0) {
				let chapterArray = []
				sequences.forEach(element => {
					let chapters = this.chapters.filter(x => x.sequence == element)
					chapterArray.push(...chapters)
				})
				return chapterArray
			}
			if (this.selectedLevel == null) {
				return this.chapters
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('level_id', this.form.level_id)
			data.append('image_url', this.form.image_url)
			data.append('video_url', this.form.video_url)
			data.append('correction', this.form.correction)
			data.append('type', this.form.type)
			data.append('teacher', this.form.teacher)
			return data
		},

		changePhoto(e) {
			this.form.image_url = e.target.files[0]
		},



	},

}
</script>

<style>
</style>
