<template>
	<div class="title-container">
		<hr />
		<h2>{{ title }}</h2>
		<hr />
		<button v-if="editable" @click="$emit('removeSlide')">
			<img src="/img/super-admin/remove-slide.svg" alt="" />
		</button>
	</div>
</template>

<script>
export default {
	props: {
		title: String,
		editable: {
			type: Boolean,
			default: false
		}
	},

}
</script>

<style>
</style>
