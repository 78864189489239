<template>
	<div
		ref="file"
		class="folder-container"
		@drop="drop($event, newFolder.id)"
		@dragover="dragover"
		@dragleave="dragleave"
		:class="selected ? 'selected' : ''"
	>
		<h1 @click="oneClick" class="folder-title">
			<img src="/img/activity/folder.svg" alt="" />
			{{ newFolder.name }}
		</h1>
		<div class="info-container">
			<p>{{ getDate(newFolder.uploaded_at) }}</p>

			<button @click="isPopUpVisible = !isPopUpVisible">
				<img src="/img/class/menu.svg" alt="" />
			</button>
		</div>
		<Teleport to="body">
			<pop-up-menu-folder
				v-if="isPopUpVisible"
				:x="x"
				:y="y"
				@close="isPopUpVisible = !isPopUpVisible"
				@remove="removeFolder"
				@showPopUpEditFolder="
					isPopUpEditFolderVisible = !isPopUpEditFolderVisible
				"
				:folder="newFolder"
			/>
		</Teleport>
		<Teleport to="body">
			<pop-up-edit-folder
				v-if="isPopUpEditFolderVisible"
				@close="isPopUpEditFolderVisible = !isPopUpEditFolderVisible"
				:folder="newFolder"
				@getFolders="changeFolderName"
			/>
		</Teleport>
	</div>
</template>

<script>
import { createFileService, updateFileService } from '../../../../services/filesService'
import PopUpEditFolder from '../../../PopUp/Class/PopUpEditFolder.vue'
import PopUpMenuFolder from '../../../PopUp/Class/PopUpMenuFolder.vue'
export default {
	components: { PopUpMenuFolder, PopUpEditFolder },
	props: {
		folder: Object,
		selected: Boolean
	},
	data() {
		return {
			newFolder: {},
			delay: 700,
			clicks: 0,
			timer: null,
			isPopUpVisible: false,
			isPopUpEditFolderVisible: false
		}
	},
	methods: {
		changeFolderName(e) {
			this.newFolder.name = e
		},

		removeFolder(e) {
			this.$emit('remove', e)
		},

		getDate(e) {
			return e.slice(0, 10)
		},
		oneClick() {
			this.clicks++
			this.$emit('select', this.folder.id)
			if (this.clicks === 1) {
				this.timer = setTimeout(() => {
					this.clicks = 0
				}, this.delay)
			} else {
				clearTimeout(this.timer)
				this.$emit('redirect')

				this.clicks = 0
			}
		},
		dragover(event) {
			event.preventDefault()
			if (!event.currentTarget.classList.contains('drag-enter')) {
				event.currentTarget.classList.remove('not-drag-enter')
				event.currentTarget.classList.add('drag-enter')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.add('not-drag-enter')
			event.currentTarget.classList.remove('drag-enter')
		},


		async drop(event, parentFolder) {
			if (event.dataTransfer.getData('itemID')) {
				const fileId = event.dataTransfer.getData('itemID')
				const data = {
					parent_folder_id: parentFolder
				}
				updateFileService(fileId, data)

					.then(() => {
						this.$emit('getFiles')
					})
			}
			else {
				this.$refs.file.files = event.dataTransfer.files
				const data = new FormData()

				data.append('name', this.$refs.file.files[0].name)
				data.append('parent_folder_id', parentFolder)
				data.append('class_id', this.$route.params.id)
				data.append('file_upload', this.$refs.file.files[0])

				await createFileService(data)
					.then(() => {
						this.$emit('getFiles')
					})
			}
			event.currentTarget.classList.add('not-drag-enter')
			event.currentTarget.classList.remove('drag-enter')
			event.preventDefault()
		}
	},
	created() {
		this.newFolder = this.folder
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY
		})
	},
}
</script>

<style>
</style>
