<template>
	<section>
		<div class="add-class">
			<h1 class="classname" v-if="getClassLength !== 0">
				{{ this.$store.getters.class(this.$route.params.id) }}
			</h1>
		</div>

		<div class="parameter-container">
			<class-router />
		</div>
	</section>
</template>

<script>

import ClassRouter from './ClassRouter.vue'

export default {
	components: {
		ClassRouter,
	},
	data() {
		return {
			evaluations: [],
			agendas: [],
			type: '',
			selectedRessourceId: null,
		}
	},

	computed: {
		getClassLength() {
			if (this.$store.state.classes) {
				return this.$store.state.classes.length
			}
			else {
				return null
			}
		}
	},

};
</script>

<style>
</style>
