
<template>
	<section class="propose-key-container">
		<h1>Activité clé en main</h1>

		<div class="basic-info-container">
			<div class="input-container">
				<label>{{ key.title }}</label>
			</div>
			<div class="input-container">
				<label>{{ key.level_name }}</label>
			</div>
			<div class="input-container">
				<label>{{ key.chapter_name }}</label>
			</div>
			<div class="input-container">
				<label>{{ key.teacher_name }}</label>
			</div>
		</div>
		<slide-visualizer
			v-for="(slide, index) in slides"
			:key="slide"
			:index="index + 1"
			:slide="slide"
		/>
		<div class="btn-container row">
			<button class="refuse" @click="refuseKey" v-if="approveMod">
				Refuser
			</button>

			<button @click="approveKey" v-if="approveMod">Approuver</button>
		</div>
	</section>
</template>
<script>
import { authenticatedApiFetch } from '../../../utils'
import SlideVisualizer from './SlideVisualizer.vue'
export default {
	props: {
		approveMod: {
			type: Boolean,
			default: false
		}
	},
	components: { SlideVisualizer },
	data() {
		return {
			key: {},
			slides: []
		}
	},
	methods: {

		async approveKey() {
			await authenticatedApiFetch(`/proposed-keys/${this.$route.params.id}/validate/`)
			this.$router.push({ name: 'SuperAdminAllProposedKeys' })
		},
		async refuseKey() {
			await authenticatedApiFetch(`/proposed-keys/${this.$route.params.id}/refuse/`)
			this.$router.push({ name: 'SuperAdminAllProposedKeys' })
		},
		async getKey() {
			this.key = await authenticatedApiFetch(`/proposed-keys/${this.$route.params.id}/`)
				.then(res => res.json())

			const res = await authenticatedApiFetch(`/proposed-key-slides/?proposed_key=${this.key.id}`)
				.then(res => res.json())
			this.slides = res.results

		}

	},

	created() {
		this.getKey()
	},
}
</script>
