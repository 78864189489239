<template>
	<td>
		<div class="dl-container" v-if="data.notice">
			<div class="dl">
				<span class="text">Télécharger la notice</span>
				<img
					@click="
						downloadItem({
							url: data.notice,
							label: data.notice,
						})
					"
					src="/img/txt-icon.svg"
					alt=""
				/>
			</div>
		</div>
		<div class="dl-container" v-if="data.type == `Calendrier de l'Avent`">
			<div class="dl">
				<span class="text">Corrections</span>
				<img
					@click="showCalendarSoluce(data.id)"
					src="/img/txt-icon.svg"
					alt=""
				/>
			</div>
		</div>
		<div class="dl-container" v-if="data.type == `TileGame`">
			<div class="dl">
				<span class="text">Corrections</span>
				<img
					@click="showTileGameSoluce(data.id)"
					src="/img/txt-icon.svg"
					alt=""
				/>
			</div>
		</div>
		<div class="dl-container" v-if="data.type == `QCM`">
			<div class="dl">
				<span class="text">Corrections</span>
				<img
					@click="showQcmSoluce(data.id)"
					src="/img/txt-icon.svg"
					alt=""
				/>
			</div>
		</div>
		<pop-up-calendar-soluce
			@close="isVisibleCalendarSoluce = !isVisibleCalendarSoluce"
			:id="data.id"
			v-if="isVisibleCalendarSoluce"
		/>
		<pop-up-tile-game-soluce
			@close="isVisibleTileGameSoluce = !isVisibleTileGameSoluce"
			:id="data.id"
			v-if="isVisibleTileGameSoluce"
		/>
		<PopUpQCMSoluce v-if="isQcmSoluceVisible" @close="isQcmSoluceVisible = !isQcmSoluceVisible" :id="data.id" />
	</td>
</template>

<script>
import { authenticatedImageFetch } from '../../../../utils'
import PopUpQCMSoluce from '../../../PopUp/QCM/PopUpQCMSoluce.vue'
import PopUpCalendarSoluce from '../../../PopUp/SeriousGame/Calendar/PopUpCalendarSoluce.vue'
import PopUpTileGameSoluce from '../../../PopUp/SeriousGame/TileGame/PopUpTileGameSoluce.vue'
export default {
	components: { PopUpCalendarSoluce, PopUpTileGameSoluce, PopUpQCMSoluce },
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			isVisibleCalendarSoluce: false,
			isVisibleTileGameSoluce: false,
			isQcmSoluceVisible: false
		}
	},
	methods: {
		async downloadItem({ url, label }) {

			const response = await authenticatedImageFetch(url, { method: 'GET', responseType: "blob" })
				.then((response) => response.blob())


			const blob = new Blob([response])
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = label
			link.click()
			URL.revokeObjectURL(link.href)
		},
		showCalendarSoluce() {
			this.isVisibleCalendarSoluce = !this.isVisibleCalendarSoluce
		},
		showTileGameSoluce() {
			this.isVisibleTileGameSoluce = !this.isVisibleTileGameSoluce
		},
		showQcmSoluce(){
			this.isQcmSoluceVisible = !this.isQcmSoluceVisible
		}
	},
}
</script>

<style>
</style>
