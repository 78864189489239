<template>
	<pop-up-wrapper>
		<div class="pop-up-preview exercise">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<div class="pop-up-text-container">
				<div>
					<h2 class="question-order-title">{{ title }}</h2>
					<div class="img-container" ref="imgContainer">
						<p class="subtitle">Exercice</p>
					</div>
					<div class="img-container" ref="imgContainer2">
						<p class="subtitle">Correction</p>
					</div>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedImageFetch } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	order: 0,
	props: {
		description_img: String,
		correction_img: String,
		title: String
	},
	data() {
		return {
			question: {
			},
			imageData: ''
		}
	},
	methods: {
		close() {
			this.$emit('closed')
		},

		async generateImg(url, container) {
			await authenticatedImageFetch(url, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						container.append(img)
					}
					reader.readAsDataURL(blob)
				})
		}
	},
	mounted() {
		this.generateImg(this.description_img, this.$refs.imgContainer)
		this.generateImg(this.correction_img, this.$refs.imgContainer2)
	},

}
</script>

<style>
</style>
