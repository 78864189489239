<template>
	<section class="create-tile-game-container">
		<img
			class="top-img"
			:src="`/img/game/tiles/bg-create-tile-game/${form.theme}-top.png`"
			alt=""
			:style="`transform:translateX(${topPos})`"
		/>
		<router-link class="return-btn" :to="{ name: 'JeuSerieux' }">
			<img src="/img/return.svg" alt="" />
		</router-link>
		<img
			class="wander-math-logo"
			src="/img/game/tiles/wander-math.svg"
			alt=""
		/>
		<p class="intro-text">Wander Game est un jeu de plateau personnalisable qui se joue en classe.
				Les élèves s'affrontent en équipe sur des questions du programme, des défis, des énigmes.

				Cela permet de revoir les notions vues auparavant tout en dynamisant la classe.</p>
		<div class="text-slider-container">
			<p class="intro-text">
				
				🔧 <strong> Matériel </strong> : Vidéoprojecteur et PC de l'enseignant
				<br>
				<br>
				⏱ <strong> Durée </strong> : 10 minutes de préparation, 1 heure de jeu
				<br>
				<br>
				📅 <strong> Quand jouer ? </strong> Avant les vacances…
			</p>
			<div class="form-container">
				<slider @changeTheme="changeTheme" />
			</div>
		</div>
		<button @click="submit" class="submit dark">Créer le jeu</button>

		<img
			class="bot-img"
			:src="`/img/game/tiles/bg-create-tile-game/${form.theme}-bot.png`"
			alt=""
			:style="`transform:translateX(${botPos})`"
		/>
		<pop-up-rule-tile
			v-if="popUpRuleVisible"
			@close="popUpRuleVisible = !popUpRuleVisible"
			@next="
				(popUpDeroulementVisible = !popUpDeroulementVisible),
					(popUpRuleVisible = !popUpRuleVisible)
			"
		/>
		<pop-up-deroulement-tile
			v-if="popUpDeroulementVisible"
			@prev="
				(popUpDeroulementVisible = !popUpDeroulementVisible),
					(popUpRuleVisible = !popUpRuleVisible)
			"
			@close="popUpDeroulementVisible = !popUpDeroulementVisible"
		/>
	</section>
</template>

<script>
import { getTileGameInfo } from '../../../../../utils'
import PopUpDeroulementTile from '../../../../PopUp/SeriousGame/TileGame/PopUpDeroulementTile.vue'
import PopUpRuleTile from '../../../../PopUp/SeriousGame/TileGame/PopUpRuleTile.vue'
import Slider from './Slider.vue'
import { allTileGameThemesService } from '../../../../../services/tileGameThemesService'

export default {
	components: { PopUpRuleTile, PopUpDeroulementTile, Slider },
	data() {
		return {
			form: {
				class_id: '',
				level_id: '',
				template: '',
				theme: ''
			},
			topPos: 0,
			botPos: 0,

			popUpRuleVisible: false,
			popUpDeroulementVisible: false,
			themes: []
		}
	},

	methods: {
		changeTheme(theme) {
			this.topPos = '-450px'
			this.botPos = '450px'
			setTimeout(() => {
				this.topPos = '0'
				this.botPos = '0'
				this.form.theme = theme

			}, 400)
		},
		getFirstLevels(levels) {
			if (levels) {
				return levels.slice(0, 4)
			}
		},

		getTileGameInfo: getTileGameInfo,
		submit() {
			this.$router.push({ name: 'TileGameOrganize', params: {  theme: this.form.theme } })
		},

		async getTheme() {
			this.themes = await allTileGameThemesService()
		}
	},
	created() {
		this.getTheme()
	},

}
</script>

<style>
</style>
