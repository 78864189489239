<template>
	<pop-up-wrapper>
		<div class="pop-up fill-input-question">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Proposer une question</p>
			<div>
				<label for="">Template</label>
				<slide-maker
					ref="slideMaker"
					:type="type"
					:theme="theme"
					:template="template"
				/>
				<div class="input-container">
					<label for="correction">Correction</label>
					<textarea
						v-model="correction"
						id="correction"
						name=""
						cols="30"
						rows="10"
					></textarea>
				</div>

				<button @click="emitSubmit" class="submit dark">Valider</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
import SlideMaker from './SlideMaker.vue'
export default {
	components: { PopUpWrapper, SlideMaker },
	props: {
		type: String,
		theme: String,
		template: Number,
		tileGameTypes: Array
	},
	data() {
		return {
			correction: '',
		}
	},
	methods: {
		checkErrors(data) {
			if (!data.text && this.template !== 2 && this.template !== 3) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ Texte',
					status: 'error'
				}
				return false
			}
			if (!data.image_url && this.template == 1) {
				this.$store.state.toast = {
					text: 'Merci de mettre une image',
					status: 'error'
				}
				return false
			}
			if (!data.image_url && this.template == 3) {
				this.$store.state.toast = {
					text: 'Merci de mettre une image',
					status: 'error'
				}
				return false
			}
			if (!data.video_url && this.template == 2) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ lien',
					status: 'error'
				}
				return false
			}
			if (data.image_url && this.$refs.slideMaker.image_url.size > 1000000) {
				this.$store.state.toast = {
					text: "La taille de l'image ne doit pas peser plus de 1 Mo",
					status: 'error'
				}
				return false
			}
			return true
		},
		async emitSubmit() {
			const slideMaker = this.$refs.slideMaker
			const image_url = slideMaker.image_url ? slideMaker.image_url : null
			const video_url = slideMaker.video_url ? slideMaker.video_url : null
			const data = {
				text: slideMaker.text,
				correction: this.correction
			}
			if (this.template == 1 || this.template == 3) {
				data.image_url = image_url
			}
			if (this.template == 2) {
				data.video_url = video_url
			}

			if (this.checkErrors(data)) {
				this.$emit('submit', data)
			}
		}
	},
}
</script>

<style>
</style>
