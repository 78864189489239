<template>
	<section class="my-resources-page">
		<div class="title-container">
			<h1>Mes ressources</h1>
			<button
				@click="
					isPopUpCHooseResourceVisible = !isPopUpCHooseResourceVisible
				"
				class="submit dark"
			>
				+ Créer une ressource
			</button>
		</div>
		<my-resources-nav />
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="resource-wrapper" :is="Component"></component>
			</transition>
		</router-view>
		<pop-up-choose-resource
			v-if="isPopUpCHooseResourceVisible"
			@close="
				isPopUpCHooseResourceVisible = !isPopUpCHooseResourceVisible
			"
			:x="x"
			:y="y"
		/>
	</section>
</template>

<script>
import PopUpChooseResource from '../../PopUp/Resource/PopUpChooseResource.vue'
import MyResourcesNav from './MyResourcesNav.vue'
export default {
	components: { MyResourcesNav, PopUpChooseResource },
	data() {
		return {
			isPopUpCHooseResourceVisible: false,
			x: 0,
			y: 0
		}
	},
	created() {
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY
		})

	},
}   
</script>

<style>
</style>
