<template>
	<div class="full-page-loader-container">
		<div class="loader">
			<span style="--index: 1"></span>
			<span style="--index: 2"></span>
			<span style="--index: 3"></span>
			<span style="--index: 4"></span>
		</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
</style>
