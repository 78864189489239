<template>
	<section class="student-place-exercise-sheet-container">
		<div class="title-container">
			<h1>Exercices Classico</h1>
		</div>

		<div class="exercise-sheet-wrapper">
			<button
				class="exercise-sheet"
				v-for="exerciseSheet in exerciseSheets"
				:key="exerciseSheet.id"
				@click="
					showPopUpPdf(
						exerciseSheet.id,
						exerciseSheet.type,
						exerciseSheet.title
					)
				"
			>
				{{ exerciseSheet.title }}
			</button>
			<key-question-container
				v-for="key in 0"
				:key="key.id"
				:id="key.id"
				:activities="key.activities"
				:name="key.name"
			/>
		</div>

		<pop-up-latex-pdf
			v-if="isPopUpPdfVisible"
			@closed="isPopUpPdfVisible = !isPopUpPdfVisible"
			:exerciseId="selectedRessourceId"
			:name="selectedRessourceName"
			:className="classe.name"
			:showCorrection="false"
		/>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt } from '../../utils'
import KeyQuestionContainer from './KeyQuestionContainer.vue'
import PopUpLatexPdf from '../PopUp/ExerciseSheet/PopUpLatexPdf.vue'
export default {
	components: { KeyQuestionContainer, PopUpLatexPdf },
	data() {
		return {
			exerciseSheets: [],
			classId: null,
			isPopUpPdfVisible: false,
			selectedRessourceId: null,
			selectedRessourceType: null,
			selectedRessourceName: null
		}
	},
	methods: {
		async getExerciseSheets() {
			const res = await axios.get(`${Config.backend_url}/api/v1/exercise-sheets/?classes=${this.classId}&visible_for_student=true`)
			this.exerciseSheets = res.data.results
			this.exerciseSheets = this.exerciseSheets.filter(x => x.type == 'EXERCISE_SHEET')
		},
		showPopUpPdf(id, type, name) {
			this.isPopUpPdfVisible = !this.isPopUpPdfVisible
			this.selectedRessourceId = id
			this.selectedRessourceType = type
			this.selectedRessourceName = name
		},
		async getClasse() {
			const res = await axios.get(`${Config.backend_url}/api/v1/classes/${this.classId}`)
			this.classe = res.data
		},
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getExerciseSheets()
		this.getClasse()
	},
}
</script>

<style>
</style>
