<template>
	<section class="question">
		<div class="question-flash-header-wrapper">
			<h1>
				<img src="/img/payment/flash-icon.svg" alt="" /> Questions Flash
			</h1>
			<img
				@click="isPopUpFlashVisible = !isPopUpFlashVisible"
				src="/img/info-bulle.svg"
				alt=""
			/>
			
		</div>
		
		<filterWrapper
			@updateFilter="updateFilter"
		/>
		<div class="link-container">
			<div class="link-wrapper">
				<router-link :to="{name:'Question'}" id="question">Choix d'une question</router-link>
				<router-link :to="{name:'QuestionKey'}" id="key">Choix d'un diaporama clé en main</router-link>

				<span class="underline" :style="'width:' + spanWidth + 'px;left:' + spanPosition + 'px;'"
				></span>
			</div>
			<div class="btn-container">
				<router-link class="submit dark" :to="{name:'MyResourcesAddQuestion'}">✏️ Créer une question</router-link>
				<router-link class="submit dark" :to="{name:'GenerateFlashQuestion'}">✨ Générer une question</router-link>
			</div>
		</div>
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="main-component" :is="Component" :filter="filter"></component>
			</transition>
		</router-view>
		<pop-up-flash
			v-if="isPopUpFlashVisible"
			@close="isPopUpFlashVisible = false"
		/>
	</section>
</template>

<script>

import PopUpFlash from '../../../PopUp/PopUpFlash.vue'
import FilterWrapper from '../FilterWrapper.vue';
export default {
	components: {
		PopUpFlash,
		FilterWrapper
	},
	data() {
		return {
			isPopUpFlashVisible: false,
			spanWidth: 0,
			spanPosition: 0,
			filter: {
				chapter: null,
				subject: null,
				level: null,
				type: [],
				theme: [],
				difficulty: [],
				offset: 0,
				search: '',
			},
		}
	},

	methods: {
		updateFilter(data) {
			this.filter.level = data.level
			this.filter.subject = data.subject
			this.filter.chapter = data.chapter
			this.filter.search = data.search
			this.filter.teacher = data.teacher
		}
	},

	mounted () {
		const question = document.getElementById('question')
		const key = document.getElementById('key')
		this.questionWidth = question.offsetWidth
		this.keyWidth = key.offsetWidth
		


		this.questionPosition = question.getBoundingClientRect().left - question.getBoundingClientRect().left
		this.keyPosition = key.getBoundingClientRect().left - question.getBoundingClientRect().left
		
		if (this.$route.name === 'Question') {
			this.spanWidth = this.questionWidth
			this.spanPosition = this.questionPosition
		} else {
			this.spanWidth = this.keyWidth
			this.spanPosition = this.keyPosition
		}
		
	},

	watch: {
		$route(to) {
			if (to.name === 'Question') {
				this.spanWidth = this.questionWidth
				this.spanPosition = this.questionPosition
			} else {
				this.spanWidth = this.keyWidth
				this.spanPosition = this.keyPosition
			}
		}
	}
}
</script>

<style>
</style>
