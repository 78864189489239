<template>
	<td>
		<button
			v-if="object.type !== 'TileGame'"
			class="share"
			:class="object.visible_for_student ? 'active' : ''"
			@click="
				setVisible(object.id, object.type, !object.visible_for_student)
			"
		>
			<img
				v-if="object.visible_for_student"
				src="/img/share.svg"
				alt=""
			/>
			<img v-else src="/img/share-line.svg" alt="" />
		</button>

		<Teleport to="body">
			<PopUpShareResource :id="object.id" :type="object.type" @close="isSharedPopUpVisible = !isSharedPopUpVisible" @updateStatus="updateStatus" v-if="isSharedPopUpVisible"/>
		</Teleport>
	</td>
</template>

<script>
import { filteredSharedResourcesService } from '../../../../services/sharedResourcesService'
import { authenticatedApiFetch } from '../../../../utils'
import PopUpShareResource from '../../../PopUp/PopUpShareResource.vue'
export default {
	components: {
		PopUpShareResource
	},
	props: {
		data: {
			type: Object,
			required: true
		}
	},
	data() {
		return {
			object: {},
			isSharedPopUpVisible: false
		}
	},
	methods: {
		updateStatus(status){
			this.object.visible_for_student = status
		},
		async setVisible(id, type, value) {
			let url

			if (type === "Activité rituelle" || type === "ACTIVITY") {
				url = 'activities'
			}
			if (type === "PACKAGE" || type === "Clé en main") {
				url = 'activities'
			}
			if (type == "Game") {
				url = 'games'
			}
			if (type == "Évaluation" || type == "ExerciseSheet" || type == "EVALUATION" || type == "EXERCISE_SHEET") {
				url = 'exercise-sheets'
			}
			if (type == "Calendrier de l'Avent") {
				url = 'christmas-calendar'
			}
			if (type == "Leçon") {
				url = 'imported-lessons'
			}
			if(type == 'QCM'){
				url = 'qcms'
				this.isSharedPopUpVisible = true
				return 
			}
			this.object.visible_for_student = value

			const data = {
				visible_for_student: value
			}

			await authenticatedApiFetch(`/${url}/${id}/`, { method: 'PATCH', body: JSON.stringify(data) })

		},
		async checkIfQCMIsShared(){
			const filter = `qcm_id=${this.object.id}`
			const res = await filteredSharedResourcesService(filter)
			if(res[0]?.students.length > 0){
				this.object.visible_for_student = true
			}
		}
	},
	created() {
		this.object = this.data
		if(this.object.type == 'QCM'){
			this.checkIfQCMIsShared()
		}
	},
}
</script>

<style>
</style>
