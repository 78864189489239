<template>
	<section class="student-container">
		<student-list-observation :students="students" />
		<student-observation :students="students" />
		<full-page-loader v-if="isLoading" />
		<pop-up-validate-class
			v-if="popUpValidateClass"
			@close="popUpValidateClass = !popUpValidateClass"
		/>
	</section>
</template>

<script>

import { allStudentsInClasseService } from '../../../services/studentsService'
import PopUpValidateClass from '../../PopUp/Class/PopUpValidateClass.vue'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
import StudentObservation from './Student/StudentObservation.vue'
import StudentListObservation from './Student/StudentListObservation.vue'
export default {
	components: { PopUpValidateClass, FullPageLoader, StudentObservation, StudentListObservation },
	data() {
		return {
			students: [],
			popUpValidateClass: false,
			isLoading: false
		}
	},
	methods: {
		allStudent: async function () {
			this.students = await allStudentsInClasseService(this.$route.params.id)
		},
	},

	created() {
		this.allStudent()
	},
}
</script>

