<template>
	<h1>Proposer une question</h1>
	<form @submit.prevent="showPopUpFillInputVisible">
		<div class="input-container half-size">
			<label for="niveaux">Niveau</label>
			<select multiple id="niveaux" v-model="form.levels">
				<option
					v-for="level in $store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>

		<div class="input-container">
			<label for="niveaux">Type de question</label>
			<select id="niveaux" v-model="form.tile_game_type_id">
				<option
					v-for="type in tileGameTypes"
					:key="type.id"
					:value="type.id"
				>
					{{ getCustomTypeName(type.name) }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="">Plateau de jeux</label>
			<div class="checkbox-container" v-if="tileGameThemes !== 0">
				<label
					v-for="tileGameTheme in tileGameThemes"
					:key="tileGameTheme.id"
					:for="tileGameTheme.name"
				>
					<input
						v-model="themes"
						:id="tileGameTheme.name"
						type="checkbox"
						:value="tileGameTheme.name"
					/>{{ getName(tileGameTheme.name) }}
				</label>
			</div>
		</div>
		<div class="input-container">
			<label for="">Contenu</label>
			<div class="checkbox-container">
				<label for="txt">
					<input
						v-model="template"
						:value="0"
						id="txt"
						type="radio"
					/>Texte Uniquement
				</label>
				<label for="txt-img">
					<input
						v-model="template"
						:value="1"
						id="txt-img"
						type="radio"
						:disabled="form.tile_game_type_id == 5"
					/>Texte + Image décorative
				</label>
				<label for="txt-img-math">
					<input
						v-model="template"
						:value="2"
						id="txt-img-math"
						type="radio"
						:disabled="form.tile_game_type_id == 5"
					/>Texte + Image mathématique
				</label>
			</div>
		</div>
		<button class="submit dark">Valider</button>
	</form>
	<Teleport to="body">
		<pop-up-fill-input
			v-if="isPopUpFillInputVisible"
			:type="getTypeName(form.tile_game_type_id)"
			:theme="themes[0]"
			:template="template"
			@close="showPopUpFillInputVisible"
			@submit="submit"
			:tileGameTypes="tileGameTypes"
		/>
	</Teleport>
</template>

<script>
import { createTileGameContentService } from '../../../services/tileGameContentsService'
import { allTileGameThemesService } from '../../../services/tileGameThemesService'
import { allTileGameTypesService } from '../../../services/tileGameTypesService'
import PopUpFillInput from '../../PopUp/SeriousGame/TileGame/PopUpFillInput.vue'

export default {
	components: { PopUpFillInput },
	data() {
		return {

			form: {
				title: '',
				text: '',
				teacher: this.$store.state.user.user,
				correction: '',
				tile_game_type_id: null,
				suggested_img_url: '',
				levels: [],
				status: ''
			},
			tileGameTypes: [],
			tileGameThemes: [],
			themes: [],
			template: 0,
			isPopUpFillInputVisible: false,
			errors: '',

		}
	},
	methods: {

		getCustomTypeName(name) {
			switch (name) {
				case 'Flash':
					return 'Flash'
				case 'OneShot':
					return 'OneShot'
				case 'Enigma':
					return 'Enigma'
				case 'Boomerang':
					return 'Boomerang'
				case 'HitOrMiss':
					return 'Chance'
			}
		},
		getTypeName(id) {
			const typeName = this.tileGameTypes.find(x => x.id == id).name
			return typeName
		},
		getName(name) {
			switch (name) {
				case 'MATH_LA_CITROUILLE':
					return 'La Citrouille'

				case 'MATH_LA_BANQUISE':
					return 'La Banquise'

				case 'MATH_LA_PLAGE':
					return 'La Plage'

				case 'MATH_L_ESPACE':
					return "L'Espace"

				case 'MATH_LE_CIEL':
					return "Le Ciel"

				case 'MATH_LE_SKATE':
					return "Le Skate"

				case 'MATH_LE_ROBOT':
					return "Le Robot"
				default:
					return "Le Robot"
			}

		},
		async alltileGameThemes() {
			this.tileGameThemes = await allTileGameThemesService()
		},
		async allTileGameContentType() {
			this.tileGameTypes = await allTileGameTypesService()
		},
		checkErrors() {
			if (this.form.levels.length == 0) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un ou plusieurs niveaux",
					status: 'error'
				}
				return false
			}
			if (!this.form.tile_game_type_id) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un type de question",
					status: 'error'
				}
				return false
			}
			if (this.themes.length == 0) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un ou plusieurs plateau de jeux",
					status: 'error'
				}
				return false
			}
			return true
		},
		showPopUpFillInputVisible() {
			if (this.checkErrors()) {
				this.isPopUpFillInputVisible = !this.isPopUpFillInputVisible
			}
		},
		async submit(data) {
			this.form.teacher = this.$store.state.user.user
			this.form.status = 'PUBLIC'
			await createTileGameContentService(this.getFormDataValue(data))
			this.isPopUpFillInputVisible = false
			this.$store.state.toast = {
				text: "Question enregistrée avec succès",
				status: 'success'
			}
			this.$emit('close')
		},

		getFormDataValue(content) {
			let themes = []
			for (const theme of this.themes) {
				themes.push(this.tileGameThemes.find(x => x.name == theme))
			}
			const data = new FormData()
			data.append('title', content.title)
			data.append('text', content.text)
			data.append('correction', content.correction)
			data.append('status', this.form.status)
			data.append('teacher', this.$store.state.user.user)
			for (const level of this.form.levels) {
				data.append('levels', level)
			}
			for (const tile_game_theme of themes) {
				data.append('tile_game_theme', tile_game_theme.id)
			}
			data.append('image', content.image)
			data.append('decorative_image', content.decorative_image)
			data.append('tile_game_type_id', this.form.tile_game_type_id)
			return data
		},
	},

	created() {
		this.allTileGameContentType()
		this.alltileGameThemes()
	},

}
</script>

<style></style>
