<template>
	<pop-up-wrapper>
		<div class="pop-up-preview-exercise-admin">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">{{ exercise.title }}</p>
			<div class="basic-info-container">
				<div>
					<p>
						{{ exercise.level_name }}
					</p>
					<p>{{ exercise.chapter_name }}</p>
				</div>
				<div>
					<p>
						{{ exercise.type_name }}
					</p>
					<p>
						{{ exercise.theme_name }}
					</p>
					<p>
						{{ exercise.difficulty_name }}
					</p>
				</div>
			</div>
			<div class="img-container">
				<p class="subtitle">Texte</p>
				<div ref="descriptionImgContainer"></div>
			</div>
			<div class="img-container">
				<p class="subtitle">Correction</p>
				<div ref="correctionImgContainer"></div>
			</div>
			<div class="btn-container">
				<button
					class="refuse"
					@click="refuseExercise"
					v-if="showValidateBtn"
				>
					Refuser
				</button>
				<button @click="approveExercise" v-if="showValidateBtn">
					Approuver
				</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { approveExerciseQuestionsService, refuseExerciseQuestionsService } from '../../../services/exerciseQuestionsService'
import { authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		exercise: Object,
		showValidateBtn: Boolean
	},
	data() {
		return {
			inputValue: ''
		}
	},
	methods: {
		async approveExercise() {
			await approveExerciseQuestionsService(this.exercise.id)
			this.$emit('refreshQuestion')
			this.$emit('close')
		},
		async refuseExercise() {
			await refuseExerciseQuestionsService(this.exercise.id)
			this.$emit('refreshQuestion')
			this.$emit('close')
		},
		async generateImg(latex, ref) {
			await authenticatedImageFetch(latex, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						const img = new Image()
						img.src = e.target.result
						ref.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},

	},
	mounted() {
		this.generateImg(this.exercise.description_img, this.$refs.descriptionImgContainer)
		this.generateImg(this.exercise.correction_img, this.$refs.correctionImgContainer)
	},
}
</script>

<style>
</style>
