<template>
	<h1>Chapitres</h1>
	<pagination-component
		:totalPages="pages"
		:perPage="20"
		:currentPage="currentPage"
		@pageChanged="getPage"
	/>
	<table>
		<tr>
			<th></th>
			<th>Name</th>
			<th>Sequence</th>
			<th>Niveau</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(chapter, index) in displayChapters" :key="index">
			<td>{{ (currentPage + 1) * perPage - perPage + index + 1 }}</td>
			<td>{{ chapter.name }}</td>
			<td>
				{{ this.$store.getters.sequence(chapter.sequence).name }}
			</td>
			<td>
				{{
					this.$store.getters.level(
						this.$store.getters.sequence(chapter.sequence).level
					).name
				}}
			</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditChapter',
						params: { id: chapter.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteChapter(chapter.id)">supprimer</button>
			</td>
		</tr>
	</table>
</template>

<script>
import PaginationComponent from '../Utils/PaginationComponent.vue'

export default {
	components: { PaginationComponent },
	data() {
		return {
			chapters: [],
			sequences: [],
			levels: [],
			count: null,
			currentPage: 0,
			pages: 0,
			perPage: 20,
		}
	},
	methods: {
		getPage(e) {
			this.currentPage = e
		},
		getSequence(e) {
			if (this.$store.state.sequences) {
				return this.$store.state.sequences.find(x => x.id == e)
			}
		},
		getLevel: function (e) {
			if (this.$store.state.levels) {
				let level = this.$store.state.levels.find(x => x.id == e)
				return level.name
			}
		},
		deleteChapter(e) {
			this.$store.dispatch('deleteChapter', e)
		},

		paginate: function (chapters) {
			let page = this.currentPage + 1
			let perPage = this.perPage
			let from = (page * perPage) - perPage
			let to = (page * perPage)


			return chapters.slice(from, to)
		},
	},
	computed: {
		displayChapters() {
			if (this.$store.state.chapters) {
				return this.paginate(this.$store.state.chapters)
			}
			else {
				return ''
			}
		}
	},

	mounted() {
		if (this.$store.state.chapters) {
			this.pages = Math.ceil(this.$store.state.chapters.length / 20)
		}
	},
}
</script>

<style>
</style>
