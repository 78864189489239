<template>
	<ul class="class-menu-container">
		<span
			:style="'width:' + spanWidth + 'px;left:' + spanPosition + 'px;'"
			class="underline"
		></span>
		<div>
			<li>
				<router-link
					id="resource"
					:to="{
						name: 'ClassResources',
					}"
					class="classes"
					>Ressources importées</router-link
				>
			</li>
			<li>
				<router-link
					id="doc"
					:to="{
						name: 'ClassDrive',
					}"
					class="classes"
					>Mes documents</router-link
				>
			</li>
			<li>
				<router-link
					id="student"
					:to="{
						name: 'ClassStudent',
					}"
					class="classes"
					>Mes élèves</router-link
				>
			</li>
			<li>
				<router-link
					id="link"
					:to="{
						name: 'ClassLink',
					}"
					class="classes"
					>Mes liens</router-link
				>
			</li>
		</div>

		<li class="btn-container">
			<router-link
				class="submit go-to-student-place"
				:to="{
					name: 'StudentPlaceHome',
					params: { classId: crypt($route.params.id) },
				}"
			>
				Espace élève
				<p class="text">
					Partage les ressources à la classe, les élèves y ont accès
					chez eux.
				</p>
			</router-link>
			<router-link
				class="submit white edit-class"
				:to="{
					name: 'EditClass',
					params: { id: this.$route.params.id },
				}"
				>Modifier la classe</router-link
			>
		</li>
	</ul>

	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component class="main-component" :is="Component"></component>
		</transition>
	</router-view>
</template>

<script>
import { crypt } from '../../../utils'
export default {
	components: {},
	data() {
		return {
			resourceWidth: '',
			studentWidth: '',
			docWidth: '',
			resourcePosition: '',
			studentPosition: '',
			docPosition: '',
			spanWidth: '',
			spanPosition: '',
		}
	},
	methods: {
		crypt: crypt,

		updateSpan(e) {
			const resource = document.getElementById('resource')
			const student = document.getElementById('student')
			const doc = document.getElementById('doc')
			const link = document.getElementById('link')


			resource.style.fontWeight = 'unset'
			student.style.fontWeight = 'unset'
			doc.style.fontWeight = 'unset'
			link.style.fontWeight = 'unset'
			switch (e) {
				case 'ClassResources':
					this.spanWidth = this.resourceWidth
					this.spanPosition = this.resourcePosition
					resource.style.fontWeight = 600
					break
				case 'ClassStudent':
					this.spanWidth = this.studentWidth
					this.spanPosition = this.studentPosition
					student.style.fontWeight = 600
					break
				case 'ClassObservation':
					this.spanWidth = this.studentWidth
					this.spanPosition = this.studentPosition
					student.style.fontWeight = 600
					break
				case 'ClassDrive':
					this.spanWidth = this.docWidth
					this.spanPosition = this.docPosition
					doc.style.fontWeight = 600
					break
				case 'ClassLink':
					this.spanWidth = this.linkWidth
					this.spanPosition = this.linkPosition
					doc.style.fontWeight = 600
					break

			}
		},

	},

	computed: {

	},
	mounted() {
		const resource = document.getElementById('resource')
		const student = document.getElementById('student')
		const doc = document.getElementById('doc')
		const link = document.getElementById('link')

		this.resourceWidth = resource.offsetWidth
		this.studentWidth = student.offsetWidth
		this.docWidth = doc.offsetWidth
		this.linkWidth = link.offsetWidth

		this.resourcePosition = resource.getBoundingClientRect().left - resource.getBoundingClientRect().left
		this.studentPosition = student.getBoundingClientRect().left - resource.getBoundingClientRect().left
		this.docPosition = doc.getBoundingClientRect().left - resource.getBoundingClientRect().left
		this.linkPosition = link.getBoundingClientRect().left - resource.getBoundingClientRect().left


		switch (this.$route.name) {
			case 'ClassResources':
				this.spanWidth = this.resourceWidth
				break
			case 'ClassStudent':
				this.spanWidth = this.studentWidth
				this.spanPosition = this.studentPosition
				break
			case 'ClassObservation':
				this.spanWidth = this.studentWidth
				this.spanPosition = this.studentPosition
				break
			case 'ClassDrive':
				this.spanWidth = this.docWidth
				this.spanPosition = this.docPosition
				break
			case 'ClassLink':
				this.spanWidth = this.linkWidth
				this.spanPosition = this.linkPosition
				break

		}
	},

	watch: {
		$route(to, from) {
			if (to.params.id !== from.params.id)
				this.updateSpan('ClassResources')

			else {
				this.updateSpan(to.name)
			}
		}
	}
}
</script>

<style>
</style>
