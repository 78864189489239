<template>
	<section>
		<h1>Modifier le profil</h1>
		<form v-if="!isLoading" @submit.prevent="submit" class="form-container">
			<div class="input-container">
				<label for="Nom">Nom*</label>
				<input
					type="text"
					class="form-control required"
					v-model="profile.last_name"
					id="Nom"
					placeholder="Nom"
				/>
			</div>
			<div class="input-container">
				<label for="Prenom">Prénom*</label>
				<input
					type="text"
					class="form-control required"
					v-model="profile.first_name"
					id="Prenom"
					placeholder="Prenom"
				/>
			</div>
			<div class="input-container">
				<label for="Ecole">Votre établissement actuel*</label>
				<input
					type="text"
					class="form-control required"
					v-model="profile.school"
					id="Ecole"
					placeholder="Ecole"
				/>
			</div>
			<div class="input-container">
				<label for="Ville">Ville de l'établissement*</label>
				<input
					type="text"
					class="form-control required"
					v-model="profile.city"
					id="Ville"
					placeholder="Ville"
				/>
			</div>
			<div class="input-container">
				<label for="Departement">Département de l'établissement* </label>
				<select v-model="profile.county" name="Departement" id="">
					<option
						v-for="county in this.$store.state.county"
						:key="county"
						:value="county.id"
					>
						{{ county.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="Academies">Votre académie de rattachement* </label>
				<select v-model="profile.academy" name="Academies" id="">
					<option
						v-for="academy in this.$store.state.academies"
						:key="academy"
						:value="academy.id"
					>
						{{ academy.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="Diplome">Diplôme(s) d'enseignement </label>
				<input
					type="text"
					class="form-control required"
					v-model="profile.diploma"
					id="Diplome"
					placeholder="Diplome"
				/>
			</div>
			<div class="input-container">
				<label for="Niveaux"
					>Vos niveaux de classe cette année (Maintenez la touche Ctrl
					pour sélectionner plusieurs niveaux) *</label
				>
				<select multiple v-model="profile.levels" name="Niveaux" id="">
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="Matiere">Matière(s) enseignée(s) *</label>
				<select
					multiple
					v-model="profile.subjects"
					name="Matiere"
					id=""
					:disabled="$store.state.user.subjects.length !== 0"
				>
					<option
						v-for="subject in this.$store.state.subjects"
						:key="subject"
						:value="subject.id"
					>
						{{ subject.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="">Numéro de téléphone</label>
				<input
					type="tel"
					class="form-control"
					v-model="profile.phone"
					id="phone"
					placeholder="Numéro de téléphone"
				/>
			</div>

			<input class="submit dark" type="submit" value="Valider" />
		</form>
	</section>
</template>

<script>
import { updateProfileService } from '../../../services/profilesService'
export default {
	data() {
		return {
			profile: {
				diploma: "",
				first_name: "",
				last_name: "",
				levels: [],
				role: "",
				school: "",
				subjects: [],
				user: null,
				county: null,
				city: "",
				academy: "",
				phone: "",
			},
			isLoading: true,

		}
	},
	methods: {
		checkForm() {
			if (!this.profile.first_name) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de remplir le champ Prénom',
				}
				return false
			}
			if (!this.profile.last_name) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de remplir le champ Nom',
				}
				return false
			}

			if (this.profile.levels.length == 0) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de sélectionner au moins un Niveau',
				}
				return false
			}
			if (this.profile.subjects.length == 0) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de sélectionner au moins une Matière',
				}
				return false
			}
			if (!this.profile.school) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de remplir le champ Ecole',
				}
				return false
			}
			if (!this.profile.city) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de remplir le champ Ville',
				}
				return false
			}
			if (!this.profile.county) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de sélectionner un Département',
				}
				return false
			}
			if (!this.profile.academy) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Merci de sélectionner une Académie',
				}
				return false
			}
			

			return true
		},
		async submit() {
			if (this.checkForm()) {
				await updateProfileService(this.$store.state.user.id, this.profile)
				await this.$store.dispatch('getProfile', this.profile.id)
				await this.$store.dispatch('getSequences')
				this.$router.push({ name: 'Profile' })
			}
		},
		getProfile () {
			this.profile = {...this.$store.state.user}
		},
	},
	created() {
		if (this.$store.state.user) {
			this.getProfile()
		}
		this.isLoading = false
	}
}
</script>

<style>
</style>
