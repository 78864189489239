import { authenticatedApiFetch } from '../utils'


export const allActivitiesService = async () => {
    const res = await authenticatedApiFetch(`/activities/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res.results
}

export const allActivitiesForTeacherService = async (userId) => {
    const res = await authenticatedApiFetch(`/activities/?teacher=${userId}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res.results
}

export const getActivityByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/activities/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const allClassActivityByIdService = async (classId) => {
    const res = await authenticatedApiFetch(`/activities/?classes=${classId}&all`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const createActivityService = async (activity) => {
    const res = await authenticatedApiFetch(`/activities/`, { method: 'POST', body: JSON.stringify(activity) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const updateActivityService = async (id, activity) => {
    const res = await authenticatedApiFetch(`/activities/${id}/`, { method: 'PATCH', body: JSON.stringify(activity) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
