<template>
	<pop-up-wrapper>
		<div class="pop-up-edit">
			<img
				@click="this.$emit('close')"
				class="close"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Choisir votre template pour vos questions</p>
			<div class="pop-up-edit-wrapper">
				<div class="container">
					<div class="input-container">
						<label for="name">Nom *</label>
						<input
							class="form-control required"
							id="name"
							v-model="form.name"
							placeholder="Nom de la ressource"
							type="text"
						/>
						<small class="error">{{ errors.name }}</small>
					</div>
					<p class="label">Modèle</p>
					<slider v-if="!interrogation" @index="checkIndex" />
					<img
						v-else
						src="/img/template/template-interrogation-preview.jpg"
						alt=""
					/>
				</div>
				<div class="container">
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							v-model="form.classes"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select
							v-if="form.classes.length !== 0"
							v-model="form.currency"
							id="date"
						>
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
						<select v-if="form.classes.length == 0"></select>
					</div>
					<div class="input-container">
						<div class="private-mode">
							<label for="private">Mode privé</label>
							<input id="private" v-model="form.privateMode" type="checkbox">
						</div>
						<p class="explication" >Votre contenu sera accessible uniquement par vous. Il ne sera pas soumis à une étape de vérification pour faire partie de la banque de clé en main publique</p>
					</div>
				</div>
			</div>
			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Slider from '../Admin/Slider.vue'
import { formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'
import { allActivityEventsFilterService } from '../../services/activityEventsService'

export default {
	components: {
		Slider,
		PopUpWrapper
	},
	props: {
		questionLength: Number,
		interrogation: Boolean
	},
	data() {
		return {
			form: {
				name: '',
				classes: [],
				index: 0,
				currency: null,
				privateMode: false
			},
			courses: [],
			classes: [],
			errors: {
			},
		}
	},

	methods: {
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)

				this.courses = formateAndOrderDate(res)

				if (this.$store.state.selectedOccurrence) {
					this.form.classes = this.$store.state.selectedOccurrence.classId
					const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
					this.form.currency = occurrenceId
				}
			}

		},

		close() {
			this.$emit('close')
		},
		submit() {
			if (this.form.name == '') {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter un nom à votre ressource'
				}
				return
			}
			if (this.form.classes.length == 0) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez selectionner une classe à votre ressource'
				}
				return
			}
			if (this.form.name !== '' && this.form.classes.length !== 0) {
				if (this.$store.state.selectedOccurrence) {
					this.$store.state.selectedOccurrence.occurrenceId = {}
				}
				this.$emit('submit', this.form)
			}
		},
		checkIndex(e) {
			this.form.index = e
		},
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},
	

	created() {
		this.allCourses()
	},

}
</script>

<style>
</style>
