<template>
	<section class="individual-stats-container">
		<h1>{{ profile.first_name }} {{ profile.last_name }}</h1>

		<table v-if="!isLoading">
			<tr>
				<th>Classe ({{ profile.classes.length }})</th>
				<th v-for="(classe, index) in profile.classes" :key="index">
					{{ classe.name }}
				</th>
			</tr>
			<tr>
				<td>Ressources ({{ notClasseTestResources().length }})</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="resource in getResources(classe.id)" :key="resource.id">
						{{ resource.name }}
					</p>
				</td>

			</tr>
			<tr>
				<td>
					Fiches d'exercices ({{ notClasseTestExercises().length }})
				</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="exercises_sheet in getExerciseSheets(classe.id)" :key="exercises_sheet.id">
						{{ exercises_sheet.title }}
					</p>
				</td>

			</tr>
			<tr>
				<td>Calendrier ({{ notClasseTestCalendars().length }})</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="game in getCalendars(classe.id)" :key="game.id">
						{{ game.id }}
					</p>
				</td>
			</tr>
			<tr>
				<td>Wander Game ({{ notClasseTestTileGames().length }})</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="game in getTileGames(classe.id)" :key="game.id">
						{{ game.id }}
					</p>
				</td>
			</tr>
			<tr>
				<td>Fichiers ({{ notClasseTestFiles().length }})</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="file in getFiles(classe.id)" :key="file.id">
						{{ file.name }}
					</p>
				</td>
			</tr>
			<tr>
				<td>Observations ({{ notClasseTestObservations().length }})</td>
				<td v-for="(classe, index) in profile.classes" :key="index">
					<p v-for="observation in getObservations(classe.id)" :key="observation.id">
						{{ observation.comment }}
					</p>
				</td>
			</tr>
		</table>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
export default {
	data() {
		return {
			profile: {},
			isLoading: true,
			calendars: [],
			tileGames: []
		}
	},
	methods: {

		notClasseTestResources() {
			const resources = this.profile.activities.filter((el) => this.filterByClasses(el))
			return resources

		},
		notClasseTestExercises() {
			const resources = this.profile.exercises_sheet.filter((el) => this.filterByClasses(el))
			return resources
		},
		notClasseTestCalendars() {
			const classes = this.profile.classes
			const resources = this.profile.games.christmasCalendars.filter((el) => {
				return classes.some((classe) => classe.id == el.class_id)
			})
			return resources
		},
		notClasseTestTileGames() {
			const classes = this.profile.classes
			const resources = this.profile.games.tileGames.filter((el) => {
				return classes.some((classe) => classe.id == el.class_id)
			})
			return resources
		},

		notClasseTestFiles() {
			const classes = this.profile.classes
			const resources = this.profile.files.filter((el) => {
				return classes.some((classe) => classe.id == el.class_id)
			})
			return resources
		},
		notClasseTestObservations() {
			const classes = this.profile.classes
			const resources = this.profile.observations.filter((el) => {
				return classes.some((classe) => classe.id == el.student_id__class_id)
			})
			return resources
		},

		filterByClasses(el) {
			const classes = this.profile.classes
			return classes.some((classe) => classe.id == el.classes)
		},

		getResources(e) {
			const resources = this.profile.activities.filter(x => x.classes == e)
			return resources
		},
		getExerciseSheets(e) {
			const resources = this.profile.exercises_sheet.filter(x => x.classes == e)
			return resources
		},

		getCalendars(e) {
			const calendars = this.profile.games.christmasCalendars.filter(x => x.class_id == e)
			return calendars
		},
		getTileGames(e) {
			const tileGames = this.profile.games.tileGames.filter(x => x.class_id == e)
			return tileGames
		},
		getFiles(e) {
			const resources = this.profile.files.filter(x => x.class_id == e)
			return resources
		},
		getObservations(e) {
			const resources = this.profile.observations.filter(x => x.student_id__class_id == e)
			return resources
		},
		async getStats() {
			this.isLoading = true
			const res = await authenticatedApiFetch(`/stat-profile/${this.$route.params.id}/`)
				.then(res => res.json())
			this.profile = res
			this.isLoading = false
		}
	},
	created() {
		this.getStats()
	},
}
</script>

<style></style>
