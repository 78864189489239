<template>
	<section id="grid" class="grid">
		<div class="grid-title-container">
			<h2>
				Liste des observations
				<button class="trash-btn" @click="deleteObservations">
					<img src="/img/class/trash.svg" alt="" />
				</button>
			</h2>
			<option-container />
		</div>
		<div v-if="isLoading" class="grid-container not-flex">
			<loader />
		</div>
		<div v-else class="grid-container not-flex">
			<table class="grid-observation-container">
				<thead>
					<tr>
						<th></th>
						<th class="red">Travail non fait</th>
						<th class="red">Comportement</th>
						<th class="orange">Oubli de matériel</th>
						<th class="green">Participation</th>
						<th></th>
					</tr>
				</thead>
				<tbody>
					<tr v-for="student in students" :key="student.id">
						<td>{{ student.name }}</td>
						<td>
							<observation-amount
								:amount="
									getObservationsAmount(
										student.id,
										'Travail non fait'
									)
								"
								:color="'#FF0000'"
							/>
						</td>
						<td>
							<observation-amount
								:amount="
									getObservationsAmount(
										student.id,
										'Comportement'
									)
								"
								:color="'#FF0000'"
							/>
						</td>
						<td>
							<observation-amount
								:amount="
									getObservationsAmount(
										student.id,
										'Oubli de matériel'
									)
								"
								:color="'#FFBA00'"
							/>
						</td>
						<td>
							<observation-amount
								:amount="
									getObservationsAmount(
										student.id,
										'Participation'
									)
								"
								:color="'#07AC00'"
							/>
						</td>
						<td>
							<button @click="showPopUp(student.id)" class="plus">
								+
							</button>
						</td>
					</tr>
				</tbody>
			</table>
		</div>
		<Teleport to="body">
			<pop-up-student
				@close="close()"
				:studentId="studentId"
				v-if="isPopUpVisible"
			/>
		</Teleport>
	</section>
</template>

<script>
// import { authenticatedApiFetch } from '../../../../utils'
import { allObservationsForClassService, deleteObservationService } from '../../../../services/observationsService'
import Loader from '../../../Loader.vue'
import PopUpStudent from '../../../PopUp/Class/PopUpStudent.vue'
import ObservationAmount from './ObservationAmount.vue'
import OptionContainer from './OptionContainer.vue'
export default {
	components: { ObservationAmount, Loader, OptionContainer, PopUpStudent },
	props: {
		students: Array
	},
	data() {
		return {
			observations: [],
			isLoading: false,
			isPopUpVisible: false,
			studentId: null
		}
	},
	methods: {
		close() {
			this.isPopUpVisible = !this.isPopUpVisible
			this.getObservations()
		},
		showPopUp(id) {
			this.studentId = id
			this.isPopUpVisible = true
		},
		getObservationsAmount(id, motifName) {
			const motif = this.$store.state.motifs.find(x => x.name == motifName)
			const observations = this.observations.filter(x => x.student_id == id)
			const amount = observations.filter(x => x.motif == motif.id)
			return amount.length
		},
		async deleteObservations() {

			if (confirm("Êtes-vous sûr de vouloir supprimer vos observations")) {
				for (const observation of this.observations) {
					await deleteObservationService(observation.id)
				}
				this.getObservations()
			}
		},

		async getObservations() {
			this.isLoading = true
			this.observations = await allObservationsForClassService(this.$route.params.id)
			this.isLoading = false
		},
	},
	created() {
		this.getObservations()
	},
}
</script>

<style>
</style>
