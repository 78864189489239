<template>
	<div :class="checkUrl">
		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component class="main-component" :class="hidden ? 'hidden' : ''" @test="a" :is="Component"></component>
			</transition>
		</router-view>
		<pop-up-not-allowed @showPopUp="showPopUp" v-if="$store.state.isPopUpNotAllowedVisible" />

		<toast v-if="
			this.$store.state.toast?.text && this.$store.state.toast?.status
		" :error="this.$store.state.toast" @resetError="resetError" />
	</div>
</template>

<script>
import MenuAdmin from './components/Admin/MenuAdmin.vue'
import UserDisplay from './components/Admin/UserDisplay.vue'
import Config from './config'
import PopUpNotAllowed from './components/PopUp/PopUpNotAllowed.vue'
import Toast from './components/Utils/Toast.vue'

export default {
	name: 'App',
	components: {
		MenuAdmin,
		UserDisplay,
		PopUpNotAllowed,
		Toast
	},
	data() {
		return {
			hidden: false,
			url: Config.landing_url,
			hsq: [],
			error: {
				text: '',
				status: ''
			}
		}
	},

	computed: {
		checkUrl() {
			if (this.$route.path.includes('/auth')) {
				return 'auth-container'
			}
			if (this.$route.path.includes('/admin')) {
				return 'admin-container'
			}
			else {
				return false
			}
		},
		checkUrlAuth() {
			if (this.$route.path.includes('/auth')) {
				return true
			}
			else {
				return false
			}
		},
		checkUrlAdmin() {
			if (this.$route.path.includes('/admin')) {
				return true
			}
			else {
				return false
			}
		},
		checkRoute() {
			if (this.$route.path.includes('/logout')) {
				return false
			}
			else {
				return true
			}
		}
	},

	methods: {
		a() {
			this.hidden = true
			setTimeout(() => {
				this.hidden = false
			}, 400)
		},

		showPopUp(error) {
			this.$store.state.toast = error
		},
		resetError() {
			this.$store.state.toast = {
				text: '',
				status: ''
			}
		}

	},
	watch: {
		$route() {
			if (window.HubSpotConversations) {
				window.HubSpotConversations.widget.refresh()
			}
		}
	},

}
</script>

<style lang="scss">
@import "./assets/scss/style.scss";
</style>
