<template>
	<ul class="class-menu-container profile-menu-container">
		<span
			:style="'width:' + spanWidth + 'px;left:' + spanPosition + 'px;'"
			class="underline"
		></span>
		<div>
			<li>
				<router-link
					id="flash"
					:to="{
						name: 'MyResourcesQuestionFlash',
					}"
					class="classes"
					>Questions Flash</router-link
				>
			</li>
			<li>
				<router-link
					id="activity"
					:to="{
						name: 'MyResourcesExpresso',
					}"
					class="classes"
					>Activités Expresso</router-link
				>
			</li>
			<!-- <li>
				<router-link
					id="exercise"
					:to="{
						name: 'MyResourcesExercise',
					}"
					class="classes"
					>Exercices Classico</router-link
				>
			</li> -->
			<li>
				<router-link
					id="qcm"
					:to="{
						name: 'MyQCMs',
					}"
					class="classes"
					>QCM</router-link
				>
			</li>
		</div>
	</ul>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			resourceWidth: '',
			studentWidth: '',
			docWidth: '',
			resourcePosition: '',
			studentPosition: '',
			docPosition: '',
			spanWidth: '',
			spanPosition: '',
		}
	},
	methods: {
		updateSpan(e) {
			switch (e) {
				case 'MyResourcesQuestionFlash':
					this.spanWidth = this.flashWidth
					this.spanPosition = this.flashPosition
					break
				case 'MyResourcesExpresso':
					this.spanWidth = this.activityWidth
					this.spanPosition = this.activityPosition
					break
				
				case 'MyQCMs':
					this.spanWidth = this.qcmWidth
					this.spanPosition = this.qcmPosition
					break
			}
		},
	},
	mounted() {
		const flash = document.getElementById('flash')
		const activity = document.getElementById('activity')
		const qcm = document.getElementById('qcm')
		this.flashWidth = flash.offsetWidth
		this.activityWidth = activity.offsetWidth
		this.qcmWidth = qcm.offsetWidth
		this.flashPosition = flash.getBoundingClientRect().left - flash.getBoundingClientRect().left
		this.activityPosition = activity.getBoundingClientRect().left - flash.getBoundingClientRect().left
		this.qcmPosition = qcm.getBoundingClientRect().left - flash.getBoundingClientRect().left
		this.updateSpan(this.$route.name)
	},
	watch: {
		$route(to) {
			this.updateSpan(to.name)
		}
	},
}
</script>

<style>
</style>
