<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-calendar secularism">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Laïcité et calendrier de l'Avent</p>
			<p>
				Et voilà, décembre est arrivé et avec lui le compte à rebours de
				Noël qui s'enclenche. Pour certains, c'est l'euphorie :
				décorations, sapin, magie de Noël, cadeaux, … Pour d'autres,
				levée de bouclier : « Au nom de la laïcité et de la neutralité
				de l'État, moi enseignant, je bannirai le mot Noël de mon
				vocabulaire pour tout le mois de décembre ».
			</p>
			<p>
				Le calendrier de l'Avent (écrit avec un « e » ) est une coutume
				liée à la liturgie chrétienne. Maintenant, cette fête de Noël
				s'est sécularisée (autrement dit, elle est passée dans les
				coutumes laïques), le calendrier en a fait de même.
			</p>
			<p>
				Donc, soyez rassurés, il est possible de parler de Noël tout en
				respectant la laïcité voire mieux : pour la nourrir ! On oublie
				souvent que tous ceux qui fêtent Noël ne sont pas forcément des
				croyants ! En effet, Noël n'est pas seulement une fête
				religieuse. Il est bon de le rappeler à tous, aux élèves et à
				leurs parents. Pourquoi ?
			</p>
			<p>- Pour mieux comprendre notre calendrier ;</p>
			<p>
				- Pour mieux vivre dans le monde qui nous entoure et mieux
				l'appréhender ;
			</p>
			<p>
				- Pour enrichir sa connaissance de l'autre et de ses coutumes
				afin de mieux le respecter.
			</p>
			<p>
				Si vous préférez vous épargner des justifications chronophages
				auprès de certains élèves ou parents, vous pouvez :
			</p>
			<p>
				- Faire un calendrier qui décompte les derniers jours de l'année
				: le calendrier de l'Avant.
			</p>
			<p>
				- Ne pas choisir les exercices qui pourrait être source de
				polémique (avec le Père Noël, avec un sapin de Noël) mais
				préférer ceux en rapport avec un match de hockey sur glace ou un
				bon vin chaud (ah non toujours pas !)
			</p>
			<p>
				- Choisir un message neutre pour le 25 Décembre : « Joyeuses
				fêtes de fin d'année ! »
			</p>
			<p>
				Laïcité et fête de Noël : Ce qu'en dit le Vademecum de Juillet
				2021
			</p>
			<p>
				Noël, comme Pâques, sont des fêtes sécularisées. On peut donc
				les fêter de manière laïque. <br />Concrètement, pour Noël,
				<span class="underlined"> on peut</span> :
			</p>
			<p>
				- installer un sapin (Le Vademecum précise : » Le sapin, symbole
				d'une fête largement laïcisée, peut être installé à condition
				qu'il ne revête aucun caractère cultuel dans sa présentation ou
				dans sa décoration. »)
			</p>
			<p>- parler des cadeaux, de la fête de famille…</p>
			<p>- faire venir un père Noël,</p>
			<p>- faire fabriquer des cartes ou des petits cadeaux,</p>
			<p>- lire et/ou étudier la plupart des contes de Noël,</p>
			<p>
				- chanter le Petit Renne au Nez Rouge, vive le vent, Noël blanc,
				Petit garçon (…)
			</p>
			<br />
			<p>
				Mais <span class="underlined">on ne peut pas</span>, dans les
				établissements publiques :
			</p>
			<p>
				- on ne peut pas, dans le cadre de la fête de Noël, évoquer ou
				représenter Jésus, Marie, Joseph, la crèche, l'âne et le boeuf…
			</p>
			<p>
				- on ne peut pas chanter des chants religieux (Il est né le
				divin enfant, entre le boeuf et l'âne gris, Minuit chrétiens…)
				ni les chants profanes qui racontent la naissance de Jésus (la
				Belle Histoire d'Henri Dès ou Bonjour Madame Marie d'Anne
				Sylvestre…)
			</p>
			<p>- on évite d'ajouter des anges pour décorer le sapin…</p>
			<p>
				<br />
				<br />
				<br />
				Les recommandations du ministère sur la question sont présentes
				sur le lien suivant :
			</p>
			<p>
				<a href="https://eduscol.education.fr/document/1609/download"
					>https://eduscol.education.fr/document/1609/download</a
				>
			</p>
			<br />
			<p>Sources et documentation :</p>
			<p>
				<a
					href="https://etreprof.fr/ressources/4035/parler-de-noel-en-classe-estce-bien-laique"
					>https://etreprof.fr/ressources/4035/parler-de-noel-en-classe-estce-bien-laique</a
				>
			</p>
			<p>
				<a
					href="https://www.banquedesterritoires.fr/laicite-lecole-le-ministere-fournit-son-mode-demploi"
					>https://www.banquedesterritoires.fr/laicite-lecole-le-ministere-fournit-son-mode-demploi</a
				>
			</p>
			<p>
				<a href="https://www.charivarialecole.fr/archives/10397"
					>https://www.charivarialecole.fr/archives/10397</a
				>
			</p>
			<p>
				<a
					href="http://www.slate.fr/story/154640/calendrier-avent-laique"
					>http://www.slate.fr/story/154640/calendrier-avent-laique</a
				>
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
