<template>
	<section class="class-stats">
		<h1>Stats de classe</h1>

		<div v-if="isLoading">
			<loader-component />
		</div>
		<div class="table-container" v-else>
			<table>
				<tr>
					<th>Nom</th>
					<th>Classes crées</th>
					<th>Pourcentage</th>
				</tr>
				<tr v-for="level in this.levels" :key="level.id">
					<td>
						{{ level.name }}
					</td>
					<td>
						<p>
							{{ level.classes_count }}
						</p>
					</td>
					<td>
						<p>
							{{ getPercentage(level.classes_count) }}
						</p>
					</td>
				</tr>
			</table>
		</div>
		<div class="chart-container">
			<vue3-chart-js
				v-if="statLoaded"
				:id="doughnutChart.id"
				:type="doughnutChart.type"
				:data="doughnutChart.data"
				:options="doughnutChart.options"
			/>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import LoaderComponent from '../../Loader.vue'

import Vue3ChartJs from '@j-t-mcc/vue3-chartjs'

export default {
	components: { LoaderComponent, Vue3ChartJs },


	data() {
		return {
			levels: [],
			activities: [],
			keys: [],
			classes: [],
			isLoading: true,
			amount: 0,
			statLoaded: false,
			doughnutChart: {
				type: "line",
				data: {
					labels: [

					],
					datasets: [
						{
							label: "Classes créées",
							data: [],
							fill: false,
							borderColor: "#00D8FF",
							tension: 0.5,
							backgroundColor: "blue",
						},
					],


				}
			}
		}
	},

	methods: {
		getPercentage(e) {
			return ((e / this.amount) * 100).toFixed(2)
		},
		async getStats() {
			this.isLoading = true
			const res = await authenticatedApiFetch('/stat-level/?ordering=-classes_count')
				.then(res => res.json())
			this.levels = res

			for (const level of this.levels) {
				this.amount += level.classes_count
			}

			this.isLoading = false
		},

		subtractMonths(numOfMonths, date) {
			date.setMonth(date.getMonth() + numOfMonths)
			return date.toISOString().split('T')[0].slice(0, 7)
		},

		async allClasses() {

			for (let i = -5; i < 5; i++) {
				this.doughnutChart.data.labels.push(this.subtractMonths(i, new Date()))
			}

			const res = await authenticatedApiFetch('/stat-class')
				.then(res => res.json())
			this.classes = res

			for (const classe of this.classes) {
				classe.created_at = classe.created_at.slice(0, 7)
			}


			for (const [index, data] of this.doughnutChart.data.labels.entries()) {

				const amount = this.classes.filter(x => x.created_at == data)
				this.doughnutChart.data.datasets[0].data[index] = amount.length
			}
			this.statLoaded = true
		}

	},
	created() {
		this.getStats()
		this.allClasses()
	},
}
</script>

<style>
</style>
