<template>
	<div class="course-wrapper">
		<div class="option-container">
			<img
				@click="deleteCourse()"
				src="/img/class/delete-icon.svg"
				alt=""
			/>
			<img
				@click="popUpEditVisible = !popUpEditVisible"
				src="/img/edit.svg"
				alt=""
			/>
		</div>
		<p class="room">Salle: {{ course.room_number }}</p>
		<p>
			{{ course.debut_hour.slice(0, 5) }} -
			{{ course.end_hour.slice(0, 5) }}
		</p>
		<p class="currency">{{ getCurrency(course.currency) }}</p>
		<p class="minutes">{{ minutes }} min</p>
		<pop-up-edit-course
			@close="popUpEditVisible = !popUpEditVisible"
			v-if="popUpEditVisible"
			@refresh="this.$emit('refreshCourses')"
			:id="course.id"
		/>
	</div>
</template>

<script>
import { deleteAgendaService } from '../../../../services/agendasService'
import PopUpEditCourse from '../../../PopUp/Class/PopUpEditCourse.vue'
export default {
	components: { PopUpEditCourse },
	props: {
		course: Object
	},
	data() {
		return {
			minutes: 0,
			popUpEditVisible: false
		}
	},
	methods: {
		deleteCourse: async function () {
			if (confirm(`Êtes-vous sûr de vouloir supprimer cette horaire ?`)) {
				await deleteAgendaService(this.course.id)
				this.$emit('refreshCourses')
			}
		},
		getCurrency(e) {
			let currency
			switch (e) {
				case 'OCCASIONAL':
					currency = 'Occasionnel'
					break
				case 'WEEKLY':
					currency = 'Chaque semaine'
					break
				case 'BIWEEKLY':
					currency = 'Une semaine sur deux'
					break
			}
			return currency
		},
		convertMsToHM(milliseconds) {
			let seconds = Math.floor(milliseconds / 1000)
			let minutes = Math.floor(seconds / 60)
			return minutes
		},
	},
	created() {
		let date = new Date()
		let isoDate = date.toISOString().split('T')[0]
		let hour1 = new Date(`${isoDate} ${this.course.debut_hour}`)
		let hour2 = new Date(`${isoDate} ${this.course.end_hour}`)
		this.minutes = this.convertMsToHM(hour2 - hour1)
	},
}
</script>

<style>
</style>
