<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-calendar principe">
			<img
				class="close"
				@click="this.$emit('close', dontShow)"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Mode d'emploi</p>
			<p>
				Pour visualiser une question, cliquez sur le nom de la question.
				<br /> <br>
				Pour attribuer la question à une case du calendrier,
				glissez-déposez la question.<br /> <br>
				Une fois votre calendrier réalisé, cliquez sur Valider.<br /> <br>
				Un lien apparaîtra. Celui-ci vous permettra de vidéo-projeter
				votre calendrier en classe.<br /> <br>
				Vous pourrez aussi transmettre le lien à vos élèves pour les
				jours où vous n'avez pas cours avec eux. Chaque case sera
				accessible uniquement le jour J.<br />
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
