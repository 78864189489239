import Config from './config'
import CryptoJS from 'crypto-js'
import store from './store'

export const authenticatedFetch = async (path, options = {}) => {
    if (!options.headers) {
        options.headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }
    options.headers['Authorization'] = `Bearer ${localStorage.token}`
    try {
        const response = await fetch(Config.backend_url + path, options)

        if (!response.ok) {
            // Si la réponse n'est pas OK (200-299), rejeter la promesse avec une erreur
            const errorMessage = `Un problème est survenu`
            store.state.toast = {
                status: 'error', text: errorMessage
            }
            throw new Error(errorMessage)
        }

        return response
    } catch (error) {
        // Si une erreur se produit, affichez-la dans votre toast
        store.state.toast = {
            status: 'error', text: 'Un problème est survenu'
        }
        throw error
    }
}


export const studentFetch = async (path, options = {}) => {
    if (!options.headers) {
        options.headers = { 'Content-Type': 'application/json', 'Accept': 'application/json' }
    }
    options.headers['Authorization'] = `Student Bearer ${localStorage.getItem('studentToken')}`
    try {
        const response = await fetch(Config.backend_url + path, options)

        if (!response.ok) {
            // Si la réponse n'est pas OK (200-299), rejeter la promesse avec une erreur
            const errorMessage = `Un problème est survenu`
            store.state.toast = {
                status: 'error', text: errorMessage
            }
            throw new Error(errorMessage)
        }

        return response
    } catch (error) {
        // Si une erreur se produit, affichez-la dans votre toast
        store.state.toast = {
            status: 'error', text: 'Un problème est survenu'
        }
        throw error
    }
}

export const authenticatedApiFetch = async (path, options = {}) => {
    return await authenticatedFetch("/api/v1" + path, options)
}

export const authenticatedImageFetch = (path, options = {}) => {
    return authenticatedFetch("/media/" + path, options)
}

export const authenticatedStudentApiFetch = async (path, options = {}) => {
	return await studentFetch("/api/v1" + path, options)
}



export const decodeAndStoreToken = (token) => {
    let base64Url = token.split('.')[1]
    let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    let jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))
    localStorage.token = token
    localStorage.userId = JSON.parse(jsonPayload).user_id
    localStorage.profileId = JSON.parse(jsonPayload).profil

    return {
        "token": localStorage.token,
        "userId": localStorage.userId,
        "profileId": localStorage.profile,
    }
}

export const random = (string, values) => {
    // Replace variables by random values
    if (values != null) {
        if (Object.keys(values).length != 0) {
            if (string != null) {
                let random_values = values
                string = string.replace(/%.*?%/, '')
                for (const key in random_values) {
                    string = string.replaceAll(key, random_values[key])
                }
                if (string.match(/\\calc{(.*?)}/g)) {
                    string = getMathValues(string)
                }
            }
        }
    }
    return string
}

export const getMathValues = (string) => {
    if (string.match(/\\calc{(.*?)}/g)) {
        let calc_values = string.match(/\\calc{(.*?)}/g)
        let final_values = []

        for (let i = 0; i < calc_values.length; i++) {
            let calc = calc_values[i].split(/\\calc{(.*?)}/)
            calc = calc[1].split(";")
            calc = calculate(Number(calc[0].replace(",", ".")), calc[1], Number(calc[2].replace(",", ".")))
            final_values.push(calc.toString().replace(".", ","))
        }
        calc_values.map((key, value) => {
            string = string.replace(key, final_values[value])
        })
        return string
    }
}

export const calculate = (x, operator, y) => {
    // Do basic math operations (+, -, /, *)
    let sum
    switch (operator) {
        case "+":
            sum = Math.round((x + y) * 100) / 100
            break
        case "-":
            sum = Math.round((x - y) * 100) / 100
            break
        case "/":
            sum = Math.round((x / y) * 100) / 100
            break
        case "*":
            sum = Math.round((x * y) * 100) / 100
            break
        case "^":
            sum = Math.pow(x, y)
            break
        default:
            return sum
    }
    return sum
}

export const image = (string, data) => {
    // Replace \picture by an image
    var image = null
    if (string) {
        if (string.match(/\\picture(.*?)/g)) {
            image = string.match(/\\picture(.*?)/g)
        }
    }
    if (image === null) {
        return string
    }
    let newImage = '<img class="inside-img" src="' + data + '" alt="" />'
    string = string.replace(image, newImage)
    return string
}

export const translateFromTexToHTML = (string) => {
    // Search & Replace LaTeX expressions
    let parserRules = [
        { tex: '\\begin{center}', html: '<center>' },
        { tex: '\\end{center}', html: '</center>' },
        { tex: '\\begin{tabularx}', html: '\\begin{array}' },
        { tex: '\\end{tabularx}', html: '\\end{array}' },
        { tex: '\\begin{enumerate}', html: '<ol>' },
        { tex: '\\end{enumerate}', html: '</ol>' },
        { tex: '\\textbf', html: '\\mathbf' },
        { tex: '{\\linewidth}', html: '' },
    ]

    for (let i = 0; i < parserRules.length; i += 1) {
        var re = RegExp('\\' + parserRules[i].tex + '(?![a-zA-Z])', 'g')
        if (!re) {
            return string
        }
        string = string.replace(re, parserRules[i].html)
    }
    return string
}


export const formateAndOrderDate = (courses) => {
    courses = courses.sort(function (a, b) {
        return new Date(`${a.day} ${a.debut_hour}`) - new Date(`${b.day} ${b.debut_hour}`)
    })
    for (const course of courses) {

        const date = new Date(course.day)
        course.formatedDay = date.toLocaleDateString('fr', { weekday: "long", day: "2-digit", month: "long" })
        course.debut_hour = course.debut_hour.slice(0, -3)
        course.end_hour = course.end_hour.slice(0, -3)
    }

    return courses
}

export const crypt = (e) => {
    const encrypted = CryptoJS.AES.encrypt(e, Config.secret_key).toString()
    return encrypted
}

export const decrypt = (e) => {
    const bytes = CryptoJS.AES.decrypt(e, Config.secret_key)
    const originalText = bytes.toString(CryptoJS.enc.Utf8)
    return originalText

}


export const getTileGameInfo = (e) => {
    switch (e) {
        case 'MATH_LA_CITROUILLE':
            return {
                name: 'Wander Game',
                img: '/img/game/demo-tile-game-halloween.jpg',
            }
        case 'MATH_LA_BANQUISE':
            return {
                name: 'Wander Game',
                img: '/img/game/demo-tile-game-winter.jpg',
            }
        case 'MATH_LA_PLAGE':
            return {
                name: 'Wander Game',
                img: '/img/game/demo-tile-game-summer.jpg',
            }
        case 'MATH_L_ESPACE':
            return {
                name: "Wander Game",
                img: '/img/game/demo-tile-game-space.jpg',
            }
        case 'MATH_LE_CIEL':
            return {
                name: "Wander Game",
                img: '/img/game/demo-tile-game-sky.jpg',
            }
        case 'MATH_LE_SKATE':
            return {
                name: "Wander Game",
                img: '/img/game/demo-tile-game-skate.jpg',
            }
        case 'MATH_LE_ROBOT':
            return {
                name: "Wander Game",
                img: '/img/game/demo-tile-game-robot.jpg',
            }
        default:
            return {
                name: 'Wander Game',
                img: '/img/game/demo-tile-game-summer.jpg',
            }

    }
}



export const filteredLevels = (filter) => {
    const classes = store.state.levels.filter(el => {
        return filter.some(f => {
            return f === el.name
        })
    })
    return classes
}



export const checkRightFor = (resource, levels) => {
    const currentDate = new Date()

    const freeAccessEnd = new Date('2024-09-27T23:59:00')

    if (currentDate < freeAccessEnd) {
        return true
    }

    if (store.state.user.role == 'ADMIN' || store.state.user.role == 'SUPER_ADMIN') {
        return true
    }


    if (store.state.freeTrial.free_trial_end && store.state.freeTrial.free_trial_start) {
        const freeTrialEndDate = new Date(store.state.freeTrial.free_trial_end)

        if (freeTrialEndDate > currentDate) {
            return true
        }
    }
    const rights = store.state.rightFor

    const matchingRight = rights.find(right => {
        return right.resourceType === resource && levels.every(level => right.levels.includes(level))
    })


    if (matchingRight) {
        const subscriptionStart = new Date(matchingRight.subscription_start)
        const subscriptionEnd = new Date(matchingRight.subscription_end)

        if (currentDate >= subscriptionStart && currentDate <= subscriptionEnd) {
            return true
        } else {
            return false
        }
    }
    return matchingRight !== undefined

}


export const formatedDate = (value) => {
    const date = new Date(value)
    const options = { year: 'numeric', month: 'long', day: 'numeric' }
    const dateFormat = new Intl.DateTimeFormat('fr-FR', options)
    const formattedDate = dateFormat.format(date)
    return formattedDate
}


export const getWebsiteIcon = (link) => {


    const mapping = {
        "https://kahoot.it": "/img/favicon/kahoot.webp",
        "https://www.index-education.com": "/img/favicon/pronote.webp",
        "genial.ly": "/img/favicon/genially.webp",
        "https://www.ecoledirecte": "/img/favicon/ecole-direct.webp",
        "https://www.lecoinduprof.fr": "/img/favicon/le-coin-du-prof.webp",
        "https://educadhoc.fr": "/img/favicon/educadhoc.webp",
        "https://www.geogebra": "/img/favicon/geogebra.webp",
        "https://manuel.sesamath": "/img/favicon/sesamath.webp",
        "https://www.online-python": "/img/favicon/python-editor.webp",
        "https://scratch": "/img/favicon/scratch.webp",
        "lumni.fr": "/img/favicon/lumni.webp",
		"https://www.canva.com": "/img/favicon/canva.webp",
		"https://padlet.com" : "/img/favicon/padlet.webp",
		"https://bouncyballs.org" : "/img/favicon/bouncyballs.webp",
		"https://plagiarismdetector.net" : "/img/favicon/plagiarismdetector.webp",
		"https://www.edumedia.com" : "/img/favicon/edumedia.webp",
		"https://www.solumaths.com" : "/img/favicon/solumaths.webp",
		"https://www.desmos.com" : "/img/favicon/desmos.webp",
		"https://www.fizziq.org" : "/img/favicon/fizziq.webp",
		"https://ptable.com": "/img/favicon/ptable.webp",
		"https://chemix.org": "/img/favicon/chemix.webp",
		"https://www.biorender.com": "/img/favicon/biorender.webp",
		"https://tophonetics.com/fr": "/img/favicon/tophonetics.webp",
		"https://www.gingersoftware.com": "/img/favicon/ginger.webp",
		"https://wordart.com/": "/img/favicon/wordart.webp",
		"https://wordsift.org/": "/img/favicon/wordsift.webp",
		"https://www.cervantesvirtual.com/": "/img/favicon/cervantes.webp",
		"https://conjugaison.tatitotu.ch/": "/img/favicon/conjugaison.webp",
		"https://languagetool.org/fr/": "/img/favicon/languagetool.webp",
		"https://www.weblettres.net/": "/img/favicon/weblettres.webp",
		"https://www.timetoast.com/": "/img/favicon/timetoast.webp",
		"https://storymap.knightlab.com/": "/img/favicon/storymap.webp",
		"https://geteach.com/": "/img/favicon/geteach.webp",
		"https://paintmaps.com/" : "/img/favicon/paintmaps.webp",

	}


    for (const sousChaine in mapping) {
        if (link.includes(sousChaine)) {
            return mapping[sousChaine]
        }
    }

    return link
}


export const checkIfMathTeacher = () => {
	if(!store.state.subjects) return false
	const requiredSubjects = ['Maths']
	console.log('allllle')
	const userSubjects = store.state.user.subjects
	const subjectsName = store.state.subjects.filter(subject => userSubjects.includes(subject.id)).map(subject => subject.name)
	return requiredSubjects.some(subject => subjectsName.includes(subject))
}
