<template>
	<div v-if="$route.name !== 'ShareSpace'" class="no-content-container">
		<p class="no-content">
			Vous aurez accès à ces questions prochainement !
		</p>
		<p class="no-content">
			Vous êtes pressé(e) ? Appuyez sur le bouton pour secouer un peu
			l'équipe de création ...
		</p>
		<button @click="suggestion" class="whip">
			<img src="/img/whip.png" alt="" />

			<span v-if="correctlySend == null"> Plus vite que ca !</span>
			<span v-else-if="correctlySend">Envoyé !</span>
			<span v-else>Vous avez déjà secoué l'équipe</span>
		</button>
		<p class="no-content">
			Ou rejoignez notre équipe pour nous aider :
			<a href="mailto:contact@lecoinduprof.fr">contact@lecoinduprof.fr</a>
		</p>
	</div>
	<div v-else class="no-content-container share-space" >
		<p class="no-content">
			<b>🚧Cet espace est actuellement en construction🚧 </b>
		</p>
		<p class="no-content">
			Vous pourrez bientôt télécharger les documents d'autres enseignants et partager les vôtres. 
		</p>
		<p class="no-content">
			Un système de tri par matière, niveau, chapitre sera mis en place pour faciliter votre recherche.
		</p>
		<p class="no-content">
			A la différence des groupes Facebook disciplinaires cet espace vous permettra de trouver efficacement la ressource recherchée.
		</p>	
		<p class="no-content">
			Cela vous intéresse ? Merci de nous le faire savoir !
		</p>
		<button @click="suggestion" class="submit dark">

			<span v-if="correctlySend == null"> Je suis interessé</span>
			<span v-else-if="correctlySend">Envoyé !</span>
			<span v-else>Vous avez déjà secoué l'équipe</span>
		</button>
		<p class="no-content">
			Vous souhaitez nous faire part de suggestions ? Ecrivez-nous à :
			<a href="mailto:contact@lecoinduprof.fr">contact@lecoinduprof.fr</a>
		</p>
	</div>
</template>

<script>
import { createSuggestionService } from '../../../services/suggestionsService'

export default {
	props: {
		level: {
			type: Number,
			required: true
		},
		chapter: {
			type: Number,
			required: false,
			default: null
		},
		type: {
			type: String,
			required: true,
		}
	},
	data() {
		return {
			correctlySend: null
		}
	},
	methods: {
		
		async suggestion() {
			
			const suggestion = {
				user_id: this.$store.state.user.user,
				level_id: this.level,
				chapter_id: this.chapter,
				type: this.type
			}

			const res = await createSuggestionService(suggestion)

			this.correctlySend = res.ok

		}
	},
}
</script>

<style>
</style>
