<template>
	<section class="student-place-home">
		<div class="title-container">
			<h1 class="white">Mathématiques - {{ classe.teacher_name }}</h1>
		</div>

		<div v-if="checkIfTeacher()" class="share-to-class">
			<button
				@click="isPopUpShareVisible = !isPopUpShareVisible"
				class="submit white"
			>
				Partager à la classe
			</button>
			<button @click="isPopUpShareCredentialsVisible = !isPopUpShareCredentialsVisible" class="submit white">Identifiants élèves</button>
		</div>

		<grid v-if="classe.subjects_name" :subjectsName="classe.subjects_name" />
		<pop-up-share
			@close="isPopUpShareVisible = !isPopUpShareVisible"
			v-if="isPopUpShareVisible"
		/>
		<PopUpShareStudentCredentials :id="classId" v-if="isPopUpShareCredentialsVisible" @close="isPopUpShareCredentialsVisible = !isPopUpShareCredentialsVisible" />
	</section>
</template>

<script>
import Config from '../../config'
import axios from 'axios'
import { decrypt } from '../../utils'
import Grid from './Grid.vue'
import PopUpShare from '../PopUp/SeriousGame/Calendar/PopUpShare.vue'
import PopUpShareStudentCredentials from '../PopUp/Class/PopUpShareStudentCredentials.vue'
export default {
	components: {
		Grid,
		PopUpShare,
		PopUpShareStudentCredentials
	},
	data() {
		return {
			calendars: [],
			classId: null,
			classe: {},
			isPopUpShareVisible: false,
			isPopUpShareCredentialsVisible: false,
		}
	},
	methods: {
		async getClasse() {
			const res = await axios.get(`${Config.backend_url}/api/v1/classes/${this.classId}`)
			this.classe = res.data
		},
		checkIfTeacher() {
			return localStorage.getItem('userId')
		},
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getClasse()
	},
}
</script>

<style>
</style>
