<template>
	<h1>Tableau de bord - Création de contenus</h1>
	<table>
		<tr>
			<th>Titre</th>
			<th class="centered">Ajouter</th>
			<th>Modifier</th>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Chapitres</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddChapter' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllChapters' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Difficultés</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddDifficulty' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllDifficulties' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Matières</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddSubject' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllSubjects' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Niveaux</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddLevel' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllLevels' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr>
			<td>Questions Flash</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddQuestion' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQuestions' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Séquences</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddSequence' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllSequences' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Thèmes de question</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddQuestionTheme' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQuestionThemes' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Types de question</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddQuestionType' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQuestionTypes' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Dépôt Images Activités Expresso</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddKey' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllKeys' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr>
			<td>Activités Expresso</td>
			<td>
				<router-link :to="{ name: 'SuperAdminProposeKey' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllProposedKeys' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Theme exercice</td>
			<td>
				<router-link
					:to="{ name: 'SuperAdminAddExerciseSheetQuestionTheme' }"
				>
					ajouter</router-link
				>
			</td>
			<td>
				<router-link
					:to="{ name: 'SuperAdminAllExerciseSheetQuestionThemes' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Type exercice</td>
			<td>
				<router-link
					:to="{ name: 'SuperAdminAddExerciseSheetQuestionType' }"
				>
					ajouter</router-link
				>
			</td>
			<td>
				<router-link
					:to="{ name: 'SuperAdminAllExerciseSheetQuestionTypes' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr>
			<td>Exercices Classico</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddExercise' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link
					:to="{ name: 'SuperAdminAllExerciseSheetQuestions' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Académies</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddAcademy' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllAcademies' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Départements</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddCounty' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllCounty' }"
					>modifier</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Leçon</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddLesson' }">
					ajouter</router-link
				>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllLessons' }"
					>modifier</router-link
				>
			</td>
		</tr>

		<tr v-if="checkSuperAdmin()">
			<td>Exercice pour calendrier de l'avent</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddCalendarExercise' }">
					ajouter
				</router-link>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllCalendarExercises' }"
					>Voir</router-link
				>
			</td>
		</tr>
		<tr v-if="checkSuperAdmin()">
			<td>Exercice pour Wander Game</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddTileGameContent' }">
					ajouter
				</router-link>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllTileGameContent' }"
					>Voir</router-link
				>
			</td>
		</tr>
		<tr>
			<td>Exercice pour QCM</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAddQcm' }">
					ajouter
				</router-link>
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQcms' }">
					Voir
				</router-link>
			</td>
		</tr>
		<tr>
			<td>Package questions qcm</td>
			<td>
				
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQcmPackages' }">
					Voir
				</router-link>
			</td>
		</tr>
		<tr>
			<td>Package questions flash</td>
			<td>
				
			</td>
			<td>
				<router-link :to="{ name: 'SuperAdminAllQuestionFlashPackages' }">
					Voir
				</router-link>
			</td>
		</tr>
		<tr>
			<td>Pourcentage</td>
			<td></td>
			<td>
				<router-link :to="{ name: 'SuperAdminPercentage' }"
					>Voir</router-link
				>
			</td>
		</tr>
	</table>
</template>

<script>

export default {
	methods: {
		checkSuperAdmin() {
			if (this.$store.state.user.role == 'SUPER_ADMIN') {
				return true
			}
			return false
		}
	},
}
</script>

<style>
</style>
