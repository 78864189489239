import { authenticatedApiFetch } from '../utils'

export const allQCMQuestionsService = async () => {
    const res = await authenticatedApiFetch('/qcm-questions/', { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const filteredQCMQuestionsService = async (filter) => {
	const res = await authenticatedApiFetch(`/qcm-questions/${filter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}

export const getQCMQuestionService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-questions/${id}/`, { method: 'GET' })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const createQCMQuestionService = async (data) => {
	const res = await authenticatedApiFetch('/qcm-questions/', { method: 'POST', body: data ,headers: {}})
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const deleteQCMQuestionService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-questions/${id}/`, { method: 'DELETE' })
		
	return res
}


export const updateQCMQuestionService = async (id, data) => {
	const res = await authenticatedApiFetch(`/qcm-questions/${id}/`, { method: 'PUT', body: data, headers: {} })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}
