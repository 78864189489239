<template>
	<div class="tile-wrapper">
		<div v-if="tile.studentName">
			<img
				@click="showPopUpStudent"
				class="eyes"
				src="/img/eyes.svg"
				alt=""
			/>
			<p v-html="truncate(tile.studentName, 12)"></p>
		</div>
		<div v-else>
			<span class="plus"
				><img src="/img/dotted-square.svg" alt=""
			/></span>
		</div>
		<Teleport to="body">
			<pop-up-student
				@close="close"
				:studentId="tile.studentId"
				v-if="isPopUpVisible"
			/>
		</Teleport>
	</div>
</template>

<script>
import PopUpStudent from "../../../PopUp/Class/PopUpStudent.vue"

export default {
	components: { PopUpStudent },
	props: {
		tile: Object
	},
	data() {
		return {
			isPopUpVisible: false
		}
	},
	methods: {
		truncate(str, len) {
			if (str.length > len) {

				let truncatedStr = str
				// Vérifier s'il y a un espace dans la partie tronquée
				const lastSpaceIndex = truncatedStr.lastIndexOf(' ')
				if (lastSpaceIndex !== -1) {
					// Remplacer le dernier espace par un retour à la ligne
					truncatedStr = truncatedStr.substring(0, lastSpaceIndex).slice(0, 8) + '<br>' + truncatedStr.substring(lastSpaceIndex + 1).slice(0, 8)
				}
				return truncatedStr
			}
			return str

		},
		showPopUpStudent() {
			if (this.tile.studentId) {
				this.isPopUpVisible = true
			}
		},
		close() {
			this.isPopUpVisible = false
		}
	},
}
</script>

<style>
</style>
