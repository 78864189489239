<template>
	<pop-up-wrapper @close="close">
		<div ref="popUp" :style="pos" class="pop-up-create-drive-element">
			<p @click="showPopUpFolder">
				<img src="/img/class/folder-icon.svg" alt="" /> Dossier
			</p>
			<hr />
			<label for="file" @click="showPopUpEdit">
				<img src="/img/class/file-icon.svg" alt="" /> Importer un
				fichier
			</label>
			<input
				@change="updateFile"
				style="display: none"
				id="file"
				type="file"
			/>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { createFileService } from '../../../services/filesService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		x: Number,
		y: Number
	},
	data() {
		return {
			inputValue: '',
			delete: false,
			pos: '',
			file: {}
		}
	},
	methods: {
		async updateFile(e) {
			this.file = e.target.files[0]
			const data = new FormData()

			data.append('name', this.file.name)
			data.append('class_id', this.$route.params.id)
			data.append('file_upload', this.file)
			if (this.$route.params.folderId) {
				data.append('parent_folder_id', this.$route.params.folderId)
			}

			await createFileService(data)

			this.$emit('close')
			this.$emit('getFiles')
		},

		close() {
			this.$emit('closed')
		},
		showPopUpFolder() {
			this.$emit('newFolder')
			this.$emit('close')
		},
		showPopUpEdit() {
			this.$emit('showPopUpEdit')
			this.$emit('closed')
		},

	},

	mounted() {
		const height = window.innerHeight
		const popUpHeight = this.$refs.popUp.clientHeight

		let x = this.x
		let y = this.y

		if (y > height - popUpHeight) {
			y = height - popUpHeight - 20
		}
		let style = `left: calc(${x}px - 15vw); top: ${y}px;`
		this.pos = style
	},
}
</script>

<style>
</style>
