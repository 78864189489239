<template>
	<div class="tile"></div>
</template>

<script>
export default {
	props: {
		hour: String
	}
}
</script>

<style>
</style>
