<template>
	<h1>Modifier difficulté</h1>
	<form @submit.prevent="submit" class="form-container">
		<div class="input-container">
			<label for="Difficulty">Difficulté</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Name"
				placeholder="Name"
			/>
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			form: {
				difficulty: '',
			},
			errors: '',
		}
	},
	methods: {
		submit() {
			this.$store.dispatch('updateDifficulty', this.form)
				.then(() => {
					this.$router.push({ name: 'SuperAdminAllDifficulties' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getDifficulty() {
			authenticatedApiFetch(`/difficulties/${this.$route.params.id}/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.form = res
				})
		},
	},
	created() {
		this.getDifficulty()
	},
}
</script>

<style>
</style>
