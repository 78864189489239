<template>
	<pop-up-wrapper>
		<div class="pop-up-agenda-show">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<h2>Details du cours</h2>

			<div class="date-hours-class-container">
				<div class="container">
					<h3>Date</h3>
					<div class="wrapper">
						<img src="/img/calendar.svg" alt="" />
						<p>{{ copiedEvent.day }}</p>
					</div>
				</div>
				<div class="container">
					<h3>Heure</h3>
					<div class="wrapper">
						<img src="/img/clock.svg" alt="" />
						<p>
							{{ copiedEvent.start.slice(0, 5) }} -
							{{ copiedEvent.end.slice(0, 5) }}
						</p>
					</div>
				</div>
				<div class="container">
					<h3>Classe</h3>
					<div class="wrapper">
						<span :class="getBackground()"></span>
						<p>{{ getClass() }}</p>
					</div>
				</div>
			</div>
			<div class="display-container">
				<div class="title-play-container">
					<h3>
						Ressource<span v-if="getResourceLength > 1">s</span>
						associée<span v-if="getResourceLength > 1">s</span>
					</h3>
				</div>

				<div
					v-for="activity in activities"
					:key="activity.id"
					class="event-title rituals-color"
				>
					<router-link
						:to="{
							name: 'DisplayActivity',
							params: { id: activity.id },
						}"
						>{{ activity.name }}</router-link
					>
					<img
						@click="deleteActivity(activity.id)"
						src="/img/close.svg"
						alt=""
					/>
				</div>
				<div
					v-for="evaluation in evaluations"
					:key="evaluation.id"
					class="event-title evaluation-color"
				>
					<p
						style="cursor: pointer"
						@click="showPopUpPdf(evaluation.id, evaluation.type)"
					>
						{{ evaluation.title }}
					</p>
					<img
						@click="deleteEvaluation(evaluation.id)"
						src="/img/close.svg"
						alt=""
					/>
				</div>
				<div
					v-for="exerciseSheet in exerciseSheets"
					:key="exerciseSheet.id"
					class="event-title evaluation-color"
				>
					<p
						style="cursor: pointer"
						@click="
							showPopUpPdf(exerciseSheet.id, exerciseSheet.type)
						"
					>
						{{ exerciseSheet.title }}
					</p>
					<img
						@click="deleteExerciseSheet(exerciseSheet.id)"
						src="/img/close.svg"
						alt=""
					/>
				</div>
				<div
					v-for="tileGame in tileGames"
					:key="tileGame.id"
					class="event-title evaluation-color"
				>
					<router-link
						:to="{
							name: 'TileGame',
							params: { id: tileGame.id },
						}"
						>{{ getTileGameInfo(tileGame.theme).name }}</router-link
					>
					<img
						@click="deleteTileGame(tileGame.id)"
						src="/img/close.svg"
						alt=""
					/>
				</div>
				<button class="submit dark" @click="addResource()">
					+ Ajouter une ressource
				</button>
			</div>
		</div>
		<pop-up-choose-resource
			v-if="isPopUpChooseResourceVisible"
			@close="
				isPopUpChooseResourceVisible = !isPopUpChooseResourceVisible
			"
			:x="x"
			:y="y"
		/>

		<Teleport to="body">
			<pop-up-latex-pdf
				v-if="isPopUpPdfVisible"
				@closed="isPopUpPdfVisible = !isPopUpPdfVisible"
				:exerciseId="selectedRessourceId"
				:name="selectedRessourceName"
				:type="selectedRessourceType"
				:className="className"
			/>
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import { updateOccurenceService } from '../../../services/occurrencesService'
import { getTileGameInfo } from '../../../utils'
import PopUpLatexPdf from '../ExerciseSheet/PopUpLatexPdf.vue'
import PopUpWrapper from '../PopUpWrapper.vue'
import PopUpChooseResource from './PopUpChooseResource.vue'
export default {
	components: { PopUpWrapper, PopUpChooseResource, PopUpLatexPdf },
	props: {
		event: Object,
		activities: Array,
		evaluations: Array,
		exerciseSheets: Array,
		tileGames: Array,
	},
	data() {
		return {
			inputValue: '',
			x: null,
			y: null,
			isPopUpChooseResourceVisible: false,
			copiedEvent: {},
			selectedRessourceId: null,
			selectedRessourceType: null,
			selectedRessourceName: null,
			isPopUpPdfVisible: false,
			className: ''
		}
	},

	methods: {
		getTileGameInfo: getTileGameInfo,

		showPopUpPdf(id, type) {
			this.isPopUpPdfVisible = !this.isPopUpPdfVisible
			this.selectedRessourceId = id
			this.selectedRessourceType = type
			this.selectedRessourceName = this.exerciseSheets.find(x => x.id == id).title
			const classe = this.exerciseSheets.find(x => x.id == id).classes[0]
			this.className = this.$store.getters.class(classe)

		},
		async deleteActivity(e) {
			let activities = this.copiedEvent.activities.filter(x => x !== e)

			this.copiedEvent.activities = activities
			await updateOccurenceService(this.copiedEvent.id, this.copiedEvent)
				.then(() => {
					this.$emit('refreshActivities', e)
				})
		},
		async deleteEvaluation(e) {
			let evaluations = this.copiedEvent.evaluations.filter(x => x !== e)

			this.copiedEvent.evaluations = evaluations
			await updateOccurenceService(this.copiedEvent.id, this.copiedEvent)
				.then(() => {
					this.$emit('refreshEvaluations', e)
				})
		},
		async deleteExerciseSheet(e) {
			let exerciseSheets = this.copiedEvent.exercise_sheets.filter(x => x !== e)
			this.copiedEvent.exercise_sheets = exerciseSheets
			await updateOccurenceService(this.copiedEvent.id, this.copiedEvent)
				.then(() => {
					this.$emit('refreshExerciseSheets', e)
				})

		},
		async deleteTileGame(e) {
			let tileGames = this.copiedEvent.tile_games.filter(x => x !== e)
			this.copiedEvent.tile_games = tileGames
			await updateOccurenceService(this.copiedEvent.id, this.copiedEvent)
				.then(() => {
					this.$emit('refreshTileGames', e)
				})
		},
		addResource() {
			this.$store.state.selectedOccurrence = {
				occurrenceId: this.event.id,
				classId: this.event.class
			}
			this.isPopUpChooseResourceVisible = !this.isPopUpChooseResourceVisible
		},
		getClass() {
			if (this.copiedEvent.class) {
				return this.$store.getters.class(this.copiedEvent.class)
			}
		},
		getBackground() {
			let color = this.$store.getters.classLevel(this.copiedEvent.class).level_name

			switch (color) {
				case '6ème':
					return 'sixth'

				case '5ème':
					return 'fifth'

				case '4ème':
					return 'fourth'

				case '3ème':
					return 'third'

				case '2nde':
					return 'second'

				case '1ère Spé':
					return 'first'

				case 'Term Spé':
					return 'term'

				case 'Term Maths Expertes':
					return 'term'

				case 'Term Maths Comp':
					return 'term'

				default:
					return 'aled'
			}
		},
	},
	computed: {
		getResourceLength() {
			return this.activities.length + this.evaluations.length + this.exerciseSheets.length
		}
	},
	created() {
		window.addEventListener('mousemove', (e) => {

			this.x = e.clientX
			this.y = e.clientY
		})
		this.copiedEvent = { ...this.event }

	},
}
</script>

<style>
</style>
