<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-tile-game">
			<img
				class="close"
				src="/img/close.svg"
				alt=""
				@click="$emit('close')"
			/>
			<tile-game-question v-if="question"
				:inheritedQuestion="question"
				:inheritedTileGameTheme="getTheme()"
			/>
			<div v-else>
				<p class="info-text">
					Pour ajouter une contenu à cette case, veuillez glisser déposer un contenu dans la grille.
				</p>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../../config'
import { authenticatedImageFetch } from '../../../../utils'
import TileGameQuestion from '../../../Admin/Search/SeriousGame/TileGame/TileGameQuestion.vue'
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper, TileGameQuestion },
	props: {
		question: {
			type: Object,
			nullable: true
		},
		theme: {
			type: String,
			nullable: true
		}
	},
	data() {
		return {
			url: `${Config.backend_url}/media/`
		}
	},
	methods: {
		getTheme() {
			if (this.$route.params.theme) {
				return this.$route.params.theme
			}
			else {
				return this.theme
			}
		},
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
	},
}
</script>

<style scoped lang="scss">
.info-text{
	font-size:2.5rem;
	padding: 8rem;
	text-align: center;
}
</style>
