<template>
	<div class="day-wrapper">
		<p class="day">{{ day }}</p>
		<div class="course-container">
			<course-wrapper
				v-for="course in courses"
				:key="course"
				:course="course"
				@refreshCourses="refreshCourses"
			>
			</course-wrapper>
		</div>
	</div>
</template>

<script>
import CourseWrapper from './CourseWrapper.vue'
export default {
	components: { CourseWrapper },
	props: {
		day: String,
		courses: Array
	},

	methods: {
		refreshCourses() {
			this.$emit('refreshCourses')
		}
	},



}
</script>

<style>
</style>
