<template>
	<h1>Clés en main</h1>
	<nav class="paginate">
		<ul v-if="pages !== null">
			<li v-for="(i, index) in pages" :key="index">
				<button
					:class="index == currentPage ? 'active' : ''"
					@click="getNewKeys(index)"
				>
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Title</th>
			<th>Séquence</th>
			<th>Niveau</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr v-for="(key, index) in keys" :key="index">
			<td>{{ index + 1 * currentPage * 20 + 1 }}</td>
			<td>{{ key.title }}</td>
			<td>
				{{ key.sequence_name }}
			</td>
			<td>
				{{ key.level_name }}
			</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditKey',
						params: { id: key.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteKey(key.id)">supprimer</button>
			</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			keys: [],
			chapters: [],
			sequences: [],
			levels: [],
			count: null,
			currentPage: 0,
			pages: null
		}
	},
	methods: {
		deleteKey(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/keys/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.getNewKeys(this.currentPage)
					})
			}
		},
		getNewKeys: function (e) {
			authenticatedApiFetch(`/keys/?offset=${e * 20}`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.count = res.count
					this.currentPage = e
					this.keys = res.results
					this.keys = this.keys.filter(x => x.admin === true)
					this.pages = Math.ceil(this.count / 20)
				})
		},
		allKeys: function () {
			authenticatedApiFetch(`/keys/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.count = res.count
					this.keys = res.results
					// this.keys = this.keys.filter(x => x.admin === true)
					this.pages = Math.ceil(this.count / 20)
				})
		},
	},
	created() {
		this.allKeys()
	},
}
</script>

<style>
</style>
