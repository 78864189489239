<template>
	<section class="super-admin">
		<router-link
			class="super-admin-logo"
			:to="{ name: 'SuperAdminDashboard' }"
		>
			<img src="/img/logo-without-text.svg" alt="" />
		</router-link>
		<user-display />

		<router-view></router-view>
	</section>
</template>

<script>
import { getProfile } from '../../getProfile'
import UserDisplay from '../Admin/UserDisplay.vue'
export default {
	components: { UserDisplay },
	methods: {
		checkProfile: function () {
			if (getProfile() == 'Teacher') {
				this.$router.push({ name: 'Dashboard' })
			}
		},
	},
	created() {
		this.checkProfile()
	},
}
</script>

<style>
</style>
