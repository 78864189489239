<template>
	<ul class="class-menu-container profile-menu-container">
		<span
			:style="'width:' + spanWidth + 'px;left:' + spanPosition + 'px;'"
			class="underline"
		></span>
		<div>
			<li>
				<router-link
					id="profile"
					:to="{
						name: 'Profile',
					}"
					class="classes"
					>Mon profil</router-link
				>
			</li>
			<li >
				<router-link
					id="abonnement"
					:to="{
						name: 'Abonnement',
					}"
					class="classes"
					>Mon abonnement</router-link
				>
			</li>
		</div>
	</ul>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component class="main-component" :is="Component"></component>
		</transition>
	</router-view>
</template>

<script>
export default {
	components: {},
	data() {
		return {
			resourceWidth: '',
			studentWidth: '',
			docWidth: '',
			resourcePosition: '',
			studentPosition: '',
			docPosition: '',
			spanWidth: '',
			spanPosition: '',
		}
	},
	methods: {
		updateSpan(e) {

			switch (e) {
				case 'Profile':
					this.spanWidth = this.profileWidth
					this.spanPosition = this.profilePosition
					break
				case 'Abonnement':
					this.spanWidth = this.abonnementWidth
					this.spanPosition = this.abonnementPosition
					break

			}
		},
	},
	mounted() {
		const profile = document.getElementById('profile')
		const abonnement = document.getElementById('abonnement')
		this.profileWidth = profile.offsetWidth
		this.abonnementWidth = abonnement.offsetWidth
		this.profilePosition = profile.getBoundingClientRect().left - profile.getBoundingClientRect().left
		this.abonnementPosition = abonnement.getBoundingClientRect().left - profile.getBoundingClientRect().left
		this.updateSpan(this.$route.name)
	},
	watch: {
		$route(to) {
			this.updateSpan(to.name)
		}
	},
}
</script>

<style>
</style>
