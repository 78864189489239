<template>
	<div class="observation-amount-container">
		<p :style="`color:${color}`">{{ amount }}</p>
	</div>
</template>

<script>
export default {
	props: {
		color: String,
		amount: Number
	}
}
</script>

<style>
</style>
