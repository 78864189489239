<template>
	<h1>Modifier un Exercice Wander Game</h1>
	<form @submit.prevent="submit" class="form-container" enctype="multipart/form-data">
		<div class="input-container">
			<label for="Title">Titre</label>
			<input type="text" class="form-control" v-model="form.title" id="Title" placeholder="Title" />
		</div>
		<div class="input-container">
			<label for="text">Text</label>
			<button class="add-latex" @click.prevent="isPopUpLatexVisibleText = !isPopUpLatexVisibleText">f(x)</button>

			<div class="fake-textarea" id="text" contenteditable></div>

		</div>
		<div class="input-container">
			<label for="correction">Correction</label>
			<button class="add-latex"
				@click.prevent="isPopUpLatexVisibleCorrection = !isPopUpLatexVisibleCorrection">f(x)</button>

			<div class="fake-textarea" id="correction" contenteditable></div>

		</div>
		<div ref="img-container" class="input-container">
			<label for="Image">Photo</label>
			<input type="file" v-on:change="changePhoto" class="form-control" placeholder="Photo" />
		</div>
		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select multiple id="niveaux" v-model="form.levels">
				<option v-for="level in $store.state.levels" :key="level" :value="level.id">
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="niveaux">Type</label>
			<select id="niveaux" v-model="form.tile_game_type_id">
				<option v-for="type in tileGameType" :key="type.id" :value="type.id">
					{{ type.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="theme">Theme</label>
			<select multiple id="theme" v-model="form.tile_game_theme">
				<option v-for="type in tileGameThemes" :key="type.id" :value="type.id">
					{{ type.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="subject">Matières</label>
			<select multiple id="subject" v-model="form.subjects">
				<option v-for="subject in $store.state.subjects" :key="subject.id" :value="subject.id">
					{{ subject.name }}
				</option>
			</select>
		</div>

		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
	<Teleport to="body">
		<PopUpInsertLatex @close="isPopUpLatexVisibleText = !isPopUpLatexVisibleText" @insert-latex="insertLatexText"
			v-if="isPopUpLatexVisibleText" />
	</Teleport>
	<Teleport to="body">
		<PopUpInsertLatex @close="isPopUpLatexVisibleCorrection = !isPopUpLatexVisibleCorrection"
			@insert-latex="insertLatexCorrection" v-if="isPopUpLatexVisibleCorrection" />
	</Teleport>
</template>

<script>
import { authenticatedApiFetch, authenticatedImageFetch } from '../../../utils'
import PopUpInsertLatex from '../../PopUp/SeriousGame/TileGame/PopUpInsertLatex.vue'

export default {
	components: { PopUpInsertLatex },
	data() {
		return {
			form: {
			},

			levels: [],
			tileGameType: [],
			tileGameThemes: [],
			errors: '',
			status: '',
			isPopUpLatexVisibleText: false,
			isPopUpLatexVisibleCorrection: false,
			carretPosition: 0

		}
	},
	methods: {
		updateCarretPosition() {
			return new Promise((resolve) => {
				setTimeout(() => {
					const selection = window.getSelection()
					if (selection.rangeCount > 0) {
						const range = selection.getRangeAt(0)
						this.carretPosition = range.startOffset
					}
					resolve()
				}, 200)
			})

		},
		insertLatexText(latex) {
			this.isPopUpLatexVisibleText = false
			const position = this.carretPosition
			const mathField = document.createElement('math-field')
			mathField.setAttribute('readonly', '')
			mathField.setAttribute('class', 'mathfield')
			mathField.setAttribute('contenteditable', false)

			mathField.textContent = latex
			mathField.style.cursor = 'pointer'
			const text = document.getElementById('text')
			text.innerHTML = text.innerHTML.replaceAll('&nbsp;', ' ')
			text.innerHTML = text.innerHTML.slice(0, position) + mathField.outerHTML + text.innerHTML.slice(position) + '&nbsp'

			this.isPopUpLatexVisible = false
		},
		insertLatexCorrection(latex) {
			this.isPopUpLatexVisibleCorrection = false
			const position = this.carretPosition
			const mathField = document.createElement('math-field')
			mathField.setAttribute('readonly', '')
			mathField.setAttribute('class', 'mathfield')
			mathField.setAttribute('contenteditable', false)

			mathField.textContent = latex
			mathField.style.cursor = 'pointer'
			const correction = document.getElementById('correction')
			correction.innerHTML = correction.innerHTML.replaceAll('&nbsp;', ' ')
			correction.innerHTML = correction.innerHTML.slice(0, position) + mathField.outerHTML + correction.innerHTML.slice(position) + '&nbsp'

			this.isPopUpLatexVisible = false
		},
		async allTileGameContentType() {
			const res = await authenticatedApiFetch(`/tile-game-type/`, { method: "GET" })
				.then(res => res.json())

			this.tileGameType = res
		},
		async alltileGameThemes() {
			const res = await authenticatedApiFetch(`/tile-game-each-theme/`, { method: "GET" })
				.then(res => res.json())

			this.tileGameThemes = res
		},
		getImage() {
			authenticatedImageFetch(this.form.image, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs['img-container'].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},

		submit() {
			authenticatedApiFetch(`/tile-game-content/${this.$route.params.id}/`, {
				method: 'PUT',
				body: this.getFormDataValue(),
				headers: {}
			})
				.then(() => {
					this.$router.go(-1)
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			let sequences = this.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
			if (sequences.length !== 0) {
				let chapterArray = []
				sequences.forEach(element => {
					let chapters = this.chapters.filter(x => x.sequence == element)
					chapterArray.push(...chapters)
				})
				return chapterArray
			}
			if (this.selectedLevel == null) {
				return this.chapters
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			for (const level of this.form.levels) {
				data.append('levels', level)
			}
			for (const subject of this.form.subjects) {
				data.append('subjects', subject)
			}

			if (typeof this.form.image == 'object' && this.form.image !== null) {
				data.append('image', this.form.image)
			}
			for (const tile_game_theme of this.form.tile_game_theme) {
				data.append('tile_game_theme', tile_game_theme)
			}
			data.append('tile_game_type_id', this.form.tile_game_type_id)
			data.append('text', document.getElementById('text').innerHTML)
			data.append('correction', document.getElementById('correction').innerHTML)
			return data
		},

		changePhoto(e) {
			this.form.image = e.target.files[0]
		},

		async getTileGameContent() {
			const res = await authenticatedApiFetch(`/tile-game-content/${this.$route.params.id}/`)
				.then(res => res.json())
			this.form = res
			if (this.form.image)
				this.getImage()

			const correction = document.getElementById('correction')
			correction.innerHTML = this.form.correction

			const text = document.getElementById('text')
			text.innerHTML = this.form.text
		}
	},

	created() {
		this.allTileGameContentType()
		this.getTileGameContent()
		this.alltileGameThemes()
	},
	mounted() {
		this.$nextTick(() => {
			const correction = document.getElementById('correction')
			const text = document.getElementById('text')
			text.addEventListener('input', async () => {
				await this.updateCarretPosition()
				this.text = text.innerHTML
			})
			text.addEventListener('click', async () => {
				await this.updateCarretPosition()
			})
			correction.addEventListener('input', async () => {
				await this.updateCarretPosition()
				this.text = correction.innerHTML
			})
			correction.addEventListener('click', async () => {
				await this.updateCarretPosition()
			})
		})
	},

}
</script>

<style lang="scss" setup>
.fake-textarea {
	width: 100%;
	height: 100%;
	min-height: 150px;
	padding: 2rem;
	background-color: white;
	border: none;
	border-radius: 15px;
	resize: none;
}
</style>
