import { authenticatedApiFetch } from '../utils'



export const allKanbansForUserService = async (userId) => {
    const res = await authenticatedApiFetch(`/kanbans/?teacher=${userId}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createKanbanService = async (data) => {
    const res = await authenticatedApiFetch(`/kanbans/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateKanbanService = async (id, data) => {
    const res = await authenticatedApiFetch(`/kanbans/${id}/`, {
        method: 'PUT', body: JSON.stringify(data)
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteKanbanService = async (id) => {
    const res = await authenticatedApiFetch(`/kanbans/${id}/`, {
        method: 'DELETE'
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

