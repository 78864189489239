<template>
	<section class="calendar-exo-container" :style="getStyle()">
		<button v-if="!id" class="return-btn" @click="$router.go(-1)">
			<img
				:src="`/img/game/calendar/return/${$route.params.template}.svg`"
				alt=""
			/>
		</button>
		<div
			v-if="
				(!exo.image_url && !exo.video_url && exo.text) ||
				exo.video_url == 'null'
			"
			class="text-only-container text-container"
			:style="getBorderColor()"
		>
			<p v-html="formattedText(exo.text)"></p>
		</div>
		<div
			v-if="exo.image_url && !exo.video_url && exo.text"
			class="text-image-container"
		>
			<div class="img-container" :style="getBorderColor()">
				<img
					v-if="exo.image_url !== null"
					:src="url + this.exo.image_url"
					alt=""
				/>
			</div>
			<div class="text-container" :style="getBorderColor()">
				<p v-html="formattedText(exo.text)"></p>
			</div>
		</div>
		<div
			v-if="exo.image_url && !exo.video_url && !exo.text"
			class="image-container"
			:style="getBorderColor()"
		>
			<img
				v-if="exo.image_url !== null"
				:src="url + this.exo.image_url"
				alt=""
			/>
		</div>

		<iframe
			v-else-if="exo.video_url && exo.video_url !== 'null'"
			:src="exo.video_url"
			allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
			allowfullscreen
		/>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../../../../config'
import { getChristmasCalendarExerciseService } from '../../../../../services/christmasCalendarExercisesService'
export default {
	props: {
		id: {
			type: Number,
			nullable: true,
		}
	},
	data() {
		return {
			exo: {},
			url: `${Config.backend_url}/media/`,
			template: [
				{
					name: 'SNOWY',
					bg: '/img/game/calendar/bg/SNOWY',
					color: '#514462'
				},
				{
					name: 'STARS',
					bg: '/img/game/calendar/bg/STARS',
					color: '#B3CAFF'
				},
				{
					name: 'MOUNTAIN',
					bg: '/img/game/calendar/bg/MOUNTAIN',
					color: '#354C6B'
				},
				{
					name: 'VILLAGE',
					bg: '/img/game/calendar/bg/VILLAGE',
					color: '#D82A2A'
				},
				{
					name: 'SNOWMAN',
					bg: '/img/game/calendar/bg/SNOWMAN',
					color: '#010101'
				},
				{
					name: 'FOREST',
					bg: '/img/game/calendar/bg/FOREST',
					color: '#0FA26F'
				},
				{
					name: 'HOPPER',
					bg: '/img/game/calendar/bg/HOPPER',
					color: '#2C2E37'
				},
			]
		}
	},
	methods: {
		getStyle() {
			let template = this.template.find(x => x.name == this.$route.params.template)
			if (!template) {
				template = this.template[0]
			}
			const randomNumber = Math.floor(Math.random() * 4) + 1

			const style = `
			background-image: url(${template.bg}-${randomNumber}.jpg);
			`
			return style
		},
		getBorderColor() {
			let template = this.template.find(x => x.name == this.$route.params.template)
			if (!template) {
				template = this.template[0]
			}
			const style = `
			filter: drop-shadow(-6px 6px 0px ${template.color});
        	border: solid 6px ${template.color};
			`
			return style
		},
		formattedText(text) {
			const formattedText = text.replace(/\n/g, '<br>')
			return formattedText
		},
		async getChristmasCalendarExercise() {
			if (this.$store.state.user) {
				this.exo = await getChristmasCalendarExerciseService(this.$route.params.id || this.id)
			}
			else {
				this.exo = await axios.get(`${Config.backend_url}/api/v1/christmas-calendar-exercises/${this.$route.params.id || this.id}/`)
					.then(res => res.data)
			}

		},
	},
	created() {
		this.getChristmasCalendarExercise()
	},
}
</script>

<style>
</style>
