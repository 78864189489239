<template>
	<div class="toast" :class="`toast-${error.status}`">
		<p><img :src="`/img/${error.status}.svg`" alt="" /> {{ error.text }}</p>
		<div class="img-container">
			<span></span>
			<img @click="resetErrors" src="/img/close.svg" alt="" />
		</div>
	</div>
</template>

<script>
export default {
	props: {
		error: Object,
		defaultTimeout: {
			type: Number,
			default: 3000
		}
	},


	methods: {
		resetErrors() {
			this.$store.state.toast = {
				status: '',
				text: ''
			}
		}
	},

	mounted() {
		setTimeout(() => {
			this.resetErrors()
		}, this.defaultTimeout)
	},
}
</script>

<style>
</style>
