<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-calendar">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<calendar-exercise :id="id" />
		</div>
	</pop-up-wrapper>
</template>

<script>
import CalendarExercise from '../../../Admin/Search/SeriousGame/Calendar/CalendarExercise.vue'
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper, CalendarExercise },
	props: {
		id: Number
	},
}
</script>

<style>
</style>
