<template>
	<div :style="getBackground()" class="slide-maker-container">
		<div class="img-text-container-calendar">
			<div v-if="template == 1 && template !== 2" class="img-container">
				<label for="file">
					<img v-if="url" class="photo" :src="url" alt="" />
					<img
						v-else
						class="import"
						src="/img/game/tiles/slide/import.svg"
						alt=""
					/>
					<p class="max-size-text" v-if="!url">
						L'image importée ne doit pas dépasser la taille de 1 Mo.
					</p>
				</label>
				<input @change="changeImg" id="file" type="file" />
			</div>
			<div v-if="template !== 2 && template !== 3" class="text-container">
				<textarea
					v-model="text"
					name=""
					id=""
					cols="30"
					rows="5"
					placeholder="Texte"
				></textarea>
			</div>
			<div class="text-container" v-if="template == 2">
				<textarea
					v-model="video_url"
					name=""
					id=""
					cols="30"
					rows="5"
					:placeholder="placeholder"
					title="YouTube video player"
					frameborder="0"
					allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
					allowfullscreen
				>
				></textarea
				>
			</div>
			<div class="img-only-container" v-if="template == 3">
				<label for="file">
					<img v-if="url" class="photo" :src="url" alt="" />
					<img
						v-else
						class="import"
						src="/img/game/tiles/slide/import.svg"
						alt=""
					/>
				</label>
				<input @change="changeImg" id="file" type="file" />
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		type: String,
		theme: String,
		template: Number
	},
	data() {
		return {
			url: '',
			urlImgMath: '',
			text: '',
			video_url: '',
			image: '',
			placeholder: `Copiez ici le lien de la vidéo. Pour ce faire, il faut en général cliquer sur bouton PARTAGER la vidéo, puis INTEGRER. Récupérez ensuite dans le code <iframe> ... </iframe> le lien de votre vidéo.`
		}
	},
	methods: {
		getTemplateBg() {
			switch (this.$route.params.template) {
				case 'SNOWY':
					return 'SNOWY.svg'
				case 'MOUNTAIN':
					return 'MOUNTAIN.jpg'
				case 'HOPPER':
					return 'HOPPER.svg'
				case 'FOREST':
					return 'FOREST.svg'
				case 'SNOWMAN':
					return 'SNOWMAN.svg'
				case 'STARS':
					return 'STARS.svg'
				case 'VILLAGE':
					return 'VILLAGE.svg'
				default:
					return 'SNOWY.svg'
			}
		},
		getBackground() {
			const style = `background-image: url(/img/game/calendar/bg/${this.getTemplateBg()})`
			return style
		},
		changeImg(e) {
			this.url = URL.createObjectURL(e.target.files[0])
			this.image_url = e.target.files[0]
		},
	},
}
</script>

<style>
</style>
