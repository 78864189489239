<template>
	<pop-up-wrapper>
		<div class="pop-up-configure-exercise-sheet">
			<img
				@click="this.$emit('close')"
				class="close"
				src="/img/close.svg"
				alt=""
			/>
			<h2>Modifier votre ressource</h2>
			<div class="pop-up-content-container">
				<div class="field-container">
					<div class="input-container">
						<label for="name">Titre *</label>
						<input
							class="form-control required"
							id="name"
							v-model="form.name"
							placeholder="Nom de la ressource"
							type="text"
							@change="updateError"
						/>
						<small class="error">{{ errors.name }}</small>
					</div>
					<div class="input-container">
						<label for="classes">Classe *</label>
						<select
							class="required"
							id="classes"
							@change="updateErrorClasse"
							v-model="form.classes"
						>
							<option
								v-for="classe in $store.state.classes"
								:key="classe.id"
								:value="classe.id"
							>
								{{ classe.name }}
							</option>
						</select>
						<small class="error">{{ errors.classe }}</small>
					</div>
					<div class="input-container">
						<label for="date">Créneau</label>
						<select v-model="form.currency" id="date">
							<option
								v-for="course in getCourses"
								:key="course.id"
								:value="course.id"
							>
								{{ course.formatedDay }}
								{{ course.debut_hour }} -
								{{ course.end_hour }}
							</option>
						</select>
					</div>
					<p>Cartouche</p>
					<slider-evaluation @index="checkIndex" />
					<!-- <div class="checkbox-container">
						<label for="doubleColumn">
							<input
								id="doubleColumn"
								v-model="form.double_column"
								type="checkbox"
							/>
							Double colonne</label
						>
					</div> -->
				</div>
				<div class="exo-container">
					<p>Exercices</p>
					<div class="exo-wrapper">
						<draggable
							v-if="organizedQuestions.length > 0"
							:list="organizedQuestions"
							:disabled="!enabled"
							item-key="name"
							class="exo"
							ghost-class="ghost"
							@start="dragging = true"
							@end="dragging = false"
						>
							<template #item="{ element }">
								<div
									class="drag-el img-container"
									:class="{ 'not-draggable': !enabled }"
								>
									<div
										class="img-arrow-container"
										:class="{
											extended:
												isExtended == element.id
													? true
													: false,
										}"
									>
										<img
											:src="`${url}/media/${element.description_img}`"
											alt=""
										/>
										<img
											@click="extend(element.id)"
											class="arrow"
											src="/img/pop-up/down-arrow.svg"
											alt=""
										/>
										<img
											@click="removeQuestion(element.id)"
											class="remove"
											src="/img/blue-cross.svg"
											alt=""
										/>
									</div>
								</div>
							</template>
						</draggable>
					</div>
				</div>
			</div>

			<button @click="submit" class="submit dark">Enregistrer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import { formateAndOrderDate } from '../../../utils'
import SliderEvaluation from '../../Admin/SliderEvaluation.vue'
import PopUpWrapper from '../PopUpWrapper.vue'
import draggable from "vuedraggable"
import { allActivityEventsFilterService } from '../../../services/activityEventsService'
import { getExerciseSheetByIdService, updateExerciseSheetService } from '../../../services/exerciseSheetsService'
import { allExerciseQuestionOrdersFilterService, createExerciseQuestionOrderService, deleteExerciseQuestionOrderService } from '../../../services/exerciseQuestionOrdersService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'
import { getExerciseQuestionInstanceService } from '../../../services/exerciseQuestionInstancesService'

export default {
	components: {
		draggable,
		PopUpWrapper,
		SliderEvaluation
	},
	props: {
		id: Number
	},
	data() {
		return {
			isExtended: null,
			url: Config.backend_url,
			dragging: false,
			enabled: true,
			form: {
				name: '',
				classes: [],
				template: 0,
				currency: null,
				double_column: false
			},
			classes: [],
			errors: {},
			courses: [],
			organizedQuestions: []
		}
	},

	methods: {
		async getExerciseSheet() {
			const res = await getExerciseSheetByIdService(this.id)
			this.form.name = res.title
			this.form.classes = res.classes[0]
			this.form.double_column = res.double_column
		},
		async getExerciseSheetQuestions() {
			const filter = `?exercise_sheet=${this.id}`
			const res = await allExerciseQuestionOrdersFilterService(filter)
			const exercises = res.sort((a, b) => (a.order > b.order ? 1 : -1))

			for await (const exo of exercises) {
				const res = await getExerciseQuestionInstanceService(exo.exercise)
				this.organizedQuestions.push(res)
			}
		},
		removeQuestion(e) {
			this.$emit('removeQuestion', e)
			this.organizedQuestions = this.organizedQuestions.filter(x => x.id !== e)
		},
		extend(id) {
			if (this.isExtended == id) {
				this.isExtended = null
			} else {
				this.isExtended = id
			}
		},
		async allCourses() {
			let classes = []

			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)
			}

			let occurrence = this.courses.find(e => {
				return e.exercise_sheets.find(x => x == this.id)
			})

			if (occurrence) {
				this.form.currency = occurrence.id
			}
		},
		updateInput() {
			this.errors.name = null
		},
		updateSelect() {
			this.errors.classe = null
		},
		close() {
			this.$emit('closed')
		},
		async submit() {
			if (this.form.name == '') {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter un nom à votre ressource'
				}
			}
			if (this.form.classes.length == 0) {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter une classe à votre ressource'
				}
			}
			if (this.form.name !== '' && this.form.classes.length !== 0) {
				const data = {
					title: this.form.name,
					classes: [this.form.classes],
					double_column: false,
					ready_to_display: false,
					template: this.form.template
				}

				await updateExerciseSheetService(this.id, data)
				const filter = `?exercise_sheet=${this.id}`
				const exercisesOrders = await allExerciseQuestionOrdersFilterService(filter)

				for (const exercisesOrder of exercisesOrders) {
					await deleteExerciseQuestionOrderService(exercisesOrder.id)
				}
				let i = 0
				for await (const questionInstance of this.organizedQuestions) {
					let body = {
						exercise: questionInstance.id,
						exercise_sheet: this.id,
						order: i
					}
					i++
					await createExerciseQuestionOrderService(body)
				}

				if (this.form.currency == undefined) {
					console.log('no agenda')
				}
				else {
					let occurrence = await getOccurenceByIdService(this.form.currency)
					occurrence.exercise_sheets.push(this.id)

					await updateOccurenceService(this.form.currency, occurrence)
					// If already have an occurrence (remove old occurrence)

					let patchedOccurence = this.courses.find(e => {
						return e.exercise_sheets.find(x => x == this.id)
					})
					if (patchedOccurence && this.oldCurrency !== this.form.currency) {
						patchedOccurence.exercise_sheets = patchedOccurence.exercise_sheets.filter(x => x !== this.id)
						await updateOccurenceService(patchedOccurence.id, patchedOccurence)
					}
				}
				this.$emit('refreshActivities')
				this.$emit('close')
			}
		},
		checkIndex(e) {
			this.form.template = e
		},
	},

	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},

	created() {
		this.getExerciseSheet()
		this.getExerciseSheetQuestions()
		this.allCourses()
	},
}
</script>

<style>
</style>
