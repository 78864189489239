<template>
	<section class="percentage">
		<h1>Rémunération</h1>
		<p class="intro-text">
			Nous avons souhaité un modèle de rémunération simple, équitable et
			transparent. Il a donc été décidé de reverser aux auteurs 30% des
			revenus du projet (hors taxes). Ces revenus seront répartis entre
			les auteurs selon un modèle “au volume”. Ainsi, plus vos contenus
			sont nombreux (et validés), plus vous êtes rémunéré(e).
		</p>
		<div class="container">
			<div class="card-container">
				<percentage-card
					:title="'Questions Flash'"
					:done="myQuestionsAmount"
					:total="questionsAmount"
					:calculatedPercentage="questionsPercentage"
					:coef="2"
					:imgName="'flash'"
				/>
				<percentage-card
					:title="'Exercices'"
					:done="myExercisesAmount"
					:total="exercisesAmount"
					:calculatedPercentage="exercisesPercentage"
					:coef="3"
					:imgName="'exercise'"
				/>
				<percentage-card
					:title="'Clés en main'"
					:done="myKeysAmount"
					:total="keysAmount"
					:calculatedPercentage="keysPercentage"
					:coef="5"
					:imgName="'key'"
				/>
			</div>

			<h2>
				Pourcentage sur l'ensemble des ressources proposées :
				<span class="blue">
					{{
						(questionsPercentage * 2 +
							exercisesPercentage * 3 +
							keysPercentage * 5) /
						10
					}}%</span
				>
				<br />
				Part sur les 30% :
				<span class="blue">
					{{
						(
							((questionsPercentage * 2 +
								exercisesPercentage * 3 +
								keysPercentage * 5) /
								10) *
							0.3
						).toFixed(1)
					}}%</span
				>
			</h2>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import PercentageCard from './PercentageCard.vue'
export default {
	components: { PercentageCard },
	data() {
		return {
			questionsAmount: 0,
			myQuestionsAmount: 0,
			questionsPercentage: 0,
			exercisesAmount: 0,
			myExercisesAmount: 0,
			exercisesPercentage: 0,
			keysAmount: 0,
			myKeysAmount: 0,
			keysPercentage: 0
		}
	},

	methods: {
		async getQuestions() {
			const res = await authenticatedApiFetch('/questions/')
				.then(res => res.json())
			this.questionsAmount = res.count

			const res2 = await authenticatedApiFetch(`/questions/?teacher=${this.$store.state.user.user}`)
				.then(res => res.json())
			this.myQuestionsAmount = res2.count

			if (this.myQuestionsAmount !== 0) {
				this.questionsPercentage = this.calculatePercentage(this.myQuestionsAmount, this.questionsAmount, 100)
			}
			else {
				this.questionsPercentage = 0
			}

		},
		async getExercises() {
			const res = await authenticatedApiFetch('/exercise-questions/?status=VALIDATED')
				.then(res => res.json())
			this.exercisesAmount = res.count

			const res2 = await authenticatedApiFetch(`/exercise-questions/?teacher=${this.$store.state.user.user}&status=VALIDATED`)
				.then(res => res.json())
			this.myExercisesAmount = res2.count

			if (this.myExercisesAmount !== 0) {
				this.exercisesPercentage = this.calculatePercentage(this.myExercisesAmount, this.exercisesAmount, 100)
			}
			else {
				this.exercisesPercentage = 0
			}
		},
		async getKeys() {
			const res = await authenticatedApiFetch('/proposed-key-slides/?status=VALIDATED')
				.then(res => res.json())
			this.keysAmount = res.count + 188

			const res2 = await authenticatedApiFetch(`/proposed-key-slides/?teacher=${this.$store.state.user.user}&status=VALIDATED`)
				.then(res => res.json())
			this.myKeysAmount = res2.count
			if (this.$store.state.user.user == 2) {
				this.myKeysAmount += 188
			}
			if (this.myKeysAmount !== 0) {
				this.keysPercentage = this.calculatePercentage(this.myKeysAmount, this.keysAmount, 100)

			}
			else {
				this.keysPercentage = 0
			}
		},
		calculatePercentage(done, total, percentage) {
			return Math.round(done / total * percentage)
		}
	},


	created() {
		this.getQuestions()
		this.getExercises()
		this.getKeys()
	},
}
</script>

<style>
</style>
