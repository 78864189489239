<template>
	<div class="drive-wrapper" ref="file">
		<div v-if="folders.length !== 0" class="folder-wrapper">
			<h2>Dossiers</h2>
			<folder
				v-for="folder in folders"
				:key="folder.id"
				:folder="folder"
				@getFiles="getFiles()"
				@getFolders="getFolders()"
				:selected="selected == folder.id ? true : false"
				@select="select"
				@remove="removeFolder"
				@redirect="
					this.$router.push({
						name: 'ClassFolder',
						params: { folderId: folder.id },
					})
				"
			/>
		</div>
		<div
			@dragover="dragover"
			@dragleave="dragleave"
			@drop="drop($event)"
			class="file-container"
		>
			<div class="drop-container"></div>
			<div v-if="filteredFiles(this.$route.params.folderId).length !== 0">
				<h2>Fichiers</h2>
				<file-component
					v-for="file in filteredFiles(this.$route.params.folderId)"
					:key="file"
					:file="file"
					:id="file.id"
					@remove="removeFile"
					:selected="selectedFile == file.id ? true : false"
					@select="selectFile"
				/>
			</div>
			<div
				v-if="files.length == 0 && folders.length == 0"
				class="no-files"
			>
				<img class="bg" src="/img/class/bg-no-files.svg" alt="" />
				<img class="file" src="/img/class/dark-file.svg" alt="" />
				<p class="title">Déposez vos fichiers ici</p>
				<p class="subtitle">ou utiliser le bouton nouveau</p>
			</div>
		</div>
	</div>
</template>

<script>
import { allFilesForClassService, createFileService } from '../../../../services/filesService'
import { allFoldersForClassService } from '../../../../services/foldersService'
import FileComponent from './FileComponent.vue'
import Folder from './FolderComponent.vue'
export default {
	components: { Folder, FileComponent },
	data() {
		return {
			folders: [],
			files: [],
			file: {},
			selected: null,
			selectedFile: null,
			loading: false,
			test: 0
		}
	},
	methods: {

		removeFolder(e) {
			this.folders = this.folders.filter(x => x.id !== e)
			this.selectedFile = null
		},
		removeFile(e) {
			this.files = this.files.filter(x => x.id !== e)
		},
		select(e) {
			this.selected = e
			this.selectedFile = null
		},
		selectFile(e) {
			this.selectedFile = e
			this.selected = null
		},
		filteredFiles(e) {
			let files = this.files.filter(x => x.parent_folder_id == e)
			return files
		},

		async getFolders() {
			const res = await allFoldersForClassService(this.$route.params.id, this.$route.params.folderId)
			this.folders = res
		},
		async getFiles() {
			const res = await allFilesForClassService(this.$route.params.id, this.$route.params.folderId)
			this.files = res
		},
		dragover(event) {
			event.preventDefault()
			if (!event.currentTarget.classList.contains('drag-enter')) {
				event.currentTarget.classList.remove('not-drag-enter')
				event.currentTarget.classList.add('drag-enter')
			}
		},
		dragleave(event) {
			event.currentTarget.classList.add('not-drag-enter')
			event.currentTarget.classList.remove('drag-enter')
		},
		async drop(event) {
			event.currentTarget.classList.add('not-drag-enter')
			event.currentTarget.classList.remove('drag-enter')
			event.preventDefault()
			this.$refs.file.files = event.dataTransfer.files
			this.loading = true
			for (let i = 0; i < this.$refs.file.files.length; i++) {
				const data = new FormData()
				data.append('name', this.$refs.file.files[i].name)
				data.append('class_id', this.$route.params.id)
				data.append('parent_folder_id', this.$route.params.folderId)
				data.append('file_upload', this.$refs.file.files[i])
				await createFileService(data)
			}
			this.loading = false
			this.getFiles()

		},
		getSelected() {
			return this.selectedFile
		},
	},
	watch: {
		$route() {
			this.getFolders()
			this.getFiles()
			this.selectedFile = null
		}
	},

	mounted() {
		this.getFolders()
		this.getFiles()
	},
}
</script>

<style>
</style>
