<template>
	<section class="add-exercise-container">
		<h1>Créer une question</h1>
		<div class="text-container">
			<p>Ici, vous pouvez créer une question flash qui prendra la forme d'une page de diaporama.</p>
			<p>Vous retrouverez cette question dans mes questions créées</p>
			<p>Pour les disciplines scientifiques vous pouvez écrire proprement les formules avec le language Latex</p>
		</div>
		<div class="filter-input-container">
			<div class="input-container">
				<label for="niveaux">Niveau</label>
				<select id="niveaux" v-model="form.level" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="niveaux">Matière</label>
				<select id="niveaux" v-model="form.subject" required>
					<option
						v-for="subject in this.$store.state.subjects"
						:key="subject"
						:value="subject.id"
					>
						{{ subject.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="chapter">Chapitre</label>
				<select v-model="form.chapter" name="chapter" id="chapter">
					<optgroup
						v-for="sequence in this.$store.getters.sequencesByLevelAndSubject(
							form.level,
							form.subject
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<div class="input-container">
				<label for="type">Type</label>
				<select id="type" v-model="form.type" required>
					<option
						v-for="type in this.$store.state.questionTypes"
						:key="type"
						:value="type.id"
					>
						{{ type.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Thème</label>
				<select id="theme" v-model="form.theme" required>
					<option
						v-for="theme in this.$store.state.questionThemes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Difficulté</label>
				<select id="theme" v-model="form.difficulty" required>
					<option
						v-for="difficulty in this.$store.state.difficulties"
						:key="difficulty"
						:value="difficulty.id"
					>
						{{ difficulty.name }}
					</option>
				</select>
			</div>
		</div>
		<div class="input-container title-input">
			<label for="Title">Titre (compétence travaillée - n'apparait pas sur la question flash)</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Titre"
			/>
		</div>
		<div class="form-container">
			<div class="column">
				<div class="input-container textarea">
					<label for="Description">Texte de la question </label>
					<textarea
						type="text"
						class="form-control md-editor"
						v-model="latex"
						id="Description"
						placeholder="Description"
					>
					</textarea>
				</div>
				<div class="input-container textarea">
					<label for="Description">Correction </label>
					<textarea
						rows="10"
						type="text"
						class="form-control md-editor"
						v-model="latex2"
						id="Description"
						placeholder="Correction"
					>
					</textarea>
				</div>
				<div class="input-container">
					<label for="Image">Image</label>
					<input
						type="file"
						v-on:change="changeImg"
						class="form-control"
						id="Image"
						placeholder="Image"
					/>
					<div v-if="url" class="img-container">
						<div class="img-delete-container" @click="removeImg">
							<img class="close" src="/img/close.svg" alt="" />
						</div>
						<img :src="url" alt="" />
					</div>
				</div>
				<div class="input-container">
					<label for="Description"><input type="checkbox" v-model="form.status" /> Mode privé </label>
					
					<small style="font-size: 1.25rem;">Votre question sera accessible uniquement par vous.  <br> Elle ne sera pas soumise à une étape de vérification pour faire partie de la banque de questions publique</small>
				</div>
			</div>
			<div class="column">
				<div class="input-container latex-container">
					<label for="Description">Visualisation brute (rendu Latex pour les Maths) </label>
					<p class="latex" :key="latex">
						{{ translateFromTexToHTML(latex) }}
					</p>
				</div>
				<div class="input-container latex-container">
					<label for="Description">Visualisation brute (rendu Latex pour les Maths) </label>
					<p class="latex" :key="latex2">
						{{ translateFromTexToHTML(latex2) }}
					</p>
				</div>
			</div>
		</div>
		<button @click="submit" class="submit dark">Valider</button>
		<router-link v-if="$route.name=='MyResourcesAddQuestion'" class="submit light" :to="{name:'Question'}">Retour</router-link>
		<full-page-loader v-if="isLoading" />
	</section>
</template>

<script>
import { createQuestionService } from '../../../services/questionsService'
import { translateFromTexToHTML } from '../../../utils'
import FullPageLoader from '../../Utils/FullPageLoader.vue'

export default {
	components: { FullPageLoader },
	data() {
		return {
			latex: '',
			latex2: '',
			form: {
				title: '',
				description: '',
				correction: '',
				image_url_1: '',
				chapter: '',
				type: '',
				theme: '',
				difficulty: '',
				status: ''
			},
			levels: [],
			selectedLevel: null,
			teacher: this.$store.state.user.user,
			url: '',
			url2: '',
			isLoading: false
		}
	},
	methods: {
		translateFromTexToHTML: translateFromTexToHTML,
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},

		checkForm() {
			if (!this.form.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ titre',
					status: 'error'
				}
				return false
			}
			if (!this.form.level) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ niveau',
					status: 'error'
				}
				return false
			}
			if (!this.form.chapter) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ chapitre',
					status: 'error'
				}
				return false
			}
			// if (!this.form.type) {
			// 	this.$store.state.toast = {
			// 		text: 'Merci de remplir le champ type',
			// 		status: 'error'
			// 	}
			// 	return false
			// }
			// if (!this.form.theme) {
			// 	this.$store.state.toast = {
			// 		text: 'Merci de remplir le champ theme',
			// 		status: 'error'
			// 	}
			// 	return false
			// }
			if (!this.form.difficulty) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ difficulté',
					status: 'error'
				}
				return false
			}
			if (!this.latex) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ texte',
					status: 'error'
				}
				return false
			}

			return true
		},
		removeImg() {
			if (confirm("Êtes-vous sur de vouloir supprimer l'image")) {
				this.form.image_url_1 = null
				this.url = null
			}
		},
		async submit() {
			if (!this.checkForm()) {
				return
			}
			this.isLoading = true
			await createQuestionService(this.getFormDataValue())
			this.form.title = ""
			this.form.description = ""
			this.form.image_url_1 = ""
			this.url = ""
			this.$store.state.toast = {
				text: 'Enregistré avec succès',
				status: 'success'
			}
			this.isLoading = false
		},
		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.selectedLevel == null) {
					return this.$store.state.chapters
				}
			}
			else {
				return ''
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', translateFromTexToHTML(this.latex))
			data.append('correction', this.latex2)
			if (typeof (this.form.image_url_1) !== 'string' && this.form.image_url_1 !== null) {
				data.append('image_url_1', this.form.image_url_1)
			}
			data.append('chapter', this.form.chapter)
			data.append('level', this.getLevel(this.form.chapter))
			data.append('type', this.form.type)
			data.append('theme', this.form.theme)
			data.append('difficulty', this.form.difficulty)
			data.append('teacher', this.teacher)
			const status = this.form.status ? 'PRIVATE' : 'PENDING'
			data.append('status', status)

			return data
		},
		getLevel(e) {
			let chapter = this.$store.state.chapters.find(x => x.id == e).sequence
			let sequence = this.$store.state.sequences.find(x => x.id == chapter).level
			let level = this.$store.state.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg(e) {
			this.form.image_url_1 = e.target.files[0]
			this.url = URL.createObjectURL(this.form.image_url_1)
		},
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		latex2: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
	}
}
</script>

<style>
</style>
