<template>
	<tr>
		<td>{{ student.name }}</td>
		<td>{{ student.username }}</td>
		<td><span>{{ getCode() }} <button @click="isHashed = !isHashed"><img src="/img/eyes.svg" alt=""></button></span> </td>
	</tr>
</template>
<script>
export default{
	props:{
		student: Object
	},
	data(){
		return{
			isHashed: true
		}
	},
	methods:{
		getCode(){
			if(this.isHashed){
				return '********'
			}
			return this.student.code
		}
	}
}
</script>
<style lang="scss" scoped>

tr {
	td {
		width: unset;
		vertical-align: middle;
		border: 1px solid #E5E5E5;
		padding: .5rem;
		span{
			font-weight: unset;
			display: flex;
			align-items: center;
			gap: 1rem;
			padding: 0;

			img{
				margin-right: 0;
				width: 20px;
			}
		}
	}
}
</style>
