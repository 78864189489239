<template>
	<pop-up-wrapper>
		<div class="pop-up-share-resource">
			<img
				@click="this.$emit('close')"
				class="close"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Choisir les élèves à qui vous voulez partager cette ressource</p>
			<div class="students-container">
				<div class="input-container">
					<label for="allClasse">
						<input id="allClasse" v-model="isAllClasseTriggered" type="checkbox" @change="toggleEveryBody">
						Toute la classe
					</label>
				</div>
				<div class="input-container" v-for="student in students" :key="student.id">
					<label><input type="checkbox" v-model="sharedTo" :value="student.id">
					{{ student.name }}</label>
				</div>
			</div>
			<button @click="submit" class="submit dark">Valider</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { allStudentsInClasseService } from '../../services/studentsService'
import { createSharedResourceService,filteredSharedResourcesService,updateSharedResourceService } from '../../services/sharedResourcesService'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		id: Number,
		type: String
	},
	data() {
		return {
			students: [],
			sharedTo: [],
			isAllClasseTriggered: false,
			sharedResource: {}
		}
	},
	methods: {
		
		getType(typeName){
			switch (typeName) {
				case 'QCM':
					return 'qcm_id'
				case 'corrected_qcm_id':
					return 'corrected_qcm_id'
				case 'resource':
					return 'resource'
				case 'evaluation':
					return 'evaluation'
				default:
					return 'resource'
			}
		},
		async submit(){

			const data = {
				class_id: this.$route.params.id,
				[this.getType(this.type)]: this.id,
				students: this.sharedTo
			}
			try{
				if(!this.sharedResource.id){
					await createSharedResourceService(data)
				}
				else{
					await updateSharedResourceService(this.sharedResource.id,data)
				}
				this.$store.state.toast = {
					status: 'success',
					text: 'Partage mis à jour',
				}
				this.$emit('updateStatus',this.sharedTo.length > 0)
				this.$emit('close')
				}
			catch(e){
				console.log(e)
			}

		},
		toggleEveryBody(){
			if(this.sharedTo.length === this.students.length){
				this.sharedTo = []
			}else{
				this.sharedTo = this.students.map(student => student.id)
			}
		
		},
		async allStudents(){
			this.students = await allStudentsInClasseService(this.$route.params.id)
		},
		async getSharedResource(){
			const filter = `${this.getType(this.type)}=${this.id}`
			const res = await filteredSharedResourcesService(filter)
			if(res.length === 0) return

			this.sharedResource = res[0]
			
			this.sharedTo = this.sharedResource.students.map(x => {
				return x
			})
		}
	},
	created() {
		this.allStudents()
		this.getSharedResource()
	},
	watch: {
		sharedTo(){
			if(this.sharedTo.length === this.students.length){
				this.isAllClasseTriggered = true
			}
			else{
				this.isAllClasseTriggered = false
			}
		}
	}

}
</script>

<style>
</style>
