import { authenticatedApiFetch } from '../utils'


export const createQuestionFlashPackageService = async (data) => {
	const res = await authenticatedApiFetch('/question-flash-packages/', { method: 'POST', body: JSON.stringify(data) })
		.then(response => response.json())
		.catch(error => console.error(error))
	return res
}


export const filteredQuestionFlashPackagesService = async (filter, status='VALIDATED') => {
	const newFilter = `${filter}&status=${status}`
	const res = await authenticatedApiFetch(`/question-flash-packages/${newFilter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const getQuestionFlashPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/question-flash-packages/${id}/`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const deleteQuestionFlashPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/question-flash-packages/${id}/`, { method: 'DELETE' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const updateQuestionFlashPackageService = async (id, data) => {
	const res = await authenticatedApiFetch(`/question-flash-packages/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}


export const validateQuestionFlashPackageService = async (id) => {
	const res = await authenticatedApiFetch(`/question-flash-packages/${id}/validate/`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}
