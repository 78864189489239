<template>
	<h1>Types de Question</h1>
	<table>
		<tr>
			<th></th>
			<th>Nom</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr
			v-for="(questionType, index) in this.$store.state.questionTypes"
			:key="index"
		>
			<td>{{ index + 1 }}</td>
			<td>{{ questionType.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditQuestionType',
						params: { id: questionType.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteQuestionTheme(questionType.id)">
					supprimer
				</button>
			</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	methods: {
		deleteQuestionTheme(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/question-types/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.allQuestionTypes()
					})
			}
		},
	},
}
</script>

<style>
</style>
