<template>
	<div class="pop-up-observation-student simple">
		<table id="simplePdf">
			<p class="classe-name">{{ getClassName() }}</p>
			<thead>
				<tr>
					<th></th>
					<th class="red">Travail non fait</th>
					<th class="red">Comportement</th>
					<th class="orange">Oubli de matériel</th>
					<th class="green">Participation</th>
				</tr>
			</thead>
			<tbody>
				<tr v-for="student in students" :key="student.id">
					<td>{{ student.name }}</td>
					<td>
						<p :style="`color:#FF0000`">
							{{
								getObservationsAmount(
									student.id,
									"Travail non fait"
								)
							}}
						</p>
					</td>
					<td>
						<p :style="`color:#FF0000`">
							{{
								getObservationsAmount(
									student.id,
									"Comportement"
								)
							}}
						</p>
					</td>
					<td>
						<p :style="`color:#FFBA00`">
							{{
								getObservationsAmount(
									student.id,
									"Oubli de matériel"
								)
							}}
						</p>
					</td>
					<td>
						<p :style="`color:#07AC00`">
							{{
								getObservationsAmount(
									student.id,
									"Participation"
								)
							}}
						</p>
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script>
import { allObservationsFilterService } from '../../../services/observationsService'
import { allStudentsFilterService } from '../../../services/studentsService'
export default {
	data() {
		return {
			students: [],
			observations: [],
			isLoading: false
		}
	},
	methods: {
		getClassName() {
			return this.$store.getters.class(this.$route.params.id)
		},
		getObservationsAmount(id, motifName) {
			const motif = this.$store.state.motifs.find(x => x.name == motifName)
			const observations = this.observations.filter(x => x.student_id == id)

			const amount = observations.filter(x => x.motif == motif.id)
			return amount.length
		},

		async getObservations() {
			this.isLoading = true
			const filter = `?classe=${this.$route.params.id}`
			this.observations = await allObservationsFilterService(filter)
			this.isLoading = false
		},
		async allStudent() {
			let classId = this.$route.params.id
			const filter = `?classes=${classId}`
			this.students = await allStudentsFilterService(filter)
		},
	},
	created() {
		this.allStudent()
		this.getObservations()
	},
}
</script>

<style>
</style>
