<template>
	<pop-up-wrapper>
		<div ref="popUp" :style="pos" class="pop-up-edit-folder">
			<p @click="showPopUpEditFile">
				<img src="/img/class/update-icon.svg" alt="" /> Modifier
			</p>
			<p
				style="margin-top: 2rem"
				@click="
					downloadItem({
						url: file.file_upload,
						label: file.name,
					})
				"
			>
				<img src="/img/class/download-icon.svg" alt="" /> Télécharger
			</p>
			<hr />
			<p class="remove" @click="deleteFile(file.id)">
				<img src="/img/class/delete-icon.svg" alt="" /> Supprimer
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedApiFetch, authenticatedImageFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		x: Number,
		y: Number,
		file: Object
	},
	data() {
		return {
			inputValue: '',
			delete: false,
			pos: '',
		}
	},
	methods: {
		async downloadItem({ url, label }) {
			const response = await authenticatedImageFetch(url, { method: 'GET', responseType: "blob" })
				.then((response) => response.blob())
		
			const blob = new Blob([response])
			const link = document.createElement("a")
			link.href = URL.createObjectURL(blob)
			link.download = label
			link.click()
			URL.revokeObjectURL(link.href)
		},
		deleteFile: async function (id) {
			let result = confirm('Etes vous sur de vouloir supprimer ?')
			if (result) {
				await authenticatedApiFetch(`/files/${id}/`, { method: 'DELETE' })
					.then(() => {
						this.$emit('remove', id)
						this.$emit('close')
					})
			}
		},

		showPopUpEditFile() {
			this.$emit('showPopUpEditFile')
			this.$emit('close')
		},

	},
	mounted() {
		const height = window.innerHeight
		const popUpHeight = this.$refs.popUp.clientHeight

		let x = this.x
		let y = this.y

		if (y > height - popUpHeight) {
			y = height - popUpHeight - 20
		}
		let style = `left: calc(${x}px - 15vw); top: ${y}px;`
		this.pos = style
	},
}
</script>

<style>
</style>
