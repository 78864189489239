<template>
	<section class="student-place-lesson-container">
		<div class="title-container">
			<h1>Liens Favoris</h1>
		</div>

		<section class="lesson-folder-container">
			<ul>
				<li v-for="link in links" :key="link.id">
					<div class="link-container">
						<p>{{ link.title }}</p>
						<a
							class="student-place-link"
							target="_blank"
							:href="link.link"
						>
							Voir
						</a>
					</div>
				</li>
			</ul>
		</section>
	</section>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import { decrypt, getWebsiteIcon } from '../../utils'
export default {
	data() {
		return {
			links: [],
			classId: null,
		}
	},
	methods: {
		getWebsiteIcon(link) {
			return getWebsiteIcon(link)
		},
		async getLinks() {
			const res = await axios.get(`${Config.backend_url}/api/v1/class-links/?classe=${this.classId}&visible_for_student=True`)
			this.links = res.data
		},
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getLinks()
	},
}
</script>

<style>
</style>
