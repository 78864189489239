<template>
	<section class="student-place-game-container">
		<div class="title-container">
			<h1>Jeux Sérieux</h1>
		</div>

		<div class="game-container">
			<div
				v-for="calendar in calendars"
				:key="calendar.id"
				class="content-wrapper"
			>
				<div style="cursor: unset" class="img-container">
					<img
						:src="`/img/game/calendar/demo/${calendar.template}.jpg`"
						alt=""
					/>
				</div>
				<div class="title-link-container">
					<div>
						<p>Calendrier de l'Avent</p>
					</div>

					<router-link
						:to="{
							name: 'DisplayCalendar',
							params: { calendarId: calendar.id },
						}"
					>
						<span>Ouvrir</span>
					</router-link>
				</div>
			</div>
		</div>
	</section>
</template>

<script>
import Config from '../../config'
import axios from 'axios'
import { decrypt } from '../../utils'
export default {
	data() {
		return {
			calendars: [],
			classId: null,
			classe: {}
		}
	},
	methods: {
		async getClasse() {
			const res = await axios.get(`${Config.backend_url}/api/v1/classes/${this.classId}`)
			this.classe = res.data
		},

		async allExercises() {
			const res = await axios.get(`${Config.backend_url}/api/v1/christmas-calendar/?classes=${this.classId}`)
			this.calendars = res.data
		},
	},

	created() {
		this.classId = decrypt(this.$route.params.classId)
		this.getClasse()
		this.allExercises()
	},
}
</script>

<style>
</style>
