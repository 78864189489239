import { authenticatedApiFetch } from '../utils'



export const getTileGameByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game/${id}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const allTileGamesForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/tile-game/?class_id=${classId}`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const updateTileGameService = async (id, tileGame) => {
    const res = await authenticatedApiFetch(`/tile-game/${id}/`, { method: 'PUT', body: JSON.stringify(tileGame) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const createTileGameService = async (data) => {
    const res = await authenticatedApiFetch(`/tile-game/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
