<template>
	<section class="drive-container">
		<div class="path-btn-container">
			<p class="indicator-path">
				<router-link :to="{ name: 'ClassDrive' }"
					>Mon drive
				</router-link>
				<span v-if="loaded">
					<router-link
						:to="{
							name: 'ClassFolder',
							params: { folderId: folder.id },
						}"
						v-for="folder in folderOrder"
						:key="folder.id"
					>
						<span class="electric-blue"> &#62;</span>
						{{ folder.name }}</router-link
					></span
				>
			</p>
			<button class="new-btn" @click="createFolder">
				<span>+</span> Nouveau
			</button>
			<pop-up-create-drive-element
				:x="x"
				:y="y"
				@close="isPopUpVisible = !isPopUpVisible"
				@newFolder="isPopUpNewFolderVisible = !isPopUpNewFolderVisible"
				@getFiles="this.$refs.drive.getFiles()"
				v-if="isPopUpVisible"
			/>
		</div>

		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component
					ref="drive"
					class="main-component"
					:is="Component"
				></component>
			</transition>
		</router-view>
		<pop-up-new-folder
			v-if="isPopUpNewFolderVisible"
			@close="isPopUpNewFolderVisible = !isPopUpNewFolderVisible"
			@getFolders="this.$refs.drive.getFolders()"
		/>
	</section>
</template>

<script>
import { getFolderService } from '../../../services/foldersService'
import PopUpCreateDriveElement from '../../PopUp/Class/PopUpCreateDriveElement.vue'
import PopUpNewFolder from '../../PopUp/Class/PopUpNewFolder.vue'
export default {
	components: {
		PopUpCreateDriveElement,
		PopUpNewFolder
	},
	data() {
		return {
			isPopUpVisible: false,
			isPopUpNewFolderVisible: false,
			x: 0,
			y: 0,
			folderOrder: [],
			folder: {},
			loaded: false
		}
	},
	methods: {
		createFolder: async function () {
			this.isPopUpVisible = !this.isPopUpVisible
		},
		getParentFolder: async function (e) {
			let newFolder = await getFolderService(e)
			this.folderOrder.push(newFolder)

			if (newFolder.parent_folder_id !== null) {
				this.getParentFolder(newFolder.parent_folder_id)
			}
			else {
				this.folderOrder.reverse()
				this.loaded = true
			}
		},
		getFolder: async function () {
			this.folder = {}
			this.folderOrder = []
			if (this.$route.params.folderId) {
				this.folder = await getFolderService(this.$route.params.folderId)
				this.folderOrder.push(this.folder)
				if (this.folder.parent_folder_id !== null) {
					this.getParentFolder(this.folder.parent_folder_id)
				}
				else {
					this.loaded = true
				}
			}
		}
	},
	created() {
		this.getFolder()
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY
		})
	},
	watch: {
		$route() {
			this.loaded = false
			this.getFolder()
		}
	}
}
</script>

<style>
</style>
