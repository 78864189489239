<template>
	<section class="tile-game-home-container">
		<img
			:src="`/img/game/tiles/home-page/bg-${tileGame.theme}.svg`"
			alt=""
		/>
		<div class="btn-container">
			<router-link
				class="play-btn"
				:to="{ name: 'TileGame', params: { id: $route.params.id } }"
			>
				<p>Jouer</p></router-link
			>
		</div>
	</section>
</template>

<script>
import { getTileGameByIdService } from '../../../../../services/tileGamesService'
export default {
	data() {
		return {
			tileGame: {}
		}
	},
	methods: {
		async getTileGame() {
			this.tileGame = await getTileGameByIdService(this.$route.params.id)
		}
	},
	created() {
		this.getTileGame()
	},
}
</script>

<style>
</style>
