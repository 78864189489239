<template>
	<div class="fake-container">
		<div class="activite individuelle key">
			<div
				@click="showPopUpSlide = !showPopUpSlide"
				class="exo"
				ref="imgContainer"
			>
				<img :src="url + image1" alt="" />
			</div>
		</div>
		<div class="add-container">
			<div>
				<p>{{ title }}</p>
			</div>
			<router-link
				:to="{
					name: 'StudentPlaceDisplayActivity',
					params: { id: activityId },
				}"
				class="submit add"
			>
				<span>Voir</span>
			</router-link>
		</div>
	</div>
</template>

<script>
import Config from '../../config'
export default {
	props: {
		index: Number,
		title: String,
		description: String,
		chapter: String,
		image1: String,
		image2: String,
		image3: String,
		image4: String,
		image5: String,
		image6: String,
		image7: String,
		image8: String,
		image9: String,
		image10: String,
		guest_visible: Boolean,
		activityId: Number
	},
	data() {
		return {
			added: false,
			readyToDisplay: false,
			isPopUpVisible: false,
			showPopUpSlide: false,
			isVisibleGuest: false,
			url: `${Config.backend_url}/media/`
		}
	},
	methods: {

		popUp() {
			if (this.$store.state.user.role !== 'GUEST') {
				this.isPopUpVisible = !this.isPopUpVisible
			}
			else if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
			}
		},
		timeToCloseGuest() {
			this.isVisibleGuest = false
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.reRender()
	},
	mounted() {
		this.reRender()
		setTimeout(() => {
			this.readyToDisplay = true
		}, 1000)
	},
}
</script>

<style>
</style>
