<template>
	<section class="link-container">
		<button class="new-btn" @click="showPopUpNewLink">
			<span>+</span> Nouveau
		</button>
		<table>
			<tr>
				<th>Titre</th>
				<th>Date du cours</th>
				<th>
					Partager <br />
					à la classe
				</th>
				<th></th>
				<th></th>
			</tr>
			<tr v-for="link in links" :key="link.id">
				<td>
					<img class="favicon" :src="link.favicon" alt="" />
					{{ link.title }}
				</td>
				<td>{{ link.day }}</td>
				<td>
					<button
						class="share"
						:class="link.visible_for_student ? 'active' : ''"
						@click="setVisible(link.id, !link.visible_for_student)"
					>
						<img
							v-if="link.visible_for_student"
							src="/img/share.svg"
							alt=""
						/>
						<img v-else src="/img/share-line.svg" alt="" />
					</button>
				</td>
				<td>
					<a class="link" target="_blank" :href="link.link">
						<img src="/img/eyes.svg" alt="" />
					</a>
				</td>
				<td class="td-center">
					<img
						class="option"
						@click="showPopUpMenu(link.id)"
						src="/img/three-dots.png"
					/>
				</td>
			</tr>
		</table>

		<pop-up-menu
			v-if="isPopUpMenuVisible"
			:id="id"
			:x="x"
			:y="y"
			:type="'class-links'"
			@showPopUpDuplicate="showPopUpDuplicate"
			@showPopUpRemove="showPopUpRemove"
			@showPopUpEdit="showPopUpEdit"
			@close="showPopUpMenu"
		/>
		<pop-up-remove
			v-if="isPopUpRemoveVisible"
			@close="showPopUpRemove"
			:titre="'Êtes-vous sûr de vouloir supprimer cette ressource?'"
			:text="'La ressource sera supprimée de vos ressources enregistrées ou attribuées.'"
			:btn="'Supprimer'"
			:path="'class-links'"
			:id="id"
			:redirect="'Ressource'"
			@refreshActivities="getLinks"
			@remove="getLinks"
		/>
		<pop-up-new-class-link
			@close="showPopUpNewLink"
			@refreshLinks="getLinks"
			v-if="isPopUpNewLinkVisible"
		/>
		<pop-up-edit-class-link
			v-if="isPopUpEditVisible"
			@close="showPopUpEdit"
			:id="id"
			@refreshLinks="getLinks"
		/>
		<pop-up-duplicate-class-link
			v-if="isPopUpDuplicateVisible"
			@close="showPopUpDuplicate"
			:id="id"
			@refreshLinks="getLinks"
		/>
	</section>
</template>

<script>
import { allClassLinksForClassService, updateClassLinkService } from '../../../../services/classLinkService'
import PopUpDuplicateClassLink from '../../../PopUp/Class/PopUpDuplicateClassLink.vue'
import PopUpEditClassLink from '../../../PopUp/Class/PopUpEditClassLink.vue'
import PopUpNewClassLink from '../../../PopUp/Class/PopUpNewClassLink.vue'
import PopUpMenu from '../../../PopUp/PopUpMenu.vue'
import PopUpRemove from '../../../PopUp/PopUpRemove.vue'
import { allOccurencesForClassAndTypeService } from '../../../../services/occurrencesService'
export default {
	components: { PopUpNewClassLink, PopUpMenu, PopUpRemove, PopUpEditClassLink, PopUpDuplicateClassLink },
	data() {
		return {
			links: [],
			isPopUpNewLinkVisible: false,
			isPopUpMenuVisible: false,
			isPopUpRemoveVisible: false,
			isPopUpEditVisible: false,
			isPopUpDuplicateVisible: false,
			id: null,
			isMounted: false,
			x: 0,
			y: 0
		}
	},

	methods: {
		showPopUpNewLink() {
			this.isPopUpNewLinkVisible = !this.isPopUpNewLinkVisible
		},
		showPopUpMenu(id) {
			this.id = id
			this.isPopUpMenuVisible = !this.isPopUpMenuVisible
		},
		showPopUpRemove() {
			this.isPopUpRemoveVisible = !this.isPopUpRemoveVisible
			this.isPopUpMenuVisible = false
		},
		showPopUpEdit() {
			this.isPopUpEditVisible = !this.isPopUpEditVisible
			this.isPopUpMenuVisible = false
		},
		showPopUpDuplicate() {
			this.isPopUpDuplicateVisible = !this.isPopUpDuplicateVisible
			this.isPopUpMenuVisible = false
		},
		async setVisible(id, value) {
			const link = this.links.find(x => x.id == id)
			link.visible_for_student = value
			await updateClassLinkService(id, link)
		},
		async getLinks() {
			this.isPopUpRemoveVisible = false
			this.isPopUpEditVisible = false
			this.isPopUpMenuVisible = false
			this.isPopUpDuplicateVisible = false
			this.links = await allClassLinksForClassService(this.$route.params.id)
			this.getAgendas()
		},

		async getAgendas() {
			for (const link of this.links) {
				const res = await allOccurencesForClassAndTypeService(this.$route.params.id, 'class_link', link.id)

				if (res.length !== 0) {
					let date = new Date(res[0].day)
					link.day = date.toLocaleDateString('fr-fr', { weekday: "long", year: "numeric", month: "short", day: "numeric" })
				}
			}
		}
	},

	created() {
		window.addEventListener('mousemove', (e) => {
			this.x = e.clientX
			this.y = e.clientY
		})
		this.getLinks()
	},

	mounted() {
		this.isMounted = true
	},
	watch: {
		$route() {
			if (this.$route.name == "ClassLink" && this.isMounted) {
				this.getLinks()
			}
		}
	},
}
</script>

<style>
</style>
