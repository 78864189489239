import { authenticatedFetch } from '../utils'


export const generateLatexImg = async (latex) => {
    const blob = await authenticatedFetch(`/latex-to-png/`, { method: 'POST', body: JSON.stringify({ latex: latex }) })
        .then((response) => response.blob())
        .then((blob) => {
            return blob
        })
    return blob
}
