<template>
	<section class="dashboard">
		<h1>Tableau de bord</h1>
		<p class="welcome-back" v-if="this.$store.state.user.first_name">
			Bonjour {{ this.$store.state.user.first_name }}, content de vous
			revoir !
		</p>

		<div class="next-classes">
			<div class="next-classes-actu-wrapper">
				<div class="next-class-title-container">
					<h2>Vos prochains cours</h2>
					<div
						v-if="this.$store.state.classes"
						class="agendas-container"
					>
						<loader v-if="!agendasReady" />
						<next-classes
							v-else
							v-for="(agenda, index) in $store.state.nextCourses"
							:key="index"
							:agenda="agenda"
							:style="`--delay: ${index}`"
							class="loading-animation"
						/>
						<div
							v-if="
								$store.state.nextCourses.length == 0 &&
								agendasReady
							"
							class="no-course"
						>
							<p>
								Vous n'avez pas de cours à venir. <br />
								Créez une classe et complétez votre agenda.
							</p>
						</div>
					</div>
				</div>
				<div class="actu-title-container" v-if="checkIfMathTeacher()">
					<h2>Nouveautés</h2>
					<div class="actu-container">
						<loader v-if="!keysReady" />
						<actu
							v-for="(key, index) in keys"
							:key="key.id"
							:keyValue="key"
							class="loading-animation"
							:style="`--delay: ${index}`"
						/>
					</div>
				</div>
			</div>
		</div>
		<div class="widget-tools-container">
			<div class="widget-container">
				<my-link />
				<tool />
				<calculator v-if="!isLoading && checkUserSubject()"/>
			</div>
		</div>
		<kanban />
	</section>
</template>
<script>

import NextClasses from './NextClasses.vue'
import Kanban from './Kanban/Kanban.vue'
import Tool from './Tool/Tool.vue'
import MyLink from './Link/Link.vue'
import Calculator from './Calculator/Calculator.vue'
import Actu from './Actu.vue'
import Loader from '../../Loader.vue'
import { allActivitiesForTeacherService } from '../../../services/activitiesService'
import { allExerciseSheetsForTeacherService } from '../../../services/exerciseSheetsService'
import { getLastThreeKeyService } from '../../../services/keysService'

export default {
	components: {
		NextClasses,
		Kanban,
		Tool,
		MyLink,
		Calculator,
		Actu,
		Loader,
	},
	data() {
		return {
			userId: '',
			ressources: [],
			agendas: [],
			agendasReady: false,
			activities: [],
			evaluations: [],
			keys: [],
			keysReady: false,
			showPopUpMenu: false,
			showPopUpPreview: false,
			isVisibleRemove: false,
			isVisibleDuplicate: false,
			isVisibleEdit: false,
			isVisibleGuest: false,
			type: '',
			popUpContent: {
				name: '',
				day: '',
				datetimeStart: '',
				datetimeEnd: '',
				ressourceName: '',
			},
			popUpId: null,
			popUpName: null,
			selectedRessourceId: null,
			date: '',
			isLoading: false
		}
	},
	methods: {
		checkUserSubject(){
			const requiredSubjects = ['Maths', 'Physique-chimie']
			const userSubjects = this.$store.state.user.subjects
			const subjectsName = this.$store.state.subjects.filter(subject => userSubjects.includes(subject.id)).map(subject => subject.name)
			return requiredSubjects.some(subject => subjectsName.includes(subject))
		},
		checkIfMathTeacher(){
			if(!this.$store.state.subjects) return false
			const requiredSubjects = ['Maths']
			const userSubjects = this.$store.state.user.subjects
			const subjectsName = this.$store.state.subjects.filter(subject => userSubjects.includes(subject.id)).map(subject => subject.name)
			return requiredSubjects.some(subject => subjectsName.includes(subject))
		},
		getRessourceName(id, type) {
			type = 'activity'
			if (type == 'activity') {
				return this.activities.find(x => x.id == id).name
			}
			else {
				return this.evaluations.find(x => x.id == id).title
			}
		},
		changeColor(e) {
			if (e == "Activité rituelle") {
				return ('activites')
			}
			if (e == "Jeux sérieux") {
				return ('jeux')
			}
			else {
				return ('evaluations')
			}
		},
		add() {
			this.isVisibleGuest = true
		},
		timeToCloseGuest() {
			this.isVisibleGuest = false
		},
		allActivities: async function () {
			// GET request to display ALL user activities elements.
			this.activities = await allActivitiesForTeacherService(this.$store.state.user.user)
		},
		allExerciseSheets: async function () {
			// GET request to display ALL evaluations elements.

			this.evaluations = await allExerciseSheetsForTeacherService(this.$store.state.user.user)
			this.ressources = this.activities.concat(this.evaluations)

		},
		allAgendas: async function () {
			if (this.$store.state.nextCourses.length !== 0) {
				this.agendasReady = true

			}
			this.$store.dispatch('getNextCourses')
			this.agendasReady = true
		},
		getDate: function () {
			const today = new Date()
			const year = today.getFullYear()
			const month = ('0' + (today.getMonth() + 1)).slice(-2)
			const day = ('0' + today.getDate()).slice(-2)
			const date = year + '-' + month + '-' + day
			this.date = date
		},
		getKeys: async function () {
			this.keys = await getLastThreeKeyService()
			this.keysReady = true
		}

	},
	async created() {
		this.isLoading = true
		await this.allActivities()
		await this.allExerciseSheets()
		await this.allAgendas()
		this.getDate()
		await this.getKeys()
		this.isLoading = false
	},
}
</script>

<style>
</style>
