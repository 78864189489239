<template>
	<pop-up-wrapper>
		<div class="pop-up-share">
			<img class="close" @click="close" src="/img/close.svg" alt="" />
			<p class="title">
				Voici le lien de l'Espace Elève à partager à votre classe
			</p>
			<div class="copied-container">
				<input
					readonly="readonly"
					@click="copy"
					ref="link"
					:value="getUrl()"
				/>
				<p><span v-if="copied">Copié !</span></p>
			</div>
			<button @click="copy" class="submit dark">Copier</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	data() {
		return {
			copied: false,
			dontShow: false,
		}
	},
	methods: {
		getUrl() {
			return window.location.href
		},
		copy() {
			const url = this.$refs['link']
			url.select()
			document.execCommand("copy")
			this.copied = true
		},
		close() {
			this.$emit('close')
		}
	},
	created() {
		this.dontShow = this.$store.state.dontShowAgainShare
	},
}
</script>

<style>
</style>
