<template>
	<section class="student-container">
		<section class="side-bar">
			<h1>Liste de élèves</h1>
			<p class="display-student-number">
				Nombre d'élèves: {{ students.length }}
			</p>
			<img src="/img/drag-and-drop.png" alt="" />
			<input
				class="add-student"
				@keyup.enter="createNewStudent(newStudent)"
				type="text"
				v-model="newStudent"
				placeholder="Ajouter un élève"
			/>
			<p class="or">ou</p>

			<div class="input-file-container">
				<input
					type="file"
					accept=".csv"
					@change="handleFileUpload($event)"
					name="file"
					id="file"
					class="inputfile"
					ref="fileInput"
				/>
				<label for="file">
					<img src="/img/upload.svg" alt="" /> Importer un
					fichier</label
				>
			</div>
			<div
				@drop="onDrop($event, 500)"
				@dragover.prevent
				@dragenter.prevent
				class="student-wrapper"
			>
				<div
					draggable="true"
					@dragstart="startDrag($event, student.id)"
					v-for="student in students"
					:key="student"
				>
					<student-list
						:class="student.pos !== 500 ? 'placed' : ''"
						@allStudent="allStudent"
						:student="student"
						:placed="student.pos !== 500"
					/>
				</div>
				<button
					v-if="students.length !== 0"
					@click="removeStudents"
					class="submit remove-students"
				>
					<img src="/img/class/trash.svg" alt="" /> Supprimer les
					élèves
				</button>
			</div>
		</section>
		<section
			v-if="students.length !== 0 && grid.length !== 0"
			id="grid"
			class="grid"
		>
			<div class="grid-title-container">
				<h2>
					Plan de classe
					<button class="trash-btn" @click="resetList">
						<img src="/img/class/trash.svg" alt="" />
					</button>
				</h2>

				<option-container />
			</div>
			<div class="grid-container">
				<div
					v-for="tile in grid"
					:key="tile"
					@dragover.prevent
					@dragenter.prevent
					draggable="true"
					@dragstart="startDrag($event, tile.studentId)"
					@drop="onDrop($event, tile.id)"
					class="tile"
					:class="tile.studentName !== null ? 'someone' : 'nobody'"
				>
					<student-place
						:class="isDragging ? 'active' : ''"
						:tile="tile"
					/>
				</div>
			</div>
		</section>
		<full-page-loader v-if="isLoading" />
		<pop-up-validate-class
			v-if="popUpValidateClass"
			@close="popUpValidateClass = !popUpValidateClass"
		/>
	</section>
</template>

<script>

import Papa from 'papaparse'
import StudentList from './Student/StudentList.vue'
import StudentPlace from './Student/StudentPlace.vue'
import PopUpValidateClass from '../../PopUp/Class/PopUpValidateClass.vue'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
import OptionContainer from './Student/OptionContainer.vue'
import { allStudentsInClasseService, createStudentService, deleteStudentService, updateStudentService } from '../../../services/studentsService'
export default {
	components: { StudentList, StudentPlace, PopUpValidateClass, FullPageLoader, OptionContainer },
	data() {
		return {
			gridX: 8,
			gridY: 6,
			grid: [],
			students: [],
			newStudent: '',
			file: '',
			content: [],
			parsed: false,
			student: {},
			popUpValidateClass: false,
			isDragging: false,
			isLoading: false
		}
	},
	methods: {
		async removeStudents() {
			if (confirm("Êtes-vous sûr de vouloir supprimer votre liste d'élèves ?")) {
				this.isLoading = true
				for (const student of this.students) {
					await deleteStudentService(student.id)
					this.students = this.students.filter(x => x.id !== student.id)
				}
				this.generateGrid()
				this.isLoading = false
			}
		},

		handleFileUpload(event) {
			this.file = event.target.files[0]
			this.parseFile()
		},
		parseFile() {
			Papa.parse(this.file, {
				complete: function (results) {
					this.content = results
					this.parsed = true
					this.cleanArray()
				}.bind(this)
			})
		},

		async cleanArray() {
			this.isLoading = true
			for await (const data of this.content.data) {
				if (data[0]) {
					const alreadyRegistered = this.students.some(x => x.name == data[0])
					if (!alreadyRegistered) {
						await this.createNewStudent(data[0])
					}
				}
			}
			this.$refs['fileInput'].value = ''
			this.isLoading = false
		},
		async resetList() {
			const answer = window.confirm('Voulez-vous vraiment réinitialiser le plan de classe actuel ?')
			if (answer) {
				this.generateGrid()
				this.students.forEach(async (element) => {
					element.pos = 500
					await updateStudentService(element.id, element)
				})
			}
		},
		async createNewStudent(newStudent = this.newStudent) {
			if (newStudent !== '') {
				const data = {
					name: newStudent,
					class_id: this.$route.params.id,
					pos: 500,
				}
				const res = await createStudentService(data)
				this.students.push(res)
				this.newStudent = ''
			}
		},



		allStudent: async function () {
			this.students = await allStudentsInClasseService(this.$route.params.id)
			this.setStudentPosition()
		},

		setStudentPosition() {
			this.students.forEach(element => {
				if (element.pos !== 500) {
					this.grid[element.pos] = {
						id: this.grid[element.pos].id,
						studentName: element.name,
						studentId: element.id
					}
				}
			})
		},
		generateGrid() {
			this.grid = []
			for (let x = 0; x < this.gridY * this.gridX; x++) {
				this.grid.push({ id: x, studentName: null, studentId: null })
			}
		},
		startDrag(evt, item) {
			evt.dataTransfer.dropEffect = 'move'
			evt.dataTransfer.effectAllowed = 'move'
			evt.dataTransfer.setData('itemID', item)
			this.isDragging = true
		},
		async onDrop(evt, id) {
			this.isDragging = false

			const studentID = evt.dataTransfer.getData('itemID')
			const student = this.students.find(student => student.id == studentID)

			let oldTile = this.grid.find(x => x.studentId == studentID)
			let tile = this.grid.find(x => x.id == id)
			let oldStudent
			if (tile) {
				// Inversion de place 
				if (tile.studentId !== null) {
					oldStudent = this.students.find(student => student.id == tile.studentId)
					tile.studentName = student.name
					tile.studentId = student.id
					oldTile.studentName = oldStudent.name
					oldTile.studentId = oldStudent.id
					oldStudent.pos = oldTile.id
				}
				// Si il n y a pas de student au nouvel emplacement
				else if (oldTile) {
					oldTile.studentName = null
					oldTile.studentId = null
				}
				tile.studentName = student.name
				tile.studentId = student.id
			}
			else {
				oldTile.studentName = null
				oldTile.studentId = null
			}
			student.pos = id

			await updateStudentService(student.id, student)
			if (oldStudent) {
				await updateStudentService(oldStudent.id, oldStudent)
			}
		},
	},


	computed: {
		notPlacedStudent() {
			return this.students.filter(x => x.pos == 500)
		}
	},

	created() {
		this.generateGrid()
		this.allStudent()
	},
}
</script>

<style lang="scss">
</style>
