<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Enregistrer votre activité</p>
			<p>
				Pour enregistrer votre activité, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container">
				<label for="classes">Classe</label>
				<select id="classes" v-model="form.classes">
					<option
						selected="selected"
						v-if="exercise.classes"
						:value="exercise.classes"
					>
						{{ getClasse(exercise.classes) }}
					</option>
					<option
						v-for="classe in $store.state.classes"
						:key="classe.id"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
				<small class="error">{{ errors.classe }}</small>
			</div>

			<button @click="submit" class="submit dark">Attribuer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { createChristmasCalendarExerciseOrderService } from '../../../../services/christmasCalendarExerciseOrdersService'
import { createChristmasCalendarService } from '../../../../services/christmasCalendarsService'
import PopUpWrapper from '../../PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		exoInCalendar: Array,
		level_id: Number
	},
	data() {
		return {
			form: {
				classes: [],
			},
			key: {},
			index: 0,
			exercise: {},
			classes: [],
			questionsList: [],
			rituals: [],
			courses: [],
			agenda: {},
			errors: {}
		}
	},

	methods: {

		close() {
			this.$emit('close')
		},
		async submit() {
			
			if (this.form.classes.length !== 0) {
				let classArray = this.form.classes
				const data = {
					level_id: this.level_id,
					class_id: classArray,
					teacher: this.$store.state.user.user,
					template: this.$route.params.template,
				}
				const res = await createChristmasCalendarService(data)

				for await (const exo of this.exoInCalendar) {
					const data = {
						christmas_calendar_id: res.id,
						christmas_calendar_exercise_id: exo.id,
						order: exo.list - 8
					}
					await createChristmasCalendarExerciseOrderService(data)
				}
				this.$router.push({ name: 'ClassResources', params: { id: classArray } })
			}
			else {
				this.$store.state.toast = {
					status: 'error',
					text: 'Veuillez ajouter une classe à votre ressource'
				}
			}

		},


		getClasse: function (e) {
			if (this.classes.length !== 0) {
				let classe = this.classes.find(x => x.id == e)
				return classe.name
			}
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},

}
</script>

<style>
</style>
