<template>
	<div class="hour-container">
		<div v-for="i in 13" :key="i" class="hour">{{ i + 6 }}:00</div>
	</div>
</template>

<script>
export default {

}
</script>

<style>
</style>
