<template>
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component :is="Component" />
		</transition>
	</router-view>
</template>

<script>
export default {
}
</script>

<style>
</style>
