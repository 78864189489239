<template>
	<pop-up-wrapper>
		<div class="fake-pop-up" @click="this.$emit('close')"></div>
		<div class="pop-up pop-up-profile">
			<a
				@click="this.$emit('close')"
				class="go-plateform"
				:href="landingUrl"
			>
				Accès à l'accueil</a
			>

			<hr />
			<router-link @click="this.$emit('close')" :to="{ name: 'Profile' }"
				>Mon profil</router-link
			>
			<router-link
				@click="this.$emit('close')"
				:to="{ name: 'Abonnement' }"
				>Mon Abonnement</router-link
			>
			<router-link
				v-if="
					this.$store.state.user.role == 'ADMIN' ||
					this.$store.state.user.role == 'SUPER_ADMIN'
				"
				:to="{ name: 'SuperAdminDashboard' }"
				>Espace admin</router-link
			>
			<button @click="logout">Se déconnecter</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../config'
import PopUpWrapper from './PopUpWrapper.vue'
export default {
	components: {
		PopUpWrapper
	},
	data() {
		return {
			url: `${Config.platform_url}/#/platform/?token=${this.$store.state.token}`,
			landingUrl: Config.landing_url
		}
	},
	methods: {
		logout() {
			this.$store.dispatch('logout')
			location.replace(`${Config.landing_url}/auth/reset-token`)
		},
	},
}
</script>

<style>
</style>
