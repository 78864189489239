<template>
	<pop-up-wrapper>
		<div class="pop-up-new-widget">
			<div class="wrapper">
				<p class="title">Ajouter une calculatrice</p>
				<div class="tools-container">
					<label
						:class="
							checkIfAlreadyExist('Numworks')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator"
					>
						<input v-model="tool" type="radio" value="Numworks" />
						<span></span>
						<img src="/img/tools/calculators/numworks.png" alt="" />
						<p class="tool-name">Numworks</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('Casio 35+ E')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator not-clickable"
					>
						<input
							v-model="tool"
							type="radio"
							value="Casio 35+ E"
						/>
						<span></span>
						<img src="/img/tools/calculators/casio-35.png" alt="" />
						<p class="tool-name">Casio 35+ E</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('TI-83 CE')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator"
					>
						<input v-model="tool" type="radio" value="TI-83 CE" />
						<span></span>
						<img src="/img/tools/calculators/ti-83.png" alt="" />
						<p class="tool-name">TI-83 CE</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('TI-Nspire')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator not-clickable"
					>
						<input v-model="tool" type="radio" value="TI-Nspire" />
						<span></span>
						<img
							src="/img/tools/calculators/ti-inspire.png"
							alt=""
						/>
						<p class="tool-name">TI-Nspire</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('TI-Collège Plus')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator not-clickable"
					>
						<input
							v-model="tool"
							type="radio"
							value="TI-Collège Plus"
						/>
						<span></span>
						<img
							src="/img/tools/calculators/ti-college.png"
							alt=""
						/>
						<p class="tool-name">TI-Collège Plus</p>
					</label>
					<label
						:class="
							checkIfAlreadyExist('Casio FX-92')
								? 'not-clickable'
								: ''
						"
						class="tools-wrapper calculator not-clickable"
					>
						<input
							v-model="tool"
							type="radio"
							value="Casio FX-92"
						/>
						<span></span>
						<img src="/img/tools/calculators/casio-fx.png" alt="" />
						<p class="tool-name">Casio FX-92</p>
					</label>
				</div>
				<div class="btn-container">
					<button @click="this.$emit('close')" class="cancel">
						Annuler
					</button>
					<button
						@click="submit"
						:class="tool ? 'active' : ''"
						class="add"
					>
						Valider
					</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { allWidgetsFilterService, createWidgetService } from '../../../services/widgetsService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	data() {
		return {
			tool: null,
			tools: []
		}
	},

	methods: {
		checkIfAlreadyExist(name) {
			let filteredTools = this.tools.filter(x => x.widget_name == name)
			if (filteredTools.length > 0) {
				return true
			}
			else {
				return false
			}
		},
		async submit() {
			const data = {
				teacher_id: this.$store.state.user.user,
				widget_name: this.tool,
				widget_type: 'calculator',
			}
			await createWidgetService(data)
				.then(() => {
					this.$emit('refreshTool')
					this.$emit('close')
				})
		},
		async getTools() {
			const filter = `?teacher=${this.$store.state.user.user}&type=calculator`
			this.tools = await allWidgetsFilterService(filter)
		}
	},

	created() {
		this.getTools()
	},
}
</script>

<style>
</style>
