<template>
	<div :class="[isAdded ? 'active' : '',question.disabled ? 'content-disabled' : '' ]" class="fake-container">
		<div @click="checkType" class="activite individuelle" >
			<div class="exo" ref="imgContainer">
				<p class="custom-id">{{ customId() }}</p>
				<p v-if="question.random_values !== null" :class="readyToDisplay ? 'visible' : ''"
					:key="question.description" class="calcul" ref="question" v-html="image(
						random(
							question.description,
							copiedQuestion.random_values
						),
						imageData
					)
						" />
				<p v-else :class="readyToDisplay ? 'visible' : ''" class="calcul" ref="question"
					v-html="image(question.description, imageData)" />

				<div v-if="question.difficulty" :title="question.difficulty_name" class="difficulty-indicator">
					<div :style="color(question.difficulty_name)" class="indicator">
					</div>
				</div>
			</div>
		</div>
		<div class="add-container question">
			<div>
				<p>{{ question.title }}</p>
				<p :style="getType(question.type_name)">
					{{ question.type_name }}
				</p>
			</div>
			<button v-if="question.disabled" class="submit add">
				<span>+ Ajouter</span>
			</button>
			<button v-else-if="question.random_values == null" @click="add" class="submit add">
				<span v-if="isAdded"> - Supprimer</span>
				<span v-else> + Ajouter</span>
			</button>
			<button v-else class="submit add">
				<span @click="addRandom" v-if="amount == 0">+ Ajouter</span>
				<span class="amount-container" v-else>
					<span @click="removeRandom">-</span>
					<span>{{ amount }}</span>
					<span @click="addRandom">+</span>
				</span>
			</button>
		</div>
	</div>
	<pop-up-preview-question @close="isVisibleQuestionPreview = !isVisibleQuestionPreview"
		v-if="isVisibleQuestionPreview" :question="question" />
	<pop-up-preview-exercise @close="isVisibleExercisePreview = !isVisibleExercisePreview"
		v-if="isVisibleExercisePreview" :id="question.id" />
</template>

<script>
import { authenticatedImageFetch, random, image } from '../../../../utils'
import PopUpPreviewExercise from '../../../PopUp/PopUpPreviewExercise.vue'
import PopUpPreviewQuestion from '../../../PopUp/PopUpPreviewQuestion.vue'

export default {
	components: {
		PopUpPreviewQuestion,
		PopUpPreviewExercise,
	},
	props: {
		added: Boolean,
		question: Object,
		amount: {
			type: Number,
			default: 0
		},
		type: {
			type: String,
			default: 'question'
		}
	},
	emits: ['addQuestion', 'addQuestionRandom', 'removeQuestionRandom'],
	data() {
		return {
			copiedQuestion: {},
			isAdded: false,
			readyToDisplay: false,
			isVisibleGuest: false,
			isVisibleQuestionPreview: false,
			isVisibleExercisePreview: false,
			imageData: '',
			copiedAmount: 0
		}
	},
	methods: {
		add() {
			if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
				return false
			}
			this.isAdded = !this.isAdded
			this.$emit('addQuestion', this.question.id)

		},
		newRandom() {
			let regex = /\\randint{(.*?)}/g
			let regex2 = /\\randfloat{(.*?)}/g

			// find regex expression
			let foundedInt = [...this.copiedQuestion.description.matchAll(regex)]
			let foundedFloat = [...this.copiedQuestion.description.matchAll(regex2)]
			let founded = [...foundedInt, ...foundedFloat]

			// re order value
			founded = founded.sort(function (a, b) {
				return a.index - b.index
			})

			let random_values = []
			for (const found of founded) {

				// calculate new random value
				let value
				let arr

				if (found[0].match('randint')) {
					// remove randint{ and last } 
					let sliced = found[0].slice(9).slice(0, -1)

					// split string -> aa, valueA, valueB
					arr = sliced.split(/[;\s]/)

					value = Math.floor(Math.random() * (parseInt(arr[2]) - parseInt(arr[1]) + 1) + parseInt(arr[1]))
				}
				else {
					// remove randint{ and last } 
					let sliced = found[0].slice(11).slice(0, -1)

					// split string -> aa, valueA, valueB
					arr = sliced.split(/[;\s]/)

					value = (Math.random() * (parseInt(arr[2]) - parseInt(arr[1]) + 1) + parseInt(arr[1])).toFixed(parseInt(arr[3]))
					value = value.replace(".", ",")
				}




				// push it in array
				random_values.push([arr[0], value])
			}

			// change random value 
			for (const value of random_values) {
				this.copiedQuestion.random_values[value[0]] = value[1]
			}
			setTimeout(() => {
				this.reRender()
			})
		},
		addRandom() {
			if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
				return false
			}
			this.isAdded = true
			this.copiedAmount++

			this.newRandom()
			this.$emit('addQuestionRandom', this.question)
		},
		removeRandom() {
			if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = true
				return false
			}
			this.copiedAmount--
			if (this.copiedAmount == 0) {
				this.isAdded = false
			}

			this.newRandom()

			this.$emit('removeQuestionRandom', this.question)
		},

		image: image,
		random: random,
		getInsideImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						this.imageData = e.target.result
					}
					reader.readAsDataURL(blob)
				})
		},
		checkType() {
			if(this.question.disabled){
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			if (this.type == 'question') {
				this.isVisibleQuestionPreview = !this.isVisibleQuestionPreview
			}
			else {
				this.isVisibleExercisePreview = !this.isVisibleExercisePreview
			}
		},
		customId() {
			
			const difficulty = this.question.difficulty_name ? this.question.difficulty_name : 'Facile'
			let id = this.question.level_name.charAt(0) + this.question.sequence_name.charAt(0) + this.question.chapter_name.charAt(0) + difficulty.charAt(0) + this.question.id
			return id
		},
		getType(e) {
			let color
			switch (e) {
				case 'Fait numérique':
					color = '#FFCE00'
					break
				case 'Question de cours':
					color = '#FF4646'
					break
				case 'Calcul réfléchi':
					color = '#02FF63'
					break
				case 'Problème ouvert':
					color = '#53d6ff'
					break
				case 'Avec calculatrice':
					color = '#EEAFFC'
					break

			}
			return `color:${color}`
		},
		color(e) {
			let color
			let width
			switch (e) {
				case 'Facile':
					color = '#09E300'
					width = '33%'
					break
				case 'Moyen':
					color = '#FF9300'
					width = '66%'
					break
				case 'Difficile':
					color = '#FF0000'
					width = '100%'
					break
				default:
					break
			}
			return `background-color: ${color};width: ${width};`
		},
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs['imgContainer'].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},

		timeToCloseGuest() {
			this.isVisibleGuest = false
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.copiedQuestion = { ...this.question }
		this.copiedQuestion.randomValuesAmount = (this.copiedQuestion.description.match(/randint/g) || []).length + (this.copiedQuestion.description.match(/randfloat/g) || []).length
		if (this.question.image_url_1 !== null) {
			this.getImage(this.question.image_url_1)
		}
		if (this.question.image_url_2 !== null) {
			this.getInsideImage(this.question.image_url_2)
		}
		this.copiedAmount = this.amount

	},
	async mounted() {
		this.isAdded = this.added

		await this.reRender()
		let question = this.$refs['question']
		question.style.marginTop = `-${this.copiedQuestion.randomValuesAmount * 2.5}rem`
	},
	watch: {
		added() {
			this.isAdded = this.added
		}
	},
}
</script>

<style></style>
