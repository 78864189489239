import { authenticatedApiFetch } from '../utils'




export const getSubscriptionResourceLevelsByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/subscription-resource-levels/${id}/`, { method: 'GET' })
        .then(response => response.json())

        .catch(error => console.error(error))
    return res
}

export const cancelSubscriptionResourceLevelService = async (id, data) => {
    const res = await authenticatedApiFetch(`/subscription-resource-levels/${id}/cancelSubscription/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())

        .catch(error => console.error(error))
    return res
}

