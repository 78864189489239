<template>
	<div class="option-container">
		<button
			class="print-btn"
			@click="isPopUpPrintVisible = !isPopUpPrintVisible"
		>
			<img src="/img/class/print.svg" alt="" />
		</button>
		<router-link
			:to="{
				name: 'ClassStudent',
				params: { id: $route.params.id },
			}"
		>
			<img src="/img/class/generate.svg" alt="" />
		</router-link>
		<router-link
			:to="{
				name: 'ClassObservation',
				params: { id: $route.params.id },
			}"
		>
			<img src="/img/class/line.svg" alt="" />
		</router-link>

		<Teleport to="body">
			<pop-up-print
				v-if="isPopUpPrintVisible"
				@close="isPopUpPrintVisible = !isPopUpPrintVisible"
		/></Teleport>
	</div>
</template>

<script>
import PopUpPrint from '../../../PopUp/Class/PopUpPrint.vue'
export default {
	components: { PopUpPrint },
	data() {
		return {
			isPopUpPrintVisible: false,
		}
	},
}
</script>

<style>
</style>
