
<template>
	<div class="slide-container">
		<title-container :title="form.title" />
		<div class="form-container">
			<div class="title-img-container visualizer">
				<div v-if="!slide" class="img-container">
					<p>Ajouter des images</p>
					<div class="img-wrapper">
						<div class="generated-img-container">
							<div
								class="img-delete-wrapper"
								v-for="(image, index) in form.images"
								:key="image"
							>
								<img
									class="uploaded-img"
									:src="image.img"
									alt="Uploaded Image"
								/>
								<div class="delete" @click="removeImg(index)">
									<img src="/img/trash.png" alt="" />
								</div>
							</div>
						</div>
					</div>
				</div>
				<div v-else class="img-container visualizer">
					<div class="img-wrapper">
						<div
							class="generated-img-container preview visualizer"
							:ref="`imgContainer${index}`"
						></div>
					</div>
				</div>
			</div>
			<div class="text-designer-container visualizer">
				<div class="input-container">
					<p class="title">Texte</p>
					<p
						v-if="form.text"
						class="text"
						v-html="formattedText(form.text)"
					></p>
				</div>
				<div class="input-container">
					<p class="title">Notes pour le designer</p>
					<p
						v-if="form.designer_note"
						class="designer-note"
						v-html="formattedText(form.designer_note)"
					></p>
				</div>
				<div class="input-container">
					<p class="title">Notes pour l'enseignant</p>
					<p
						v-if="form.teacher_note"
						class="teacher-note"
						v-html="formattedText(form.teacher_note)"
					></p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
import { authenticatedImageFetch } from '../../../utils'
import TitleContainer from './TitleContainer.vue'
export default {
	components: {
		TitleContainer,
	},
	props: {
		index: {
			type: Number,
			required: true
		},
		slide: {
			type: Object,
		}
	},
	data() {
		return {
			form: {
				title: '',
				text: '',
				designer_note: '',
				images: [

				]
			},

		}
	},
	methods: {
		formattedText(text) {
			const formattedText = text.replace(/\n/g, '<br>')
			return formattedText
		},
		removeImg(index) {
			this.form.images.splice(index, 1)
		},
		handleImageUpload(event) {
			const file = event.target.files[0]
			if (file) {
				this.form.images.push(
					{
						file: event.target.files[0],
						img: URL.createObjectURL(file)
					}
				)
			}
		},
		async generateImg(imgUrl, ref) {
			await authenticatedImageFetch(imgUrl, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						const img = new Image()
						img.src = e.target.result
						img.className = 'uploaded-img'
						ref.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
	},

	mounted() {
		if (this.slide) {
			this.form = this.slide

			for (let i = 1; i < 4; i++) {
				if (this.slide[`image_url_${i}`]) {
					this.generateImg(this.slide[`image_url_${i}`], this.$refs[`imgContainer${this.index}`])
				}
			}


		}
	},
}
</script>

<style>
</style>
