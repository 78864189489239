<template>
	<nav class="paginate">
		<ul class="pagination">
			<li class="pagination-item">
				<button
					type="button"
					@click="onClickFirstPage"
					:disabled="isInFirstPage"
					aria-label="Go to first page"
				>
					Début
				</button>
			</li>

			<li class="pagination-item">
				<button
					type="button"
					@click="onClickPreviousPage"
					:disabled="isInFirstPage"
					aria-label="Go to previous page"
				>
					Précédent
				</button>
			</li>

			<li v-for="page in pages" :key="page" class="pagination-item">
				<button
					type="button"
					@click="onClickPage(page.id)"
					:disabled="page.isDisabled"
					:class="{ active: isPageActive(page.id) }"
					:aria-label="`Go to page number ${page.id}`"
				>
					{{ page.id }}
				</button>
			</li>

			<li class="pagination-item">
				<button
					type="button"
					@click="onClickNextPage"
					:disabled="isInLastPage"
					aria-label="Go to next page"
				>
					Suivant
				</button>
			</li>

			<li class="pagination-item">
				<button
					type="button"
					@click="onClickLastPage"
					:disabled="isInLastPage"
					aria-label="Go to last page"
				>
					Fin
				</button>
			</li>
		</ul>
	</nav>
</template>

<script>
export default {
	props: {
		totalPages: Number,
		perPage: Number,
		currentPage: Number,
		maxVisibleButtons: {
			type: Number,
			required: false,
			default: 3
		},
	},
	computed: {
		startPage() {
			if (this.currentPage === 0) {
				return 0
			}

			if (this.currentPage === this.totalPages) {
				return this.totalPages - this.maxVisibleButtons + 1
			}

			return this.currentPage - 1

		},
		endPage() {

			return Math.min(this.startPage + this.maxVisibleButtons - 1, this.totalPages - 1)

		},
		pages() {
			const range = []

			for (let i = this.startPage; i <= this.endPage; i += 1) {
				range.push({
					id: i,
					isDisabled: i === this.currentPage
				})
			}

			return range
		},
		isInFirstPage() {
			return this.currentPage === 0
		},
		isInLastPage() {
			return this.currentPage === this.totalPages - 1
		},
	},
	methods: {
		reset() {

		},
		onClickFirstPage() {
			this.$emit('pageChanged', 0)
		},
		onClickPreviousPage() {
			this.$emit('pageChanged', this.currentPage - 1)
		},
		onClickPage(page) {
			this.$emit('pageChanged', page)
		},
		onClickNextPage() {
			this.$emit('pageChanged', this.currentPage + 1)
		},
		onClickLastPage() {
			this.$emit('pageChanged', this.totalPages - 1)
		},
		isPageActive(page) {
			return this.currentPage === page
		},
	}
}
</script>

<style>
</style>
