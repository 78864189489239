<template>
	<view-proposed-key />
</template>

<script>
import ViewProposedKey from '../../../../SuperAdmin/Key/ViewProposedKey.vue'
export default {
	components: { ViewProposedKey },
}
</script>

<style>
</style>
