<template>
	<h1>Modifier une question pour fiche d'exercice</h1>
	<section class="form-container-latex">
		<form
			@submit.prevent="submit"
			class="form-container"
			enctype="multipart/form-data"
		>
			<div class="input-container">
				<label for="Title">Title</label>
				<input
					type="text"
					class="form-control"
					v-model="form.title"
					id="Title"
					placeholder="Title"
				/>
			</div>
			<div class="input-container">
				<label for="Description">Texte 1</label>
				<textarea
					type="text"
					rows="10"
					class="form-control md-editor"
					v-model="latex"
					id="Description"
					placeholder="Description"
				>
				</textarea>
			</div>
			<div class="input-container">
				<label for="Description">Correction</label>
				<textarea
					type="text"
					rows="10"
					class="form-control md-editor"
					v-model="latex2"
					id="Description"
					placeholder="Correction"
				>
				</textarea>
			</div>
			<div class="input-container">
				<label for="Image">Image</label>
				<input
					type="file"
					v-on:change="changeImg"
					class="form-control"
					id="Image"
					placeholder="Image"
				/>
			</div>
			<div class="input-container">
				<label for="niveaux">Niveaux</label>
				<select id="niveaux" v-model="selectedLevel">
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="chapter">Chapter</label>
				<select id="chapter" v-model="form.chapter">
					<option
						v-for="chapter in getChapter()"
						:key="chapter"
						:value="chapter.id"
					>
						{{ chapter.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="type">Type</label>
				<select id="type" v-model="form.type">
					<option
						v-for="type in this.$store.state.exerciseQuestionTypes"
						:key="type"
						:value="type.id"
					>
						{{ type.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Theme</label>
				<select id="theme" v-model="form.theme">
					<option
						v-for="theme in this.$store.state
							.exerciseQuestionThemes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Difficulty</label>
				<select id="theme" v-model="form.difficulty">
					<option
						v-for="difficulty in this.$store.state.difficulties"
						:key="difficulty"
						:value="difficulty.id"
					>
						{{ difficulty.name }}
					</option>
				</select>
			</div>
			<p v-if="status !== ''" class="status">{{ status }}</p>
			<p class="errors">{{ errors }}</p>
			<input class="submit dark" type="submit" value="Valider" />
		</form>
		<div ref="imgContainer" class="latex-container">
			<p :key="latex">{{ random(latex) }}</p>
			<img :src="url" alt="" />
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

import { authenticatedImageFetch } from '../../utils'
export default {
	data() {
		return {
			latex: '',
			latex2: '',
			form: {
				title: '',
				description: '',
				correction: '',
				random_values: '',
				image_url_1: '',
				chapter: '',
				type: '',
				theme: '',
				difficulty: '',
			},
			errors: '',
			status: '',
			selectedLevel: null
		}
	},
	methods: {
		random(str) {
			var rand = []
			if (str.match(/\\rand{(.*?)}/g)) {
				rand.push(str.match(/\\rand{(.*?)}/g))
			}
			if (str.match(/\\float{(.*?)}/g)) {
				rand.push(str.match(/\\float{(.*?)}/g))
			}
			if (rand === null) {
				return str
			}
			let random_values = JSON.parse(JSON.stringify(this.form.random_values))
			rand.flat().map((key, value) => {
				str = str.replace(key, random_values[value])
			})
			return str
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},
		getImage(file, ref) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[ref].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		submit() {
			authenticatedApiFetch(`/exercise-questions/${this.$route.params.id}/`, {
				method: 'PUT',
				body: this.getFormDataValue(),
				headers: {}
			})
				.then(() => {
					this.$router.push({ name: 'SuperAdminAllExerciseSheetQuestions' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.selectedLevel == null) {
					return this.$store.state.chapters
				}
			}
			else {
				return ''
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', this.latex)
			data.append('correction', this.latex2)
			if (typeof this.form.image_url_1 !== 'string' && this.form.image_url_1 !== null) {
				data.append('image_url_1', this.form.image_url_1)
			}
			data.append('chapter', this.form.chapter)
			data.append('level', this.selectedLevel)
			data.append('type', this.form.type)
			data.append('theme', this.form.theme)
			data.append('difficulty', this.form.difficulty)
			return data
		},
		getLevel(e) {
			let chapter = this.$store.state.chapters.find(x => x.id == e).sequence
			let sequence = this.$store.state.sequences.find(x => x.id == chapter).level
			let level = this.$store.state.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg(e) {
			this.form.image = e.target.files[0]
		},
		getQuestion() {
			authenticatedApiFetch(`/exercise-questions/${this.$route.params.id}/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.form = res
					this.selectedLevel = res.level
					this.latex = res.description
					this.latex2 = res.correction
					if (this.form.image !== null) {
						this.getImage(this.form.image, 'imgContainer')
					}
				})
		},
	},
	created() {
		this.getQuestion()
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		latex2: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
	}
}
</script>

<style>
</style>
