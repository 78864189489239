<template>
	<pop-up-wrapper>
		<div class="pop-up tile-pop-up create-team">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Total des points</p>

			<div class="teams-container">
				<div class="team" v-for="team in teams" :key="team">
					<div class="indicator-wrapper">
						<span
							class="indicator"
							:style="`--color:${team.color}`"
						></span>
						<p>{{ team.name }}</p>
					</div>
					<input
						@change="updateScore(team.id, $event)"
						:value="team.points"
						type="number"
						min="0"
					/>
				</div>
			</div>
			<div class="btn-container">
				<button @click="submit" class="validate">Valider</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { deleteTileGameContentOrderService } from '../../../../services/tileGameContentsOrderService'
import { allTileGameTeamsFilterService, updateTileGameTeamService } from '../../../../services/tileGameTeamsService'
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		tileGameId: Number
	},
	data() {
		return {
			teams: [],
		}
	},

	methods: {
		async getTeams() {
			const filter = `?tile_game_id=${this.tileGameId}`
			this.teams = await allTileGameTeamsFilterService(filter)
		},
		updateScore(id, event) {
			let team = this.teams.find(x => x.id == id)
			team.points = parseInt(event.target.value)
		},
		async submit() {
			for (const team of this.teams) {
				await updateTileGameTeamService(team.id, team)
			}
			await deleteTileGameContentOrderService(this.$route.params.id)
			this.$router.go(-1)
		}
	},

	mounted() {
		this.getTeams()
	},


}
</script>

<style>
</style>
