<template>
	<h1>Modifier clés en main</h1>
	<form
		@submit.prevent="submit"
		class="form-container"
		enctype="multipart/form-data"
	>
		<div class="input-container">
			<label for="Title">Titre</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div class="input-container">
			<label for="Description">Description</label>
			<textarea
				type="text"
				class="form-control md-editor"
				v-model="latex"
				id="Description"
				placeholder="Description"
			>
			</textarea>
			<div :key="latex">{{ latex }}</div>
		</div>
		<div class="input-container">
			<label for="Image">Image1</label>
			<div class="edit-question-img" ref="imgContainer"></div>
			<input
				type="file"
				v-on:change="changeImg1"
				class="form-control"
				id="Image1"
				placeholder="Image1"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image2</label>
			<div class="edit-question-img" ref="imgContainer2"></div>
			<input
				type="file"
				v-on:change="changeImg2"
				class="form-control"
				id="Image2"
				placeholder="Image2"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image3</label>
			<div class="edit-question-img" ref="imgContainer3"></div>
			<input
				type="file"
				v-on:change="changeImg3"
				class="form-control"
				id="Image3"
				placeholder="Image3"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image4</label>
			<div class="edit-question-img" ref="imgContainer4"></div>
			<input
				type="file"
				v-on:change="changeImg4"
				class="form-control"
				id="Image4"
				placeholder="Image4"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image5</label>
			<div class="edit-question-img" ref="imgContainer5"></div>
			<input
				type="file"
				v-on:change="changeImg5"
				class="form-control"
				id="Image5"
				placeholder="Image5"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image6</label>
			<div class="edit-question-img" ref="imgContainer6"></div>
			<input
				type="file"
				v-on:change="changeImg6"
				class="form-control"
				id="Image6"
				placeholder="Image6"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image7</label>
			<div class="edit-question-img" ref="imgContainer7"></div>
			<input
				type="file"
				v-on:change="changeImg7"
				class="form-control"
				id="Image7"
				placeholder="Image7"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image8</label>
			<div class="edit-question-img" ref="imgContainer8"></div>
			<input
				type="file"
				v-on:change="changeImg8"
				class="form-control"
				id="Image8"
				placeholder="Image8"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image9</label>
			<div class="edit-question-img" ref="imgContainer9"></div>
			<input
				type="file"
				v-on:change="changeImg9"
				class="form-control"
				id="Image9"
				placeholder="Image9"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image10</label>
			<div class="edit-question-img" ref="imgContainer10"></div>
			<input
				type="file"
				v-on:change="changeImg10"
				class="form-control"
				id="Image10"
				placeholder="Image10"
			/>
		</div>
		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select id="niveaux" v-model="selectedLevel">
				<option
					v-for="level in this.$store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="chapter">Chapter</label>
			<select id="chapter" v-model="form.chapter">
				<option
					v-for="chapter in getChapter()"
					:key="chapter"
					:value="chapter.id"
				>
					{{ chapter.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="type">Type</label>
			<select id="type" v-model="form.type">
				<option value="INTRODUCTION">INTRODUCTION</option>
				<option value="MOTIVATION">MOTIVATION</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Image2">Rendre visible au utilisateur guest</label>
			<input
				type="checkbox"
				class="form-control"
				v-model="form.guest_visible"
			/>
		</div>
		<div class="input-container">
			<label for="Order">Order</label>
			<input type="number" v-model="form.order" id="Order" />
		</div>
		<div class="input-container">
			<label for="notice">Notice</label>
			<input type="file" @change="changeFile" id="notice" />
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

import { authenticatedImageFetch } from '../../utils'
export default {
	data() {
		return {
			latex: '',
			latex2: '',
			form: {
				title: '',
				description: '',
				description2: '',
				image1: '',
				image2: '',
				image3: '',
				image4: '',
				image5: '',
				image6: '',
				image7: '',
				image8: '',
				image9: '',
				image10: '',
				chapter: '',
				type: '',
				theme: '',
				difficulty: '',
				order: null,
				notice: ''
			},
			chapters: [],
			questionTypes: [],
			questionThemes: [],
			difficulties: [],
			sequences: [],
			levels: [],
			errors: '',
			status: '',
			selectedLevel: null,
			admin: true,
		}
	},
	methods: {
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},
		getImage(file, ref) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs[ref].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		submit() {
			authenticatedApiFetch(`/keys/${this.$route.params.id}/`, {
				method: 'PUT',
				body: this.getFormDataValue(),
				headers: {}
			})
				.then(() => {
					this.$router.push({ name: 'SuperAdminAllKeys' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.selectedLevel == null) {
					return this.chapters
				}
			}
			else {
				return ''
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', this.latex)
			data.append('order', this.form.order)
			if (typeof this.form.image1 !== 'string' && this.form.image1 !== null) {
				data.append('image1', this.form.image1)
			}
			if (typeof this.form.image2 !== 'string' && this.form.image2 !== null) {
				data.append('image2', this.form.image2)
			}
			if (typeof this.form.image3 !== 'string' && this.form.image3 !== null) {
				data.append('image3', this.form.image3)
			}
			if (typeof this.form.image4 !== 'string' && this.form.image4 !== null) {
				data.append('image4', this.form.image4)
			}
			if (typeof this.form.image5 !== 'string' && this.form.image5 !== null) {
				data.append('image5', this.form.image5)
			}
			if (typeof this.form.image6 !== 'string' && this.form.image6 !== null) {
				data.append('image6', this.form.image6)
			}
			if (typeof this.form.image7 !== 'string' && this.form.image7 !== null) {
				data.append('image7', this.form.image7)
			}
			if (typeof this.form.image8 !== 'string' && this.form.image8 !== null) {
				data.append('image8', this.form.image8)
			}
			if (typeof this.form.image9 !== 'string' && this.form.image9 !== null) {
				data.append('image9', this.form.image9)
			}
			if (typeof this.form.image10 !== 'string' && this.form.image10 !== null) {
				data.append('image10', this.form.image10)
			}
			if (typeof this.form.notice !== 'string' && this.form.notice !== null) {
				data.append('notice', this.form.notice)
			}
			data.append('chapter', this.form.chapter)
			data.append('level', this.selectedLevel)
			data.append('type', this.form.type)
			data.append('admin', this.admin)
			data.append('guest_visible', this.form.guest_visible)
			return data
		},
		getLevel(e) {
			let chapter = this.chapters.find(x => x.id == e).sequence
			let sequence = this.sequences.find(x => x.id == chapter).level
			let level = this.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg1(e) {
			this.form.image1 = e.target.files[0]
		},
		changeImg2(e) {
			this.form.image2 = e.target.files[0]
		},
		changeImg3(e) {
			this.form.image3 = e.target.files[0]
		},
		changeImg4(e) {
			this.form.image4 = e.target.files[0]
		},
		changeImg5(e) {
			this.form.image5 = e.target.files[0]
		},
		changeImg6(e) {
			this.form.image6 = e.target.files[0]
		},
		changeImg7(e) {
			this.form.image7 = e.target.files[0]
		},
		changeImg8(e) {
			this.form.image8 = e.target.files[0]
		},
		changeImg9(e) {
			this.form.image9 = e.target.files[0]
		},
		changeImg10(e) {
			this.form.image10 = e.target.files[0]
		},
		changeFile(e) {
			this.form.notice = e.target.files[0]
		},
		getKey() {
			authenticatedApiFetch(`/keys/${this.$route.params.id}/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.form = res
					this.selectedLevel = res.level
					this.latex = res.description
					if (this.form.image1 !== null) {
						this.getImage(this.form.image1, 'imgContainer')
					}
					if (this.form.image2 !== null) {
						this.getImage(this.form.image2, 'imgContainer2')
					}
					if (this.form.image3 !== null) {
						this.getImage(this.form.image3, 'imgContainer3')
					}
					if (this.form.image4 !== null) {
						this.getImage(this.form.image4, 'imgContainer4')
					}
					if (this.form.image5 !== null) {
						this.getImage(this.form.image5, 'imgContainer5')
					}
					if (this.form.image6 !== null) {
						this.getImage(this.form.image6, 'imgContainer6')
					}
					if (this.form.image7 !== null) {
						this.getImage(this.form.image7, 'imgContainer7')
					}
					if (this.form.image8 !== null) {
						this.getImage(this.form.image8, 'imgContainer8')
					}
					if (this.form.image9 !== null) {
						this.getImage(this.form.image9, 'imgContainer9')
					}
					if (this.form.image10 !== null) {
						this.getImage(this.form.image10, 'imgContainer10')
					}
				})
		},
		allChapters: async function () {
			await authenticatedApiFetch(`/chapters/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.chapters = res
				})
		},
		allTypes: async function () {
			await authenticatedApiFetch(`/question-types/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.questionTypes = res
				})
		},
		allThemes: async function () {
			await authenticatedApiFetch(`/question-themes/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.questionThemes = res
				})
		},
		allDifficulties: async function () {
			await authenticatedApiFetch(`/difficulties/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.difficulties = res
				})
		},
		allSequences: async function () {
			await authenticatedApiFetch(`/sequences/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.sequences = res
				})
		},
	},
	created() {
		this.getKey()
		this.allChapters()
		this.allTypes()
		this.allThemes()
		this.allDifficulties()
		this.allSequences()
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
	}
}
</script>

<style>
</style>
