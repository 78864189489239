<template>
	<pop-up-wrapper>
		<div class="pop-up suggest-question">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Proposer une question</p>
			<form @submit.prevent="showPopUpFillInputVisible">
				<div class="input-container half-size">
					<label for="niveaux">Niveau</label>
					<select multiple id="niveaux" v-model="form.levels">
						<option
							v-for="level in this.$store.getters.levels(this.$store.state.user.levels)"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				<div class="input-container checkbox half-size">
					
					<label for="share">Mode privé </label>
					<input v-model="share" id="share" type="checkbox" /> 
					<br />
					<p>
						Votre question sera accessible uniquement par vous.
					
						Elle ne sera pas soumise à une étape de vérification
						pour faire partie de la banque de question publique
					</p>
				</div>
				<div class="input-container">
					<label for="subject">Matières</label>
					<select multiple id="subject" v-model="form.subjects">
						<option v-for="subject in this.$store.getters.subjects(this.$store.state.user.subjects)" :key="subject.id" :value="subject.id">
							{{ subject.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="niveaux">Type de question</label>
					<select id="niveaux" v-model="form.tile_game_type_id">
						<option
							v-for="type in tileGameTypes"
							:key="type.id"
							:value="type.id"
						>
							{{ getCustomTypeName(type.name) }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="">Plateau de jeux</label>
					<div class="checkbox-container" v-if="tileGameThemes !== 0">
						<label
							v-for="tileGameTheme in tileGameThemes"
							:key="tileGameTheme.id"
							:for="tileGameTheme.name"
						>
							<input
								v-model="themes"
								:id="tileGameTheme.name"
								type="checkbox"
								:value="tileGameTheme.name"
							/>{{ getName(tileGameTheme.name) }}
						</label>
					</div>
				</div>
				<div class="input-container">
					<label for="">Contenu</label>
					<div class="checkbox-container">
						<label for="txt">
							<input
								v-model="template"
								:value="0"
								id="txt"
								type="radio"
							/>Texte Uniquement
						</label>
						<label for="txt-img">
							<input
								v-model="template"
								:value="1"
								id="txt-img"
								type="radio"
								:disabled="form.tile_game_type_id == 5"
							/>Texte + Image décorative
						</label>
						<label for="txt-img-math">
							<input
								v-model="template"
								:value="2"
								id="txt-img-math"
								type="radio"
								:disabled="form.tile_game_type_id == 5"
							/>Texte + image non rognée
						</label>
					</div>
				</div>
				<button class="submit dark">Valider</button>
			</form>
		</div>
		<Teleport to="body">
			<pop-up-fill-input
				v-if="isPopUpFillInputVisible"
				:type="getTypeName(form.tile_game_type_id)"
				:theme="themes[0]"
				:template="template"
				@close="showPopUpFillInputVisible"
				@submit="submit"
				:tileGameTypes="tileGameTypes"
			/>
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import { createTileGameContentService } from '../../../../services/tileGameContentsService'
import { allTileGameThemesService } from '../../../../services/tileGameThemesService'
import { allTileGameTypesService } from '../../../../services/tileGameTypesService'
import PopUpWrapper from '../../PopUpWrapper.vue'
import PopUpFillInput from './PopUpFillInput.vue'
export default {
	components: { PopUpWrapper, PopUpFillInput },

	data() {
		return {
			form: {
				title: '',
				text: '',
				teacher: null,
				correction: '',
				tile_game_type_id: null,
				suggested_img_url: '',
				levels: [],
				subjects: [],
				status: ''
			},
			share: false,
			tileGameTypes: [],
			tileGameThemes: [],
			themes: [],
			template: 0,
			isPopUpFillInputVisible: false,
		}
	},

	methods: {
		getCustomTypeName(name) {
			switch (name) {
				case 'Flash':
					return 'Flash'
				case 'OneShot':
					return 'OneShot'
				case 'Enigma':
					return 'Enigma'
				case 'Boomerang':
					return 'Boomerang'
				case 'HitOrMiss':
					return 'Chance'
			}
		},
		getTypeName(id) {
			const typeName = this.tileGameTypes.find(x => x.id == id).name
			return typeName
		},
		getName(name) {
			switch (name) {
				case 'MATH_LA_CITROUILLE':
					return 'La Citrouille'

				case 'MATH_LA_BANQUISE':
					return 'La Banquise'

				case 'MATH_LA_PLAGE':
					return 'La Plage'

				case 'MATH_L_ESPACE':
					return "L'Espace"

				case 'MATH_LE_CIEL':
					return "Le Ciel"

				case 'MATH_LE_SKATE':
					return "Le Skate"

				case 'MATH_LE_ROBOT':
					return "Le Robot"
				default:
					return "Le Robot"
			}

		},
		async alltileGameThemes() {
			this.tileGameThemes = await allTileGameThemesService()
		},
		getFormDataValue(content) {
			let themes = []
			for (const theme of this.themes) {
				themes.push(this.tileGameThemes.find(x => x.name == theme))
			}
			const data = new FormData()
			data.append('title', content.title)
			data.append('text', content.text)
			data.append('correction', content.correction)
			data.append('status', this.form.status)
			data.append('teacher', this.$store.state.user.user)
			for (const level of this.form.levels) {
				data.append('levels', level)
			}
			for (const subject of this.form.subjects) {
				data.append('subjects', subject)
			}
			for (const tile_game_theme of themes) {
				data.append('tile_game_theme', tile_game_theme.id)
			}
			data.append('image', content.image)
			data.append('decorative_image', content.decorative_image)
			data.append('tile_game_type_id', this.form.tile_game_type_id)
			return data
		},
		checkErrors() {
			if (this.form.levels.length == 0) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un ou plusieurs niveaux",
					status: 'error'
				}
				return false
			}
			if (!this.form.tile_game_type_id) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un type de question",
					status: 'error'
				}
				return false
			}
			if (this.themes.length == 0) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un ou plusieurs plateau de jeux",
					status: 'error'
				}
				return false
			}
			return true
		},
		showPopUpFillInputVisible() {
			if (this.checkErrors()) {
				this.isPopUpFillInputVisible = !this.isPopUpFillInputVisible
			}
		},

		async submit(data) {
			this.form.teacher = this.$store.state.user.user
			this.form.status = this.share ? 'PRIVATE' : 'PENDING'
			await createTileGameContentService(this.getFormDataValue(data))
			this.$emit('addQuestion', this.form)
			this.$emit('close')
		},
		async allTileGameContentType() {
			this.tileGameTypes = await allTileGameTypesService()
		},
	},
	created() {
		this.themes = [
			'MATH_LA_CITROUILLE',
			'MATH_LA_BANQUISE',
			'MATH_LA_PLAGE',
			'MATH_L_ESPACE',
			'MATH_LE_CIEL',
			'MATH_LE_SKATE',
			'MATH_LE_ROBOT',
		]
		this.alltileGameThemes()
		this.allTileGameContentType()
	},
	watch: {
		form: {
			handler(newValue) {
				if (newValue.tile_game_type_id == 5) {
					this.template = 0
				}
			},
			deep: true
		},
	},
}
</script>

<style>
</style>
