import { authenticatedApiFetch } from '../utils'


export const allOccurencesService = async () => {
    const res = await authenticatedApiFetch(`/occurrences/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allOccurencesForClassAndTypeService = async (classId, type, activityId) => {
    const res = await authenticatedApiFetch(`/occurrences/?classes=${classId}&${type}=${activityId}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const getOccurenceByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/occurrences/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const updateOccurenceService = async (id, data) => {
    const res = await authenticatedApiFetch(`/occurrences/${id}/`, { method: 'PATCH', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
