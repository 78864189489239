<template>
	<edit-exercise class="admin-wrapper" />
</template>

<script>
import EditExercise from '../../../../SuperAdmin/Exercise/EditExercise.vue'

export default {
	components: { EditExercise },
}
</script>

<style>
</style>
