<template>
	<section class="question-flash-container">
		<filter-wrapper
			@setFilterVisible="setFilterVisible"
			@updateFilter="updateFilter"
			:showFilter="false"
		/>

		<table>
			<tr>
				<th></th>
				<th>Titre</th>
				<th>Séquence</th>
				<th>Niveau</th>
				<th>Voir</th>
				<th>Modifier</th>
				<th>Supprimer</th>
				<th>Statut</th>
			</tr>
			<tr v-for="(question, index) in individualQuestions" :key="index">
				<td>{{ currentPage * perPage + index + 1 }}</td>
				<td>{{ question.title }}</td>
				<td
					v-if="
						this.$store.state.sequences.length !== 0 &&
						this.$store.state.chapters.length !== 0
					"
				>
					{{ question.sequence_name }}
				</td>
				<td v-if="this.$store.state.levels.length !== 0">
					{{ question.level_name }}
				</td>
				<td>
					<button class="see" @click="showPopUpApprove(question)">
						<img src="/img/super-admin/blue-eye.svg" alt="" />
					</button>
				</td>
				<td>
					<router-link
						class="table-btn"
						:to="{
							name: 'MyResourcesEditExercise',
							params: { id: question.id },
						}"
						>Modifier</router-link
					>
				</td>
				<td>
					<button class="delete" @click="deleteQuestion(question.id)">
						<img src="/img/super-admin/blue-trash.svg" alt="" />
					</button>
				</td>
				<td>
					<span
						v-if="question.status !== 'PENDING'"
						:style="getStyleColor(question.status)"
					>
						{{ translate(question.status) }}</span
					>
					<span v-else>
						<button
							:style="getStyleColor(question.status)"
							class="table-btn"
						>
							En attente
						</button>
					</span>
				</td>
			</tr>
		</table>
		<pop-up-preview-exercise-admin
			v-if="isPopUpExercisePreviewVisible"
			:exercise="selectedExercise"
			:showValidateBtn="isShowValidateBtnVisible"
			@close="closePopUpPreview"
			@refreshQuestion="getNewQuestions(currentPage)"
		/>
		<pagination-component
			class="question-paginate"
			ref="paginate"
			v-if="pages !== null && pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="changeFilter"
		/>
	</section>
</template>

<script>
import { allExerciseQuestionsFilterService, allExerciseQuestionsForTeacherService, deleteExerciseQuestionsService } from '../../../../../services/exerciseQuestionsService'
import PopUpPreviewExerciseAdmin from '../../../../PopUp/ExerciseSheet/PopUpPreviewExerciseAdmin.vue'
import PaginationComponent from '../../../../Utils/PaginationComponent.vue'
import FilterWrapper from '../../../Search/FilterWrapper.vue'
export default {
	components: { FilterWrapper, PaginationComponent, PopUpPreviewExerciseAdmin },
	data() {
		return {
			individualQuestions: [],
			currentPage: 0,
			filter: {
				chapter: null,
				level: null,
				type: [],
				theme: [],
				difficulty: [],
				offset: 0,
				search: '',
			},
			pages: null,
			actualFilter: '',
			isPopUpExercisePreviewVisible: false,
			perPage: 20,
			count: null,
		}
	},
	methods: {
		setFilterVisible() {
			this.isFilterVisible = !this.isFilterVisible
			if (this.isFilterVisible) {
				document.body.style.overflow = 'hidden'
			}
			else {
				document.body.style.overflow = 'visible'
			}
		},
		closePopUpPreview() {
			this.isPopUpExercisePreviewVisible = false
			this.isShowValidateBtnVisible = false
		},
		showPopUpApprove(exercise, isApproveMod = false) {
			this.selectedExercise = exercise
			this.isPopUpExercisePreviewVisible = true
			this.isShowValidateBtnVisible = isApproveMod
		},

		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
				case 'PENDING':
					return 'En attente'
				case 'PRIVATE':
					return 'Privé'
			}
		},
		updateFilter(data) {
			this.filter.level = data.level
			this.filter.chapter = data.chapter
			this.filter.search = data.search
			this.changeFilter()
		},
		changeLevel() {
			this.filter.chapter = []
			this.changeFilter()
		},
		async deleteQuestion(e) {
			if (confirm("Êtes-vous sûr de vouloir supprimer ?")) {
				await deleteExerciseQuestionsService(e)
				this.changeFilter(this.currentPage)
			}
		},
		changeFilter: function (e) {
			if (typeof e == 'number') {
				this.filter.offset = 20 * e
				this.currentPage = e
			}
			else {
				this.currentPage = 0
				this.filter.offset = 0
			}

			if (e !== undefined) {
				if (Object.prototype.hasOwnProperty.call(e, 'types')) {
					this.filter.type = e.types
				}
				if (Object.prototype.hasOwnProperty.call(e, 'themes')) {
					this.filter.theme = e.themes
				}
				if (Object.prototype.hasOwnProperty.call(e, 'difficulties')) {
					this.filter.difficulty = e.difficulties
				}
			}
			const search = this.filter.search ? `&search=${this.filter.search}` : '&search='
			const chapter = this.filter.chapter ? `chapter=${this.filter.chapter}` : ''
			const level = this.filter.level ? `&level=${this.filter.level}` : '&level='
			const type = this.filter.type ? `&type=${this.filter.type}` : '&type='
			const theme = this.filter.theme ? `&theme=${this.filter.theme}` : '&theme='
			const difficulty = this.filter.difficulty ? `&difficulty=${this.filter.difficulty}` : '&difficulty='
			const teacher = `&teacher=${this.$store.state.user.user}`
			this.actualFilter = `?${chapter}${level}&offset=${this.filter.offset}${type}${theme}${difficulty}${search}${teacher}`
			this.refreshExercises(this.actualFilter)
		},
		async allExerciseQuestions() {
			const res = await allExerciseQuestionsForTeacherService(this.$store.state.user.user)
			this.individualQuestions = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)

		},
		async refreshExercises(e) {
			const res = await allExerciseQuestionsFilterService(e)
			this.individualQuestions = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)

		}
	},
	created() {
		this.allExerciseQuestions()
	},
}
</script>

<style>
</style>
