<template>
	<section class="add-exercise-container">
		<h1>Modifier un exercice</h1>
		<div class="filter-input-container">
			<div class="input-container">
				<label for="niveaux">Niveaux</label>
				<select id="niveaux" v-model="form.level" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="chapter">Chapitres</label>
				<select v-model="form.chapter" name="chapter" id="chapter">
					<optgroup
						v-for="sequence in this.$store.getters.sequences(
							form.level
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<div class="input-container">
				<label for="type">Type</label>
				<select id="type" v-model="form.type" required>
					<option
						v-for="type in this.$store.state.exerciseQuestionTypes"
						:key="type"
						:value="type.id"
					>
						{{ type.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Theme</label>
				<select id="theme" v-model="form.theme" required>
					<option
						v-for="theme in this.$store.state
							.exerciseQuestionThemes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Difficulté</label>
				<select id="theme" v-model="form.difficulty" required>
					<option
						v-for="difficulty in this.$store.state.difficulties"
						:key="difficulty"
						:value="difficulty.id"
					>
						{{ difficulty.name }}
					</option>
				</select>
			</div>
		</div>
		<div class="input-container title-input">
			<label for="Title">Title</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div class="form-container">
			<div class="column">
				<div class="input-container">
					<label for="">Image</label>
					<input
						multiple
						@change="handleImgUpload"
						type="file"
						accept="image/png, image/jpeg"
					/>
					<p v-for="url in urls" :key="url">
						Pour inclure votre image: \includegraphics&#123;{{
							url.name
						}}&#125;

						<img width="50" :src="url.url" alt="" />
					</p>
				</div>
				<div class="input-container">
					<label for="">Texte</label>
					<full-latex-editor
						ref="descriptionLatex"
						:heritedLatex="form.description"
					/>
				</div>

				<div class="input-container">
					<label for="">Correction</label>
					<full-latex-editor
						ref="correctionLatex"
						:heritedLatex="form.correction"
					/>
				</div>
			</div>
			<div class="column">
				<div class="preview-container">
					<div>
						<div class="preview-wrapper">
							<label>Texte</label>
							<div class="text-preview">
								<loader v-if="isdescriptionLatexLoading" />
								<div ref="exercisePreview"></div>
							</div>
						</div>
						<div class="preview-wrapper">
							<label>Correction</label>
							<div class="correction-preview">
								<loader v-if="isCorrectionLatexLoading" />
								<div ref="correctionPreview"></div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>
		<div class="checkbox-container">
			<input id="private" v-model="form.private_mode" type="checkbox" />
			<label for="private">Mode privé</label>
		</div>
		<div class="btn-container">
			<button @click="previewLatex">Prévisualiser</button>
			<button @click="submit">Valider</button>
		</div>
		<full-page-loader v-if="isLoading" />
	</section>
</template>

<script>
import Config from '../../../config'
import { getExerciseQuestionsService, updateExerciseQuestionsService } from '../../../services/exerciseQuestionsService'
import { generateLatexImg } from '../../../services/latexService'
import { authenticatedFetch, authenticatedImageFetch } from '../../../utils'
import FullLatexEditor from '../../Admin/Latex/FullLatexEditor.vue'
import Loader from '../../Loader.vue'
import FullPageLoader from '../../Utils/FullPageLoader.vue'
export default {
	components: {
		FullLatexEditor,
		Loader,
		FullPageLoader
	},
	data() {
		return {
			form: {
				title: '',
				description: '',
				correction: '',
				image: '',
				level: null,
				chapter: null,
				type: null,
				difficulty: null,
				private_mode: false
			},
			urls: [],
			isdescriptionLatexLoading: false,
			isCorrectionLatexLoading: false,
			isLoading: false
		}
	},
	methods: {
		checkForm() {
			if (!this.form.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ titre',
					status: 'error'
				}
				return false
			}
			if (!this.form.level) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ niveau',
					status: 'error'
				}
				return false
			}
			if (!this.form.chapter) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ chapitre',
					status: 'error'
				}
				return false
			}
			if (!this.form.type) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ type',
					status: 'error'
				}
				return false
			}
			if (!this.form.theme) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ theme',
					status: 'error'
				}
				return false
			}
			if (!this.form.difficulty) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ difficulté',
					status: 'error'
				}
				return false
			}
			if (!this.$refs.descriptionLatex.latex) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ texte',
					status: 'error'
				}
				return false
			}
			if (!this.$refs.correctionLatex.latex) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ correction',
					status: 'error'
				}
				return false
			}
			return true
		},
		async submit() {
			if (!this.checkForm()) {
				return
			}
			this.isLoading = true
			this.form.description = this.$refs.descriptionLatex.latex
			this.form.correction = this.$refs.correctionLatex.latex
			await updateExerciseQuestionsService(this.$route.params.id, this.form)
			this.$store.state.toast = {
				status: 'success',
				text: 'Exercice enregistré avec succès'
			}
			this.isLoading = false
			this.$router.go(-1)
		},
		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.form.level).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.form.level == null) {
					return this.$store.state.chapters
				}
			}
			else {
				return ''
			}
		},
		async handleImgUpload(e) {
			this.urls = []
			this.form.image = e.target.files
			for (const image of this.form.image) {
				const data = new FormData()
				data.append('image', image)

				const res = await authenticatedFetch('/exercise-img/', {
					method: 'POST', body: data,
					headers: {}
				})
					.then((res => res.json()))

				this.urls.push(
					{
						url: Config.backend_url + res.image_url,
						name: res.image_name
					}
				)
			}

		},
		async previewLatex() {
			const descriptionLatex = this.$refs.descriptionLatex.latex
			const correctionLatex = this.$refs.correctionLatex.latex
			this.$refs.correctionPreview.innerHTML = ''
			this.$refs.exercisePreview.innerHTML = ''


			this.isdescriptionLatexLoading = true
			// convert latex to img backend side
			const blobDescription = await generateLatexImg(descriptionLatex)
			const readerDescription = new FileReader()
			const componentDescription = this

			readerDescription.onload = function () {
				const img = new Image()
				img.src = this.result
				componentDescription.$refs.exercisePreview.append(img)
			}
			readerDescription.readAsDataURL(blobDescription)

			this.isdescriptionLatexLoading = false
			this.isCorrectionLatexLoading = true

			const blobCorrection = await generateLatexImg(correctionLatex)

			const readerCorrection = new FileReader()
			const componentCorrection = this
			readerCorrection.onload = function () {
				const img = new Image()
				img.src = this.result
				componentCorrection.$refs.correctionPreview.append(img)
			}
			readerCorrection.readAsDataURL(blobCorrection)
			this.isCorrectionLatexLoading = false
		},
		async getExercise() {
			this.form = await getExerciseQuestionsService(this.$route.params.id)
			this.form.private_mode = this.form.status == 'PRIVATE' ? true : false
			this.displayImg(this.form.description_img, this.$refs.exercisePreview)
			this.displayImg(this.form.correction_img, this.$refs.correctionPreview)
			delete this.form.description_img
			delete this.form.correction_img
		},

		async displayImg(latex, ref) {
			authenticatedImageFetch(latex, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						const img = new Image()
						img.src = e.target.result
						ref.append(img)
					}
					reader.readAsDataURL(blob)
				})

		}
	},

	created() {
		this.getExercise()
	},

}
</script>

<style>
</style>
