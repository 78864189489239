<template>
	<h1>Add Key</h1>
	<form
		@submit.prevent="submit"
		class="form-container"
		enctype="multipart/form-data"
	>
		<div class="input-container">
			<label for="Title">Title</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div class="input-container">
			<label for="Description">Description</label>
			<textarea
				type="text"
				class="form-control md-editor"
				v-model="latex"
				id="Description"
				placeholder="Description"
			>
			</textarea>
			<div :key="latex">{{ latex }}</div>
		</div>
		<div class="input-container">
			<label for="Image">Image1</label>
			<input
				type="file"
				v-on:change="changeImg1"
				class="form-control"
				id="Image1"
				placeholder="Image1"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image2</label>
			<input
				type="file"
				v-on:change="changeImg2"
				class="form-control"
				id="Image2"
				placeholder="Image2"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image3</label>
			<input
				type="file"
				v-on:change="changeImg3"
				class="form-control"
				id="Image3"
				placeholder="Image3"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image4</label>
			<input
				type="file"
				v-on:change="changeImg4"
				class="form-control"
				id="Image4"
				placeholder="Image4"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image5</label>
			<input
				type="file"
				v-on:change="changeImg5"
				class="form-control"
				id="Image5"
				placeholder="Image5"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image6</label>
			<input
				type="file"
				v-on:change="changeImg6"
				class="form-control"
				id="Image6"
				placeholder="Image6"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image7</label>
			<input
				type="file"
				v-on:change="changeImg7"
				class="form-control"
				id="Image7"
				placeholder="Image7"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image8</label>
			<input
				type="file"
				v-on:change="changeImg8"
				class="form-control"
				id="Image8"
				placeholder="Image8"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image9</label>
			<input
				type="file"
				v-on:change="changeImg9"
				class="form-control"
				id="Image9"
				placeholder="Image9"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Image10</label>
			<input
				type="file"
				v-on:change="changeImg10"
				class="form-control"
				id="Image10"
				placeholder="Image10"
			/>
		</div>
		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select
				id="niveaux"
				v-model="selectedLevel"
				@change="form.chapter = ''"
			>
				<option
					v-for="level in $store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>

		<div class="input-container">
			<label for="chapter">Chapter</label>
			<select
				v-if="chapters.length !== 0 && sequences.length !== 0"
				id="chapter"
				v-model="form.chapter"
			>
				<option
					v-for="chapter in getChapter()"
					:key="chapter"
					:value="chapter.id"
				>
					{{ chapter.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="type">Type</label>
			<select id="type" v-model="form.type">
				<option value="INTRODUCTION">INTRODUCTION</option>
				<option value="MOTIVATION">MOTIVATION</option>
			</select>
		</div>
		<div class="input-container">
			<label for="Notice">Notice</label>
			<input
				type="file"
				v-on:change="changeFile"
				class="form-control"
				id="Notice"
				placeholder="Notice"
			/>
		</div>
		<div class="input-container">
			<label for="Image2">Rendre visible au utilisateur guest</label>
			<input
				type="checkbox"
				class="form-control"
				v-model="form.guest_visible"
			/>
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			latex: '',
			form: {
				title: '',
				description: '',
				image1: '',
				image2: '',
				image3: '',
				image4: '',
				image5: '',
				image6: '',
				image7: '',
				image8: '',
				image9: '',
				image10: '',
				chapter: '',
				type: '',
				theme: '',
				notice: null,
				difficulty: '',
				guest_visible: false
			},
			chapters: [],
			questionTypes: [],
			questionThemes: [],
			difficulties: [],
			sequences: [],
			levels: [],
			errors: '',
			status: '',
			selectedLevel: null,
			teacher: this.$store.state.user.user,
			admin: true,
		}
	},
	methods: {
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},
		submit() {
			if (this.form.chapter) {
				authenticatedApiFetch('/keys/', {
					method: 'POST',
					body: this.getFormDataValue(),
					headers: {}
				})
					.then(() => {
						this.status = 'Enregistré avec succès'
						this.form.title = ""
						this.form.description = ""
						setTimeout(() => {
							this.status = ''
							this.errors = ''
						}, 2000)
					})
					.catch((error) => {
						this.errors = error
					})
			}
			else {
				this.errors = "Le champ chapitre n'est pas rempli"
			}
		},
		getChapter() {
			let sequences = this.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
			if (sequences.length !== 0) {
				let chapterArray = []
				sequences.forEach(element => {
					let chapters = this.chapters.filter(x => x.sequence == element)
					chapterArray.push(...chapters)
				})
				return chapterArray
			}
			if (this.selectedLevel == null) {
				return this.chapters
			}
		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', this.latex)
			data.append('level', this.getLevel(this.form.chapter))
			data.append('chapter', this.form.chapter)
			data.append('type', this.form.type)
			data.append('image1', this.form.image1)
			data.append('image2', this.form.image2)
			data.append('image3', this.form.image3)
			data.append('image4', this.form.image4)
			data.append('image5', this.form.image5)
			data.append('image6', this.form.image6)
			data.append('image7', this.form.image7)
			data.append('image8', this.form.image8)
			data.append('image9', this.form.image9)
			data.append('image10', this.form.image10)
			if (this.form.notice) {
				data.append('notice', this.form.notice)
			}
			data.append('teacher', this.teacher)
			data.append('admin', this.admin)
			data.append('guest_visible', this.form.guest_visible)
			return data
		},
		getLevel(e) {
			let chapter = this.$store.state.chapters.find(x => x.id == e).sequence
			let sequence = this.$store.state.sequences.find(x => x.id == chapter).level
			let level = this.$store.state.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg1(e) {
			this.form.image1 = e.target.files[0]
		},
		changeImg2(e) {
			this.form.image2 = e.target.files[0]
		},
		changeImg3(e) {
			this.form.image3 = e.target.files[0]
		},
		changeImg4(e) {
			this.form.image4 = e.target.files[0]
		},
		changeImg5(e) {
			this.form.image5 = e.target.files[0]
		},
		changeImg6(e) {
			this.form.image6 = e.target.files[0]
		},
		changeImg7(e) {
			this.form.image7 = e.target.files[0]
		},
		changeImg8(e) {
			this.form.image8 = e.target.files[0]
		},
		changeImg9(e) {
			this.form.image9 = e.target.files[0]
		},
		changeImg10(e) {
			this.form.image10 = e.target.files[0]
		},
		changeFile(e) {
			this.form.notice = e.target.files[0]
		},
		allChapters: async function () {
			await authenticatedApiFetch(`/chapters/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.chapters = res
				})
		},
		allTypes: async function () {
			await authenticatedApiFetch(`/question-types/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.questionTypes = res
				})
		},
		allThemes: async function () {
			await authenticatedApiFetch(`/question-themes/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.questionThemes = res
				})
		},
		allDifficulties: async function () {
			await authenticatedApiFetch(`/difficulties/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.difficulties = res
				})
		},
		allSequences: async function () {
			await authenticatedApiFetch(`/sequences/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.sequences = res
				})
		},
	},
	created() {
		this.allChapters()
		this.allTypes()
		this.allThemes()
		this.allDifficulties()
		this.allSequences()
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		}
	}
}
</script>

<style>
</style>
