<template>
	<section class="add-exercise-container">
		<h1>Modifier une question</h1>
		<div class="filter-input-container">
			<div class="input-container">
				<label for="niveaux">Niveaux</label>
				<select id="niveaux" v-model="selectedLevel" required>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="niveaux">Matiere</label>
				<select id="niveaux" v-model="selectedSubject" required>
					<option
						v-for="subject in this.$store.state.subjects"
						:key="subject"
						:value="subject.id"
					>
						{{ subject.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="chapter">Chapitres</label>
				<select v-model="form.chapter" name="chapter" id="chapter">
					<optgroup
						v-for="sequence in this.$store.getters.sequencesByLevelAndSubject(
							selectedLevel,
							selectedSubject
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<div v-if="selectedSubject == 1" class="input-container">
				<label for="type">Type</label>
				<select id="type" v-model="form.type" required>
					<option
						v-for="type in this.$store.state.questionTypes"
						:key="type"
						:value="type.id"
					>
						{{ type.name }}
					</option>
				</select>
			</div>
			<div v-if="selectedSubject == 1" class="input-container">
				<label for="theme">Theme</label>
				<select id="theme" v-model="form.theme" required>
					<option
						v-for="theme in this.$store.state.questionThemes"
						:key="theme"
						:value="theme.id"
					>
						{{ theme.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="theme">Difficulté</label>
				<select id="theme" v-model="form.difficulty" required>
					<option
						v-for="difficulty in this.$store.state.difficulties"
						:key="difficulty"
						:value="difficulty.id"
					>
						{{ difficulty.name }}
					</option>
				</select>
			</div>
		</div>
		<div class="input-container title-input">
			<label for="Title">Title</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>
		<div class="form-container">
			<div class="column">
				<div class="input-container">
					<label for="Description">Texte </label>
					<textarea
						rows="10"
						type="text"
						class="form-control md-editor"
						v-model="latex"
						id="Description"
						placeholder="Description"
					>
					</textarea>
				</div>
				<div class="input-container">
					<label for="Description">Correction </label>
					<textarea
						rows="10"
						type="text"
						class="form-control md-editor"
						v-model="latex2"
						id="Description"
						placeholder="Correction"
					>
					</textarea>
				</div>
				<div class="input-container">
					<label for="Image">Image</label>
					<input
						type="file"
						v-on:change="changeImg"
						class="form-control"
						id="Image"
						placeholder="Image"
					/>
					<div v-if="url" class="img-container">
						<div class="img-delete-container" @click="removeImg">
							<img class="close" src="/img/close.svg" alt="" />
						</div>
						<img :src="url" alt="" />
					</div>
					<div class="input-container private">
						<label for="Description">Mode privé </label>
						<input type="checkbox" v-model="form.status" />
					</div>
				</div>
			</div>
			<div class="column">
				<div class="input-container latex-container">
					<label for="Description">Texte </label>
					<p class="latex" :key="latex">
						{{ translateFromTexToHTML(latex) }}
					</p>
				</div>
				<div class="input-container latex-container">
					<label for="Description">Correction </label>
					<p v-if="latex2" class="latex" :key="latex2">
						{{ translateFromTexToHTML(latex2) }}
					</p>
				</div>
			</div>
		</div>
		<p v-if="status !== ''" class="status">{{ status }}</p>
		<button @click="submit" class="submit dark">Valider</button>

		<full-page-loader v-if="isLoading" />
	</section>
</template>

<script>
import Config from '../../../config'
import { updateQuestionService } from '../../../services/questionsService'
import { getQuestionService } from '../../../services/allQuestionsService'
import { authenticatedImageFetch, translateFromTexToHTML } from '../../../utils'
import FullPageLoader from '../../Utils/FullPageLoader.vue'

export default {
	components: { FullPageLoader },
	data() {
		return {
			latex: '',
			latex2: '',
			form: {
				title: '',
				description: '',
				correction: '',
				random_values: '',
				image: '',
				chapter: '',
				type: '',
				theme: '',
				difficulty: '',
				status: ''
			},
			status: '',
			selectedLevel: null,
			selectedSubject: null,
			url: '',
			isLoaded: false,
			isLoading: false,
			imgDeleted: false
		}
	},
	methods: {
		translateFromTexToHTML: translateFromTexToHTML,
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},

		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						this.url = this.result
						// component.$refs[ref].append(img)
					}
					reader.readAsDataURL(blob)
				})
		},

		checkForm() {
			if (!this.form.title) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ titre',
					status: 'error'
				}
				return false
			}
			if (!this.form.level) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ niveau',
					status: 'error'
				}
				return false
			}
			if (!this.form.chapter) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ chapitre',
					status: 'error'
				}
				return false
			}
			if (!this.latex) {
				this.$store.state.toast = {
					text: 'Merci de remplir le champ texte',
					status: 'error'
				}
				return false
			}

			return true
		},

		getChapter() {
			if (this.$store.state.sequences) {
				let sequences = this.$store.state.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
				if (sequences.length !== 0) {
					let chapterArray = []
					sequences.forEach(element => {
						let chapters = this.$store.state.chapters.filter(x => x.sequence == element)
						chapterArray.push(...chapters)
					})
					return chapterArray
				}
				if (this.selectedLevel == null) {
					return this.$store.state.chapters
				}
			}
			else {
				return ''
			}
		},
		async submit() {
			if (!this.checkForm()) {
				return
			}
			const formData = this.getFormDataValue()

			this.isLoading = true
			await updateQuestionService(this.$route.params.id, formData)

			this.$store.state.toast = {
				text: 'Modification effectuée',
				status: 'success'
			}
			this.isLoading = false
			this.$router.go(-1)

		},
		getFormDataValue() {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('description', translateFromTexToHTML(this.latex))
			if (this.latex2) {
				data.append('correction', this.latex2)
			}
			else {
				data.append('correction', null)
			}

			if (typeof (this.form.image_url_1) !== 'string' && this.form.image_url_1 !== null) {
				data.append('image_url_1', this.form.image_url_1)
			}
			else if (this.imgDeleted) {
				data.append('image_url_1', new File([], ''))

			}
			data.append('chapter', this.form.chapter)
			data.append('level', this.selectedLevel)
			if(this.form.type){
				data.append('type', this.form.type)
			}
			if(this.form.theme){
				data.append('theme', this.form.theme)
			}
			if(this.form.difficulty){
				data.append('difficulty', this.form.difficulty)
			}
			const status = this.form.status ? 'PRIVATE' : 'PENDING'
			data.append('status', status)
			return data
		},
		getLevel(e) {
			let chapter = this.$store.state.chapters.find(x => x.id == e).sequence
			let sequence = this.$store.state.sequences.find(x => x.id == chapter).level
			let level = this.$store.state.levels.find(x => x.id == sequence).id
			return level
		},
		changeImg(e) {

			this.form.image_url_1 = e.target.files[0]
			this.url = URL.createObjectURL(this.form.image_url_1)
		},
		removeImg() {
			if (confirm("Êtes-vous sur de vouloir supprimer l'image")) {
				this.form.image_url_1 = null
				this.url = null
				this.imgDeleted = true
			}
		},
		async getQuestion() {
			this.form = await getQuestionService(this.$route.params.id)
			this.selectedLevel = this.form.level
			const sequence = this.$store.getters.chapter(this.form.chapter).sequence
			this.selectedSubject = this.$store.getters.sequence(sequence).subject

			this.latex = this.form.description
			this.latex2 = this.form.correction
			if (this.form.image_url_1 !== null) {
				this.getImage(this.form.image_url_1)
				this.url = Config.backend_url + '/media/' + this.form.image_url_1
			}
			this.form.status = ''
		},

	},
	created() {
		this.getQuestion()
	},
	mounted() {
		this.reRender()
	},
	watch: {
		latex: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		latex2: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		selectedLevel: function () {
			if (this.isLoaded)
				this.form.chapter = null
		}
	}
}
</script>

<style>
</style>
