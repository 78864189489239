import { authenticatedApiFetch } from '../utils'


export const createProposedKeySlideService = async (data) => {
    const res = await authenticatedApiFetch(`/proposed-key-slides/`, {
        method: 'POST',
        body: data,
        headers: {}
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateProposedKeySlideService = async (id, data) => {
    const res = await authenticatedApiFetch(`/proposed-key-slides/${id}/`, {
        method: 'PUT',
        body: data,
        headers: {}
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allProposedKeySlidesFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/proposed-key-slides/${filter}`, {
        method: 'GET',
    })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
