<template>
	<router-link :style="`--color:${resource.color}`" :to="{ name: resource.componentName }" class="each-resource">
		<img :src="resource.img" alt="" />
		<p class="name">{{ resource.name }}</p>
		<p class="text">{{ resource.text }}</p>
	</router-link>
</template>

<script>
export default {
	props: {
		resource: {
			type: Object,
			required: true
		}
	}
}
</script>

<style></style>
