<template>
	<pop-up-wrapper @close="close">
		<div ref="popUp" :style="pos" class="pop-up-edit-folder">
			<p
				v-if="typeIsNotGame()"
				class="duplicate"
				@click="showPopUpDuplicate"
			>
				<img src="/img/class/copy-paste.svg" alt="" /> Dupliquer
			</p>
			<p v-if="typeIsNotGame()" @click="showPopUpEdit">
				<img src="/img/class/update-icon.svg" alt="" /> Modifier
			</p>
			<hr v-if="typeIsNotGame()" />
			<p class="remove" @click="showPopUpRemove">
				<img src="/img/class/delete-icon.svg" alt="" /> Supprimer
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from './PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		id: Number,
		x: Number,
		y: Number,
		type: String
	},
	data() {
		return {
			inputValue: '',
			delete: false,
			pos: '',
		}
	},
	methods: {
		typeIsNotGame() {
			if (this.type !== 'christmas-calendar' && this.type !== 'games' && this.type !== 'imported-lessons' && this.type !== 'tile-game') {
				return true
			}
			else {
				return false
			}
		},
		close() {
			this.$emit('closed')
		},
		showPopUpRemove() {
			this.$emit('showPopUpRemove')
			this.$emit('closed')
		},
		showPopUpEdit() {
			this.$emit('showPopUpEdit')
			this.$emit('closed')
		},
		showPopUpDuplicate() {
			this.$emit('showPopUpDuplicate')
			this.$emit('closed')
		},
	},

	mounted() {
		const height = window.innerHeight
		const popUpHeight = this.$refs.popUp.clientHeight

		let x = this.x
		let y = this.y

		if (y > height - popUpHeight) {
			y = height - popUpHeight - 20
		}
		let style = `left: calc(${x}px - 15vw); top: ${y}px;`
		this.pos = style
	},
}
</script>

<style>
</style>
