<template>
	<section>
		<div class="profile">
			<div class="info-container">
				<p class="title">Email</p>
				<p>{{ this.$store.state.user.username }}</p>
			</div>
			<div class="info-container">
				<p class="title">Nom / Prénom</p>
				<p>
					{{ this.$store.state.user.last_name }}
					{{ this.$store.state.user.first_name }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Matières enseignées</p>
				<p
					v-for="e in this.$store.state.user.subjects"
					:key="e"
					class="tile subject"
				>
					{{ getSubject(e) }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Niveaux</p>
				<p
					v-for="e in this.$store.state.user.levels"
					:key="e"
					class="tile level"
				>
					{{ getLevel(e) }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Académie</p>
				<p>
					{{ this.$store.state.user.academy_name }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Département</p>
				<p>
					{{ this.$store.state.user.county_name }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Ville</p>
				<p>
					{{ this.$store.state.user.city }}
				</p>
			</div>
			<div class="info-container">
				<p class="title">Établissement scolaire</p>
				<p>{{ this.$store.state.user.school }}</p>
			</div>

			<div class="info-container">
				<p class="title">Dernier diplôme obtenu</p>
				<p>{{ this.$store.state.user.diploma }}</p>
			</div>
		</div>
		<router-link :to="{ name: 'EditProfile' }" class="submit dark"
			>Modifier</router-link
		>
	</section>
</template>

<script>
import Config from '../../../config'


export default {

	data() {
		return {
			notif: '',
			delete: false,
			isCancel: false,
		}
	},

	methods: {
		logout() {
			this.$store.dispatch('logout')
			location.replace(`${Config.landing_url}/auth/reset-token`)
		},


		showPopUp() {
			this.delete = true
		},
		timeToClose() {
			this.delete = false
		},
		getSubject(e) {
			if (this.$store.state.subjects) {
				return this.$store.state.subjects.find(x => x.id === e).name
			}
			else {
				return
			}
		},
		getLevel(e) {
			if (this.$store.state.levels) {
				return this.$store.state.levels.find(x => x.id === e).name
			}
			else {
				return
			}
		}
	},
}
</script>

<style>
</style>
