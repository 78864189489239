import { authenticatedApiFetch } from '../utils'

export const allLinksForUserService = async (userId) => {
    const res = await authenticatedApiFetch(`/links/?teacher=${userId}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createLinkService = async (data) => {
    const res = await authenticatedApiFetch(`/links/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteLinkService = async (id) => {
    const res = await authenticatedApiFetch(`/links/${id}`, { method: 'DELETE' })
    return res
}
