import store from '../store'
import { authenticatedApiFetch } from '../utils'

export const createSuggestionService = async (data) => {
    try {
        const res = await authenticatedApiFetch(`/suggestions/`, { method: 'POST', body: JSON.stringify(data) })
        return res
    }
    catch (err) {
        const error = 'Vous avez déjà fait une suggestion pour ce contenu'
        store.state.toast = {
            status: 'error', text: error
        }
        return error
    }
}
