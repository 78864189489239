<template>
	<header class="stats-header">
		<router-link :to="{ name: 'SuperAdminStats' }">Statistiques Comptes</router-link>
		<router-link :to="{ name: 'SuperAdminClassStats' }">Classes</router-link>
		<router-link :to="{ name: 'SuperAdminKeyStats' }">Activités Expresso</router-link>
		<router-link :to="{ name: 'SuperAdminFlashStats' }">Questions flash</router-link>
		<router-link :to="{ name: 'SuggestionDetails' }">Suggestions</router-link>
	</header>
</template>

<script>
export default {

}
</script>

<style></style>
