<template>
	<pop-up-wrapper>
		<div ref="popUp" :style="pos" class="pop-up-edit-folder">
			<p @click="showPopUpEditFolder">
				<img src="/img/class/update-icon.svg" alt="" /> Modifier
			</p>
			<hr />
			<p class="remove" @click="deleteFolder(folder.id)">
				<img src="/img/class/delete-icon.svg" alt="" /> Supprimer
			</p>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		x: Number,
		y: Number,
		folder: Object
	},
	data() {
		return {
			inputValue: '',
			delete: false,
			pos: '',
			file: {}
		}
	},
	methods: {
		deleteFolder(id) {
			authenticatedApiFetch(`/folders/${id}/`, { method: 'DELETE' })
				.then(() => {
					this.$emit('remove', id)
					this.$emit('close')
				})
		},

		showPopUpEditFolder() {
			this.$emit('showPopUpEditFolder')
			this.$emit('close')
		},

	},

	mounted() {
		const height = window.innerHeight
		const popUpHeight = this.$refs.popUp.clientHeight

		let x = this.x
		let y = this.y

		if (y > height - popUpHeight) {
			y = height - popUpHeight - 20
		}
		let style = `left: calc(${x}px - 15vw); top: ${y}px;`
		this.pos = style
	},
}
</script>

<style>
</style>
