<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-student">
			<div class="student-observation-wrapper">
				<div class="student-wrapper">
					<div class="img-name-container">
						<img src="/img/class/user.svg" alt="" />
						<p class="name">{{ student.name }}</p>
					</div>
					<div @click="showPopUpObservation" class="plus">+</div>
				</div>
				<div class="observation-wrapper">
					<div
						class="observation-container"
						v-for="motif in this.$store.state.motifs"
						:key="motif.id"
					>
						<p>{{ motif.name }}</p>
						<div
							@click="showPopUpShowObservations(motif.id)"
							class="count-container"
						>
							<p class="number">{{ getObservation(motif.id) }}</p>
						</div>
					</div>
				</div>
			</div>
			<pop-up-show-observations
				@refresh="studentObservation"
				@close="showPopUpShowObservations"
				:observations="filteredObservations"
				v-if="isPopUpShowObservationsVisible"
			/>
		</div>
	</pop-up-wrapper>
	<pop-up-observation
		@close="showPopUpObservation"
		:studentId="studentId"
		v-if="isPopUpObservationVisible"
	/>
</template>


<script>
import { allObservationsFilterService } from '../../../services/observationsService'
import { getStudentService } from '../../../services/studentsService'
import PopUpWrapper from '../PopUpWrapper.vue'
import PopUpObservation from './PopUpObservation.vue'
import PopUpShowObservations from './PopUpShowObservations.vue'
export default {
	components: { PopUpWrapper, PopUpObservation, PopUpShowObservations },
	props: {
		studentId: Number
	},
	emits: ["close"],

	data() {
		return {
			student: {},
			isPopUpObservationVisible: false,
			isPopUpShowObservationsVisible: false,
			observations: [],
			filteredObservations: [],
			motif: null
		}
	},

	methods: {
		close() {
			this.$emit('close')
		},
		showPopUpObservation() {
			this.isPopUpObservationVisible = !this.isPopUpObservationVisible

			if (!this.isPopUpObservationVisible) {
				this.studentObservation()
			}
		},
		showPopUpShowObservations(motif) {
			if (motif == this.motif) {
				this.isPopUpShowObservationsVisible = false
				this.motif = null
			}
			else {
				this.motif = motif
				this.isPopUpShowObservationsVisible = true
			}
			this.filteredObservations = this.observations.filter(x => x.motif == motif)
		},
		async getStudent() {
			this.student = await getStudentService(this.studentId)
		},
		async studentObservation() {
			const filter = `?student=${this.studentId}`
			this.observations = await allObservationsFilterService(filter)
		},
		getObservation(e) {
			let observation = this.observations.filter(x => x.motif == e)
			return observation.length
		}
	},

	created() {
		this.getStudent()
		this.studentObservation()
	},

}
</script>

<style>
</style>
