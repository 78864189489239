<template>
	<td class="resource-name">
		<p>{{ slicedString(name) }}</p>
	</td>
</template>

<script>
export default {
	props: {
		name: {
			type: String,
			default: "Calendrier de l'Avent"
		}
	},
	methods: {
		slicedString(text) {
			const maxLength = 40
			if (text.length <= maxLength) {
				return text
			} else {
				const texteTronque = text.slice(0, maxLength - 3) + '...'
				return texteTronque
			}
		}
	},
}
</script>

<style>
</style>
