<template>
	<pop-up-wrapper>
		<div class="pop-up-class-created">
			<p class="title">Classe créée !</p>
			<p class="paragraph">
				La classe a bien été ajoutée et votre planning a été mis à jour.
			</p>
			<router-link
				class="submit light"
				:to="{ name: 'ClassResources', params: { id: id } }"
				>Retour vers vos classes</router-link
			>
			<router-link
				class="submit dark"
				:to="{ name: 'EditClass', params: { id: id } }"
			>
				Attribuer des horaires
			</router-link>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from './PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		id: Number
	},
}
</script>

<style>
</style>
