<template>
	<pop-up-wrapper-without-background @close="close">
		<div class="pop-up-latex-pdf">
			<img @click="close" class="close" src="/img/close.svg" alt="" />
			<loader v-if="isLoading" />
			<div ref="correctionPreview"></div>
			<div v-if="!isLoading" class="btn-container">
				<button
					@click="download(exerciseSheet.pdf_url)"
					class="dl submit dark"
				>
					Télécharger
				</button>
				<button
					v-if="this.showCorrection"
					@click="download(exerciseSheet.pdf_correction_url, true)"
					class="dl submit dark"
				>
					Télécharger correction
				</button>
			</div>
		</div>
	</pop-up-wrapper-without-background>
</template>

<script>
import axios from 'axios'
import Loader from '../../Loader.vue'
import PopUpWrapperWithoutBackground from '../PopUpWrapperWithoutBackground.vue'
import Config from '../../../config'
export default {
	components: { PopUpWrapperWithoutBackground, Loader },
	props: {
		exerciseId: {
			type: Number,
			required: true
		},
		name: {
			type: String,
			required: true
		},
		className: {
			type: Number,
			default: null
		},
		showCorrection: {
			type: Boolean,
			default: true
		}
	},
	data() {
		return {
			isLoading: false,
			url: `${Config.backend_url}`,
			exerciseSheet: {

			}
		}
	},

	methods: {
		async download(name, correction = false) {
			try {
				const response = await axios.get(`${this.url}/media/${name}`, {
					responseType: "blob",
				})

				const blob = new Blob([response.data], { type: "application/pdf" })
				const url = window.URL.createObjectURL(blob)

				let resourceName = this.name
				if (correction) {
					resourceName += '-correction'
				}

				const link = document.createElement("a")
				link.href = url
				link.download = `${resourceName}.pdf`

				document.body.appendChild(link)
				link.click()

				// Libère l'URL de l'objet Blob après le téléchargement
				window.URL.revokeObjectURL(url)

				// Supprime le lien créé après le téléchargement
				document.body.removeChild(link)
			} catch (error) {
				console.error("Erreur lors du téléchargement du PDF :", error)
			}
		},
		async getExercisePdf() {
			this.isLoading = true
			const className = this.className ? this.className : this.$store.getters.class(this.$route.params.id)
			const data = {
				id: this.exerciseId,
				className: className,
				name: this.name,
				classId: this.$route.params.id
			}

			await axios
				.post(`${this.url}/generate-pdf/`, data, { responseType: "blob" })
				.then((axiosResponse) => axiosResponse.data)
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.correctionPreview.append(img)
					}
					reader.readAsDataURL(blob)
				})
			await axios
				.post(`${this.url}/generate-pdf-correction/`, data, { responseType: "blob" })
				.then((axiosResponse) => axiosResponse.data)
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.correctionPreview.append(img)
					}
					reader.readAsDataURL(blob)
				})

			this.isLoading = false
		},
		close() {
			this.$emit('closed')
		},
		async generateImg(item) {
			await axios.get(`${this.url}/media/${item}`, {
				responseType: "blob",
			})
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.correctionPreview.append(img)
					}
					reader.readAsDataURL(blob.data)
				})
		},
		async getExerciseSheet() {
			const res = await axios
				.get(`${this.url}/api/v1/exercise-sheets/${this.exerciseId}/`)
				.then(res => res.data)

			this.exerciseSheet = res
			if (res.pdf_url && res.png_url && res.pdf_correction_url && res.png_correction_url && res.ready_to_display) {
				await this.generateImg(res.png_url)
				if (this.showCorrection) {
					await this.generateImg(res.png_correction_url)
				}
			}
			else {
				await this.getExercisePdf()
			}
			this.exerciseSheet = await axios
				.get(`${this.url}/api/v1/exercise-sheets/${this.exerciseId}/`)
				.then(res => res.data)
		}
	},

	async created() {
		await this.getExerciseSheet()
	},
}
</script>

<style>
</style>
