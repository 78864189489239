<template>
	<h1>Difficultés</h1>
	<table>
		<tr>
			<th></th>
			<th>Name</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr
			v-for="(difficulty, index) in this.$store.state.difficulties"
			:key="index"
		>
			<td>{{ index + 1 }}</td>
			<td>{{ difficulty.name }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditDifficulty',
						params: { id: difficulty.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteDifficulty(difficulty.id)">
					supprimer
				</button>
			</td>
		</tr>
	</table>
</template>

<script>

export default {
	data() {
		return {
			difficulties: [],
		}
	},
	methods: {
		deleteDifficulty(e) {
			this.$store.dispatch('deleteDifficulty', e)
		},
	},
}
</script>

<style>
</style>
