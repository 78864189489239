<template>
	<section>
		<menu-admin />
		<user-display />


		<router-view v-slot="{ Component }">
			<transition name="route" mode="out-in">
				<component
					class="admin-wrapper"
					:class="this.$store.getters.menuToggle ? '' : 'expanded'"
					:is="Component"
				></component>
			</transition>
		</router-view>
	</section>
</template>

<script>
import MenuAdmin from './MenuAdmin.vue'
import UserDisplay from './UserDisplay.vue'
export default {
	components: {
		MenuAdmin,
		UserDisplay,
	},
	
	watch: {
		'$route'() {
			if(this.$store.state.user.subjects.length == 0 && this.$route.name != 'EditProfile') {
				confirm('Merci de compléter votre profil pour accéder aux différentes fonctionnalités')
				this.$store.state.user.subjects.length == 0 ? this.$router.push({ name: 'EditProfile' }) : null
			}
			
		},
	},

}
</script>


<style>
</style>
