<template>
	<div
		@click="isPopUpVisible = true"
		:style="getStyle()"
		class="event-container"
	>
		<div :class="getBackground()" class="event">
			<div class="class-hours-container">
				<p class="class-name">{{ getClassName(event.class) }}</p>
				<div
					class="activity-container"
					v-if="
						activities.length !== 0 ||
						evaluations.length !== 0 ||
						exerciseSheets.length !== 0 ||
						tileGames.length !== 0
					"
				>
					<p
						v-for="activity in activities"
						:key="activity"
						class="title"
					>
						{{ activity.name }}
					</p>
					<p
						v-for="evaluation in evaluations"
						:key="evaluation"
						class="title"
					>
						{{ evaluation.title }}
					</p>
					<p
						v-for="exerciseSheet in exerciseSheets"
						:key="exerciseSheet"
						class="title"
					>
						{{ exerciseSheet.title }}
					</p>
					<p
						v-for="tileGame in tileGames"
						:key="tileGame"
						class="title"
					>
						{{ getTileGameInfo(tileGame.theme).name }}
					</p>
				</div>
				<p class="room">
					Salle {{ event.room ? event.room : "inconnue" }}
				</p>
			</div>
		</div>
		<Teleport to="body">
			<pop-up-agenda-show
				@close="isPopUpVisible = false"
				@refreshActivities="removeActivity"
				@refreshEvaluations="removeEvaluation"
				@refreshExerciseSheets="removeExerciseSheet"
				@refreshTileGames="removeTileGame"
				v-if="isPopUpVisible"
				:event="event"
				:activities="activities"
				:evaluations="evaluations"
				:exerciseSheets="exerciseSheets"
				:tileGames="tileGames"
			/>
		</Teleport>
	</div>
</template>

<script>
import { getActivityByIdService } from '../../../services/activitiesService'
import { getExerciseSheetByIdService } from '../../../services/exerciseSheetsService'
import { getTileGameByIdService } from '../../../services/tileGamesService'

import { getTileGameInfo } from '../../../utils'
import PopUpAgendaShow from '../../PopUp/Agenda/PopUpAgendaShow.vue'
export default {
	components: { PopUpAgendaShow },
	props: {
		event: Object
	},
	data() {
		return {
			isPopUpVisible: false,
			activities: [],
			evaluations: [],
			exerciseSheets: [],
			tileGames: [],
		}
	},

	methods: {
		getTileGameInfo: getTileGameInfo,

		removeActivity(e) {
			this.activities = this.activities.filter(x => x.id !== e)
		},
		removeEvaluation(e) {
			this.evaluations = this.evaluations.filter(x => x.id !== e)
		},
		removeExerciseSheet(e) {
			this.exerciseSheets = this.exerciseSheets.filter(x => x.id !== e)
		},
		removeTileGame(e) {
			this.tileGames = this.tileGames.filter(x => x.id !== e)

		},
		getActivities: async function () {
			for (const id of this.event.activities) {
				const gettedActivity = await getActivityByIdService(id)
				this.activities.push(gettedActivity)
			}
		},

		getExerciseSheets: async function () {
			for (const id of this.event.exercise_sheets) {
				const gettedExerciseSheet = await getExerciseSheetByIdService(id)
				this.exerciseSheets.push(gettedExerciseSheet)
			}
		},
		async getTileGames() {
			for (const id of this.event.tile_games) {
				const gettedTileGame = await getTileGameByIdService(id)
				this.tileGames.push(gettedTileGame)
			}
		},
		getClassName(e) {
			if (e) {
				return this.$store.getters.class(e)
			}
		},
		getBackground() {
			if (this.$store.getters.classLevel(this.event.class)) {
				let color = this.$store.getters.classLevel(this.event.class).level_name
				switch (color) {
					case '6ème':
						return 'sixth'
					case '5ème':
						return 'fifth'
					case '4ème':
						return 'fourth'
					case '3ème':
						return 'third'
					case '2nde':
						return 'second'
					case '1ère Spé':
						return 'first'
					case 'Term Spé':
						return 'term'
					case 'Term Maths Expertes':
						return 'term'
					case 'Term Maths Comp':
						return 'term'
					default:
						return 'aled'
				}
			}
		},
		convertMsToHM(milliseconds) {
			let seconds = Math.floor(milliseconds / 1000)
			let minutes = Math.floor(seconds / 60)
			let hours = minutes / 60
			hours = hours % 24
			return hours
		},
		getStyle() {
			let start = new Date(this.event.dateStart)
			let end = new Date(this.event.dateEnd)
			let milliseconds = (end - start)
			let time = this.convertMsToHM(milliseconds)
			let milliseconds2 = new Date(`${this.event.day} 07:00:00`)
			let pos = this.convertMsToHM(start - milliseconds2)
			let height = `height: ${(time / 13) * 100}%;`
			let top = `top: ${(pos / 13) * 100}%;`
			let style = height + top
			return style
		}
	},

	created() {
		this.getActivities()
		this.getExerciseSheets()
		this.getTileGames()
	},

}
</script>

<style>
</style>
