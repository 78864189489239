
<template>
	<edit-proposed-key class="admin-wrapper" />
</template>

<script>
import EditProposedKey from '../../../../SuperAdmin/Key/EditProposedKey.vue'

export default {
	components: { EditProposedKey },
}
</script>

<style>
</style>

<style>
</style>
