<template>
	<pop-up-wrapper>
		<div class="pop-up-validate-class">
			<p class="title">Votre changement a été pris en compte.</p>
			<button class="submit ok" @click="$emit('close')">Ok</button>
		</div>
	</pop-up-wrapper>
</template>


<script>
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
