<template>
	<h1>Liste des exercices</h1>

	<pagination-component
		:totalPages="pages"
		:perPage="20"
		:currentPage="currentPage"
		@pageChanged="getNewQuestions"
	/>
	<div class="all-filter-container">
		<div class="all-filter-wrapper">
			<div class="filter-wrapper">
				<label for="">Niveaux</label>
				<select
					v-model="filters.levelFilter"
					@change="getNewQuestions()"
					name=""
					id=""
				>
					<option value="">------</option>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="chapter">Chapitres</label>
				<select
					@change="getNewQuestions()"
					v-model="filters.chapterFilter"
					name="chapter"
					id="chapter"
				>
					<optgroup
						v-for="sequence in this.$store.getters.sequences(
							filters.levelFilter
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="">Auteur</label>
				<select
					v-model="filters.teacherFilter"
					@change="getNewQuestions()"
					name=""
					id=""
				>
					<option value="">------</option>
					<option
						v-for="admin in admins"
						:key="admin"
						:value="admin.user"
					>
						{{ admin.first_name + admin.last_name }}
					</option>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="">Statut</label>
				<select
					v-model="filters.statusFilter"
					@change="getNewQuestions()"
					name=""
					id=""
				>
					<option selected value="">------</option>
					<option value="VALIDATED">Validé</option>
					<option value="PENDING">En attente</option>
					<option value="REFUSED">Refusé</option>
					<option value="PRIVATE">Privé</option>
				</select>
			</div>
		</div>
	</div>

	<router-link class="add-content" :to="{ name: 'SuperAdminAddExercise' }"
		>Ajouter un exercice</router-link
	>

	<table>
		<tr>
			<th></th>
			<th>Titre</th>
			<th>Auteur</th>
			<th>Séquence</th>
			<th>Niveau</th>
			<th>Voir</th>
			<th>Modifier</th>
			<th>Supprimer</th>
			<th>Statut</th>
		</tr>
		<tr v-for="(question, index) in questions" :key="index">
			<td>{{ currentPage * perPage + index + 1 }}</td>
			<td>{{ question.title }}</td>
			<td>{{ question.teacher_name }}</td>
			<td
				v-if="
					this.$store.state.sequences.length !== 0 &&
					this.$store.state.chapters.length !== 0
				"
			>
				{{ question.sequence_name }}
			</td>
			<td v-if="this.$store.state.levels.length !== 0">
				{{ question.level_name }}
			</td>
			<td>
				<button class="see" @click="showPopUpApprove(question)">
					<img src="/img/super-admin/blue-eye.svg" alt="" />
				</button>
			</td>
			<td>
				<router-link
					class="table-btn"
					v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
					:to="{
						name: 'SuperAdminEditExercise',
						params: { id: question.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button
					class="delete"
					v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
					@click="deleteQuestion(question.id)"
				>
					<img src="/img/super-admin/blue-trash.svg" alt="" />
				</button>
			</td>
			<td>
				<span
					v-if="question.status !== 'PENDING'"
					:style="getStyleColor(question.status)"
				>
					{{ translate(question.status) }}</span
				>
				<span v-else>
					<button
						:style="getStyleColor(question.status)"
						class="table-btn"
						@click="showPopUpApprove(question, true)"
					>
						En attente
					</button>
				</span>
			</td>
		</tr>
	</table>
	<pop-up-preview-exercise-admin
		v-if="isPopUpExercisePreviewVisible"
		:exercise="selectedExercise"
		:showValidateBtn="isShowValidateBtnVisible"
		@close="closePopUpPreview"
		@refreshQuestion="getNewQuestions(currentPage)"
	/>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'
import PopUpPreviewExerciseAdmin from '../PopUp/ExerciseSheet/PopUpPreviewExerciseAdmin.vue'
import PaginationComponent from '../Utils/PaginationComponent.vue'

export default {
	components: { PaginationComponent, PopUpPreviewExerciseAdmin },
	data() {
		return {
			questions: [],
			levels: [],

			count: null,
			pages: [],
			perPage: 20,
			filter: '',
			filters: {
				levelFilter: '',
				teacherFilter: '',
				chapterFilter: '',
				statusFilter: '',
			},
			currentPage: 0,
			isMounted: false,
			admins: [],
			selectedExercise: {},
			isPopUpExercisePreviewVisible: false,
			isShowValidateBtnVisible: false
		}


	},
	methods: {

		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
			}
		},
		checkIfSuperAdminAndValidatedStatus(status) {

			if (status == 'VALIDATED') {
				if (this.$store.state.user.role == 'SUPER_ADMIN')
					return true
				return false
			}
			return true
		},
		closePopUpPreview() {
			this.isPopUpExercisePreviewVisible = false
			this.isShowValidateBtnVisible = false
		},
		showPopUpApprove(exercise, isApproveMod = false) {
			this.selectedExercise = exercise
			this.isPopUpExercisePreviewVisible = true
			this.isShowValidateBtnVisible = isApproveMod
		},
		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		deleteQuestion(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/all-exercise-questions/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.getNewQuestions(this.currentPage)
					})
			}
		},

		getNewQuestions: async function (e = 0) {
			this.currentPage = e
			const filter = { ...this.filters }
			filter.currentPage = this.currentPage
			this.filter = `chapter=${this.filters.chapterFilter}&level=${this.filters.levelFilter}&teacher=${this.filters.teacherFilter}&status=${this.filters.statusFilter}&`

			// Mettre à jour l'URL avec les nouveaux paramètres du filtre avant d'appeler l'API
			this.$router.push({
				name: 'SuperAdminAllExerciseSheetQuestions',
				query: filter
			})

			await authenticatedApiFetch(`/all-exercise-questions/?offset=${this.currentPage * this.perPage}&${this.filter}`, {
				method: 'GET',
			})
				.then((res) => res.json())
				.then((res) => {
					this.count = res.count
					this.questions = res.results
					this.pages = Math.ceil(this.count / this.perPage)
				})
		},
		allAdmin: function () {
			authenticatedApiFetch(`/admin-profile/`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.admins = res
				})
		}
	},
	async mounted() {
		this.allAdmin()
		// Récupérer les paramètres de requête de l'URL et les stocker dans les données du composant
		this.filters.levelFilter = this.$route.query.levelFilter || ''
		this.filters.chapterFilter = this.$route.query.chapterFilter || ''
		this.filters.teacherFilter = this.$route.query.teacherFilter || ''
		this.filters.statusFilter = this.$route.query.statusFilter || ''
		this.currentPage = parseInt(this.$route.query.currentPage) || 0
		// Vous pouvez ajouter plus de paramètres ici selon vos besoins
		await this.getNewQuestions(this.currentPage)
		this.isMounted = true

	},



	watch: {
		'filters.levelFilter': function () {
			// Nouvelle valeur de filters.levelFilter
			// Ancienne valeur de filters.levelFilter
			this.filters.chapterFilter = '' // Réinitialiser la valeur de chapterFilter à une chaîne vide
		},
		filters: {
			handler() {


				// Appeler l'API pour récupérer les questions filtrées
				this.getNewQuestions(this.currentPage)
			},
			deep: true
		},
		$route(to) {
			if (to.name == 'SuperAdminAllExerciseSheetQuestions') {
				this.filters.levelFilter = to.query.levelFilter || ''
				this.filters.chapterFilter = to.query.chapterFilter || ''
				this.filters.teacherFilter = to.query.teacherFilter || ''
				this.filters.statusFilter = to.query.statusFilter || ''
				this.currentPage = parseInt(this.$route.query.currentPage) || 0
			}
		}
	}
}
</script>

<style>
</style>
