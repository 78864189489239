export default {

    // Return a single className
    class: (state) => (id) => {
        if (state.classes) {
            return state.classes.find(classe => classe.id == id).name
        }
    },
    classLevel: (state) => (id) => {
        if (state.classes) {
            let classe = state.classes.find(classe => classe.id == id)
            return classe
        }
    },
    sequence: (state) => (id) => {
        if (state.sequences) {
            return state.sequences.find(sequence => sequence.id == id)
        }
    },
    sequences: (state) => (id) => {
        if (state.sequences && id) {
            const sequences = state.sequences.filter(x => x.level == id)
            return sequences
        }
    },
	sequencesByLevelAndSubject: (state) => (levelId, subjectId) => {

		if (state.sequences && levelId && subjectId) {
			const sequences = state.sequences.filter(x => x.level == levelId && x.subject == subjectId)
			return sequences
		}
	},
	subject: (state) => (id) => {
		if (state.subjects) {
			return state.subjects.find(subject => subject.id == id)
		}
	},
    level: (state) => (id) => {
        if (state.levels && state.sequences) {
            let level = state.levels.find(level => level.id == id)
            return level
        }
    },
	levels: (state) => (ids) => {
		if (state.levels && ids) {
			const levels = state.levels.filter(x => ids.includes(x.id))
			return levels
		}
	},
	subjects: (state) => (ids) => {
		if (state.subjects && ids) {
			const subjects = state.subjects.filter(x => ids.includes(x.id))
			return subjects
		}
	},
    chapters: (state) => (sequenceId) => {
        if (state.chapters) {
            const chapters = state.chapters.filter(x => x.sequence == sequenceId)
			const orderedChapters = chapters.sort((a, b) => a.id - b.id)
            return orderedChapters
        }
    },
	
    chaptersBySequenceLevel: (state) => (id) => {
        if (state.chapters) {
            const chapters = state.chapters.filter(x => x.sequence_level == id)
            return chapters
        }
    },
    chapter: (state) => (id) => {
        if (state.chapters) {
            let chapter = state.chapters.find(chapter => chapter.id == id)
            return chapter
        }
    },
    menuToggle: (state) => {
        if (state.isMenuToggled)
            return state.isMenuToggled
		console.log('menuToggle', state.isMenuToggled)
    },
	
}
