<template>
	<edit-question class="admin-wrapper" />
</template>

<script>
import EditQuestion from '../../../../SuperAdmin/Flash/EditQuestion.vue'

export default {
	components: { EditQuestion },
}
</script>

<style>
</style>
