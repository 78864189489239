import { authenticatedApiFetch } from '../utils'

export const getKeyByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/keys/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const getLastThreeKeyService = async () => {
    const res = await authenticatedApiFetch('/keys/', { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))

    const key = res.results.slice(-3)
    return key
}


export const allkeysFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/keys/${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
