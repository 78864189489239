<template>
	<div v-if="product" class="each-price-wrapper">
		<input
			:class="isAlreadyOwned ? 'disabled' : ''"
			v-model="check"
			:id="product.title"
			type="checkbox"
		/>
		<label
			:class="{
				checked: check ? true : false,
				already_owned: isAlreadyOwned ? true : false,
			}"
			:for="product.title"
			class="text-container"
		>
			<img :src="product.img" alt="" />
			<div class="text-price-container">
				<div class="title-text-container">
					<p class="title" :style="`color:${product.color}`">
						{{ product.title }}
					</p>
					<p>{{ product.text }}</p>
				</div>
				<p class="price">72€</p>
			</div>
		</label>
	</div>
</template>

<script>
export default {
	props: {
		product:{ 
			type:Object,
			required:true
		},
		isAlreadyOwned: Boolean
	},
	data() {
		return {
			check: false
		}
	},
	methods: {

	},
	watch: {
		check() {
			this.$emit('updateSelected', this.product.title)

		},
		alreadyOwned() {
			this.check = this.isAlreadyOwned
		}
	},
	mounted() {
		if (this.isAlreadyOwned) {
			this.check = true
		}
		else {
			this.check = false
		}
	},
}
</script>

<style lang="scss" scoped>
</style>
