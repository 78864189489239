<template>
	<pop-up-wrapper>
		<div class="pop-up suggest-question">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Proposer un contenu</p>
			<form @submit.prevent="showPopUpFillInputVisible">
				<div class="input-container">
					<label for="niveaux">Titre</label>
					<input type="text" v-model="form.title" />
				</div>
				<div class="input-container half-size">
					<label for="niveaux">Niveau</label>
					<select multiple id="niveaux" v-model="form.levels">
						<option
							v-for="level in this.$store.getters.levels(this.$store.state.user.levels)"
							:key="level"
							:value="level.id"
						>
							{{ level.name }}
						</option>
					</select>
				</div>
				
				<div class="input-container checkbox half-size">
					<input v-model="share" id="share" type="checkbox" />
					<label for="share">Mode privé </label> <br />
					<p>
						(votre question sera accessible uniquement par vous.
						Elle ne sera pas soumise à une étape de vérification
						pour faire partie de la banque de question publique)
					</p>
				</div>
				<div class="input-container half-size">
					<label for="subjects">Matières</label>
					<select multiple id="subjects" v-model="form.subjects">
						<option
							v-for="subject in this.$store.getters.subjects(this.$store.state.user.subjects)"
							:key="subject"
							:value="subject.id"
						>
							{{ subject.name }}
						</option>
					</select>
				</div>
				<div class="input-container">
					<label for="niveaux">Type de question</label>
					<select id="niveaux" v-model="form.type">
						<option value="Exercices">
							Exercices
						</option>
						<option value="Enigmes">Enigmes</option>
						<option value="Défis par équipe">
							Défis par équipe
						</option>
						<option value="Constructions géométriques">
							Illustrations / Schémas
						</option>
						<option value="Enigmes à distance">
							Enigmes à distance
						</option>
						<option value="Messages pour le 25 Décembre">
							Messages pour le 25 Décembre
						</option>
						<option value="Vidéos à distance">
							Vidéos à distance
						</option>
					</select>
				</div>

				<div class="input-container">
					<label for="">Contenu</label>
					<div class="checkbox-container">
						<label for="txt">
							<input
								v-model="template"
								:value="0"
								id="txt"
								type="radio"
								:disabled="form.type == 'Vidéos à distance'"
							/>Texte Uniquement
						</label>
						<label for="txt-img">
							<input
								v-model="template"
								:value="1"
								id="txt-img"
								type="radio"
								:disabled="form.type == 'Vidéos à distance'"
							/>Texte + Image mathématique
						</label>
						<label for="txt-img-math">
							<input
								v-model="template"
								:value="2"
								id="txt-img-math"
								type="radio"
								:disabled="form.type !== 'Vidéos à distance'"
							/>Vidéo uniquement
						</label>
						<label for="img">
							<input
								v-model="template"
								:value="3"
								id="img"
								type="radio"
								:disabled="form.type == 'Vidéos à distance'"
							/>Image uniquement
						</label>
					</div>
				</div>

				<button class="submit dark">Valider</button>
			</form>
		</div>
		<Teleport to="body">
			<pop-up-fill-input
				v-if="isPopUpFillInputVisible"
				:type="form.type"
				:theme="themes[0]"
				:template="template"
				@close="showPopUpFillInputVisible"
				@submit="submit"
			/>
		</Teleport>
	</pop-up-wrapper>
</template>

<script>
import { createChristmasCalendarExerciseService } from '../../../../services/christmasCalendarExercisesService'
import PopUpWrapper from '../../PopUpWrapper.vue'
import PopUpFillInput from './PopUpFillInput.vue'
export default {
	components: { PopUpWrapper, PopUpFillInput },
	data() {
		return {
			form: {
				title: '',
				text: '',
				teacher: null,
				correction: '',
				tile_game_type_id: null,
				suggested_img_url: '',
				levels: [],
				subjects: [],
				status: ''
			},
			share: false,
			tileGameThemes: [],
			themes: [],
			template: 0,
			isPopUpFillInputVisible: false,
		}
	},

	methods: {
		getCustomTypeName(name) {
			switch (name) {
				case 'Flash':
					return 'Flash'
				case 'OneShot':
					return 'OneShot'
				case 'Enigma':
					return 'Enigma'
				case 'Boomerang':
					return 'Boomerang'
				case 'HitOrMiss':
					return 'Chance'
			}
		},


		getFormDataValue(content) {
			const data = new FormData()
			data.append('title', this.form.title)
			data.append('text', content.text)
			data.append('correction', content.correction)
			data.append('status', this.form.status)
			data.append('teacher', this.$store.state.user.user)
			for (const level of this.form.levels) {
				data.append('levels', level)
			}
			for (const subject of this.form.subjects) {
				data.append('subjects', subject)
			}
			if (this.template == 1 || this.template == 3) {
				data.append('image_url', content.image_url)
			}
			if (this.template == 2) {
				data.append('video_url', content.video_url)
			}
			data.append('type', this.form.type)
			return data
		},
		checkErrors() {
			if (this.form.title == '') {
				this.$store.state.toast = {
					text: "Merci de remplir le champ titre",
					status: 'error'
				}
				return false
			}
			if (this.form.levels.length == 0) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un ou plusieurs niveaux",
					status: 'error'
				}
				return false
			}
			if (!this.form.type) {
				this.$store.state.toast = {
					text: "Merci de sélectionner un type de question",
					status: 'error'
				}
				return false
			}

			return true
		},
		showPopUpFillInputVisible() {
			if (this.checkErrors()) {
				this.isPopUpFillInputVisible = !this.isPopUpFillInputVisible
			}
		},

		async submit(data) {
			this.form.teacher = this.$store.state.user.user
			this.form.status = this.share ? 'PRIVATE' : 'PENDING'
			await createChristmasCalendarExerciseService(this.getFormDataValue(data))
			this.$emit('addQuestion', this.form)
			this.$emit('close')
		},
	},

	watch: {
		form: {
			handler(newValue) {
				if (newValue.type == 'Vidéos à distance') {
					this.template = 2
				}
				else {
					this.template = 0
				}
			},
			deep: true
		},
	},
}
</script>

<style>
</style>
