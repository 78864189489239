<template>
	<h1>Activités Expresso</h1>

	<pagination-component
		:totalPages="pages"
		:perPage="20"
		:currentPage="currentPage"
		@pageChanged="getNewQuestions"
	/>
	<div class="all-filter-container">
		<div class="all-filter-wrapper">
			<div class="filter-wrapper">
				<label for="">Niveaux</label>
				<select
					v-model="levelFilter"
					@change="questionFilter()"
					name=""
					id=""
				>
					<option value="">------</option>
					<option
						v-for="level in this.$store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="chapter">Chapitres</label>
				<select
					@change="questionFilter()"
					v-model="chapterFilter"
					name="chapter"
					id="chapter"
				>
					<optgroup
						v-for="sequence in this.$store.getters.sequences(
							levelFilter
						)"
						:key="sequence.id"
						:label="sequence.name"
					>
						<option
							v-for="chapter in this.$store.getters.chapters(
								sequence.id
							)"
							:key="chapter.id"
							:value="chapter.id"
						>
							{{ chapter.name }}
						</option>
					</optgroup>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="">Auteur</label>
				<select
					v-model="teacherFilter"
					@change="questionFilter()"
					name=""
					id=""
				>
					<option value="">------</option>
					<option
						v-for="admin in admins"
						:key="admin"
						:value="admin.user"
					>
						{{ admin.first_name + admin.last_name }}
					</option>
				</select>
			</div>
			<div class="filter-wrapper">
				<label for="">Statut</label>
				<select
					v-model="statusFilter"
					@change="questionFilter()"
					name=""
					id=""
				>
					<option selected value="">------</option>
					<option value="VALIDATED">Validé</option>
					<option value="PENDING">En attente</option>
					<option value="REFUSED">Refusé</option>
					<option value="PRIVATE">Privé</option>
				</select>
			</div>
		</div>
	</div>

	<router-link class="add-content" :to="{ name: 'SuperAdminProposeKey' }"
		>Ajouter une Activité Expresso</router-link
	>

	<table>
		<tr>
			<th></th>
			<th>Titre</th>
			<th>Auteur</th>
			<th>Chapitre</th>
			<th>Niveau</th>
			<th>Voir</th>
			<th>Modifier</th>
			<th>Supprimer</th>
			<th>Statut</th>
		</tr>
		<tr v-for="(question, index) in keys" :key="index">
			<td>{{ currentPage * perPage + index + 1 }}</td>
			<td>{{ question.title }}</td>
			<td>{{ question.teacher_name }}</td>
			<td
				v-if="
					this.$store.state.sequences.length !== 0 &&
					this.$store.state.chapters.length !== 0
				"
			>
				{{ question.chapter_name }}
			</td>
			<td v-if="this.$store.state.levels.length !== 0">
				{{ question.level_name }}
			</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminViewProposedKey',
						params: { id: question.id },
					}"
					class="see"
				>
					<img src="/img/super-admin/blue-eye.svg" alt="" />
				</router-link>
			</td>
			<td>
				<router-link
					class="table-btn"
					v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
					:to="{
						name: 'SuperAdminEditProposedKey',
						params: { id: question.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button
					class="delete"
					v-if="checkIfSuperAdminAndValidatedStatus(question.status)"
					@click="deleteQuestion(question.id)"
				>
					<img src="/img/super-admin/blue-trash.svg" alt="" />
				</button>
			</td>
			<td>
				<span
					v-if="question.status !== 'PENDING'"
					:style="getStyleColor(question.status)"
				>
					{{ translate(question.status) }}</span
				>
				<span v-else>
					<router-link
						:style="getStyleColor(question.status)"
						:to="{
							name: 'SuperAdminViewProposedKeyApproveMod',
							params: { id: question.id },
						}"
						class="table-btn"
					>
						A&#160;approuver
					</router-link>
				</span>
			</td>
		</tr>
	</table>
</template>

<script>
import { allProposedKeyFilterService, allProposedKeysService, deleteProposedKeyService } from '../../../services/proposedKeysService'
import { authenticatedApiFetch } from '../../../utils'
import PaginationComponent from '../../Utils/PaginationComponent.vue'

export default {
	components: { PaginationComponent },
	data() {
		return {
			keys: [],
			levelFilter: '',
			teacherFilter: '',
			chapterFilter: '',
			statusFilter: '',
			count: null,
			currentPage: 0,
			pages: [],
			perPage: 20,
			filter: '',
			admins: [],
			selectedExercise: {},
			isPopUpExercisePreviewVisible: false,
			isShowValidateBtnVisible: false
		}


	},
	methods: {

		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
			}
		},
		checkIfSuperAdminAndValidatedStatus(status) {

			if (status == 'VALIDATED') {
				if (this.$store.state.user.role == 'SUPER_ADMIN')
					return true
				return false
			}
			return true
		},
		closePopUpPreview() {
			this.isPopUpExercisePreviewVisible = false
			this.isShowValidateBtnVisible = false

		},

		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		async deleteQuestion(e) {
			if (confirm("Do you really want to delete?")) {
				await deleteProposedKeyService(e)
				await this.getNewQuestions(this.currentPage)
			}
		},
		async questionFilter() {
			this.filter = `chapter=${this.chapterFilter}&level=${this.levelFilter}&teacher=${this.teacherFilter}&status=${this.statusFilter}&`
			const res = await allProposedKeyFilterService(this.filter)
			this.count = res.count
			this.currentPage = 0
			this.keys = res.results
			this.pages = Math.ceil(this.count / 20)
		},
		async getNewQuestions(e) {
			const filter = `?offset=${e * 20}&${this.filter}`
			const res = await allProposedKeyFilterService(filter)
			this.count = res.count
			this.currentPage = e
			this.keys = res.results
			this.pages = Math.ceil(this.count / 20)
		},
		async allQuestions() {
			const res = await allProposedKeysService()
			this.count = res.count
			this.keys = res.results
			this.pages = Math.ceil(this.count / 20)
		},
		async allAdmin() {
			this.admins = await authenticatedApiFetch(`/admin-profile/`, { method: 'GET' })
				.then(res => res.json())

		}
	},
	created() {
		this.allQuestions()
		this.allAdmin()
	},

	watch: {
		levelFilter: function () {
			this.chapterFilter = ''
		}
	}
}
</script>

<style>
</style>
