<template>
	<pop-up-wrapper>
		<div class="pop-up-order" :class="interrogationMode ? 'active' : ''">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title" v-if="!interrogationMode">Mode entraînement</p>
			<p class="title" v-else>Mode interrogation</p>
			<p class="subtitle" v-if="interrogationMode">
				L'écran sera séparé en deux et il sera proposé 2 séries de
				questions. <br />
				Cela est utile pour éviter la triche lors d'interrogation de
				questions flash.
			</p>

			<div class="drag-wrapper">
				<div class="draggable-title-container">
					<h3 v-if="interrogationMode">Sujet A</h3>
					<draggable
						:list="questionsList"
						:disabled="!enabled"
						item-key="name"
						class="drag-container"
						ghost-class="ghost"
						@start="dragging = true"
						@end="end"
					>
						<h2>Sujet A</h2>
						<template #item="{ element }">
							<div
								class="drag-el"
								:class="{ 'not-draggable': !enabled }"
							>
								<div
									class="text"
									@click="previewQuestion(element.id)"
								>
									<img
										class="drag"
										src="/img/drag.png"
										alt=""
									/>
									<p>{{ element.title }}</p>
								</div>
								<div>
									<img
										class="remove"
										@click="remove(element)"
										src="/img/close.png"
										alt=""
									/>
								</div>
							</div>
						</template>
					</draggable>
				</div>
				<div v-if="interrogationMode" class="draggable-title-container">
					<h3>Sujet B</h3>
					<draggable
						:list="questionsList2"
						:disabled="!enabled"
						item-key="name"
						class="drag-container"
						ghost-class="ghost"
						@start="dragging = true"
						@end="end()"
					>
						<template #item="{ element }">
							<div
								class="drag-el"
								:class="{ 'not-draggable': !enabled }"
							>
								<div
									class="text"
									@click="previewQuestion(element.id)"
								>
									<img
										class="drag"
										src="/img/drag.png"
										alt=""
									/>
									<p>{{ element.title }}</p>
								</div>
								<div>
									<img
										class="remove"
										@click="remove(element)"
										src="/img/close.png"
										alt=""
									/>
								</div>
							</div>
						</template>
					</draggable>
				</div>
			</div>
			<div class="switch-container">
				<p class="first">
					<img src="/img/pop-up/simple.svg" alt="" />Enoncé simple
				</p>
				<label class="switch">
					<input
						@click="setInterrogationMode"
						v-model="interrogationMode"
						type="checkbox"
					/>
					<span class="slider round"></span>
				</label>
				<p><img src="/img/pop-up/double.svg" alt="" />Enoncé double</p>
			</div>

			<div class="btn-container">
				<button @click="submit" class="submit dark">Suivant</button>
			</div>
		</div>
		<div v-if="showQuestion">
			<pop-up-preview-question
				@close="showQuestion = false"
				:id="currentQuestion"
			/>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedImageFetch, random } from '../../utils'
import draggable from "vuedraggable"
import PopUpPreviewQuestion from './PopUpPreviewQuestion.vue'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	order: 0,
	components: {
		draggable,
		PopUpPreviewQuestion,
		PopUpWrapper
	},
	props: {
		questions: Array
	},
	data() {
		return {
			questionsList: [],
			questionsList2: [],
			dragging: false,
			enabled: true,
			showQuestion: false,
			currentQuestion: null,
			imageData: '',
			interrogationMode: false,
		}
	},
	methods: {
		end() {
			this.dragging = false
			this.reRender()
		},
		setInterrogationMode() {
			this.interrogationMode = !this.interrogationMode
			this.questionsList2 = JSON.parse(JSON.stringify(this.questionsList))
			if (this.interrogationMode) {
				for (const question of this.questionsList2) {

					let regex = /\\randint{(.*?)}/g
					let regex2 = /\\randfloat{(.*?)}/g

					// find regex expression
					let foundedInt = [...question.description.matchAll(regex)]
					let foundedFloat = [...question.description.matchAll(regex2)]


					let founded = [...foundedInt, ...foundedFloat]

					// re order value
					founded = founded.sort(function (a, b) {
						return a.index - b.index
					})

					let random_values = []
					for (const found of founded) {

						// calculate new random value
						let value
						let arr

						if (found[0].match('randint')) {
							// remove randint{ and last } 
							let sliced = found[0].slice(9).slice(0, -1)

							// split string -> aa, valueA, valueB
							arr = sliced.split(/[;\s]/)

							value = Math.floor(Math.random() * (parseInt(arr[2]) - parseInt(arr[1]) + 1) + parseInt(arr[1]))
						}
						else {
							// remove randint{ and last } 
							let sliced = found[0].slice(11).slice(0, -1)

							// split string -> aa, valueA, valueB
							arr = sliced.split(/[;\s]/)

							value = (Math.random() * (parseInt(arr[2]) - parseInt(arr[1]) + 1) + parseInt(arr[1])).toFixed(parseInt(arr[3]))
							value = value.replace(".", ",")
						}

						// push it in array
						random_values.push([arr[0], value])
					}

					// change random value 
					for (const value of random_values) {
						question.random_values[value[0]] = value[1]
					}

				}
			}
			else {
				this.questionsList2 = []
			}
			this.reRender()
		},
		previewQuestion(e) {
			this.showQuestion = true
			this.currentQuestion = e
		},
		close() {
			this.$emit('closed')
		},
		random: random,
		image(str) {
			var image = null
			if (str.match(/\\picture(.*?)/g)) {
				image = str.match(/\\picture(.*?)/g)
			}
			if (image === null) {
				return str
			}
			let newImage = '<img class="inside-img" src="' + this.imageData + '" alt="" />'
			str = str.replace(image, newImage)
			return str
		},
		getInsideImage() {
			var image = this.question.image2
			if (image !== null) {
				authenticatedImageFetch(image, {
					method: "GET",
				})
					.then((response) => response.blob())
					.then((blob) => {
						const reader = new FileReader()
						reader.onload = (e) => {
							this.imageData = e.target.result
						}
						reader.readAsDataURL(blob)
					})
			}
		},
		submit() {
			let orderedQuestions = []
			let orderedQuestions2 = []
			for (let i = 0; i < this.questionsList.length; i++) {
				orderedQuestions.push({
					"id": this.questionsList[i].id,
					"title": this.questionsList[i].title,
					"description": this.random(this.questionsList[i].description, this.questionsList[i].random_values),
					"correction": this.random(this.questionsList[i].correction, this.questionsList[i].random_values),
					"image_url_1": this.questionsList[i].image_url_1,
					"image_url_2": this.questionsList[i].image_url_2,
					"level": this.questionsList[i].level,
					"type": this.questionsList[i].type,
					"theme": this.questionsList[i].theme,
					"difficulty": this.questionsList[i].difficulty,
					"chapter": this.questionsList[i].chapter,
					"order": i
				})
			}
			for (let i = 0; i < this.questionsList2.length; i++) {
				orderedQuestions2.push({
					"id": this.questionsList2[i].id,
					"title": this.questionsList2[i].title,
					"description": this.random(this.questionsList2[i].description, this.questionsList2[i].random_values),
					"image_url_1": this.questionsList2[i].image_url_1,
					"image_url_2": this.questionsList2[i].image_url_2,
					"level": this.questionsList2[i].level,
					"type": this.questionsList2[i].type,
					"theme": this.questionsList2[i].theme,
					"difficulty": this.questionsList2[i].difficulty,
					"chapter": this.questionsList2[i].chapter,
					"order": i
				})
			}

			if (this.questionsList2.length !== 0) {
				this.$emit('showPopUp2', orderedQuestions, orderedQuestions2)
			}
			else {
				this.$emit('showPopUp2', orderedQuestions, [])
			}
			this.$emit('close')
		},
		checkIndex(e) {
			this.form.index = e
		},

		orderQuestions() {
			let result = []
			this.questions.forEach((e) => {
				result.push(this.questionsList.find(x => x.id == e))
			})
			this.questionsList = result
		},
		remove(e) {
			let index = this.questionsList.findIndex(x => x.id == e.id)
			this.questionsList.splice(index, 1)
			if (this.questionsList2.length !== 0) {
				let index = this.questionsList2.findIndex(x => x.id == e.id)
				this.questionsList2.splice(index, 1)
			}
			if (e.random_values) {
				this.$emit('removeQuestionRandom', e.id)
			}
			else {
				this.$emit('addQuestion', e.id)
			}
			this.reRender()
			if (this.questionsList.length == 0) {
				this.$emit('close')
			}
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		for (let i = 0; i < this.questions.length; i++) {
			this.questionsList.push(this.questions[i])
		}
	},
	mounted() {
		this.reRender()
	},
	watch: {
		interrogationMode: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		questionsList: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		},
		questionsList2: function () {
			this.$nextTick().then(() => {
				this.reRender()
			})
		}
	}
}
</script>

<style>
</style>
