<template>
	<pop-up-wrapper>
		<div class="pop-up-exo-calendar soluce">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Correction</p>
			<div class="tab-container">
				<table id="correction" ref="dl" class="tab">
					<thead>
						<tr>
							<th>Jours</th>
							<th>Titres</th>
							<th style="text-align: left">Solutions</th>
						</tr>
					</thead>
					<tbody>
						<tr v-for="exo in exercises" :key="exo.id">
							<td>{{ exo.order }}</td>
							<td>{{ exo.title }}</td>
							<td>{{ exo.correction }}</td>
						</tr>
					</tbody>
				</table>
			</div>
			<button class="submit btn" @click="download">Télécharger</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
import jsPDF from 'jspdf'
import html2canvas from 'html2canvas'
import { getChristmasCalendarService } from '../../../../services/christmasCalendarsService'
import { getChristmasCalendarExerciseService } from '../../../../services/christmasCalendarExercisesService'
import { allChristmasCalendarExerciseOrdersFilterService } from '../../../../services/christmasCalendarExerciseOrdersService'


export default {
	components: { PopUpWrapper },
	props: {
		id: Number

	},
	data() {
		return {
			calendar: {},
			exercisesOrder: [],
			exercises: []
		}
	},

	methods: {
		async download() {
			const content = document.querySelector('#correction')
			const HTML_Width = content.clientWidth
			const HTML_Height = content.clientHeight
			const PDF_Width = HTML_Width
			const PDF_Height = (PDF_Width * 1.5)
			const canvas_image_width = HTML_Width
			const canvas_image_height = HTML_Height

			const totalPDFPages = Math.ceil(HTML_Height / PDF_Height)

			await html2canvas(content, {
				allowTaint: false, quality: 1,
				scale: 2,
			}).then(function (canvas) {
				canvas.getContext('2d')

				let imgData = canvas.toDataURL("image/jpg", 1.0)
				let pdf = new jsPDF('p', 'pt', [PDF_Width, PDF_Height], true)
				pdf.addImage(imgData, 'JPG', 0, 0, canvas_image_width, canvas_image_height, 'SLOW')


				for (let i = 1; i < totalPDFPages; i++) {
					pdf.addPage([PDF_Width, PDF_Height])
					pdf.addImage(imgData, 'JPG', 0, -(PDF_Height * i) + 0, canvas_image_width, canvas_image_height)
				}
				pdf.save(`correction`)
			})
		},
		async getCalendar() {
			this.calendar = await getChristmasCalendarService(this.id)
		},
		async getExercisesOrder() {
			const filter = `?christmas_calendar_id=${this.id}`
			this.exercisesOrder = await allChristmasCalendarExerciseOrdersFilterService(filter)
		},
		async getExercises() {
			for (const exo of this.exercisesOrder) {
				console.log(exo)
				const res = await getChristmasCalendarExerciseService(exo.christmas_calendar_exercise_id)
				let exercise = res
				exercise.order = exo.order
				this.exercises.push(exercise)
			}
			this.exercises.sort((a, b) => {
				return a.order - b.order
			})
		},
	},

	async created() {
		await this.getCalendar()
		await this.getExercisesOrder()
		await this.getExercises()
	},
}
</script>

<style>
</style>
