import { authenticatedApiFetch } from '../utils'


export const allExerciseSheetsService = async () => {
    const res = await authenticatedApiFetch(`/exercise-sheets/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res.results
}

export const allExerciseSheetsForTeacherService = async (userId) => {
    const res = await authenticatedApiFetch(`/exercise-sheets/?teacher=${userId}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res.results
}

export const allExerciseSheetsForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/exercise-sheets/?classes=${classId}&all`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const getExerciseSheetByIdService = async (id) => {
    const res = await authenticatedApiFetch(`/exercise-sheets/${id}/`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const createExerciseSheetService = async (data) => {
    const res = await authenticatedApiFetch(`/exercise-sheets/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateExerciseSheetService = async (id, data) => {
    const res = await authenticatedApiFetch(`/exercise-sheets/${id}/`, { method: 'PATCH', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
