<template>
	<section
		v-if="this.tileGameType || inheritedQuestion"
		class="question-page-container"
	>
		<div :style="getBackground()" class="suggest-question-container">
			<div class="logo-title-container">
				<img
					class="logo"
					:src="`/img/game/tiles/slide/icon/${tileGameTheme}/${getTypeRealName(
						tileGameType
					)}.svg`"
					alt=""
				/>
			</div>
			<h1>{{ question.title }}</h1>
			<div class="content-container">
				<div
					:style="!question.decorative_image ? 'margin-left:0' : ''"
					class="img-text-container"
				>
					<div class="shadow"></div>
					<div
						v-if="
							question.decorative_image ||
							getTypeRealName(tileGameType) == 'interogation'
						"
						:class="
							getTypeRealName(tileGameType) == 'interogation'
								? 'no-shadow'
								: ''
						"
						class="img-container"
					>
						<div
							:class="
								getTypeRealName(tileGameType) == 'interogation'
									? 'smile'
									: ''
							"
							class="file"
						>
							<img
								v-if="
									getTypeRealName(tileGameType) ==
									'interogation'
								"
								class="smiley"
								:src="getSmiley()"
								alt=""
							/>
							<img
								v-else
								class="photo"
								:src="getImg(question.decorative_image)"
								alt=""
							/>
						</div>
					</div>
					<div class="text-container">
						<p v-html="formattedText(question.text)"></p>
					</div>
					<div v-if="question.image" class="math-img">
						<img
							class="photo"
							:src="getImg(question.image)"
							alt=""
						/>
					</div>
				</div>
			</div>
		</div>
		<button
			v-if="!inheritedQuestion"
			class="go-back"
			@click="$router.go(-1)"
		>
			<img src="/img/game/tiles/go-back.svg" alt="" />
		</button>
		<div class="validate-container">
			<button
				v-if="!inheritedQuestion"
				class="validate"
				@click="deleteQuestion"
			>
				Valider
			</button>
		</div>
		<pop-up-allocate-points
			v-if="showPopUpAllocate"
			@close="showPopUpAllocate = !showPopUpAllocate"
			:tileGameId="tileGameId"
		/>
	</section>
</template>

<script>
import Config from '../../../../../config'
import { deleteTileGameContentOrderService, getTileGameContentOrderService } from '../../../../../services/tileGameContentsOrderService'
import { getTileGameContentService } from '../../../../../services/tileGameContentsService'
import { getTileGameByIdService } from '../../../../../services/tileGamesService'
import { allTileGameTeamsFilterService } from '../../../../../services/tileGameTeamsService'
import { authenticatedImageFetch } from '../../../../../utils'
import PopUpAllocatePoints from '../../../../PopUp/SeriousGame/TileGame/PopUpAllocatePoints.vue'
export default {
	props: {
		inheritedQuestion: Object,
		inheritedTileGameTheme: String
	},
	components: { PopUpAllocatePoints },
	data() {
		return {
			question: {},
			showPopUpAllocate: false,
			teams: [],
			isLoading: false,
			url: `${Config.backend_url}/media/`,
			tileGameId: null,
			tileGameTheme: '',
			tileGameType: ''
		}
	},
	methods: {
		getImg(img) {
			const url = Config.backend_url + '/media/' + img
			return url
		},
		getSmiley() {
			return `/img/game/tiles/smiley/smiley-${this.tileGameTheme}.png`
		},
		formattedText(text) {
			const formattedText = text.replace(/\n/g, '<br>')
			return formattedText
		},
		getTypeRealName(name) {
			switch (name) {
				case 'OneShot':
					return 'target'
				case 'Flash':
					return 'timer'
				case 'Enigma':
					return 'enigma'
				case 'HitOrMiss':
					return 'interogation'
				case 'Boomerang':
					return 'boomerang'
			}
		},
		getBackground() {
			const style = `background-image: url(/img/game/tiles/slide/bg/${this.tileGameTheme}/bg-${this.getTypeRealName(this.tileGameType)}.svg)`
			return style
		},
		getImage(file) {
			authenticatedImageFetch(file, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					const component = this
					reader.onload = function () {
						const img = new Image()
						img.src = this.result
						component.$refs.imgContainer.append(img)
					}
					reader.readAsDataURL(blob)
				})
		},
		getQuestion: async function () {
			const res = await getTileGameContentOrderService(this.$route.params.id)
			this.tileGameId = res.tile_game_id
			const tileGame = await getTileGameByIdService(this.tileGameId)

			this.tileGameTheme = tileGame.theme

			this.question = await getTileGameContentService(res.tile_game_content_id)
			this.tileGameType = this.question.tile_game_type_name

		},
		deleteQuestion: async function () {
			if (this.teams.length !== 0) {
				this.showPopUpAllocate = !this.showPopUpAllocate
			}
			else {
				await deleteTileGameContentOrderService(this.$route.params.id)
				this.$router.go(-1)
			}
		},
		async getTeams() {
			const filter = `?tile_game_id=${this.tileGameId}`
			this.teams = await allTileGameTeamsFilterService(filter)
		},
	},
	async created() {
		this.isLoading = true
		if (this.inheritedQuestion) {
			this.question = this.inheritedQuestion
			this.tileGameTheme = this.inheritedTileGameTheme
			this.tileGameType = this.inheritedQuestion.tile_game_type_name
		}
		else {
			await this.getQuestion()
			await this.getTeams()
		}
		if (this.question.suggested_img_url) {
			this.getImage(this.question.suggested_img_url)
		}
		this.isLoading = false
	},
}
</script>

<style>
</style>
