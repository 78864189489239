import Config from '../config'
import { createClassesService, deleteClassesService, getClassesService, updateClassesService } from '../services/classesService'
import { getProfileByIdService } from '../services/profilesService'
import { getSubscriptionResourceLevelsByIdService } from '../services/subscriptionResourceLevelsService'
import { authenticatedApiFetch } from '../utils'

export default {
    setUserInfo(state, info) {
        this.state.user = info
    },
    async getSubscription() {
        const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
            .then(response => response.json())
            .then(response => {
                return response[0]
            })
        if (res) {
            this.state.freeTrial = {
                free_trial_start: res.free_trial_start,
                free_trial_end: res.free_trial_end,
            }
        }
        else {
            this.state.freeTrial = {
                free_trial_start: null,
                free_trial_end: null,
            }
        }
    },
    async getProfile(state, id) {
        const response = await getProfileByIdService(id)
		localStorage.setItem('profile', JSON.stringify(response))

        if (response.code == "token_not_valid") {
            location.replace(`${Config.landing_url}/auth/login`)
        }
        this.state.user = response

        const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
            .then(response => response.json())
            .then(response => {
                return response[0]
            })
        if (res) {
            this.state.freeTrial = {
                free_trial_start: res.free_trial_start,
                free_trial_end: res.free_trial_end,
            }
        }
        else {
            this.state.freeTrial = {
                free_trial_start: null,
                free_trial_end: null,
            }
        }

        this.state.rightFor = []
        if (res) {
            for await (const subscriptionResource of res.subscription_resource_level) {

                const res = await getSubscriptionResourceLevelsByIdService(subscriptionResource)
                this.state.rightFor.push({
                    id: res.id,
                    resourceType: res.resource_name,
                    levels: res.levels_id,
                    subscription_start: res.subscription_start,
                    subscription_end: res.subscription_end,
                })
            }
        }

    },
    logout() {
        localStorage.token = null
        localStorage.userId = null
        this.state.user = {
            userId: '',
            token: '',
            diploma: "",
            first_name: "",
            last_name: "",
            levels: [],
            role: "",
            school: "",
            subjects: [],
        }
        this.state.classes = []
    },
    // Get all classes
    async getClasses(state, teacherId = this.state.user.user) {

        this.state.classes = await getClassesService(teacherId)
    },
    // Create class
    async addClass(state, data) {
        const res = await createClassesService(data)
        this.state.classes.push(res)
    },
    // Update class
    async updateClass(state, data) {
        try {
            const updatedClass = await updateClassesService(data)
            const index = state.state.classes.findIndex((c) => c.id == updatedClass.id)

            if (index !== -1) {
                Object.assign(state.state.classes[index], updatedClass)
            }
        }
        catch (error) {
            console.error('Erreur lors de la mise à jour de la classe :', error)
            // Gérez l'erreur selon vos besoins
        }

    },
    // Delete class
    async deleteClass(state, id) {
        try {
            await deleteClassesService(id)
            const index = state.state.classes.findIndex((c) => c.id == id)
            if (index !== -1) {
                state.state.classes.splice(index, 1)
            }
        }
        catch (error) {
            console.error('Erreur lors de la suppression de la classe :', error)
        }

        // this.dispatch('getClasses')

    },
    // Get all levels
    async getLevels() {
        await authenticatedApiFetch(`/levels/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.levels = response
            })
            .catch((error) => console.error(error))
    },
    // Create level
    async addLevel(state, e) {
        await authenticatedApiFetch(`/levels/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getLevels')
            })
    },
    // Update level
    async updateLevel(state, e) {
        await authenticatedApiFetch(`/levels/${e.id}/`, {
            method: 'PUT',
            body: JSON.stringify(e)
        })
            .then(() => {
                this.dispatch('getLevels')
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
    },
    // Delete level
    async deleteLevel(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/levels/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getLevels')
                })
        }
    },
    // Get all subjects
    async getSubjects() {
        await authenticatedApiFetch(`/subjects/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.subjects = response
            })
            .catch((error) => console.error(error))
    },
    // Create subject
    async addSubject(state, e) {
        await authenticatedApiFetch(`/subjects/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getSubjects')
            })
    },
    // Update subject
    async updateSubject(state, e) {
        await authenticatedApiFetch(`/subjects/${e.id}/`, {
            method: 'PUT',
            body: JSON.stringify(e)
        })
            .then(() => {
                this.dispatch('getSubjects')
            }).catch((error) => {
                this.errors = error.response.data.errors
            })
    },
    // Delete subject
    async deleteSubject(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/subjects/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getSubjects')
                })
        }
    },
    // Get all chapters
    async getChapters() {
        await authenticatedApiFetch(`/chapters/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.chapters = response
            })
            .catch((error) => console.error(error))
    },
    // Add chapter
    async addChapter(state, e) {
        await authenticatedApiFetch(`/chapters/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getChapters')
            })
            .catch((error) => {
                this.errors = error
            })
    },
    // Update chapter
    async updateChapter(state, e) {
        await authenticatedApiFetch(`/chapters/${e.id}/`, { method: 'PUT', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getChapters')
            })
    },
    // Delete chapter
    async deleteChapter(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/chapters/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getChapters')
                })
        }
    },
    // Get all sequences
    async getSequences() {
        await authenticatedApiFetch(`/sequences/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.sequences = response
            })
            .catch((error) => console.error(error))
    },
    // Get all difficulties
    async getDifficulties() {
        await authenticatedApiFetch(`/difficulties/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.difficulties = response
            })
            .catch((error) => console.error(error))
    },
    // Add difficulty
    async addDifficulty(state, e) {
        await authenticatedApiFetch(`/difficulties/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getDifficulties')
            })
            .catch((error) => {
                this.errors = error
            })
    },
    // Update difficulty
    async updateDifficulty(state, e) {
        await authenticatedApiFetch(`/difficulties/${e.id}/`, { method: 'PUT', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getDifficulties')
            })
    },
    // Delete difficulty
    async deleteDifficulty(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/difficulties/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getDifficulties')
                })
        }
    },
    // Get all evaluation types
    async getEvaluationTypes() {
        await authenticatedApiFetch(`/evaluation-types/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.evaluationTypes = response
            })
            .catch((error) => console.error(error))
    },
    // Get all question types
    async getQuestionTypes() {
        await authenticatedApiFetch(`/question-types/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.questionTypes = response
            })
            .catch((error) => console.error(error))
    },
    // Get all question themes
    async getQuestionThemes() {
        await authenticatedApiFetch(`/question-themes/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.questionThemes = response
            })
            .catch((error) => console.error(error))
    },

    // Get all Motifs
    async getMotifs() {
        await authenticatedApiFetch(`/motifs/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.motifs = response
            })
            .catch((error) => console.error(error))
    },

    // Get all exercise  questions types
    async getExerciseQuestionTypes() {
        await authenticatedApiFetch(`/exercise-question-types/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.exerciseQuestionTypes = response
            })
            .catch((error) => console.error(error))
    },

    // Get all exercise  questions themes
    async getExerciseQuestionThemes() {
        await authenticatedApiFetch(`/exercise-question-themes/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.exerciseQuestionThemes = response
            })
            .catch((error) => console.error(error))
    },
    // Get all academies
    async getAcademies() {
        await authenticatedApiFetch(`/academies/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.academies = response
            })
            .catch((error) => console.error(error))
    },
    // Add academy
    async addAcademy(state, e) {
        await authenticatedApiFetch(`/academies/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getAcademies')
            })
            .catch((error) => {
                this.errors = error
            })
    },
    // Update academy
    async updateAcademy(state, e) {
        await authenticatedApiFetch(`/academies/${e.id}/`, { method: 'PUT', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getAcademies')
            })
    },
    // Delete academy
    async deleteAcademy(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/academies/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getAcademies')
                })
        }
    },
    // Get all County
    async getCounty() {
        await authenticatedApiFetch(`/county/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {
                this.state.county = response
            })
            .catch((error) => console.error(error))
    },
    // Add County
    async addCounty(state, e) {
        await authenticatedApiFetch(`/county/`, { method: 'POST', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getCounty')
            })
            .catch((error) => {
                this.errors = error
            })
    },
    // Update County
    async updateCounty(state, e) {
        await authenticatedApiFetch(`/county/${e.id}/`, { method: 'PUT', body: JSON.stringify(e) })
            .then(() => {
                this.dispatch('getCounty')
            })
    },
    // Delete County
    async deleteCounty(state, e) {
        if (confirm("Do you really want to delete?")) {
            await authenticatedApiFetch(`/county/${e}/`, { method: 'DELETE' })
                .then(() => {
                    this.dispatch('getCounty')
                })
        }
    },

    async getTileGameTheme() {
        await authenticatedApiFetch(`/tile-game-theme/`, { method: "GET" })
            .then((response) => response.json())
            .then((response) => {

                let formatedResponse = {
                    id: response[0].id,
                    theme: response[0].tile_game_theme_name,
                    theme_id: response[0].theme_id
                }

                this.state.tileGameTheme = formatedResponse
            })
            .catch((error) => console.error(error))
    },


    async getNextCourses() {
        const response = await authenticatedApiFetch(`/activity-events/?teacher=${this.state.user.user}`, { method: 'GET' })
            .then(response => response.json())
            .catch(error => console.error(error))

        const agendas = response
        const sortedAgendas = agendas.sort(function (a, b) {
            // Turn your strings into dates, and then subtract them
            // to get a value that is either negative, positive, or zero.
            return new Date(a.day) - new Date(b.day)
        })
        this.state.nextCourses = sortedAgendas.slice(0, 4)
    },


    toggleMenu() {

        this.state.isMenuToggled = !this.state.isMenuToggled
    }
}
