<template>
	<pop-up-wrapper>
		<div class="pop-up-cancel-subscription">
			<div class="pop-up-text-container">
				<p class="title">Résilier votre abonnement ?</p>
				<p>
					Votre abonnement sera renouvelé automatiquement le
					{{ subscriptionEnd }}
					<br />
					En choisissant de résilier, votre abonnement se terminera le
					{{ subscriptionEnd }}.
				</p>
				<p>Êtes-vous sur de vouloir résilier votre abonnement ?</p>
				<div class="btn-container">
					<button @click="close" class="submit cancel">Retour</button>
					<button @click="cancelSubscription" class="submit dark">
						Résilier
					</button>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { cancelSubscriptionResourceLevelService } from '../../../services/subscriptionResourceLevelsService'
import { getSubscriptionService } from '../../../services/subscriptionsService'
import PopUpWrapper from '../PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	props: {
		product: String,
		subscriptionEnd: String
	},

	methods: {
		close() {
			this.$emit('close')
		},
		async cancelSubscription() {
			if (confirm(`Êtes-vous sûr de vouloir résilier votre abonnement aux ${this.product} ?`)) {
				const subscription = await getSubscriptionService()

				const subscriptionResourceLevel = this.$store.state.rightFor.filter(x => x.resourceType == this.product)
				const ids = subscriptionResourceLevel.map((item) => {
					return item.id
				})
				await cancelSubscriptionResourceLevelService(subscription.id, { product: ids })

				this.$emit('refreshProducts')
				this.$emit('close')
			}
		}
	}
}
</script>

<style>
</style>
