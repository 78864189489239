import { authenticatedApiFetch } from '../utils'



export const allTileGameContentOrderFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/tile-game-content-order/${filter}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const getTileGameContentOrderService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game-content-order/${id}/`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const deleteTileGameContentOrderService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game-content-order/${id}/`, { method: 'DELETE' })
    return res
}

export const createTileGameContentOrderService = async (data) => {
    const res = await authenticatedApiFetch(`/tile-game-content-order/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

