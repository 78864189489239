<template>
	<div>
		<textarea ref="textarea" v-model="latex" rows="10" cols="50"></textarea>
	</div>
</template>

<script>
export default {
	props: {
		heritedLatex: {
			type: String,
			required: true
		}
	},


	data() {
		return {
			latex: `% Entrez votre code LaTeX ici (seulement le code de l'exercice) %
% Exemple: %
Ainsi, les solutions de l'équation sont :
\\[x_1 = \\frac{4 + 2}{6} = \\frac{2}{3}\\]`,
			convertedLatex: '',
			isPreviewVisible: false
		}
	},
	methods: {
		async submit() {
			this.isPreviewVisible = true
		},
	},

	created() {
		if (this.heritedLatex !== '') {
			this.latex = this.heritedLatex
		}
	},
	watch: {
		heritedLatex() {
			this.latex = this.heritedLatex
		}
	},
}
</script>

<style scoped lang="scss">
.pdf-png-container {
	max-width: 100%;
	width: fit-content;
	background-color: #fff;
}

div {
	display: flex;
	flex-direction: column;
	gap: 2rem;
	font-size: 2rem;

	math-field {
		* {
			text-align: left !important;
		}
	}

	.ML__mathlive {
		* {
			text-align: left !important;
		}
	}
}
</style>
