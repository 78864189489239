<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">Enregistrer votre activité</p>
			<p>
				Pour enregistrer votre activité, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container">
				<label for="classes">Classe</label>
				<select id="classes" v-model="form.classes">
					<option
						selected="selected"
						v-if="exercise.classes"
						:value="exercise.classes"
					>
						{{ getClasse(exercise.classes) }}
					</option>
					<option
						v-for="classe in $store.state.classes"
						:key="classe.id"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select
					v-if="form.classes.length !== 0"
					v-model="form.currency"
					id="date"
				>
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>
			<button @click="submit" class="submit dark">Attribuer</button>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { createActivityService } from '../../services/activitiesService'
import { allActivityEventsFilterService } from '../../services/activityEventsService'
import { getKeyByIdService } from '../../services/keysService'
import { getOccurenceByIdService, updateOccurenceService } from '../../services/occurrencesService'
import { checkRightFor, formateAndOrderDate } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: {
		PopUpWrapper
	},
	props: {
		id: Number,
	},
	data() {
		return {
			form: {
				name: '',
				classes: [],
				currency: null
			},
			key: {},
			index: 0,
			exercise: {},
			classes: [],
			questionsList: [],
			teacher: localStorage.userId,
			rituals: [],
			courses: [],
			agenda: {},
		}
	},

	methods: {
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)
				this.courses = formateAndOrderDate(res)

				if (this.$store.state.selectedOccurrence) {
					this.form.classes = this.$store.state.selectedOccurrence.classId
					const occurrenceId = this.$store.state.selectedOccurrence.occurrenceId
					this.form.currency = occurrenceId
				}
			}
		},
		close() {
			this.$emit('close')
		},
		checkErrors() {
			if (this.form.classes.length == 0) {
				this.$store.state.toast = {
					status: 'error',
					text: "Merci de sélectionner une classe",
				}
				return false
			}
			return true
		},
		async submit() {
			if (!checkRightFor('Activités Expresso', [this.key.level])) {
				this.$store.state.isPopUpNotAllowedVisible = true
				return
			}
			if (!this.checkErrors()) {
				return
			}
			if (this.form.classes !== null) {
				let classArray = this.form.classes
				let questionArray = this.id
				const body = {
					name: this.key.title,
					teacher: this.teacher,
					classes: [classArray],
					template: null,
					key: questionArray,
					type: 'PACKAGE'
				}
				const activity = await createActivityService(body)

				if (this.form.currency) {
					let occurrence = await getOccurenceByIdService(this.form.currency)
					occurrence.activities.push(activity.id)

					await updateOccurenceService(this.form.currency, occurrence)
				}
				this.$router.push({ name: 'ClassResources', params: { id: classArray } })
			}
		},

		async getKey() {
			const res = await getKeyByIdService(this.id)
			this.key = res
		},
		getClasse(e) {
			if (this.classes.length !== 0) {
				let classe = this.classes.find(x => x.id == e)
				return classe.name
			}
		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.form.classes)
			return courses
		}
	},
	created() {
		this.allCourses()
		this.getKey()
	},
}
</script>

<style>
</style>
