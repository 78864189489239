<template>
	<section class="namepicker-container">
		<router-link class="go-back" :to="{ name: 'Dashboard' }"
			>Retour</router-link
		>
		<div class="add-student-container">
			<p
				:style="`background-color: ${
					templates[Math.abs(selectedTemplate % templates.length)]
						.color
				}`"
			>
				Nombre d'élèves: {{ students.length }}
			</p>

			<textarea
				@keyup="addStudent"
				rows="30"
				v-model="student"
				type="text"
				placeholder="Ajouter un élève"
			></textarea>

			<select @change="importClass" v-model="selectedClass" name="" id="">
				<option value="import une classe" hidden>
					Importer une classe
				</option>
				<option
					v-for="classe in $store.state.classes"
					:key="classe"
					:value="classe.id"
				>
					{{ classe.name }}
				</option>
			</select>
		</div>
		<div class="template-selector">
			<span
				v-if="selectedTemplate > 0"
				@click="updateTemplate(-1)"
				id="left"
			></span>
			<p>
				{{ templates[selectedTemplate].name }}
			</p>
			<span
				v-if="selectedTemplate < 3"
				@click="updateTemplate(1)"
				id="right"
			></span>
		</div>

		<div
			class="wheel-container"
			:style="`background-image: url(${templates[selectedTemplate].img})`"
		>
			<div
				class="wheel-pointer"
				@click="onClickRotate"
				:style="`transform: rotate(${
					(360 / prizeList.length) * 0 + 360 / (2 * prizeList.length)
				}deg)`"
			>
				<div>
					<p>Start</p>
				</div>
			</div>

			<div class="wheel-wrapper">
				<div
					class="wheel-bg"
					:style="`transform: rotate(${wheelDeg}deg);${bg}`"
					:class="{ freeze: freeze }"
				>
					<div class="prize-list">
						<div
							class="prize-item-wrapper"
							v-for="(item, index) in prizeList"
							:key="index"
						>
							<div
								class="prize-item"
								:style="`transform: rotate(${
									(360 / prizeList.length) * index +
									360 / (2 * prizeList.length)
								}deg)`"
							>
								<div class="prize-name">
									{{ item }}
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		</div>

		<pop-up-winner
			:color="
				templates[Math.abs(selectedTemplate % templates.length)].color
			"
			@close="close"
			v-if="winner"
			:winner="winner"
		/>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'
import PopUpWinner from '../PopUp/PopUpWinner'


export default {
	components: { PopUpWinner },


	data() {
		return {
			selectedClass: 'import une classe',
			freeze: false,
			rolling: false,
			wheelDeg: 0,
			student: '',
			students: [],
			colors: [
				[
					'#2E2977',
					'#0574C2',
					'#31A5E2',
					'#FFFFFF',
					'#5D2377',
					'#C2040E',
					'#E85E58',
				],
				[
					'#24294A',
					'#0154A2',
					'#076892',
					'#31A5E2',
					'#00E2D3',
					'#16EAAA',
					'#FFFFFF',
				],
				[
					'#541640',
					'#8A1C4D',
					'#B82159',
					'#FF788E',
					'#FFA8B6',
					'#FFCCD4',
					'#FFFFFF',
				],
				[
					'#4A4A66',
					'#63A297',
					'#72C1B2',
					'#B4DED4',
					'#F5ECD8',
					'#D95252',
					'#FF7373',
				]
			],
			conicGradient: [],
			winner: '',
			templates: [
				{
					name: 'Poésie aérienne',
					img: '/img/tools/namepicker/template1.jpg',
					color: '#CF0F1E'
				},
				{
					name: 'Dunes astrales',
					img: '/img/tools/namepicker/template2.jpg',
					color: '#00F4AD'
				},
				{
					name: 'Soleil couchant',
					img: '/img/tools/namepicker/template3.jpg',
					color: '#541640'
				},
				{
					name: 'Nature sereine',
					img: '/img/tools/namepicker/template4.jpg',
					color: '#FF7373'
				},
			],
			selectedTemplate: 0,
			bg: ''
		}
	},
	computed: {
		prizeList() {
			return this.students
		},

	},
	methods: {
		importClass: async function () {
			authenticatedApiFetch(`/students/?classes=${this.selectedClass}`)
				.then(res => res.json())
				.then(res => {
					this.student = ''
					res.forEach((element, index) => {
						if (index !== res.length - 1) {
							this.student += element.name + '\n'
						}
						else {
							this.student += element.name
						}

					})
					this.addStudent()
				})
		},
		updateTemplate(e) {
			this.selectedTemplate = this.selectedTemplate + e
			this.addStudent()
		},

		gradient() {
			let style = ''
			for (let i = 0; i < this.conicGradient.length; i++) {
				style += ' ' + this.conicGradient[i % this.colors[this.selectedTemplate].length].color + ' ' + this.conicGradient[i].intervalStart + 'deg ' + this.conicGradient[i].intervalEnd + 'deg,'
			}

			this.bg = `background: conic-gradient(${style.substring(0, style.length - 1)})`

		},
		addStudent() {
			if (this.student !== '') {
				this.students = this.student.split("\n")
				this.conicGradient = []
				// this.students.push(this.student);
				let p = 360 / this.students.length
				let k = 0

				for (let i = 0; i < this.students.length; i++) {
					this.conicGradient.push({ color: this.colors[this.selectedTemplate][i], intervalStart: k, intervalEnd: k + p })
					k += p
				}
				this.gradient()
			}
		},
		onClickRotate() {
			if (this.rolling) {
				return
			}
			const result = Math.floor(Math.random() * this.prizeList.length)
			this.roll(result)
		},
		roll(result) {
			this.rolling = true
			const { wheelDeg, prizeList } = this
			this.wheelDeg = wheelDeg - wheelDeg % 360 + 6 * 360 + (360 - 360 / prizeList.length * result)
			setTimeout(() => {
				this.rolling = false
				this.winner = prizeList[result]
				this.remove(prizeList[result])
			}, 4500)
		},

		remove(e) {
			let index = this.students.indexOf(e)

			this.conicGradient.splice(index, 1)
			this.students = this.students.filter(students => students !== e)
			let p = 360 / this.students.length
			let k = 0
			this.conicGradient = []
			for (let i = 0; i < this.students.length; i++) {
				this.conicGradient.push({ color: this.colors[this.selectedTemplate][i], intervalStart: k, intervalEnd: k + p })
				k += p
			}

			this.student = this.students.toString()
			this.student = this.student.replaceAll(',', '\n')
			this.gradient()
		},
		close() {
			this.winner = ''
		}

	},


};

</script>
