<template>
	<h1>Modifier un contenu de calendrier de l'avent</h1>
	<form
		@submit.prevent="submit"
		class="form-container"
		enctype="multipart/form-data"
	>
		<div class="input-container">
			<label for="Title">Titre</label>
			<input
				type="text"
				class="form-control"
				v-model="form.title"
				id="Title"
				placeholder="Title"
			/>
		</div>

		<div class="input-container">
			<label for="niveaux">Niveaux</label>
			<select multiple id="niveaux" v-model="form.levels">
				<option
					v-for="level in $store.state.levels"
					:key="level"
					:value="level.id"
				>
					{{ level.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="matiere">Matières</label>
			<select multiple id="matiere" v-model="form.subjects">
				<option
					v-for="subject in $store.state.subjects"
					:key="subject"
					:value="subject.id"
				>
					{{ subject.name }}
				</option>
			</select>
		</div>
		<div class="input-container">
			<label for="niveaux">Type de question</label>
			<select id="niveaux" v-model="form.type">
				<option value="Exercices">
					Exercices
				</option>
				<option value="Enigmes">Enigmes</option>
				<option value="Défis par équipe">Défis par équipe</option>
				<option value="Constructions géométriques">
					Illustrations / Schémas
				</option>
				<option value="Enigmes à distance">Enigmes à distance</option>
				<option value="Messages pour le 25 Décembre">
					Messages pour le 25 Décembre
				</option>
				<option value="Vidéos à distance">Vidéos à distance</option>
			</select>
		</div>
		<div class="input-container">
			<label for="">Contenu</label>
			<div class="checkbox-container">
				<label for="txt">
					<input
						v-model="template"
						:value="0"
						id="txt"
						type="radio"
						:disabled="form.type == 'Vidéos à distance'"
					/>Texte Uniquement
				</label>
				<label for="txt-img">
					<input
						v-model="template"
						:value="1"
						id="txt-img"
						type="radio"
						:disabled="form.type == 'Vidéos à distance'"
					/>Texte + Image mathématique
				</label>
				<label for="txt-img-math">
					<input
						v-model="template"
						:value="2"
						id="txt-img-math"
						type="radio"
						:disabled="form.type !== 'Vidéos à distance'"
					/>Vidéo uniquement
				</label>
				<label for="img">
					<input
						v-model="template"
						:value="3"
						id="img"
						type="radio"
						:disabled="form.type == 'Vidéos à distance'"
					/>Image uniquement
				</label>
			</div>
		</div>
		<div v-if="template !== 2 && template !== 3" class="input-container">
			<label for="text">Texte</label>
			<textarea
				type="text"
				class="form-control"
				v-model="form.text"
				id="text"
				cols="30"
				rows="5"
				placeholder="Texte"
			/>
		</div>
		<div v-if="template == 1 || template == 3" class="input-container">
			<label for="image">Image</label>
			<input
				id="image"
				type="file"
				class="form-control"
				@change="changeImg"
			/>
			<img v-if="url" class="photo" :src="url" alt="" />
		</div>
		<div v-if="template == 2" class="input-container">
			<label for="Image2">Vidéo url</label>
			<input
				type="text"
				v-model="form.video_url"
				class="form-control"
				placeholder="Vidéo url"
			/>
		</div>
		<div class="input-container">
			<label for="Correction">Correction</label>
			<textarea
				type="text"
				class="form-control"
				v-model="form.correction"
				id="text"
				cols="30"
				rows="5"
				placeholder="Texte"
			/>
		</div>

		<p v-if="status !== ''" class="status">{{ status }}</p>
		<p class="errors">{{ errors }}</p>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>
import { authenticatedApiFetch, authenticatedImageFetch } from '../../../utils'

export default {
	data() {
		return {
			form: {
				title: '',
				text: '',
				image_url: '',
				video_url: '',
				correction: '',
				level_id: '',
				type: '',
				teacher: this.$store.state.user.user,
				levels: [],
				subjects: [],
			},
			url: '',
			template: null,
			levels: [],
			errors: '',
			status: '',

		}
	},
	methods: {
		changeImg(e) {
			this.url = URL.createObjectURL(e.target.files[0])
			this.form.image_url = e.target.files[0]
		},
		async getImage() {
			await authenticatedImageFetch(this.form.image_url, {
				method: "GET",
			})
				.then((response) => response.blob())
				.then((blob) => {
					const reader = new FileReader()
					reader.onload = (e) => {
						this.url = e.target.result
					}
					reader.readAsDataURL(blob)

				})
		},
		cleanData() {

			// text only
			if (this.template == 0) {
				this.form.image_url = new File([], '')
				this.form.video_url = ''
			}
			// video only
			else if (this.template == 2) {
				this.form.image_url = new File([], '')
				this.form.text = ''
			}
			// text + img
			else if (this.template == 1) {
				this.form.video_url = ''
			}
			// img only
			else {
				this.form.video_url = ''
				this.form.text = ''
			}

		},
		async submit() {

			const formData = this.getFormDataValue()
			await authenticatedApiFetch(`/christmas-calendar-exercises/${this.$route.params.id}/`, {
				method: 'PUT',
				body: formData,
				headers: {}
			})
				.then(() => {
					this.$router.push({ name: 'SuperAdminAllCalendarExercises' })
				})
				.catch((error) => {
					this.errors = error
				})
		},
		getChapter() {
			let sequences = this.sequences.filter(x => x.level == this.selectedLevel).map(x => x.id) || null
			if (sequences.length !== 0) {
				let chapterArray = []
				sequences.forEach(element => {
					let chapters = this.chapters.filter(x => x.sequence == element)
					chapterArray.push(...chapters)
				})
				return chapterArray
			}
			if (this.selectedLevel == null) {
				return this.chapters
			}
		},
		getFormDataValue() {
			this.cleanData()

			const data = new FormData()
			data.append('title', this.form.title)
			data.append('text', this.form.text)
			for (const level of this.form.levels) {
				data.append('levels', level)
			}
			for (const subject of this.form.subjects) {
				data.append('subjects', subject)
			}

			if (this.form.image_url && typeof this.form.image_url == 'object') {
				data.append('image_url', this.form.image_url)
			}


			data.append('video_url', this.form.video_url)
			data.append('correction', this.form.correction)
			data.append('type', this.form.type)
			data.append('teacher', this.form.teacher)
			return data
		},

		changePhoto(e) {
			this.form.image_url = e.target.files[0]
		},

		async getExercise() {
			const res = await authenticatedApiFetch(`/christmas-calendar-exercises/${this.$route.params.id}/`)
				.then(res => res.json())
			this.form = res
			if (this.form.image_url) {
				this.getImage()
			}
		},

		getTemplate() {
			const { text, image_url, video_url } = this.form


			if (text !== '' && image_url == null) {
				this.template = 0
			}
			else if (video_url) {
				this.template = 2
			}
			else if (text !== '' && image_url !== null) {
				this.template = 1
			}
			else {
				this.template = 3
			}

		},



	},

	async created() {
		await this.getExercise()
		this.getTemplate()
	},

	watch: {
		'form.type': {
			handler(newValue) {
				if (newValue == 'Vidéos à distance') {
					this.template = 2
				}
				else {
					this.template = 0
				}

			},
			deep: true
		},

	},

}
</script>

<style>
</style>
