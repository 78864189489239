<template>
	<pop-up-wrapper>
		<form class="pop-up-class-created" @submit.prevent="submit">
			<div class="wrapper">
				<p class="title">Modifier le lien</p>

				<div class="input-container">
					<label for="">Titre</label>
					<input
						placeholder="Titre"
						v-model="form.title"
						type="text"
					/>
				</div>
				<div class="input-container">
					<label for="">Lien</label>
					<input placeholder="Lien" type="text" v-model="form.link" />
				</div>

				<div class="input-container">
					<label for="date">Créneau</label>
					<select v-model="form.currency" id="date">
						<option
							v-for="course in courses"
							:key="course.id"
							:value="course.id"
						>
							{{ course.formatedDay }} {{ course.debut_hour }} -
							{{ course.end_hour }}
						</option>
					</select>
				</div>

				<div class="btn-container">
					<p @click="this.$emit('close')" class="cancel">
						Abandonner
					</p>
					<button class="add">Valider</button>
				</div>
			</div>
		</form>
	</pop-up-wrapper>
</template>

<script>
import { allActivityEventsFilterService } from '../../../services/activityEventsService'
import { getClassLinkService, updateClassLinkService } from '../../../services/classLinkService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'
import { formateAndOrderDate } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	props: {
		id: Number
	},

	data() {
		return {
			form: {
				title: '',
				link: '',
				teacher_id: this.$store.state.user.user,
				currency: null
			},
			courses: []

		}
	},
	methods: {
		async submit() {
			if (!this.form.link.includes('https://')) {
				this.form.link = 'https://' + this.form.link
			}
			const data = {
				title: this.form.title,
				link: this.form.link,
				favicon: this.getFavicon()
			}
			await updateClassLinkService(this.id, data)


			if (this.form.currency == undefined) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.form.currency)


				occurrence.class_links.push(this.id)

				await updateOccurenceService(this.form.currency, occurrence)


				let patchedOccurence = this.courses.find(e => {
					return e.class_links.find(x => x == this.id)
				})

				if (patchedOccurence) {
					patchedOccurence.class_links = patchedOccurence.class_links.filter(x => x !== this.id)
				}
				if (patchedOccurence && this.oldCurrency !== this.form.currency) {
					await updateOccurenceService(patchedOccurence.id, patchedOccurence)

						.catch((error) => {
							this.errors = error.response.data.errors
						})
				}
			}
			this.$emit('refreshLinks')
		},
		getFavicon() {
			return `https://s2.googleusercontent.com/s2/favicons?domain_url=${this.form.link}&size=128`
		},
		async getCourses() {
			const filter = `?classes=${this.$route.params.id}`
			const res = await allActivityEventsFilterService(filter)
			this.courses = formateAndOrderDate(res)

			let link = this.courses.find(e => {
				return e.class_links.find(x => x == this.id)
			})
			if (link) {
				this.form.currency = link.id
				this.oldCurrency = link.id
			}

		},
		async getLink() {
			this.form = getClassLinkService(this.id)
		},
	},

	async created() {
		await this.getLink()
		await this.getCourses()
	},
}
</script>

<style>
</style>
