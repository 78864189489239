import { authenticatedApiFetch } from '../utils'


export const allExerciseQuestionsService = async () => {
    const res = await authenticatedApiFetch(`/exercise-questions/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const allExerciseQuestionsForTeacherService = async (userId) => {
    const res = await authenticatedApiFetch(`/exercise-questions/?teacher=${userId}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}

export const allExerciseQuestionsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/exercise-questions/${filter}`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
export const deleteExerciseQuestionsService = async (id) => {
    const res = await authenticatedApiFetch(`/all-exercise-questions/${id}/`, { method: 'DELETE' })
    return res
}
export const getExerciseQuestionsService = async (id) => {
    const res = await authenticatedApiFetch(`/all-exercise-questions/${id}/`, { method: 'GET' })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}



export const approveExerciseQuestionsService = async (id) => {
    const res = await authenticatedApiFetch(`/all-exercise-questions/${id}/validate/`)
        .catch(err => {
            console.error(err)
        })
    return res
}

export const refuseExerciseQuestionsService = async (id) => {
    const res = await authenticatedApiFetch(`/all-exercise-questions/${id}/refuse/`)
        .catch(err => {
            console.error(err)
        })
    return res
}

export const createExerciseQuestionsService = async (exerciseQuestion) => {
    const res = await authenticatedApiFetch(`/exercise-questions/`, { method: 'POST', body: JSON.stringify(exerciseQuestion) })
        .then(response => response.json())
        .catch(err => {
            console.error(err)
        })
    return res
}

export const updateExerciseQuestionsService = async (id, exerciseQuestion) => {
    const res = await authenticatedApiFetch(`/all-exercise-questions/${id}/`, { method: 'PUT', body: JSON.stringify(exerciseQuestion) })
        .then(response => response.json())
        .catch(err => {
            console.error(err)
        })
    return res
}
