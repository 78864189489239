<template>
	<section>
		<div class="activite-container">
			<loader v-if="!questionReady" />
			<question-container
				@addQuestion="addQuestion"
				@addQuestionRandom="addQuestionRandom"
				@removeQuestionRandom="removeQuestionRandom"
				v-for="individualQuestion in individualQuestions"
				:added="isAdded(individualQuestion.id)"
				:amount="checkAmount(individualQuestion.id)"
				:question="individualQuestion"
				:key="individualQuestion.id"
			/>
			<no-content
				v-if="
					isLoaded &&
					individualQuestions.length == 0 &&
					filter.level !== null
				"
				:level="filter.level"
				:chapter="filter.chapter"
				:type="'ACTIVITY'"
			/>
		</div>
		<pagination-component
			class="question-paginate"
			ref="paginate"
			v-if="pages !== null && pages > 1"
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="changeFilter"
		/>

		<div
			:class="questions.length > 0 ? 'active' : ''"
			class="footer-validation"
		>
			<button @click="resetQuestions">Reinitialiser</button>
			<button @click="showPopUp">
				Enregistrer
				<span
					>&nbsp; ( {{ questions.length }} question<span
						v-if="questions.length > 1"
						>s
					</span>
					)
				</span>
			</button>
		</div>

		<pop-up-question-order
			@showPopUp2="showPopUp2"
			@close="showPopUp"
			@addQuestion="addQuestion"
			@removeQuestionRandom="removeQuestionRandom"
			:questions="questions"
			v-if="isVisible"
		/>
		<pop-up-question
			:questionLength="questions.length"
			:interrogation="questions2.length !== 0"
			@ritualName="name"
			@submit="submit"
			@close="this.isVisible2 = !this.isVisible2"
			v-if="isVisible2"
		/>
		<div>
			<filter-component
				ref="filter"
				:style="
					isFilterVisible
						? 'transform: translateX(0);'
						: 'transform: translateX(100vw);'
				"
				:class="isFilterVisible ? 'active' : ''"
				@changeFilter="changeFilter"
				@close="setFilterVisible"
			/>
		</div>
		<Teleport to="body">
			<full-page-loader v-if="isLoading" />
		</Teleport>
	</section>
</template>

<script>
import QuestionContainer from './QuestionContainer.vue'
import PopUpQuestion from '../../../PopUp/PopUpQuestion'
import PopUpQuestionOrder from '../../../PopUp/PopUpQuestionOrder.vue'
import { checkRightFor, random } from '../../../../utils'
import FilterComponent from '../Filter.vue'
import PaginationComponent from '../../../Utils/PaginationComponent.vue'
import NoContent from '../NoContent.vue'
import Loader from '../../../Loader.vue'
import FullPageLoader from '../../../Utils/FullPageLoader.vue'
import { allQuestionsFilterService } from '../../../../services/questionsService'
import { createQuestionInstanceService } from '../../../../services/questionInstancesService'
import { createQuestionInterrogationInstanceService } from '../../../../services/questionInterrogationInstancesService'
import { createActivityService } from '../../../../services/activitiesService'
import { createRitualActivityService } from '../../../../services/ritualActivitiesService'
import { createRitualActivityInterrogationService } from '../../../../services/ritualActivitiesInterrogationService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../../services/occurrencesService'
import { createQuestionFlashPackageService } from '../../../../services/questionFlashPackagesServices'



export default {
	props:{
		filter: Object
	},
	components: {
		QuestionContainer,
		PopUpQuestion,
		PopUpQuestionOrder,
		FilterComponent,
		PaginationComponent,
		NoContent,
		Loader,
		FullPageLoader,
	},
	data() {
		return {
			isFilterVisible: false,
			isVisible: false,
			isVisible2: false,
			individualQuestions: [],
			name: '',
			questions: [],
			questions2: [],
			teacher: null,
			order: 0,
			activityId: null,
			questionInstances: [],
			questionInstances2: [],
			actualFilter: '',
			clonedFilter: {
				chapter: null,
				subject: null,
				level: null,
				type: [],
				theme: [],
				difficulty: [],
				offset: 0,
				search: '',
			},
			currentPage: 0,
			pages: null,
			count: null,
			admin: false,
			isLoaded: false,
			questionReady: true,
			isLoading: false,
		}
	},
	
	methods: {

		removeFilter(type, id) {
			this.clonedFilter[type] = this.clonedFilter[type].filter(x => x !== id)
			let getPlurals
			switch (type) {
				case 'type':
					getPlurals = 'types'
					break
				case 'theme':
					getPlurals = 'themes'
					break
				case 'difficulty':
					getPlurals = 'difficulties'
					break
			}
			this.$refs['filter'].filter[getPlurals] = this.$refs['filter'].filter[getPlurals].filter(x => x !== id)
			this.$refs['filter'].changeFilter()
		},
		resetQuestions() {
			this.questions = []
		},
		checkAmount(e) {
			let questions = this.questions.filter(x => x.id == e)
			return questions.length
		},


		setFilterVisible() {
			this.isFilterVisible = !this.isFilterVisible
			if (this.isFilterVisible) {
				document.body.style.overflow = 'hidden'
			}
			else {
				document.body.style.overflow = 'visible'
			}
		},
		isAdded(e) {
			let question = this.questions.find(x => x.id == e)
			if (question)
				question = true
			else
				question = false
			return question
		},
		sequenceChapters(e) {
			return this.$store.state.chapters.filter(x => x.sequence == e)
		},

		changeFilter(e) {
			if (typeof e == 'number') {
				this.clonedFilter.offset = 20 * e
				this.currentPage = e
			}
			else {
				this.currentPage = 0
				this.clonedFilter.offset = 0
			}
			if (e !== undefined) {
				if (Object.prototype.hasOwnProperty.call(e, 'types')) {
					this.clonedFilter.type = e.types
				}
				if (Object.prototype.hasOwnProperty.call(e, 'themes')) {
					this.clonedFilter.theme = e.themes
				}
				if (Object.prototype.hasOwnProperty.call(e, 'difficulties')) {
					this.clonedFilter.difficulty = e.difficulties
				}
			}
			const chapter = this.clonedFilter.chapter ? `chapter=${this.clonedFilter.chapter}` : ''
			const level = this.clonedFilter.level ? `&level=${this.clonedFilter.level}` : '&level='
			const subject = this.clonedFilter.subject ? `&subject=${this.clonedFilter.subject}` : '&subject='
			const type = this.clonedFilter.type ? `&type=${this.clonedFilter.type}` : '&type='
			const theme = this.clonedFilter.theme ? `&theme=${this.clonedFilter.theme}` : '&theme='
			const difficulty = this.clonedFilter.difficulty ? `&difficulty=${this.clonedFilter.difficulty}` : '&difficulty='
			const search = this.clonedFilter.search ? `&search=${this.clonedFilter.search}` : '&search='
			const teacher = this.clonedFilter.teacher ? `&teacher=${this.clonedFilter.teacher}` : '&teacher='
			this.actualFilter = `?${chapter}${level}${subject}&offset=${this.clonedFilter.offset}${type}${theme}${difficulty}${search}${teacher}`
			this.refreshQuestion(this.actualFilter)
		},
		checkRight(){
			if(!checkRightFor('Questions Flash', [])){
				this.individualQuestions.map((item,index) => {
					if(index > 4) item.disabled = true
				})
			}
				
		},

		async refreshQuestion(e) {
			this.questionReady = false
			this.isLoaded = false
			const res = await allQuestionsFilterService(e)
			this.individualQuestions = res.results
			this.checkRight()
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			if (this.pages < this.currentPage) {
				this.currentPage = 0
			}
			this.isLoaded = true
			this.questionReady = true
		},
		async submit(e) {
			
			this.isLoading = true
			let classArray = e.classes
			let questionArray = this.questions
			let questionArray2 = this.questions2
			for await (const question of questionArray) {
				let res = await createQuestionInstanceService(question)
				res.order = question.order
				this.questionInstances.push(res)
			}
			for await (const question of questionArray2) {
				let res = await createQuestionInterrogationInstanceService(question)
				res.order = question.order
				this.questionInstances2.push(res)
			}
			if (this.teacher == 2) {
				this.admin = true
			}
			let level = questionArray[0].level
			let chapter = questionArray[0].chapter

			const body = {
				name: e.name,
				teacher: this.teacher,
				classes: [classArray],
				template: e.index,
				admin: this.admin,
				level: level,
				chapter: chapter,
			}
			const res = await createActivityService(body)
			this.activityId = res.id

			for await (const questionInstance of this.questionInstances) {
				let body = {
					activity: questionInstance.id,
					ritual: this.activityId,
					order: questionInstance.order
				}
				await createRitualActivityService(body)
			}
			for await (const questionInstance of this.questionInstances2) {
				let body = {
					activity: questionInstance.id,
					ritual: this.activityId,
					order: questionInstance.order
				}
				await createRitualActivityInterrogationService(body)
			}
			if (e.currency == undefined) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(e.currency)

				occurrence.activities.push(this.activityId)
				await updateOccurenceService(e.currency, occurrence)

			}
			if(!e.privateMode){
				
				const data = {
					title: e.name,
					questions: this.questions.map(x => x.id),
				}
				await createQuestionFlashPackageService(data)
			}

			this.isLoading = false
			this.$router.push({ name: 'ClassResources', params: { id: classArray } })
		},
		random: random,
		addQuestion(i) {
			let question = this.questions.find(x => x.id == i)
			let individualQuestion = this.individualQuestions.find(x => x.id == i)
			if (question) {
				this.questions.splice(this.questions.findIndex(x => x.id == i), 1)
			}
			else {
				this.questions.push(individualQuestion)
			}
		},
		addQuestionRandom(question) {
			let questionRandomized = { ...question }
			questionRandomized.random_values = {}
			for (const value in question.random_values) {
				questionRandomized.random_values[value] = question.random_values[value]
			}
			this.questions.push(questionRandomized)
		},
		removeQuestionRandom(i) {
			let index = this.questions.findIndex(x => x.id == i.id)
			this.questions.splice(index, 1)
		},
		showPopUp() {
			this.isVisible = !this.isVisible
		},
		showPopUp2(questions, questions2) {
			this.questions = questions
			this.questions2 = questions2
			this.isVisible2 = !this.isVisible2
		}
	},
	
	created() {
		this.teacher = this.$store.state.user.user
		this.clonedFilter = JSON.parse(JSON.stringify(this.filter))
		if (this.$store.state.selectedFlashQuestions){
			this.questions = this.$store.state.selectedFlashQuestions
		}
		this.changeFilter()
	},
	watch: {
		'$route': {
			handler(newValue) {
				if (newValue.name !== 'GenerateFlashQuestion' && newValue.name !== 'MyResourcesAddQuestion' ) {
					this.questions = []
					this.$store.state.selectedFlashQuestions = []
				}
			},
		},
		filter: {
			handler() {
				this.clonedFilter = JSON.parse(JSON.stringify(this.filter))
				this.changeFilter()
			},
			deep: true
		}
	},
	unmounted() {
		if(this.questions.length > 0){
			this.$store.state.selectedFlashQuestions = this.questions
		}
	},
}
</script>

<style scoped>
.btn-container{
	display: flex; 
	gap: 2rem;
	margin-bottom: 4rem;
}
</style>
