<template>
	<section class="create-calendar-container">
		<router-link class="return-btn" :to="{ name: 'JeuSerieux' }">
			<img src="/img/return.svg" alt="" />
		</router-link>
		<img
			class="calendar-logo"
			src="/img/game/calendar/calendar-logo.svg"
			alt=""
		/>
		<p class="intro-text">
			Motivez votre classe en leur proposant pour chaque jour de décembre
			une énigme, un exercice, une vidéo … <br />
			Sélectionnez un contenu pour chaque case du calendrier et en 10 min
			le tour est joué !
		</p>
		<div class="form-info-container">
			<div class="form-container" @submit.prevent="submit">
				
				<div class="input-container">
					<label class="template-label" for="item name"
						>Template</label
					>
					<slider @changeTheme="changeTheme" />
				</div>

			</div>
			<p class="intro-text">
				
				🔧 <strong> Matériel </strong> : Vidéoprojecteur et PC de l'enseignant
				<br>
				<br>
				⏱ <strong> Durée </strong> : 10 minutes en début ou fin de cours
				<br>
				<br>
				📅 <strong> Quand jouer ? </strong> Au mois de décembre
			</p>
			
		</div>
		<button @click="submit" class="submit dark">Créer le calendrier</button>
	</section>
</template>

<script>
import Slider from './Slider.vue'
export default {
	components: {
		Slider,
	},
	data() {
		return {
			form: {
				template: '',
			},
		}
	},

	methods: {
		submit() {
			this.$router.push({ name: 'CalendarOrganize', params: { template: this.form.template} })
		},
		changeTheme(e) {
			this.form.template = e
		},
	},
}
</script>

<style>
</style>
