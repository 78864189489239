<template>
	<section class="container">
		<label for="carte">
			<input @change="changeFilter" id="carte" type="radio" value="ACTIVITY" v-model="type" />A la carte</label>
		<label for="key">
			<input @change="changeFilter" id="key" type="radio" value="PACKAGE" v-model="type" />Clès en main</label>
		<label for="sheet">
			<input @change="changeFilter" id="sheet" type="radio" value="EXERCISE_SHEET" v-model="type" />Fiche
			d'exercices</label>
		<label for="eval">
			<input @change="changeFilter" id="eval" type="radio" value="EVALUATION" v-model="type" />Evaluation</label>
		<table>
			<tr>
				<th></th>
				<th>Niveaux</th>
				<th>Chapitre</th>
				<th>Quantité</th>
			</tr>
			<tr v-for="(customSuggestion, index) in customSuggestions" :key="index">
				<td>{{ index + 1 }}</td>
				<td>{{ customSuggestion.level_name }}</td>
				<td>{{ customSuggestion.chapter_name }}</td>
				<td>{{ customSuggestion.amount }}</td>
			</tr>
		</table>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'

export default {
	data() {
		return {
			suggestions: [],
			customSuggestions: [],
			chapters: [],
			levels: [],
			type: 'ACTIVITY'
		}
	},
	methods: {
		changeFilter() {
			this.allSuggestions()
		},
		async allSuggestions() {
			const res = await authenticatedApiFetch(`/suggestions/?type=${this.type}`, { method: 'GET' })
				.then(res => res.json())
			this.suggestions = res

			for (const suggestion of this.suggestions) {

				suggestion.level_name = this.$store.getters.level(suggestion.level_id).name
				if (suggestion.chapter_id) {
					suggestion.chapter_name = this.$store.getters.chapter(suggestion.chapter_id).name
				}
			}

			for (const chapter of this.chapters) {
				let suggestion = this.suggestions.filter(x => x.chapter_id == chapter.id)
				chapter.amount = suggestion.length
				chapter.level_name = this.$store.getters.level(chapter.sequence_level).name
				chapter.chapter_name = chapter.name
			}
			for (const level of this.levels) {
				let suggestion = this.suggestions.filter(function (suggestion) {
					return suggestion.level_id == level.id && !suggestion.chapter_id
				})
				level.amount = suggestion.length
				level.level_name = level.name
				level.chapter_name = ''
			}
			this.customSuggestions = [...this.chapters, ...this.levels]
			this.customSuggestions.sort((a, b) => (a.amount < b.amount ? 1 : -1))

		}
	},
	created() {
		this.allSuggestions()
		this.chapters = [...this.$store.state.chapters]
		this.levels = [...this.$store.state.levels]
	},
}
</script>

<style lang="scss" scoped>
.container {
	width: 100%;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
}
</style>
