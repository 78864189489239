<template>
	<stats-header />
	<router-view v-slot="{ Component }">
		<transition name="route" mode="out-in">
			<component class="main-component" :is="Component"></component>
		</transition>
	</router-view>
</template>

<script>
import StatsHeader from './StatsHeader.vue'
export default {
	components: { StatsHeader },

}
</script>

<style>
</style>
