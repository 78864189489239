
<template>
	<view-proposed-key :approve-mod="true" />
</template>
<script>
import ViewProposedKey from './ViewProposedKey.vue'
export default {
	components: { ViewProposedKey },

}
</script>
