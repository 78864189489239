import { authenticatedApiFetch } from '../utils'

export const allClassLinksForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/class-links/?classe=${classId}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getClassLinkService = async (id) => {
    const res = await authenticatedApiFetch(`/class-links/${id}/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const updateClassLinkService = async (id, data) => {
    const res = await authenticatedApiFetch(`/class-links/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
export const createClassLinksService = async (data) => {
    const res = await authenticatedApiFetch(`/class-links/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
