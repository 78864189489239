<template>
	<section class="rule-container">
		<h1>Les règles</h1>
		<div class="rule-wrapper">
			<rule v-for="(rule, index) in rules" :key="index" :rule="rule" />
		</div>
		<button class="go-back" @click="$router.go(-1)">Retour</button>
	</section>
</template>

<script>
import Rule from "./Rule.vue"

export default {
	components: { Rule },
	data() {
		return {
			rules: [
				{
					img: '/img/game/tiles/rule/timer.svg',
					title: 'Flash',
					text: 'Question du programme. La 1ère équipe qui trouve la réponse gagne les points.',
					color: '#DF34FF'
				},
				{
					img: '/img/game/tiles/rule/puzzle.svg',
					title: 'Enigma',
					text: 'Question de logique ou énigme. Chaque équipe qui trouve la solution gagne les points.',
					color: '#0055FF'
				},
				{
					img: '/img/game/tiles/rule/target.svg',
					title: 'OneShot',
					text: 'Question de rapidité. A la fin du temps imparti, chaque équipe ayant la bonne réponse gagne les points.',
					color: '#FF0000'
				},
				{
					img: '/img/game/tiles/rule/interrogation.svg',
					title: 'Chance',
					text: 'Case mystère. Chaque équipe peut remettre son destin dans les mains du hasard, à ses risques et périls !',
					color: '#1AFF7D'
				},
				{
					img: '/img/game/tiles/rule/boomerang.svg',
					title: 'Boomerang',
					text: 'Les équipes s’affrontent tour à tour. Une équipe proposant une réponse fausse, une réponse déjà dite ou pas de réponse est éliminée.',
					color: '#FFCC00'
				},
			]
		}
	},
}
</script>

<style>
</style>
