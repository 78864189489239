import { authenticatedApiFetch } from '../utils'



export const createRitualActivityService = async (ritualActivity) => {
    const res = await authenticatedApiFetch(`/ritual-activities/`, { method: 'POST', body: JSON.stringify(ritualActivity) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const allRitualActivitiesFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/ritual-activities/?${filter}`)
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
