<template>
	<div :style="getBackground()" class="slide-maker-container">
		<div class="icon-container">
			<img class="icon" :src="`/img/game/tiles/slide/icon/${theme}/${getTypeRealName(
				type
			)}.svg`" alt="" />
		</div>
		<input v-model="title" class="input-title" type="text" placeholder="titre" />
		<div class="img-text-container">
			<div v-if="template == 1" class="img-container">
				<label for="file">
					<img class="bg" src="/img/game/tiles/slide/hexagone.svg" alt="" />
					<img v-if="url" class="photo" :src="url" alt="" />
					<img v-else class="import" src="/img/game/tiles/slide/import.svg" alt="" />
				</label>
				<input @change="changeImg" id="file" type="file" />
			</div>
			<div class="text-container">
				<button class="add-latex" @click="isPopUpLatexVisible = !isPopUpLatexVisible">f(x)</button>
				<div class="fake-textarea" id="exercise" contenteditable></div>
				<!-- <textarea v-model="text" name="" id="" cols="30" rows="5" placeholder="Texte"></textarea> -->
			</div>
			<div v-if="template == 2" class="math-img">
				<label for="mathImg">
					<img v-if="urlImgMath" class="photo" :src="urlImgMath" alt="" />
					<img v-else class="import" src="/img/game/tiles/slide/import.svg" alt="" />
				</label>
				<input @change="changeMathImg" id="mathImg" type="file" />
			</div>
		</div>
		<PopUpInsertLatex @close="closeLatexPopUp" @insert-latex="insertLatex" v-if="isPopUpLatexVisible" />
	</div>
</template>

<script>
import PopUpInsertLatex from './PopUpInsertLatex.vue'

export default {
	components: {
		PopUpInsertLatex
	},
	props: {
		type: String,
		theme: String,
		template: Number
	},
	data() {
		return {
			url: '',
			urlImgMath: '',
			title: '',
			text: '',
			decorative_image: '',
			image: '',
			isPopUpLatexVisible: false,
			carretPosition: 0
		}
	},
	methods: {
		closeLatexPopUp() {
			this.isPopUpLatexVisible = false
		},
		updateCarretPosition() {
			return new Promise((resolve) => {
				setTimeout(() => {
					const selection = window.getSelection()
					if (selection.rangeCount > 0) {
						const range = selection.getRangeAt(0)
						this.carretPosition = range.startOffset
					}
					resolve()
				}, 200)
			})

		},
		insertLatex(latex) {
			const position = this.carretPosition
			const mathField = document.createElement('math-field')
			mathField.setAttribute('readonly', '')
			mathField.setAttribute('class', 'mathfield')
			mathField.setAttribute('contenteditable', false)

			mathField.textContent = latex
			mathField.style.cursor = 'pointer'
			const exercise = document.getElementById('exercise')
			exercise.innerHTML = exercise.innerHTML.replaceAll('&nbsp;', ' ')
			exercise.innerHTML = exercise.innerHTML.slice(0, position) + mathField.outerHTML + exercise.innerHTML.slice(position) + '&nbsp'
			this.text = exercise.innerHTML

			this.isPopUpLatexVisible = false
		},
		getTypeRealName(name) {
			switch (name) {
				case 'OneShot':
					return 'target'
				case 'Flash':
					return 'timer'
				case 'Enigma':
					return 'enigma'
				case 'HitOrMiss':
					return 'interogation'
				case 'Boomerang':
					return 'boomerang'
			}
		},
		getBackground() {
			const style = `background-image: url(/img/game/tiles/slide/bg/${this.theme}/bg-${this.getTypeRealName(this.type)}.svg)`
			return style
		},
		changeImg(e) {
			this.url = URL.createObjectURL(e.target.files[0])
			this.decorative_image = e.target.files[0]
		},

		changeMathImg(e) {
			this.urlImgMath = URL.createObjectURL(e.target.files[0])
			this.image = e.target.files[0]
		},
	},
	computed: {
		formatedText() {
			return this.text
		}
	},
	async mounted() {
		this.$nextTick(() => {
			const exercise = document.getElementById('exercise')
			exercise.addEventListener('input', async () => {
				await this.updateCarretPosition()
				this.text = exercise.innerHTML
			})
			exercise.addEventListener('click', async () => {
				await this.updateCarretPosition()
			})
		})
	},
}
</script>

<style></style>
