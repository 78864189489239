<template>
	<div class="slider">
		<div id="left" class="arrow-container" @click="prev">
			<span class="arrow"></span>
		</div>
		<div id="right" class="arrow-container" @click="next">
			<span class="arrow"></span>
		</div>
		<div class="slide" v-for="i in [currentSlide]" :key="i">
			<img style="" :src="currentImg" alt="Image calendrier" />
		</div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			currentSlide: 0,
			themes: [
				{
					theme: 'MATH_LA_BANQUISE',
					img: '/img/game/demo-tile-game-winter.jpg',
				},
				{
					theme: 'MATH_LA_CITROUILLE',
					img: '/img/game/demo-tile-game-halloween.jpg',
				},
				{
					theme: 'MATH_LA_PLAGE',
					img: '/img/game/demo-tile-game-summer.jpg',
				},
				{
					theme: 'MATH_L_ESPACE',
					img: '/img/game/demo-tile-game-space.jpg',
				},
				{
					theme: 'MATH_LE_CIEL',
					img: '/img/game/demo-tile-game-sky.jpg',
				},
				{
					theme: 'MATH_LE_SKATE',
					img: '/img/game/demo-tile-game-skate.jpg',
				},
				{
					theme: 'MATH_LE_ROBOT',
					img: '/img/game/demo-tile-game-robot.jpg',
				},
			],
		}
	},
	methods: {
		next() {
			if (this.currentSlide == this.themes.length - 1) {
				this.currentSlide = 0
			}
			else {
				this.currentSlide += 1
			}
			this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
		},
		prev() {
			if (this.currentSlide == 0) {
				this.currentSlide = this.themes.length - 1
			}
			else {
				this.currentSlide -= 1
			}
			this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
		},
	},
	computed: {
		currentImg() {
			return this.themes[Math.abs(this.currentSlide) % this.themes.length].img
		}
	},
	created() {
		this.$emit('changeTheme', this.themes[Math.abs(this.currentSlide) % this.themes.length].theme)
	},
}
</script>
