import { authenticatedApiFetch } from '../utils'



export const deleteProposedKeyService = async (id) => {
    const res = await authenticatedApiFetch(`/proposed-keys/${id}/`, { method: 'DELETE' })
    return res
}

export const allProposedKeyFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/proposed-keys/?${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const allProposedKeysService = async () => {
    const res = await authenticatedApiFetch(`/proposed-keys/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getProposedKeyService = async (id) => {
    const res = await authenticatedApiFetch(`/proposed-keys/${id}/`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createProposedKeyService = async (data) => {
    const res = await authenticatedApiFetch(`/proposed-keys/`, { method: 'POST', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}



export const updateProposedKeyService = async (id, data) => {
    const res = await authenticatedApiFetch(`/proposed-keys/${id}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}
