<template>
	<div :style="position()" class="indicator-container">
		<div class="indicator"></div>
	</div>
</template>

<script>
export default {
	data() {
		return {
			top: null,
		}
	},

	methods: {
		convertMsToHM(milliseconds) {
			let seconds = Math.floor(milliseconds / 1000)
			let minutes = Math.floor(seconds / 60)
			let hours = minutes / 60
			hours = hours % 24

			return hours
		},
		position() {
			let date = new Date()
			let isoDate = date.toISOString().split('T')[0]
			let milliseconds2 = new Date(`${isoDate} 07:00:00`)

			let actualDate = new Date(`${isoDate} ${date.getHours()}:${date.getMinutes()}`)
			let pos = this.convertMsToHM(actualDate - milliseconds2)


			let top = `top: ${(pos / 13) * 100}%;`
			return top
		}
	},

}
</script>

<style>
</style>
