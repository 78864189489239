import { authenticatedApiFetch } from '../utils'
import Config from '../config'


export const getProfileByIdService = async (profileId) => {
    const res = await authenticatedApiFetch(`/profile/${profileId}/`, { method: 'GET' })
        .then(res => res.json())
        .catch((error) => {
            console.error(error)
            location.replace(`${Config.landing_url}/auth/login`)
        })
    return res
}


export const updateProfileService = async (profileId, data) => {
    const res = await authenticatedApiFetch(`/profile/${profileId}/`, { method: 'PUT', body: JSON.stringify(data) })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


export const updateLoginCountService = async () => {
    if (localStorage.userId && localStorage.userId !== 'null') {
        authenticatedApiFetch('/user-login-count/', { method: 'GET' })
    }
}
