import { authenticatedApiFetch } from '../utils'

export const createQCMQuestionOrderService = async (data) => {
	const res = await authenticatedApiFetch('/qcm-questions-orders/', { method: 'POST', body: JSON.stringify(data) })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}



export const deleteQCMQuestionOrderService = async (id) => {
	const res = await authenticatedApiFetch(`/qcm-questions-orders/${id}/`, { method: 'DELETE' })
		.catch(error => console.error(error))
	return res
}

export const filteredQCMQuestionOrdersService = async (filter) => {
	const res = await authenticatedApiFetch(`/qcm-questions-orders/?${filter}`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}
