<template>
	<pop-up-wrapper>
		<div class="pop-up tile-pop-up tile-rule">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">DÉROULEMENT</p>

			<ul>
				<li>Vidéoprojetez la grille de jeu à la classe</li>
				<li>Demandez aux élèves
				de se mettre par équipes (le mieux est d'avoir 5 équipes
				maximum)</li>
				<li>Dresser un tableau des scores au tableau avec le nom
				des équipes</li>
				<li>Présentez le jeu puis les règles (en cliquant sur
				l'icône ?)</li>
				<li>Chaque équipe va tour à tour choisir une case. Au 1er
				tour, il est interdit de choisir une case Chance</li>
				<li>Pour
				déterminer l'équipe qui va commencer, vous pouvez écrire une
				question de rapidité au tableau (du type : 15x17 ou 2^10) ou
				lancer la roue de la chance <router-link :to="{name:'NamePicker'}">ici </router-link></li>
			</ul>
			<p class="title" style="margin-top: 4rem;">DÉCOMPTE DES POINTS</p>

			<p class="deroulement">
			
				Une fois le jeu lancé, à vous de choisir et d'annoncer pour
				chaque case combien de points seront attribués aux équipes
				gagnantes. Sur les questions Flash, il est possible de
				récompenser non pas la 1ère équipe mais les 3 premières équipes
				avec un nombre de points décroissant (5points, 3 points, 2
				points). Au fur et à mesure du jeu, vous pouvez ajuster le
				nombre de points distribué pour que toutes les équipes aient
				toujours une chance de gagner (par exemple, plus on avance dans
				le jeu, plus le nombre de points donné est grand…). <br /><br />
				Terminez le jeu en faisant un point sur les scores et en
				annonçant les élèves sur le podium. Vous pouvez même offrir une
				petite récompense aux membres de l'équipe victorieuse.
			</p>
			<img
				@click="this.$emit('prev')"
				class="next"
				src="/img/previous.svg"
				alt=""
			/>
		</div>
	</pop-up-wrapper>
</template>

<script>
import PopUpWrapper from '../../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
}
</script>

<style>
</style>
