<template>
	<pop-up-wrapper @close="this.$emit('close')">
		<div class="pop-up-preview-exercise-admin question-preview">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">{{ question.title }}</p>
			<div class="basic-info-container">
				<div>
					<p>
						{{ question.level_name }}
					</p>
					<p>{{ question.chapter_name }}</p>
				</div>
				<div>
					<p>
						{{ question.type_name }}
					</p>
					<p>
						{{ question.theme_name }}
					</p>
					<p>
						{{ question.difficulty_name }}
					</p>
				</div>
			</div>
			<div class="img-container">
				<p class="subtitle">Texte</p>
				<p
					:class="readyToDisplay ? 'visible' : ''"
					class="calcul"
					ref="question"
					v-html="image(question.description, imageData)"
				/>

				<img :src="`${url}/${question.image_url_1}`" alt="" />
			</div>
			<div class="img-container">
				<p class="subtitle">Correction</p>
				<p
					:class="readyToDisplay ? 'visible' : ''"
					class="calcul"
					ref="question"
					v-html="image(question.correction, imageData)"
				/>
			</div>
			<div class="btn-container">
				<button
					class="refuse"
					@click="refuseQuestion"
					v-if="showValidateBtn"
				>
					Refuser
				</button>
				<button @click="approveQuestion" v-if="showValidateBtn">
					Approuver
				</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import Config from '../../../config'
import { approveQuestionService, refuseQuestionService } from '../../../services/allQuestionsService'
import { translateFromTexToHTML, image } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	emits: ['close'],
	props: {
		question: Object,
		showValidateBtn: Boolean
	},
	data() {
		return {
			url: `${Config.backend_url}/media`

		}
	},

	methods: {
		translateFromTexToHTML: translateFromTexToHTML,
		image: image,
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub], () => console.log('done'))
			}
		},
		async approveQuestion() {
			await approveQuestionService(this.question.id)
			this.$emit('refreshQuestion')
			this.$emit('close')
		},
		async refuseQuestion() {
			await refuseQuestionService(this.question.id)
			this.$emit('refreshQuestion')
			this.$emit('close')
		},
	},
	mounted() {
		this.reRender()
	},

}
</script>

<style>
</style>
