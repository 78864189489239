<template>
	<section>

		<div class="activite-container">
			<loader v-if="!isLoaded && packageQuestions.length == 0" />

			<key-question-container v-for="packageQuestion in packageQuestions" :key="packageQuestion.id" :id="packageQuestion.id" :activities="packageQuestion.questions"
				:name="packageQuestion.title" />

			<no-content v-if="isLoaded && packageQuestions.length == 0 && filter.level !== null
				" :level="filter.level" :chapter="filter.chapter" :type="'ACTIVITY'" />
		</div>
		<pagination-component ref="paginate" v-if="pages !== null && pages > 1" :totalPages="pages" :perPage="20"
			:currentPage="currentPage" @pageChanged="changeFilter" />
	</section>
</template>

<script>
import PaginationComponent from '../../../Utils/PaginationComponent.vue'
import NoContent from '../NoContent.vue'
import Loader from '../../../Loader.vue'
import KeyQuestionContainer from '../KeyQuestionContainer.vue'

import { filteredQuestionFlashPackagesService } from '../../../../services/questionFlashPackagesServices'
export default {
	props: {
		filter: {
			type: Object,
			required: true
		}
	},
	components: {
		PaginationComponent,
		NoContent,
		Loader,
		KeyQuestionContainer,
	},
	data() {
		return {
			expanded: false,
			opened: null,
			type: null,
			packageQuestions: [],
			
			questions: [],
			chapterSelected: null,
			
			clonedFilter: {
				chapter: null,
				level: null,
				search: '',
				subject: null,
				offset: 0,
			},
			currentPage: 0,
			pages: null,
			count: null,
			
			actualFilter: '',
			isLoaded: false
		}
	},

	methods: {
		
		changeFilter(e) {
			if (e) {
				this.currentPage = e
			}
			this.clonedFilter.offset = this.currentPage * 20
			const level = this.clonedFilter.level ? `level=${this.clonedFilter.level}` : 'level='
			const chapter = this.clonedFilter.chapter ? `&chapter=${this.clonedFilter.chapter}` : '&chapter='
			const search = this.clonedFilter.search ? `&search=${this.clonedFilter.search}` : '&search='
			const subject = this.clonedFilter.subject ? `&subject=${this.clonedFilter.subject}` : '&subject='
			const offset = `&offset=${this.clonedFilter.offset}`
			this.actualFilter = `?${level}${chapter}${search}${subject}${offset}`
			
			this.filteredQuestionFlashPackage()
		},
		async filteredQuestionFlashPackage() {
			const res = await filteredQuestionFlashPackagesService(this.actualFilter)
			this.packageQuestions = res.results
			this.count = res.count
			this.pages = Math.ceil(this.count / 20)
			if (this.pages < this.currentPage) {
				this.currentPage = 0
			}
			this.isLoaded = true
		},
	},
	created() {
		this.clonedFilter = JSON.parse(JSON.stringify(this.filter))
		this.changeFilter()
	},
	watch: {
		filter: {
			handler() {
				this.clonedFilter = JSON.parse(JSON.stringify(this.filter))
				this.changeFilter()
			},
			deep: true
		}
	},
	
}
</script>

<style>
</style>
