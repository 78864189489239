<template>
	<div class="rule">
		<div class="flip-card">
			<div class="flip-card-inner">
				<div class="flip-card-front">
					<img :src="rule.img" alt="régle" />
				</div>
				<div class="flip-card-back">
					<p :style="`color:${rule.color}`" class="title">
						{{ rule.title }}
					</p>
					<p class="text">{{ rule.text }}</p>
				</div>
			</div>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		rule: {
			type: Object,
			required: true
		}
	}
}
</script>

<style>
</style>
