<template>
	<h1>All sequences</h1>
	<nav class="paginate">
		<ul v-if="pages !== null">
			<li v-for="(i, index) in pages" :key="index">
				<button
					:class="index == currentPage ? 'active' : ''"
					@click="getNewSequences(index)"
				>
					{{ index }}
				</button>
			</li>
		</ul>
	</nav>
	<table>
		<tr>
			<th></th>
			<th>Name</th>
			<th>Subject</th>
			<th>Level</th>
			<th>Ordre</th>
			<th>Modifier</th>
			<th>Supprimer</th>
		</tr>
		<tr
			v-for="(sequence, index) in this.$store.state.sequences"
			:key="index"
		>
			<td>{{ index + 1 * currentPage * 20 + 1 }}</td>
			<td>{{ sequence.name }}</td>
			<td>
				{{ getSubject(sequence.subject) }}
			</td>
			<td>{{ getLevel(sequence.level) }}</td>
			<td>{{ sequence.order }}</td>
			<td>
				<router-link
					:to="{
						name: 'SuperAdminEditSequence',
						params: { id: sequence.id },
					}"
					>Modifier</router-link
				>
			</td>
			<td>
				<button @click="deleteSequence(sequence.id)">supprimer</button>
			</td>
		</tr>
	</table>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'

export default {
	data() {
		return {
			count: null,
			currentPage: 0,
			pages: null
		}
	},
	methods: {
		getSubject: function (e) {
			return this.$store.state.subjects.find(x => x.id == e).name
		},
		getLevel: function (e) {
			return this.$store.state.levels.find(x => x.id == e).name
		},
		deleteSequence(e) {
			if (confirm("Do you really want to delete?")) {
				authenticatedApiFetch(`/sequences/${e}/`, { method: 'DELETE' })
					.then(() => {
						this.getNewSequences(this.currentPage)
					})
			}
		},
		getNewSequences: function (e) {
			authenticatedApiFetch(`/sequences/?offset=${e * 20}`, { method: 'GET' })
				.then(res => res.json())
				.then(res => {
					this.count = res.length
					this.currentPage = e
					this.sequences = res
					this.pages = Math.ceil(this.count / 20)
				})
		},
	},
}
</script>

<style>
</style>
