<template>
	<pop-up-wrapper>
		<div class="pop-up-edit-key">
			<img
				class="close"
				@click="this.$emit('close')"
				src="/img/close.svg"
				alt=""
			/>
			<p class="title">
				Pour enregistrer votre activité, sélectionnez une classe et un
				créneau.
			</p>
			<div class="input-container">
				<label for="classes">Classe</label>
				<select
					v-if="this.$store.state.classes.length !== 0"
					id="classes"
					v-model="classe"
				>
					<option
						v-for="classe in this.$store.state.classes"
						:key="classe.name"
						:value="classe.id"
					>
						{{ classe.name }}
					</option>
				</select>
			</div>
			<div class="input-container">
				<label for="date">Créneau</label>
				<select v-if="classe" v-model="currency" id="date">
					<option
						v-for="course in getCourses"
						:key="course.id"
						:value="course.id"
					>
						{{ course.formatedDay }} {{ course.debut_hour }} -
						{{ course.end_hour }}
					</option>
				</select>
				<select v-else name="" id=""></select>
			</div>
			<div class="btn-container">
				<button @click="duplicateThing" class="submit light">
					Dupliquer
				</button>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { formateAndOrderDate } from '../../../utils'
import { allActivityEventsFilterService } from '../../../services/activityEventsService'

import PopUpWrapper from '../PopUpWrapper.vue'
import { createClassLinksService, getClassLinkService } from '../../../services/classLinkService'
import { getOccurenceByIdService, updateOccurenceService } from '../../../services/occurrencesService'

export default {
	components: { PopUpWrapper },
	props: {
		id: Number,
	},
	data() {
		return {
			newLinkId: null,
			classe: null,
			courses: [],
			currency: null
		}
	},
	methods: {
		close() {
			this.$emit('close')
		},
		async allCourses() {
			let classes = []
			for (const classe of this.$store.state.classes) {
				classes.push(classe.id)
			}
			if (classes.length !== 0) {
				const filter = `?classes=${classes}`
				const res = await allActivityEventsFilterService(filter)

				this.courses = formateAndOrderDate(res)
			}

		},
		async duplicateThing() {
			const res = await getClassLinkService(this.id)
			let link = res
			link.class_id = this.classe

			const classLink = await createClassLinksService(link)
			this.newLinkId = classLink.id

			if (!this.currency) {
				console.log('no agenda')
			}
			else {
				let occurrence = await getOccurenceByIdService(this.currency)

				occurrence.class_links.push(this.newLinkId)

				await updateOccurenceService(this.currency, occurrence)

			}


			this.$emit('refreshLinks')

		},
	},
	computed: {
		getCourses() {
			let courses = this.courses.filter(x => x.classroom == this.classe)
			return courses
		}
	},
	created() {
		this.allCourses()
	},
}
</script>

<style>
</style>
