<template>
	<section class="stats-container">
		<h1>Statistiques Comptes</h1>
		<pagination-component :totalPages="pages" :perPage="20" :currentPage="currentPage" @pageChanged="orderBy" />

		<div v-if="isLoading">
			<loader-component />
		</div>
		<div class="table-container" v-else>
			<form @submit.prevent="findByUsername">
				<input placeholder="username" v-model="username" type="text" />
				<input type="submit" />
			</form>
			<table>
				<tr>
					<th>Email</th>
					<th @click="changeFilter('classes_count')">
						Classes crées
					</th>
					<th @click="changeFilter('activities_count')">
						Ressources importés
					</th>
					<th @click="changeFilter('exercises_sheet_count')">
						Fiches d'exercices
					</th>
					<th @click="changeFilter('games_count')">Jeux</th>
					<th @click="changeFilter('files_count')">Fichiers</th>
					<th @click="changeFilter('observations_count')">
						Observations
					</th>
					<th @click="changeFilter('last_login')">
						Derniere connexion
					</th>
					<th @click="changeFilter('date_joined')">
						Date d'inscription
					</th>
					<th>Voir</th>
				</tr>
				<tr v-for="user in this.users" :key="user.id">
					<td>
						{{ user.username }}
					</td>
					<td>
						<p>
							{{ user.classes_count }}
						</p>
					</td>
					<td>
						<p>
							{{ user.activities_count }}
						</p>
					</td>
					<td>
						<p>
							{{ user.exercises_sheet_count }}
						</p>
					</td>
					<td>
						<p>
							{{ user.games_count }}
						</p>
					</td>
					<td>
						<p>
							{{ user.files_count }}
						</p>
					</td>
					<td>
						<p>
							{{ user.observations_count }}
						</p>
					</td>
					<td>
						<p>
							{{ formatedDate(user.last_login) }}
						</p>
					</td>
					<td>
						<p>
							{{ formatedDate(user.date_joined) }}
						</p>
					</td>
					<td>
						<router-link class="see-btn" :to="{
							name: 'SuperAdminIndiviualStats',
							params: { id: user.id },
						}">
							Voir</router-link>
					</td>
				</tr>
			</table>
		</div>
	</section>
</template>

<script>
import { authenticatedApiFetch } from '../../../utils'
import LoaderComponent from '../../Loader.vue'
import PaginationComponent from '../../Utils/PaginationComponent.vue'
export default {
	components: { PaginationComponent, LoaderComponent },


	data() {
		return {
			users: [],
			count: null,
			currentPage: 0,
			pages: 0,
			perPage: 20,
			isLoading: true,
			filter: '',
			username: '',
		}
	},

	methods: {
		changeFilter(e) {
			this.filter = e
			this.orderBy()
		},
		formatedDate(date) {
			if (date) {
				return date.slice(0, 10)
			}
		},
		async findByUsername() {
			this.isLoading = true
			const res = await authenticatedApiFetch(`/stat-profile/?search=${this.username}`)
				.then(res => res.json())
			this.users = res.results
			this.count = res.count
			this.currentPage = 0
			this.pages = Math.ceil(this.count / 20)
			this.isLoading = false
		},
		async getStats() {
			this.isLoading = true
			const res = await authenticatedApiFetch('/stat-profile/')
				.then(res => res.json())
			this.users = res.results
			this.count = res.count
			this.currentPage = 0
			this.pages = Math.ceil(this.count / 20)
			this.isLoading = false
		},
		async orderBy(offset) {
			if (!offset) {
				offset = 0
			}
			this.isLoading = true
			const res = await authenticatedApiFetch(`/stat-profile/?ordering=-${this.filter}&offset=${offset * 20}`)
				.then(res => res.json())
			this.users = res.results
			this.count = res.count
			this.currentPage = offset
			this.pages = Math.ceil(this.count / 20)
			this.isLoading = false
		}
	},
	created() {
		this.getStats()
	},
}
</script>

<style></style>
