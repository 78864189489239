
<template>
	<pop-up-wrapper>
		<form class="pop-up-class-created" @submit.prevent="submit">
			<div class="wrapper">
				<p class="title">Nouvelle tâche</p>

				<div class="input-container">
					<label for="">Titre</label>
					<input v-model="form.title" type="text" />
				</div>
				<div class="input-container">
					<label for="">Description</label>
					<textarea v-model="form.description" rows="10" />
				</div>
				<div class="input-container">
					<label for=""
						>Attribué à (Maintenez la touche Ctrl pour sélectionner
						plusieurs classes)
					</label>
					<select multiple v-model="form.classes" name="" id="">
						<option
							v-for="classe in this.$store.state.classes"
							:key="classe.id"
							:value="classe.id"
						>
							{{ classe.name }}
						</option>
					</select>
				</div>
				<div class="btn-container">
					<p @click="this.$emit('close')" class="cancel">
						Abandonner
					</p>
					<button class="add">Valider</button>
				</div>
			</div>
		</form>
	</pop-up-wrapper>
</template>

<script>
import { createKanbanService } from '../../../services/kanbansService'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },

	data() {
		return {
			form: {
				title: '',
				description: '',
				classes: [],
				teacher_id: this.$store.state.user.user,
				state: 'TODO'
			}
		}
	},

	methods: {
		async submit() {
			await createKanbanService(this.form)
				.then(() => {
					this.$emit('refreshKanban')
					this.$emit('close')
				})
		}
	},
}
</script>

<style>
</style>
