import { authenticatedApiFetch } from '../utils'



export const deleteTileGameContentService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game-content/${id}/`, { method: 'DELETE' })
    return res
}


export const allTileGameContentsFilterService = async (filter) => {
    const res = await authenticatedApiFetch(`/tile-game-content/?${filter}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const getTileGameContentService = async (id) => {
    const res = await authenticatedApiFetch(`/tile-game-content/${id}`, { method: 'GET' })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}

export const createTileGameContentService = async (data) => {
    const res = await authenticatedApiFetch(`/tile-game-content/`, { method: 'POST', body: data, headers: {} })
        .then(res => res.json())
        .catch(error => console.error(error))
    return res
}


