<template>
	<section>
		<h1>Ajouter une classe</h1>
		<form @submit.prevent="submit">
			<div class="input-container">
				<input
					id="name"
					v-model="form.name"
					name="name"
					type="text"
					placeholder=" "
				/>
				<label class="custom-label" for="name">Nom de la classe</label>
			</div>
			<div class="input-container">
				<select
					class="days"
					v-model="form.level"
					name="level"
					id="level"
				>
					<option value="" selected disabled hidden>
						Niveau de la classe
					</option>
					<option
						v-for="level in $store.state.levels"
						:key="level"
						:value="level.id"
					>
						{{ level.name }}
					</option>
				</select>
			</div>
			<div class="error-container">
				<p v-if="errors !== ''">{{ errors }}</p>
			</div>
			<input class="submit dark" type="submit" value="Valider" />
		</form>
		<pop-up-question
			:id="
				this.$store.state.classes[this.$store.state.classes.length - 1]
					.id
			"
			v-if="showPopUp"
		/>
	</section>
</template>

<script>
import PopUpQuestion from '../../PopUp/PopUpClasse.vue'

export default {
	components: {
		PopUpQuestion
	},
	data() {
		return {
			form: {
				name: '',
				level: '',
				teacher: localStorage.userId
			},
			levels: [],
			errors: '',
			isOk: false,
			isFirstTime: true
		}
	},
	methods: {
		submit() {
			if (this.form.name == '' || this.form.level == '') {
				this.errors = 'Veuillez remplir les champs manquants'
			}
			else {
				this.$store.dispatch('addClass', this.form)
					.then(() => {
						this.isOk = true
					})
					.catch((error) => {
						this.errors = error.response.data
					})
			}
		},
	},
	computed: {
		showPopUp() {
			if (this.isOk) {
				return true
			}
			else {
				return false
			}
		}
	},

}
</script>

<style>
</style>
