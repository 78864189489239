import { createWebHashHistory, createRouter } from "vue-router"

import { trackRouter } from "vue-gtag-next"


import Platform from '../components/Platform'
import LoggedUser from '../components/LoggedUser'
import Admin from '../components/Admin/Admin'
import Dashboard from '../components/Admin/Dashboard/Dashboard'

import Class from '../components/Admin/Class/Class'
import AddClass from '../components/Admin/Class/AddClass'
import EditClass from '../components/Admin/Class/EditClass'
import EditCourse from '../components/Admin/Class/Course/Course'
import ClassResources from '../components/Admin/Class/ClassResources/ClassResources'
import ClassStudent from '../components/Admin/Class/ClassStudent'
import ClassObservation from '../components/Admin/Class/ClassObservation'


import ClassCourse from '../components/Admin/Class/Course/Course'
import ClassDoc from '../components/Admin/Class/ClassDoc'
import ClassFolder from '../components/Admin/Class/Drive/ClassFolder'
import ClassDrive from '../components/Admin/Class/Drive/ClassDrive'
import ShowImg from '../components/Admin/Class/Drive/ShowImg'
import ClassLink from '../components/Admin/Class/Link/ClassLink'


import Logout from '../components/Logout'

import Agenda from '../components/Admin/Agenda/Agenda'

import ProfileWrapper from '../components/Admin/Profile/ProfileWrapper'
import Profile from '../components/Admin/Profile/Profile'
import Abonnement from '../components/Admin/Profile/Abonnement'

import JeuSerieux from '../components/Admin/Search/SeriousGame/SeriousGame'
import CalendarWrapper from '../components/Admin/Search/SeriousGame/Calendar/CalendarWrapper'
import CalendarCreate from '../components/Admin/Search/SeriousGame/Calendar/CalendarCreate'
import CalendarOrganize from '../components/Admin/Search/SeriousGame/Calendar/CalendarOrganize'
import DisplayCalendar from '../components/Admin/Search/SeriousGame/Calendar/DisplayCalendar'
import CalendarExercise from '../components/Admin/Search/SeriousGame/Calendar/CalendarExercise'

import TileGameWrapper from '../components/Admin/Search/SeriousGame/TileGame/TileGameWrapper'
import TileGameCreate from '../components/Admin/Search/SeriousGame/TileGame/TileGameCreate'
import TileGameOrganize from '../components/Admin/Search/SeriousGame/TileGame/TileGameOrganize'
import TileGame from '../components/Admin/Search/SeriousGame/TileGame/TileGame'
import TileRules from '../components/Admin/Search/SeriousGame/TileGame/TileRules'
import TileGameLeaderBoard from '../components/Admin/Search/SeriousGame/TileGame/TileGameLeaderBoard'
import TileGameQuestion from '../components/Admin/Search/SeriousGame/TileGame/TileGameQuestion'
import TileGameHome from '../components/Admin/Search/SeriousGame/TileGame/TileGameHome'


import Key from '../components/Admin/Search/Key'
import QuestionWrapper from '../components/Admin/Search/Question/QuestionWrapper'
import Question from '../components/Admin/Search/Question/Question'
import QuestionKey from '../components/Admin/Search/Question/QuestionKey'


import DisplayActivity from '../components/Admin/DisplayActivity'
import EditProfile from '../components/Admin/Profile/EditProfile'
import SuperAdmin from '../components/SuperAdmin/SuperAdmin'
import SuperAdminDashboard from '../components/SuperAdmin/Dashboard'

import SuperAdminAddChapter from '../components/SuperAdmin/AddChapter'
import SuperAdminAddDifficulty from '../components/SuperAdmin/AddDifficulty'
import SuperAdminAddLevel from '../components/SuperAdmin/AddLevel'
import SuperAdminAddQuestionTheme from '../components/SuperAdmin/AddQuestionTheme'
import SuperAdminAddQuestionType from '../components/SuperAdmin/AddQuestionType'
import SuperAdminAddSequence from '../components/SuperAdmin/AddSequence'
import SuperAdminAddSubject from '../components/SuperAdmin/AddSubject'
import SuperAdminAddKey from '../components/SuperAdmin/AddKey'
import SuperAdminAddExerciseSheetQuestion from '../components/SuperAdmin/AddExerciseSheetQuestion'
import SuperAdminAddExerciseSheetQuestionTheme from '../components/SuperAdmin/AddExerciseSheetQuestionTheme'
import SuperAdminAddExerciseSheetQuestionType from '../components/SuperAdmin/AddExerciseSheetQuestionType'
import SuperAdminAddAcademy from '../components/SuperAdmin/AddAcademy'
import SuperAdminAddCounty from '../components/SuperAdmin/AddCounty'
import SuperAdminAddLesson from '../components/SuperAdmin/AddLesson'


import SuperAdminAddExercise from '../components/SuperAdmin/Exercise/AddExercise'
import SuperAdminEditExercise from '../components/SuperAdmin/Exercise/EditExercise'

import SuperAdminEditQuestion from '../components/SuperAdmin/Flash/EditQuestion'
import SuperAdminAddQuestion from '../components/SuperAdmin/Flash/AddQuestion'


import SuperAdminAllChapters from '../components/SuperAdmin/AllChapters'
import SuperAdminAllDifficulties from '../components/SuperAdmin/AllDifficulties'
import SuperAdminAllSubjects from '../components/SuperAdmin/AllSubjects'
import SuperAdminAllLevels from '../components/SuperAdmin/AllLevels'
import SuperAdminAllQuestions from '../components/SuperAdmin/AllQuestions'
import SuperAdminAllSequences from '../components/SuperAdmin/AllSequences'
import SuperAdminAllQuestionThemes from '../components/SuperAdmin/AllQuestionThemes'
import SuperAdminAllQuestionTypes from '../components/SuperAdmin/AllQuestionTypes'
import SuperAdminAllEvaluationTypes from '../components/SuperAdmin/AllEvaluationTypes'
import SuperAdminAllKeys from '../components/SuperAdmin/AllKeys'

import SuperAdminAllProposedKeys from '../components/SuperAdmin/Key/AllKeys'

import SuperAdminProposeKey from '../components/SuperAdmin/Key/ProposeKey'
import SuperAdminEditProposedKey from '../components/SuperAdmin/Key/EditProposedKey'

import SuperAdminViewProposedKey from '../components/SuperAdmin/Key/ViewProposedKey'
import SuperAdminViewProposedKeyApproveMod from '../components/SuperAdmin/Key/ViewProposedKeyApproveMod'


import SuperAdminPercentage from '../components/SuperAdmin/Percentage/Percentage'


import SuperAdminAllExerciseSheetQuestions from '../components/SuperAdmin/AllExerciseSheetQuestions'


import SuperAdminAllExerciseSheetQuestionThemes from '../components/SuperAdmin/AllExerciseSheetQuestionThemes'
import SuperAdminAllExerciseSheetQuestionTypes from '../components/SuperAdmin/AllExerciseSheetQuestionTypes'
import SuperAdminAllAcademies from '../components/SuperAdmin/AllAcademies'
import SuperAdminAllCounty from '../components/SuperAdmin/AllCounty'
import SuperAdminAllLessons from '../components/SuperAdmin/AllLessons'
import SuperAdminAllSuggestions from '../components/SuperAdmin/Suggestion/AllSuggestions'
import SuggestionDetails from '../components/SuperAdmin/Suggestion/SuggestionDetails'
import SuggestionStats from '../components/SuperAdmin/Suggestion/SuggestionStats'

import SuperAdminEditChapter from '../components/SuperAdmin/EditChapter'
import SuperAdminEditDifficulty from '../components/SuperAdmin/EditDifficulty'
import SuperAdminEditSubject from '../components/SuperAdmin/EditSubject'
import SuperAdminEditLevel from '../components/SuperAdmin/EditLevel'
import SuperAdminEditSequence from '../components/SuperAdmin/EditSequence'
import SuperAdminEditQuestionTheme from '../components/SuperAdmin/EditQuestionTheme'
import SuperAdminEditQuestionType from '../components/SuperAdmin/EditQuestionType'
import SuperAdminEditEvaluationType from '../components/SuperAdmin/EditEvaluationType'
import SuperAdminEditKey from '../components/SuperAdmin/EditKey'
import SuperAdminEditExerciseSheetQuestion from '../components/SuperAdmin/EditExerciseSheetQuestion'
import SuperAdminEditExerciseSheetQuestionTheme from '../components/SuperAdmin/EditExerciseSheetQuestionTheme'
import SuperAdminEditExerciseSheetQuestionType from '../components/SuperAdmin/EditExerciseSheetQuestionType'
import SuperAdminEditAcademy from '../components/SuperAdmin/EditAcademy'
import SuperAdminEditCounty from '../components/SuperAdmin/EditCounty'
import SuperAdminEditLesson from '../components/SuperAdmin/EditLesson'


import SuperAdminAllCalendarExercises from '../components/SuperAdmin/Game/AllCalendarExercises'
import SuperAdminAllPendingCalendarExerciseContent from '../components/SuperAdmin/Game/AllPendingCalendarExercise'

import SuperAdminAddCalendarExercise from '../components/SuperAdmin/Game/AddCalendarExercise'
import SuperAdminEditCalendarExercise from '../components/SuperAdmin/Game/EditCalendarExercise'



import SuperAdminAllTileGameContent from '../components/SuperAdmin/Game/AllTileGameContent'
import SuperAdminPendingTileGameContent from '../components/SuperAdmin/Game/PendingTileGame'
import SuperAdminAddTileGameContent from '../components/SuperAdmin/Game/AddTileGameContent'
import SuperAdminEditTileGameContent from '../components/SuperAdmin/Game/EditTileGameContent'

import SuperAdminStatsWrapper from '../components/SuperAdmin/Stats/StatsWrapper'
import SuperAdminStats from '../components/SuperAdmin/Stats/Stats'
import SuperAdminIndiviualStats from '../components/SuperAdmin/Stats/IndividualStats'
import SuperAdminClassStats from '../components/SuperAdmin/Stats/ClassStats'
import SuperAdminKeyStats from '../components/SuperAdmin/Stats/KeyStats'
import SuperAdminFlashStats from '../components/SuperAdmin/Stats/FlashStats'


import NamePicker from '../components/Tool/NamePicker'
import Timer from '../components/Tool/Timer'
import ExerciseSheet from '../components/Admin/Search/Exercise/ExerciseSheet'

import StudentPlace from '../components/StudentPlace/StudentPlace'
import StudentPlaceHome from '../components/StudentPlace/Home'
import StudentPlaceLesson from '../components/StudentPlace/StudentPlaceLesson'
import StudentPlaceFlash from '../components/StudentPlace/StudentPlaceFlashQuestion'
import StudentPlaceKey from '../components/StudentPlace/StudentPlaceKey'
import StudentPlaceGame from '../components/StudentPlace/StudentPlaceGame'
import StudentPlaceExercise from '../components/StudentPlace/StudentPlaceExercise'
import StudentPlaceEvaluation from '../components/StudentPlace/StudentPlaceEvaluation'
import StudentPlaceLink from '../components/StudentPlace/StudentPlaceLink'

import PriceList from '../components/Admin/Payment/PriceList'
import ThankYouBuying from '../components/Admin/Payment/ThankYouBuying'


import StudentPlaceDisplayActivity from '../components/StudentPlace/StudentPlaceDisplayActivity'

import Resources from '../components/Admin/Resources/Resources'
import MyResources from '../components/Admin/Resources/MyResources'
import MyResourcesQuestionFlash from '../components/Admin/Resources/Resource/QuestionFlash/QuestionFlash'
import MyResourcesExercise from '../components/Admin/Resources/Resource/Exercise/Exercise'

import MyResourcesAddQuestion from '../components/Admin/Resources/Resource/QuestionFlash/AddQuestionFlash'

import MyResourcesAddExercise from '../components/Admin/Resources/Resource/Exercise/AddExercise'
import MyResourcesEditExercise from '../components/Admin/Resources/Resource/Exercise/EditExercise'

import MyResourcesEditQuestion from '../components/Admin/Resources/Resource/QuestionFlash/EditQuestion'

import MyResourcesExpresso from '../components/Admin/Resources/Resource/Expresso/Expresso'
import MyResourcesAddExpresso from '../components/Admin/Resources/Resource/Expresso/AddExpresso'
import MyResourcesEditExpresso from '../components/Admin/Resources/Resource/Expresso/EditExpresso'

import MyResourcesViewExpresso from '../components/Admin/Resources/Resource/Expresso/ViewExpresso'



import Config from '../config'
const routes = [
    {
        path: '/show-image/:fileName',
        component: ShowImg,
        name: 'ShowImg',

    },
    {
        path: '/mentions-legales',
        component: () => import('../components/LegalNotice'),
        name: 'LegalNotice'
    },
    {
        path: '/cgv',
        component: () => import('../components/CGV'),
        name: 'CGV'
    },
    {
        path: '/auth',
        component: () => import('../components/Auth/Auth'),
        children: [
            {
                path: '',
                component: () => import('../components/Auth/SSOAuth'),
                name: 'SSOAuth'
            }
        ]
    },
	{
		path: '/login-student/:classId',
		component:  ()=>import('../components/StudentPlace/StudentLogin'),
		name: 'StudentPlaceLogin'
	},
    {
        path: '/espace-etudiant/:classId',
        component: StudentPlace,
        name: 'StudentPlace',
		beforeEnter: (to, from, next) => {
			if (localStorage.studentToken || localStorage.token) {
				next()
			}
			else {
				router.push({name: 'StudentPlaceLogin', params: {classId: to.params.classId}})
			}
		},
        children: [
            {
                path: '',
                component: StudentPlaceHome,
                name: 'StudentPlaceHome',
            },
            {
                path: 'cours',
                component: StudentPlaceLesson,
                name: 'StudentPlaceLesson'
            },
            {
                path: 'question-flash',
                component: StudentPlaceFlash,
                name: 'StudentPlaceFlash'
            },
            {
                path: 'key',
                component: StudentPlaceKey,
                name: 'StudentPlaceKey'
            },
            {
                path: 'jeux',
                component: StudentPlaceGame,
                name: 'StudentPlaceGame'
            },
            {
                path: 'exercice',
                component: StudentPlaceExercise,
                name: 'StudentPlaceExercise'
            },
            {
                path: 'evaluation',
                component: StudentPlaceEvaluation,
                name: 'StudentPlaceEvaluation'
            },
            {
                path: 'link',
                component: StudentPlaceLink,
                name: 'StudentPlaceLink'

            },
			{
				path: 'qcm/:id',
				component: ()=>import('../components/StudentPlace/StudentPlaceQcm'),
				name: 'StudentPlaceQcm'
			},
            {
                path: 'displayActivity/:id',
                component: StudentPlaceDisplayActivity,
                name: 'StudentPlaceDisplayActivity'
            },
            {
                path: 'calendrier/:calendarId',
                component: DisplayCalendar,
                name: 'DisplayCalendar'
            },
            {
                path: 'exercise/:id&:template',
                component: CalendarExercise,
                name: 'CalendarExercise'
            },

        ]

    },
    {
        path: '/platform/',
        component: Platform,
        name: 'Platform',
    },
    {
        path: '/logout/',
        component: Logout,
        name: 'Logout',
    },
    {
        path: '/tarif',
        component: PriceList,
        name: 'PriceList',
    },
    {
        path: '/merci',
        component: ThankYouBuying,
        name: 'ThankYouBuying',

    },
    {
        path: '/',
        component: LoggedUser,
        redirect: { name: 'Dashboard' },
        children: [
            {
                path: '/admin/',
                component: Admin,
                children: [

                    {
                        path: 'dashboard',
                        component: Dashboard,
                        name: 'Dashboard',
                        beforeEnter: (to, from, next) => {
                            if (localStorage.token) {
                                next()
                            }
                            else {
                                location.replace(`${Config.landing_url}/auth/login`)
                            }
                        }
                    },
					{
						path: 'generer-question-flash',
						component: ()=>import('../components/Admin/GenerateFlashQuestion'),
						name: 'GenerateFlashQuestion'
					},
					{
						path: 'generer-qcm',
						component: ()=>import('../components/Admin/Search/QCM/GenerateQCM'),
						name: 'GenerateQCM'
					},
                    {
                        path: 'classe/:id',
                        component: Class,
                        name: 'Class',
                        children: [
                            {
                                path: '',
                                component: ClassResources,
                                name: 'ClassResources',
                            },
                            {
                                path: 'student',
                                component: ClassStudent,
                                name: 'ClassStudent',
                            },
                            {
                                path: 'observation',
                                component: ClassObservation,
                                name: 'ClassObservation',
                            },
                            {
                                path: 'course',
                                component: ClassCourse,
                                name: 'ClassCourse',
                            },
                            {
                                path: 'liens',
                                component: ClassLink,
                                name: 'ClassLink'
                            },
                            {
                                path: 'doc',
                                component: ClassDoc,
                                name: 'ClassDoc',
                                children: [

                                    {
                                        path: '',
                                        component: ClassDrive,
                                        name: 'ClassDrive',
                                    },
                                    {
                                        path: 'folder/:folderId',
                                        component: ClassFolder,
                                        name: 'ClassFolder',
                                    },
                                ]
                            },
                        ]
                    },


                    {
                        path: 'agenda',
                        component: Agenda,
                        name: 'Agenda'
                    },
                    {
                        path: 'profil',
                        component: ProfileWrapper,
                        name: 'ProfileWrapper',
                        children: [
                            {
                                path: '',
                                component: Profile,
                                name: 'Profile'
                            },
                            {
                                path: 'abonnement',
                                component: Abonnement,
                                name: 'Abonnement'
                            },

                        ]
                    },
                    {
                        path: 'recherche/jeu-serieux',
                        component: JeuSerieux,
                        name: 'JeuSerieux'
                    },
                    {
                        path: 'creation-tile-game',
                        component: TileGameWrapper,
                        children: [
                            {
                                path: '',
                                component: TileGameCreate,
                                name: 'TileGameCreate'
                            },
                            {
                                path: 'organization-tile-game/:theme',
                                component: TileGameOrganize,
                                name: 'TileGameOrganize',
                            },
                        ]
                    },
                    {
                        path: 'calendrier',
                        component: CalendarWrapper,
                        name: 'CalendarWrapper',
                        children: [
                            {
                                path: 'creation-calendrier',
                                component: CalendarCreate,
                                name: 'CalendarCreate'
                            },
                            {
                                path: 'organization-calendrier/:template',
                                component: CalendarOrganize,
                                name: 'CalendarOrganize',
                            },
                        ]
                    },
                    {
                        path: 'ressources',
                        component: Resources,
                        name: 'Resources',
                    },
					{
						path: 'share-place',
						component: ()=>import('../components/Admin/Resources/Resource/ShareSpace/ShareSpace'),
						name: 'ShareSpace'
					},
					{
						path: 'view-qcm/:id',
						component: ()=>import('../components/Admin/Resources/Resource/QCM/ViewQcm'),
						name: 'ViewQcm'
					},
					{
						path: 'qcm/:id',
						component: ()=>import('../components/Admin/Resources/Resource/QCM/QcmRouter'),
						children: [
							{
								path: '',
								component: ()=>import('../components/Admin/Resources/Resource/QCM/ViewQcmNote'),
								name: 'ViewQcmNote'
							},
							{
								path:'stats',
								component: ()=>import('../components/Admin/Resources/Resource/QCM/ViewQcmStats'),
								name: 'ViewQcmStats'
							},
							{
								path: 'students',
								component: ()=>import('../components/Admin/Resources/Resource/QCM/ViewQcmStatsStudents'),
								name: 'ViewQcmStatsStudents'
							}
						]
					},
					
                    {
                        path: 'mes-ressources',
                        component: MyResources,
                        name: 'MyResources',
                        children: [
                            {
                                path: '',
                                component: MyResourcesQuestionFlash,
                                name: 'MyResourcesQuestionFlash',
                            },
                            {
                                path: 'activite',
                                component: MyResourcesExpresso,
                                name: 'MyResourcesExpresso',
                            },
                            {
                                path: 'exercice',
                                component: MyResourcesExercise,
                                name: 'MyResourcesExercise',
                            },
							{
								path: 'qcm',
								component: ()=>import('../components/Admin/Resources/Resource/QCM/QCMs'),
								name: 'MyQCMs'
							}
							

                        ]
                    },
                    {
                        path: 'ajouter-question',
                        component: MyResourcesAddQuestion,
                        name: 'MyResourcesAddQuestion',
                    },
                    {
                        path: 'ajouter-exercise',
                        component: MyResourcesAddExercise,
                        name: 'MyResourcesAddExercise',
                    },
                    {
                        path: 'ajouter-expresso',
                        component: MyResourcesAddExpresso,
                        name: 'MyResourcesAddExpresso',
                    },
					{
						path: 'ajouter-qcm',
						component: ()=>import('../components/Admin/Resources/Resource/QCM/AddQcm'),
						name: 'MyResourcesAddQcm'
					},
                    {
                        path: 'modifier-exercise/:id',
                        component: MyResourcesEditExercise,
                        name: 'MyResourcesEditExercise',
                    },
                    {
                        path: 'modifier-question/:id',
                        component: MyResourcesEditQuestion,
                        name: 'MyResourcesEditQuestion',
                    },
                    {
                        path: 'modifier-expresso/:id',
                        component: MyResourcesEditExpresso,
                        name: 'MyResourcesEditExpresso',
                    },
					{
						path: 'modifier-qcm/:id',
						component: ()=>import('../components/Admin/Resources/Resource/QCM/EditQcm'),
						name: 'MyResourcesEditQcm'
					},
                    {
                        path: 'voir-expresso/:id',
                        component: MyResourcesViewExpresso,
                        name: 'MyResourcesViewExpresso',
                    },
                    {
                        path: 'recherche/exercice',
                        component: ExerciseSheet,
                        name: 'ExerciseSheet',
                    },
                    {
                        path: 'recherche/clef-en-main',
                        component: Key,
                        name: 'Key'
                    },
                    {
                        path: 'recherche/qcm',
                        component: ()=>import('../components/Admin/Search/QCM/QCM'),
                        name: 'QCM'
                    },
					

                    {
                        path: 'recherche/questions-individuelles',
                        component: QuestionWrapper,
                        name: 'QuestionWrapper',
                        children: [
                            {
                                path: 'a-la-carte',
                                component: Question,
                                name: 'Question',
                            },
                            {
                                path: 'cles-en-main',
                                component: QuestionKey,
                                name: 'QuestionKey',
                            }
                        ]
                    },

                    {
                        path: 'ajouter-classe',
                        component: AddClass,
                        name: 'AddClass'
                    },
                    {
                        path: 'edit-classe/:id',
                        component: EditClass,
                        name: 'EditClass',
                    },
                    {
                        path: 'edit-classe/:id/edit-course',
                        component: EditCourse,
                        name: 'EditCourse',

                    },
                    {
                        path: 'edit-profile',
                        component: EditProfile,
                        name: 'EditProfile',
                    },

                ]
            },
            {
                path: '/super-admin/',
                component: SuperAdmin,

                children: [
                    {
                        path: 'dashboard',
                        component: SuperAdminDashboard,
                        name: 'SuperAdminDashboard',
                    },
                    {
                        path: 'stats',
                        component: SuperAdminStatsWrapper,
                        name: 'SuperAdminStatsWrapper',
                        children: [
                            {
                                path: '',
                                component: SuperAdminStats,
                                name: 'SuperAdminStats',
                            },
                            {
                                path: 'class-stats',
                                component: SuperAdminClassStats,
                                name: 'SuperAdminClassStats',
                            },
                            {
                                path: 'key-stats',
                                component: SuperAdminKeyStats,
                                name: 'SuperAdminKeyStats',
                            },
                            {
                                path: 'flash-stats',
                                component: SuperAdminFlashStats,
                                name: 'SuperAdminFlashStats',
                            },

                            {
                                path: 'individual-stats/:id',
                                component: SuperAdminIndiviualStats,
                                name: 'SuperAdminIndiviualStats'
                            },
                            {
                                path: 'all-suggestions',
                                component: SuperAdminAllSuggestions,
                                name: 'SuperAdminAllSuggestions',
                                children: [
                                    {
                                        path: '',
                                        component: SuggestionDetails,
                                        name: 'SuggestionDetails',

                                    },
                                    {
                                        path: 'stat',
                                        component: SuggestionStats,
                                        name: 'SuggestionStats',

                                    },
                                ]
                            },
                        ]
                    },

                    {
                        path: 'add-chapter',
                        component: SuperAdminAddChapter,
                        name: 'SuperAdminAddChapter',
                    },
                    {
                        path: 'add-difficulty',
                        component: SuperAdminAddDifficulty,
                        name: 'SuperAdminAddDifficulty',
                    },
                    {
                        path: 'add-level',
                        component: SuperAdminAddLevel,
                        name: 'SuperAdminAddLevel',
                    },
                    {
                        path: 'add-question',
                        component: SuperAdminAddQuestion,
                        name: 'SuperAdminAddQuestion',
                    },
                    {
                        path: 'add-question-theme',
                        component: SuperAdminAddQuestionTheme,
                        name: 'SuperAdminAddQuestionTheme',
                    },
                    {
                        path: 'add-question-type',
                        component: SuperAdminAddQuestionType,
                        name: 'SuperAdminAddQuestionType',
                    },
                    {
                        path: 'add-sequence',
                        component: SuperAdminAddSequence,
                        name: 'SuperAdminAddSequence',
                    },
                    {
                        path: 'add-subject',
                        component: SuperAdminAddSubject,
                        name: 'SuperAdminAddSubject',
                    },
                    {
                        path: 'add-key',
                        component: SuperAdminAddKey,
                        name: 'SuperAdminAddKey',
                    },
                    {
                        path: 'add-exercise-sheet-question',
                        component: SuperAdminAddExerciseSheetQuestion,
                        name: 'SuperAdminAddExerciseSheetQuestion',
                    },
                    {
                        path: 'add-exercise-sheet-question-theme',
                        component: SuperAdminAddExerciseSheetQuestionTheme,
                        name: 'SuperAdminAddExerciseSheetQuestionTheme',
                    },
                    {
                        path: 'add-exercise-sheet-question-type',
                        component: SuperAdminAddExerciseSheetQuestionType,
                        name: 'SuperAdminAddExerciseSheetQuestionType',
                    },
                    {
                        path: 'ajouter-un-exercice',
                        component: SuperAdminAddExercise,
                        name: 'SuperAdminAddExercise'
                    },
                    {
                        path: 'modifier-un-exercice/:id',
                        component: SuperAdminEditExercise,
                        name: 'SuperAdminEditExercise'
                    },
                    {
                        path: 'add-academy',
                        component: SuperAdminAddAcademy,
                        name: 'SuperAdminAddAcademy',
                    },
                    {
                        path: 'add-county',
                        component: SuperAdminAddCounty,
                        name: 'SuperAdminAddCounty',
                    },
                    {
                        path: 'add-lesson',
                        component: SuperAdminAddLesson,
                        name: 'SuperAdminAddLesson',
                    },
                    {
                        path: 'all-chapters',
                        component: SuperAdminAllChapters,
                        name: 'SuperAdminAllChapters',
                    },
                    {
                        path: 'all-difficulties',
                        component: SuperAdminAllDifficulties,
                        name: 'SuperAdminAllDifficulties',
                    },
                    {
                        path: 'all-subjects',
                        component: SuperAdminAllSubjects,
                        name: 'SuperAdminAllSubjects',
                    },
                    {
                        path: 'all-levels',
                        component: SuperAdminAllLevels,
                        name: 'SuperAdminAllLevels',
                    },
                    {
                        path: 'all-questions',
                        component: SuperAdminAllQuestions,
                        name: 'SuperAdminAllQuestions',
                    },
                    {
                        path: 'all-qcms',
                        component: () => import('../components/SuperAdmin/QCM/AllQcms'),
                        name: 'SuperAdminAllQcms',
                    },
					{
						path: 'pending-qcms',
						component: ()=>import('../components/SuperAdmin/QCM/PendingQcms'),
						name: 'SuperAdminPendingQcms'
					},
					{
						path: 'all-qcm-packages',
						component: ()=>import('../components/SuperAdmin/QCMPackage/AllQcmPackages'),
						name: 'SuperAdminAllQcmPackages'
					},
					{
						path: 'pending-qcm-packages',
						component: ()=>import('../components/SuperAdmin/QCMPackage/PendingQcmPackages'),
						name: 'SuperAdminPendingQcmPackages'
					},
					{
						path: 'edit-qcm-package/:id',
						component: ()=>import('../components/SuperAdmin/QCMPackage/EditQcmPackage'),
						name: 'SuperAdminEditQcmPackage'
					},
					{
						path: 'all-question-flash-packages',
						component: ()=>import('../components/SuperAdmin/QuestionFlashPackage/AllQuestionFlashPackages'),
						name: 'SuperAdminAllQuestionFlashPackages'
					},
					{
						path: 'edit-question-flash-package/:id',
						component: ()=>import('../components/SuperAdmin/QuestionFlashPackage/EditQuestionFlashPackage'),
						name: 'SuperAdminEditQuestionFlashPackage'
					},
                    {
                        path: 'all-sequences',
                        component: SuperAdminAllSequences,
                        name: 'SuperAdminAllSequences',
                    },
                    {
                        path: 'all-question-themes',
                        component: SuperAdminAllQuestionThemes,
                        name: 'SuperAdminAllQuestionThemes',
                    },
                    {
                        path: 'all-question-types',
                        component: SuperAdminAllQuestionTypes,
                        name: 'SuperAdminAllQuestionTypes',
                    },
                    {
                        path: 'all-evaluation-types',
                        component: SuperAdminAllEvaluationTypes,
                        name: 'SuperAdminAllEvaluationTypes',
                    },

                    {
                        path: 'all-keys',
                        component: SuperAdminAllKeys,
                        name: 'SuperAdminAllKeys',
                    },
                    {
                        path: 'proposed-keys',
                        component: SuperAdminAllProposedKeys,
                        name: 'SuperAdminAllProposedKeys'
                    },
                    {
                        path: 'view-proposed-key/:id',
                        component: SuperAdminViewProposedKey,
                        name: 'SuperAdminViewProposedKey'
                    },
                    {
                        path: 'view-proposed-key-approve/:id',
                        component: SuperAdminViewProposedKeyApproveMod,
                        name: 'SuperAdminViewProposedKeyApproveMod'

                    },
                    {
                        path: 'propose-key',
                        component: SuperAdminProposeKey,
                        name: 'SuperAdminProposeKey'
                    },
                    {
                        path: 'edit-proposed-key/:id',
                        component: SuperAdminEditProposedKey,
                        name: 'SuperAdminEditProposedKey'
                    },
                    {
                        path: 'percentage',
                        component: SuperAdminPercentage,
                        name: 'SuperAdminPercentage'

                    },
                    {
                        path: 'all-exercise-sheet-questions',
                        component: SuperAdminAllExerciseSheetQuestions,
                        name: 'SuperAdminAllExerciseSheetQuestions',
                    },
                    {
                        path: 'all-exercise-sheet-question-themes',
                        component: SuperAdminAllExerciseSheetQuestionThemes,
                        name: 'SuperAdminAllExerciseSheetQuestionThemes',
                    },
                    {
                        path: 'all-exercise-sheet-question-types',
                        component: SuperAdminAllExerciseSheetQuestionTypes,
                        name: 'SuperAdminAllExerciseSheetQuestionTypes',
                    },
                    {
                        path: 'all-academies',
                        component: SuperAdminAllAcademies,
                        name: 'SuperAdminAllAcademies',
                    },
                    {
                        path: 'all-county',
                        component: SuperAdminAllCounty,
                        name: 'SuperAdminAllCounty',
                    },
                    {
                        path: 'all-lessons',
                        component: SuperAdminAllLessons,
                        name: 'SuperAdminAllLessons',
                    },


                    {
                        path: 'edit-chapter/:id',
                        component: SuperAdminEditChapter,
                        name: 'SuperAdminEditChapter',
                    },
                    {
                        path: 'edit-difficulty/:id',
                        component: SuperAdminEditDifficulty,
                        name: 'SuperAdminEditDifficulty',
                    },
                    {
                        path: 'edit-subject/:id',
                        component: SuperAdminEditSubject,
                        name: 'SuperAdminEditSubject',
                    },
                    {
                        path: 'edit-level/:id',
                        component: SuperAdminEditLevel,
                        name: 'SuperAdminEditLevel',
                    },
                    {
                        path: 'edit-question/:id',
                        component: SuperAdminEditQuestion,
                        name: 'SuperAdminEditQuestion',
                    },
					{
						path: 'edit-qcm/:id',
						component: ()=>import('../components/SuperAdmin/QCM/EditQcm'),
						name: 'SuperAdminEditQcm'
					},
                    {
                        path: 'edit-sequence/:id',
                        component: SuperAdminEditSequence,
                        name: 'SuperAdminEditSequence',
                    },
                    {
                        path: 'edit-question-theme/:id',
                        component: SuperAdminEditQuestionTheme,
                        name: 'SuperAdminEditQuestionTheme',
                    },
                    {
                        path: 'edit-question-type/:id',
                        component: SuperAdminEditQuestionType,
                        name: 'SuperAdminEditQuestionType',
                    },
                    {
                        path: 'edit-evaluation-type/:id',
                        component: SuperAdminEditEvaluationType,
                        name: 'SuperAdminEditEvaluationType',
                    },
                    {
                        path: 'edit-key/:id',
                        component: SuperAdminEditKey,
                        name: 'SuperAdminEditKey',
                    },
                    {
                        path: 'edit-exercise-sheet-question/:id',
                        component: SuperAdminEditExerciseSheetQuestion,
                        name: 'SuperAdminEditExerciseSheetQuestion',
                    },
                    {
                        path: 'edit-exercise-sheet-question-theme/:id',
                        component: SuperAdminEditExerciseSheetQuestionTheme,
                        name: 'SuperAdminEditExerciseSheetQuestionTheme',
                    },
                    {
                        path: 'edit-exercise-sheet-question-type/:id',
                        component: SuperAdminEditExerciseSheetQuestionType,
                        name: 'SuperAdminEditExerciseSheetQuestionType',
                    },
                    {
                        path: 'edit-academy/:id',
                        component: SuperAdminEditAcademy,
                        name: 'SuperAdminEditAcademy',
                    },
                    {
                        path: 'edit-county/:id',
                        component: SuperAdminEditCounty,
                        name: 'SuperAdminEditCounty',
                    },
                    {
                        path: 'edit-lesson/:id',
                        component: SuperAdminEditLesson,
                        name: 'SuperAdminEditLesson',
                    },
                    {
                        path: 'all-calendar-exercises',
                        component: SuperAdminAllCalendarExercises,
                        name: 'SuperAdminAllCalendarExercises'
                    },
                    {
                        path: 'all-pending-calendar-exercises',
                        component: SuperAdminAllPendingCalendarExerciseContent,
                        name: 'SuperAdminAllPendingCalendarExerciseContent'

                    },
                    {
                        path: 'add-calendar-exercise',
                        component: SuperAdminAddCalendarExercise,
                        name: 'SuperAdminAddCalendarExercise'
                    },
                    {
                        path: 'edit-calendar-exercise/:id',
                        component: SuperAdminEditCalendarExercise,
                        name: 'SuperAdminEditCalendarExercise'
                    },
                    {
                        path: 'all-tile-game-content',
                        component: SuperAdminAllTileGameContent,
                        name: 'SuperAdminAllTileGameContent'
                    },
                    {
                        path: 'pending-tile-game-content',
                        component: SuperAdminPendingTileGameContent,
                        name: 'SuperAdminPendingTileGameContent'
                    },
                    {
                        path: 'add-tile-game-content',
                        component: SuperAdminAddTileGameContent,
                        name: 'SuperAdminAddTileGameContent'
                    },
                    {
                        path: 'edit-tile-game-content/:id',
                        component: SuperAdminEditTileGameContent,
                        name: 'SuperAdminEditTileGameContent'
                    },
					{
						path:'add-qcm-question',
						component: ()=>import('../components/SuperAdmin/QCM/AddQcm'),
						name: 'SuperAdminAddQcm'
					}


                ]
            },
            {
                path: 'namepicker',
                component: NamePicker,
                name: 'NamePicker',
            },
            {
                path: 'timer',
                component: Timer,
                name: 'Timer',
            },

            {
                path: '/display-activity/:id',
                component: DisplayActivity,
                name: 'DisplayActivity',
            },
            {
                path: 'tile-game',
                component: TileGameWrapper,
                name: 'TileGameWrapper',
                children: [
                    {
                        path: 'tile-game-home/:id',
                        component: TileGameHome,
                        name: 'TileGameHome'

                    },

                    {
                        path: 'tile-game/:id',
                        component: TileGame,
                        name: 'TileGame',
                    },
                    {
                        path: 'tile-rules',
                        component: TileRules,
                        name: 'TileRules',
                    },
                    {
                        path: 'tile-game-leaderboard/:id',
                        component: TileGameLeaderBoard,
                        name: 'TileGameLeaderBoard',
                    },
                    {
                        path: 'question/:id',
                        component: TileGameQuestion,
                        name: 'TileGameQuestion',
                    },
                ]
            }


        ]
    }
]

const router = createRouter({
    history: createWebHashHistory(),
    routes,
})


trackRouter(router)

export default router
