<template>
	<h1>Ajouter une nouvelle académie</h1>
	<form @submit.prevent="submit" class="form-container">
		<div class="input-container">
			<label for="Académie">Académie</label>
			<input
				type="text"
				class="form-control"
				v-model="form.name"
				id="Académie"
				placeholder="Académie"
			/>
			<p v-if="status !== ''" class="status">{{ status }}</p>
			<p class="errors">{{ errors }}</p>
		</div>
		<input class="submit dark" type="submit" value="Valider" />
	</form>
</template>

<script>

export default {
	data() {
		return {
			form: {
				name: '',
			},
			errors: '',
			status: ''
		}
	},
	methods: {
		submit() {
			this.$store.dispatch('addAcademy', this.form)
				.then(() => {
					this.status = 'Enregistré avec succès'
					this.form.name = ""
					setTimeout(() => {
						this.status = ''
					}, 2000)
				})
		},
	},
}
</script>

<style>
</style>
