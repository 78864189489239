<template>
	<ul class="hex-grid__list">
		<li class="hex-grid__item">
			<div class="hex-grid__content">1</div>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">2</div>
		</li>
		<li class="hex-grid__item" style="visibility: hidden;">
			<router-link
				:to="{ name: 'StudentPlaceLesson' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/cours.svg" alt="" />
				<p>Cours</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">4</div>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">5</div>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">6</div>
		</li>
		<li class="hex-grid__item">
			<router-link
				:to="{ name: 'StudentPlaceExercise' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/classico.svg" alt="" />
				<p>Exercices Classico</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<router-link
				:to="{ name: 'StudentPlaceLink' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/link.svg" alt="" />
				<p>Liens</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<router-link
				:to="{ name: 'StudentPlaceEvaluation' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/evaluation.svg" alt="" />
				<p>QCM</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">10</div>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">11</div>
		</li>
		<li class="hex-grid__item">
			<router-link
				:to="{ name: 'StudentPlaceFlash' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/flash.svg" alt="" />
				<p>Questions Flash</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<router-link
				:to="{ name: 'StudentPlaceGame' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/game.svg" alt="" />
				<p>Jeux Sérieux</p>
			</router-link>
		</li>
		<li class="hex-grid__item" >
			<router-link v-if="checkIfMathTeacher()"
				:to="{ name: 'StudentPlaceKey' }"
				class="hex-grid__content"
			>
				<img src="/img/student-place/expresso.svg" alt="" />
				<p>Activités Expresso</p>
			</router-link>
		</li>
		<li class="hex-grid__item">
			<div class="hex-grid__content">15</div>
		</li>
	</ul>
</template>

<script>
export default {
	props: {
		subjectsName: {
			type:Array,
			required: true
		},
	},
	
	methods: {
		checkIfMathTeacher() { 

			if (!this.subjectsName.includes('Maths')) {
				return false
			}
			return true
		}
	},
	created() {
		console.log(this.subjectsName)
	}
	
	
	
}
</script>

<style>
</style>
