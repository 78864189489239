<template>
	<nav class="menu" :class="this.$store.getters.menuToggle ? '' : 'hidden'">
		<div @click="toggleMenu" class="hide-icon">
			<img src="/img/menu-arrow.svg" alt="" />
		</div>
		<div>
			<a :href="landing" class="logo-container"><img class="logo" src="/img/logo.svg" alt="" /></a>
			<ul class="first-ul">
				<li>
					<router-link :class="isActive.includes('/dashboard') ? 'active' : ''" to="/admin/dashboard"><img
							src="/img/dashboard.svg" alt="" /> Tableau de
						bord</router-link>
				</li>
				<li>
					<router-link :class="isActive.includes('/ressources') ? 'active' : ''
		" :to="{ name: 'Resources' }"><img src="/img/search.svg" alt="" /> Ressources Le Coin
						du Prof</router-link>
				</li>
				<li>
					<router-link ref="menuAdminClass" :class="checkUrl2 == true ? 'research-active' : ''"
						@click="setClassActive" class="flex-link" to="">
						<span>
							<img src="/img/classes.svg" alt="" />
							Classes
							<img class="arrow" src="/img/down-arrow.svg" alt="" />
						</span>
						<ul class="classes-container">
							<li v-for="classe in this.$store.state.classes" :key="classe">
								<router-link :class="isActiveParams == classe.id
		? 'active'
		: ''
		" :to="{
		name: 'ClassResources',
		params: { id: classe.id },
	}">{{ classe.name }}</router-link>
							</li>
							<li v-if="this.$store.state.user.role != 'GUEST'">
								<router-link to="/admin/ajouter-classe">
									+ Ajouter une classe</router-link>
							</li>
							<li v-else>
								<button @click="checkGuest">
									+ Ajouter une classe
								</button>
							</li>
						</ul>
					</router-link>
				</li>
				<li>
					<router-link :class="isActive.includes('/agenda') ? 'active' : ''" to="/admin/agenda"><img
							src="/img/calendar.svg" alt="" />
						Agenda</router-link>
				</li>
				<li>
					<router-link :class="isActive.includes('/profil') ? 'active' : ''" to="/admin/profil"><img
							src="/img/profile.svg" alt="" />
						Mon Profil</router-link>
				</li>
			</ul>
		</div>
	</nav>
	<pop-up-jeu @closed="timeToCloseJeu" v-if="isVisibleJeu" />
</template>

<script>
import PopUpJeu from '../PopUp/PopUpJeu.vue'
import Config from "../../config"

export default {
	components: {
		PopUpJeu,
	},
	data() {
		return {
			isVisibleJeu: false,
			classes: [],
			isClassActive: false,
			isCreateActive: false,
			isVisibleGuest: false,
			landing: Config.landing_url,
		}
	},

	mounted() {
		this.$store.state.isMenuToggled = true
	},

	methods: {
		toggleMenu() {
			this.$store.dispatch('toggleMenu')
		},
		checkGuest(e) {
			if (this.$store.state.user.role === 'GUEST') {
				this.isVisibleGuest = !this.isVisibleGuest
			}
			else {
				this.$router.push({ name: e })
			}
		},
		setCreateActive() {

			if (this.isCreateActive) {
				this.isCreateActive = false
			}
			else {
				this.isClassActive = false
				this.isCreateActive = true
			}
		},
		setClassActive() {
			if (this.isClassActive) {
				this.isClassActive = false
			}
			else {
				this.isCreateActive = false
				this.isClassActive = true
			}

		},
		showPopUpJeu() {
			this.isVisibleJeu = true
		},
		timeToCloseJeu() {
			this.isVisibleJeu = false
		},
	},
	computed: {
		
		isActive() {
			return this.$route.path
		},
		isActiveParams() {
			return this.$route.params.id
		},
		checkUrl() {
			if ((this.$route.path.includes('/recherche/') || this.isCreateActive) && !this.isClassActive) {
				return true
			}
			else {
				return false
			}
		},
		checkUrl2() {
			if ((this.$route.path.includes('/classe/') || this.isClassActive) && !this.isCreateActive) {
				return true
			}
			else {
				return false
			}
		},
	},
}
</script>

<style></style>
