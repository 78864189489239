import { authenticatedApiFetch } from "../utils"

export const deleteAllQuestionService = async (id) => {
    const res = await authenticatedApiFetch(`/all-questions/${id}/`, { method: 'DELETE' })
    return res
}


export const approveQuestionService = async (id) => {
    const res = await authenticatedApiFetch(`/all-questions/${id}/validate/`)
        .catch(err => {
            console.error(err)
        })
    return res
}

export const refuseQuestionService = async (id) => {
    const res = await authenticatedApiFetch(`/all-questions/${id}/refuse/`)
        .catch(err => {
            console.error(err)
        })
    return res
}


export const getQuestionService = async (id) => {
	const res = await authenticatedApiFetch(`/all-questions/${id}/`, { method: 'GET' })
		.then(res => res.json())
		.catch(error => console.error(error))
	return res
}
