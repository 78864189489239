<template>
	<section class="question-flash-container">
		<filter-wrapper
			@updateFilter="updateFilter"
			:showFilter="false"
			:showSearch="false"
		/>
		<loader v-if="!questionReady" />

		<table>
			<tr>
				<th></th>
				<th>Titre</th>
				<th>Auteur</th>
				<th>Chapitre</th>
				<th>Niveau</th>
				<th>Voir</th>
				<th>Modifier</th>
				<th>Supprimer</th>
				<th>Statut</th>
			</tr>
			<tr v-for="(question, index) in keys" :key="index">
				<td>{{ currentPage * perPage + index + 1 }}</td>
				<td>{{ question.title }}</td>
				<td>{{ question.teacher_name }}</td>
				<td
					v-if="
						this.$store.state.sequences.length !== 0 &&
						this.$store.state.chapters.length !== 0
					"
				>
					{{ question.chapter_name }}
				</td>
				<td v-if="this.$store.state.levels.length !== 0">
					{{ question.level_name }}
				</td>
				<td>
					<router-link
						:to="{
							name: 'MyResourcesViewExpresso',
							params: { id: question.id },
						}"
						class="see"
					>
						<img src="/img/super-admin/blue-eye.svg" alt="" />
					</router-link>
				</td>
				<td>
					<router-link
						class="table-btn"
						v-if="
							checkIfSuperAdminAndValidatedStatus(question.status)
						"
						:to="{
							name: 'MyResourcesEditExpresso',
							params: { id: question.id },
						}"
						>Modifier</router-link
					>
				</td>
				<td>
					<button
						class="delete"
						v-if="
							checkIfSuperAdminAndValidatedStatus(question.status)
						"
						@click="deleteQuestion(question.id)"
					>
						<img src="/img/super-admin/blue-trash.svg" alt="" />
					</button>
				</td>
				<td>
					<span
						v-if="question.status !== 'PENDING'"
						:style="getStyleColor(question.status)"
					>
						{{ translate(question.status) }}</span
					>
					<span
						class="table-btn"
						:style="getStyleColor(question.status)"
						v-else
					>
						A&#160;approuver
					</span>
				</td>
			</tr>
		</table>
		<pagination-component
			:totalPages="pages"
			:perPage="20"
			:currentPage="currentPage"
			@pageChanged="getNewQuestions"
		/>
	</section>
</template>

<script>
import { allProposedKeyFilterService, deleteProposedKeyService } from '../../../../../services/proposedKeysService'
import PaginationComponent from '../../../../Utils/PaginationComponent.vue'
import FilterWrapper from '../../../Search/FilterWrapper.vue'
import Loader from '../../../../Loader.vue'

export default {
	components: { PaginationComponent, FilterWrapper, Loader },
	data() {
		return {
			keys: [],
			levelFilter: '',
			teacherFilter: '',
			chapterFilter: '',
			statusFilter: '',
			count: null,
			currentPage: 0,
			pages: [],
			perPage: 20,
			filter: '',
			admins: [],
			selectedExercise: {},
			isPopUpExercisePreviewVisible: false,
			isShowValidateBtnVisible: false,
			questionReady: false
		}
	},
	methods: {
		updateFilter(data) {
			this.levelFilter = data.level || ''
			this.chapterFilter = data.chapter || ''
			this.questionFilter()
		},
		translate(status) {
			switch (status) {
				case 'VALIDATED':
					return 'Approuvé'
				case 'REFUSED':
					return 'Refusé'
			}
		},
		checkIfSuperAdminAndValidatedStatus(status) {
			if (status == 'VALIDATED') {
				if (this.$store.state.user.role == 'SUPER_ADMIN')
					return true
				return false
			}
			return true
		},
		closePopUpPreview() {
			this.isPopUpExercisePreviewVisible = false
			this.isShowValidateBtnVisible = false
		},

		getStyleColor(status) {
			switch (status) {
				case 'VALIDATED':
					return `color: #17A81C`
				case 'PENDING':
					return `color: #fff;background-color:#F0961A;`
				case 'REFUSED':
					return `color: #DE1D1D`
				case 'PRIVATE':
					return `color: #000`
			}
		},
		async deleteQuestion(id) {
			if (confirm("Êtes-vous sûr de vouloir supprimer ?")) {
				await deleteProposedKeyService(id)

				this.getNewQuestions(this.currentPage)
			}
		},
		async questionFilter() {
			this.questionReady = false
			this.filter = `chapter=${this.chapterFilter}&level=${this.levelFilter}&teacher=${this.$store.state.user.user}&status=${this.statusFilter}&`

			const res = await allProposedKeyFilterService(this.filter)
			this.count = res.count
			this.currentPage = 0
			this.keys = res.results
			this.pages = Math.ceil(this.count / 20)
			this.questionReady = true
		},
		async getNewQuestions(e = 0) {
			this.questionReady = false
			const res = await allProposedKeyFilterService(`?offset=${e * 20}&${this.filter}`)

			this.count = res.count
			this.currentPage = e
			this.keys = res.results
			this.pages = Math.ceil(this.count / 20)
			this.questionReady = true

		},


	},
	created() {
		this.questionFilter()
	},

	watch: {
		levelFilter: function () {
			this.chapterFilter = ''
		}
	}
}
</script>

<style>
</style>
