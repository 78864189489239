<template>
	<div v-if="questions.length !== 0" class="fake-container">
		<display-hand-made-key
			@click="showPopUpSlide = !showPopUpSlide"
			@close="this.$emit('close')"
			:id="id"
		/>
		<div class="add-container">
			<div>
				<p>{{ name }}</p>
			</div>
			<router-link
				:to="{
					name: 'StudentPlaceDisplayActivity',
					params: { id: id },
				}"
				class="submit add"
			>
				<span>Voir</span>
			</router-link>
		</div>
	</div>
</template>

<script>
import axios from 'axios'
import Config from '../../config'
import DisplayHandMadeKey from './DisplayHandMadeKey.vue'
export default {
	components: { DisplayHandMadeKey },
	props: {
		id: Number,
		activities: Array,
		name: String,
	},
	data() {
		return {
			added: false,
			readyToDisplay: false,
			questions: [],
			isPopUpVisible: false,
			chapters: [],
			showPopUpSlide: false,
		}
	},
	methods: {
		getChapters() {
			let chapter = []
			let chapterName = []
			this.questions.forEach(element => {
				if (!chapter.includes(element.chapter)) {
					chapter.push(element.chapter)
				}
			})
			chapter.forEach((element, index) => {
				if (element) {
					axios.get(`${Config.backend_url}/api/v1/chapters/${element}/`)
						.then(response => {
							chapterName.push(response.data.name)
							if (index == chapter.length - 1) {
								this.chapters = chapterName
							}
						})
						.catch(error => console.error(error))
				}
			})
			return chapterName
		},
		popUp() {
			this.isPopUpVisible = !this.isPopUpVisible
		},
		getQuestions: function () {
			let isImgSet = false
			this.activities.forEach(element => {
				axios.get(`${Config.backend_url}/api/v1/questions-instances/${element}/`, { method: 'GET' })
					.then(response => {
						this.questions.push(response.data)
						if (this.questions.length !== 0 && !isImgSet) {

							isImgSet = true
						}
						this.getChapters()
					})
					.catch(error => console.error(error))
			}
			)
		},
		color(e) {
			let color
			switch (e) {
				case 'Facile':
					color = 'green'
					break
				case 'Moyen':
					color = 'orange'
					break
				case 'Difficile':
					color = 'red'
					break

				default:
					break
			}
			return color
		},

		add() {
			this.added = !this.added
			this.$emit('addQuestion')
		},
		reRender() {
			if (window.MathJax) {
				window.MathJax.Hub.Queue(["Typeset", window.MathJax.Hub])
			}
		},
	},
	created() {
		this.reRender()
		this.getQuestions()
	},
	mounted() {
		this.reRender()
		setTimeout(() => {
			this.readyToDisplay = true
		}, 1000)
	},
}
</script>

<style>
</style>
