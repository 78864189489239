<template>
	<div class="qcm-container">
		<div class="img-container">
			<img src="/img/student-place/qcm-bg.jpg" alt="">
			<div class="text-container">
				<p class="title">QCM</p>
				<p>{{ qcm.title }}</p>
			</div>
		</div>
		<div class="action-container">
			<p>{{ qcm.duration }}min</p>
			<div v-if="!checkIfTeacher()">
				<button v-if="isCorrected" @click="$emit('showSoluce')">Voir le corrigé</button>
				<p v-else-if="qcm.isDone">Terminé</p>
				<router-link v-else :to="{ name: 'StudentPlaceQcm', params: { id: qcm.id } }">Commencer</router-link>
			</div>
			<div v-else>
				<router-link :to="{name:'ViewQcm', params: {id:qcm.id}}">Voir</router-link>
			</div>
		</div>
	</div>
</template>
<script>
export default {
	props:{
		qcm: Object,
		isCorrected: Boolean	
	},
	methods:{
		checkIfTeacher(){
			return localStorage.getItem('userId')
		}
	}
}
</script>
<style lang="scss" scoped>
@import '../../assets/scss/student-place/qcm-container.scss';
</style>
