<template>
	<pop-up-wrapper>
		<div class="pop-up-new-folder">
			<p class="title">Nom du dossier</p>
			<form @submit.prevent="submit">
				<input
					ref="name"
					v-model="name"
					placeholder="Nom du dossier"
					type="text"
				/>
				<div class="btn-container">
					<p class="submit cancel" @click="$emit('close')">Annuler</p>
					<button class="submit dark">Valider</button>
				</div>
			</form>
		</div>
	</pop-up-wrapper>
</template>


<script>
import { authenticatedApiFetch } from '../../../utils'
import PopUpWrapper from '../PopUpWrapper.vue'
export default {
	components: { PopUpWrapper },
	data() {
		return {
			name: ''
		}
	},
	methods: {
		submit: async function () {

			const data = {
				name: this.name,
				class_id: this.$route.params.id,
				parent_folder_id: this.$route.params.folderId
			}
			authenticatedApiFetch(`/folders/`, { method: 'POST', body: JSON.stringify(data) })
				.then(() => {
					this.$emit('close')
					this.$emit('getFolders')
				})
		},
	},

	mounted() {
		this.$refs.name.focus()
	},



}
</script>

<style>
</style>
