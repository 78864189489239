<template>
	<div v-if="isBuyed" class="buyed-product">
		<img :src="product.img" alt="" />
		<p :style="`color:${product.color}`">{{ product.title }}</p>
	</div>
</template>

<script>
export default {
	props: {
		product: Object,
		isBuyed: Boolean
	}
}
</script>

<style>
</style>
