<template>
	<div class="user-wrapper">
		<button
			@click="popUpProfileVisible = !popUpProfileVisible"
			class="user-container"
		>
			<div v-if="this.$store.state.user.first_name">
				{{ this.$store.state.user.first_name.charAt(0)
				}}{{ this.$store.state.user.last_name.charAt(0) }}
			</div>
			<div v-else>JD</div>
		</button>
		<pop-up-profile
			@close="popUpProfileVisible = !popUpProfileVisible"
			v-if="popUpProfileVisible"
		/>
	</div>
</template>

<script>
import PopUpProfile from "../PopUp/PopUpProfile.vue"
export default {
	components: { PopUpProfile },
	data() {
		return {
			popUpProfileVisible: false
		}
	},
}
</script>

<style>
</style>
