import { authenticatedApiFetch } from '../utils'

export const allChristmasCalendarsForClassService = async (classId) => {
    const res = await authenticatedApiFetch(`/christmas-calendar/?classes=${classId}`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}


export const getChristmasCalendarService = async (id) => {
    const res = await authenticatedApiFetch(`/christmas-calendar/${id}/`)
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}



export const createChristmasCalendarService = async (data) => {
    const res = await authenticatedApiFetch(`/christmas-calendar/`, { method: 'POST', body: JSON.stringify(data) })
        .then(response => response.json())
        .catch(error => console.error(error))
    return res
}
