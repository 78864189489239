import { authenticatedApiFetch } from './utils'
import router from './router'
export const getProfile = () => {
    authenticatedApiFetch(`/profile/${parseJwt(localStorage.token).profil}/`, { method: 'GET' })
        .then(res => res.json())
        .then(res => {
            if (res.role !== 'ADMIN' && res.role !== 'SUPER_ADMIN') {
                router.push({ name: 'Dashboard' })
            }
        })
}

function parseJwt(token) {
    var base64Url = token.split('.')[1]
    var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
    var jsonPayload = decodeURIComponent(atob(base64).split('').map(function (c) {
        return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
    }).join(''))

    return JSON.parse(jsonPayload)
}
