<template>
	<div
		v-if="agenda.classroom"
		class="agendas-ward"
		:class="getColor(agenda.classroom)"
	>
		<p class="day">{{ getDay(agenda.day) }}</p>
		<p class="class-name">
			{{ getClassName(agenda.classroom) }}
		</p>

		<p class="hour">
			{{ agenda.debut_hour.slice(0, -3) }} -
			{{ agenda.end_hour.slice(0, -3) }}
		</p>
		<div class="resources-container">
			<router-link
				:to="{ name: 'DisplayActivity', params: { id: activity.id } }"
				v-for="activity in activities"
				:key="activity"
				class="ressource"
			>
				{{ activity.name }}
			</router-link>
			<p
				v-for="evaluation in evaluations"
				:key="evaluation"
				class="ressource"
			>
				{{ evaluation.title }}
			</p>
			<p
				v-for="exerciseSheet in exerciseSheets"
				:key="exerciseSheet"
				class="ressource"
			>
				{{ exerciseSheet.title }}
			</p>
		</div>
	</div>
</template>

<script>
import { getActivityByIdService } from '../../../services/activitiesService'
import { getExerciseSheetByIdService } from '../../../services/exerciseSheetsService'
export default {
	props: {
		agenda: Object,
	},
	data() {
		return {
			activities: [],
			evaluations: [],
			exerciseSheets: []
		}
	},
	methods: {
		getDay(e) {
			let date = new Date(e.substring(0, 10))
			return date.toLocaleString('fr', { weekday: "long", month: 'long', day: 'numeric' })
		},
		getActivities: async function () {
			for (const activity of this.agenda.activities) {
				this.activities.push(await getActivityByIdService(activity))
			}
		},

		getExerciseSheets: async function () {
			for (const exercise_sheet of this.agenda.exercise_sheets) {
				this.exerciseSheets.push(await getExerciseSheetByIdService(exercise_sheet))
			}

		},
		getClassName(id) {
			if (this.$store.state.classes.length !== 0) {
				return this.$store.state.classes.find(x => x.id == id).name
			}
		},
		getColor(id) {
			let classe = this.$store.getters.classLevel(id)
			if (classe) {
				let color = classe.level_name
				switch (color) {
					case '6ème':
						return 'sixth'

					case '5ème':
						return 'fifth'

					case '4ème':
						return 'fourth'

					case '3ème':
						return 'third'

					case '2nde':
						return 'second'

					case '1ère Spé':
						return 'first'

					case 'Term Spé':
						return 'term'

					case 'Term Maths Expertes':
						return 'term'

					case 'Term Maths Comp':
						return 'term'
					default:
						return 'aled'
				}
			}
		}
	},

	created() {
		this.getActivities()
		this.getExerciseSheets()
	},
}
</script>

<style>
</style>
