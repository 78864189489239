<template>
	<section class="calendar-container">
		<div class="btn-container">
			<p>
				{{ week[0].number }} {{ week[0].month }} - {{ week[6].number }}
				{{ week[6].month }} {{ week[0].formatedDate.slice(0, 4) }}
			</p>
			<button @click="prev">
				<img src="/img/calendar/left-arrow.svg" alt="" />
			</button>
			<button @click="today">Aujourd'hui</button>
			<button @click="next">
				<img src="/img/calendar/right-arrow.svg" alt="" />
			</button>
		</div>

		<calendar-header :week="week" />
		<div v-if="loaded" class="calendar-day-container">
			<calendar-hour />
			<calendar-day
				v-for="day in week"
				:key="day"
				:events="getEvents(day)"
				:day="day"
				:indicator="checkDate(day)"
			/>
		</div>
		<div class="calendar-loader-container" v-else-if="isLoading">
			<loader />
		</div>
	</section>
</template>

<script>
import { allActivitiesService } from '../../../services/activitiesService'
import { allExerciseSheetsService } from '../../../services/exerciseSheetsService'
import { allOccurencesService } from '../../../services/occurrencesService'
import Loader from '../../Loader.vue'
import CalendarDay from './CalendarDay.vue'
import CalendarHeader from './CalendarHeader.vue'
import CalendarHour from './CalendarHour.vue'
export default {
	components: { CalendarHeader, CalendarDay, CalendarHour, Loader },
	data() {
		return {
			week: [],
			agendas: [],
			evaluations: [],
			activities: [],
			exerciseSheets: [],
			events: [],
			date: new Date(),
			loaded: false,
			isLoading: false
		}
	},

	methods: {
		checkDate(day) {
			if (day.formatedDate == this.computedDate()) {
				return true
			}
			else {
				return false
			}
		},
		computedDate() {
			let date = new Date()
			return date.toISOString().split('T')[0]
		},
		getEvents(day) {
			let events = this.events.filter(x => x.day == day.formatedDate)

			if (events) {
				return events
			}
			else {
				return ''
			}

		},
		next() {
			this.date.setDate(this.date.getDate() + 5)
			this.getDate()
		},
		prev() {
			this.date.setDate(this.date.getDate() - 9)
			this.getDate()
		},
		today() {
			this.date = new Date()
			this.getDate()
		},
		getDate() {
			let week = []
			this.date.setDate(this.date.getDate() - this.date.getDay() + 1)
			for (let i = 0; i < 7; i++) {
				const date = new Date(this.date)
				week.push(
					{
						day: date.toLocaleDateString('fr', { weekday: "long" }),
						number: date.toLocaleDateString('fr', { day: "2-digit" }),
						month: date.toLocaleDateString('fr', { month: "long" }),
						formatedDate: date.toISOString().split('T')[0],
					}
				)
				this.date.setDate(this.date.getDate() + 1)
			}
			this.week = week
		},

		allAgendas: async function () {
			this.agendas = await allOccurencesService()
			for (let agenda of this.agendas) {
				let data = {}
				let activity = this.activities.find(x => x.id == agenda.activity) || ''
				let evaluation = this.evaluations.find(x => x.id == agenda.evaluation) || ''
				let exerciseSheet = this.exerciseSheets.find(x => x.id == agenda.exercise_sheet) || ''
				data = {
					activities: agenda.activities,
					evaluations: agenda.evaluations,
					exercise_sheets: agenda.exercise_sheets,
					tile_games: agenda.tile_games,
					day: agenda.day,
					start: agenda.debut_hour,
					end: agenda.end_hour,
					dateStart: `${agenda.day} ${agenda.debut_hour}`,
					dateEnd: `${agenda.day} ${agenda.end_hour}`,
					title: activity.name || evaluation.title || exerciseSheet.title,
					class: agenda.classroom,
					level: activity.level_name,
					room: agenda.room_number,
					id: agenda.id
				}
				this.events.push(data)
			}

		},
		allActivities: async function () {
			this.activities = await allActivitiesService()
		},

		allExerciseSheets: async function () {
			this.exerciseSheets = await allExerciseSheetsService()
		},
	},


	async created() {
		this.isLoading = true
		this.getDate()
		await this.allActivities()
		await this.allExerciseSheets()
		await this.allAgendas()
		this.isLoading = false
		this.loaded = true
	},
}
</script>

<style>
</style>
