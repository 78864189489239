<template>
	<pop-up-wrapper @close="close">
		<div class="pop-up-not-allowed">
			<img class="close" @click="close" src="/img/close.svg" alt="" />
			<div class="pop-up-text-container">
				<p class="title">
					Pour accéder à ces contenus, vous devez être abonné(e) !
				</p>
				<div class="column-container">
					<div class="column">
						<div class="text-container">
							<p>
								L'abonnement à la plateforme est à la carte :
								vous choisissez les contenus qui vous
								intéressent.
							</p>
							<br />
							<p>
								Si vous souhaitez le faire financer par votre
								établissement, contactez-nous!
							</p>
						</div>
						<router-link
							@click="close"
							:to="{ name: 'PriceList' }"
							class="submit dark"
						>
							S'abonner
						</router-link>
					</div>
					<div class="separator"></div>
					<div class="column">
						<div class="text-container">
							<p>
								L'essai gratuit à la plateforme donne accès à
								l'ensemble des fonctionnalités et à l'ensemble
								des contenus.
							</p>
							<br />
							<p>
								Cet essai est non renouvelable, il commence
								immédiatement et prend fin au bout d'un mois.
							</p>

							<p class="no-card">
								<strong style="text-align: center"
									>Pas de CB requise</strong
								>
							</p>
						</div>
						<button
							v-if="checkDate()"
							@click="freeTrial"
							class="submit light"
						>
							Commencer mon essai gratuit
						</button>
						<button
							v-else
							@click="showError"
							class="submit dark disabled"
						>
							Commencer l'essai gratuit
						</button>
					</div>
				</div>
			</div>
		</div>
	</pop-up-wrapper>
</template>

<script>
import { authenticatedApiFetch } from '../../utils'
import PopUpWrapper from './PopUpWrapper.vue'

export default {
	components: { PopUpWrapper },
	data() {
		return {
			subscription: {},
			error: {
				text: '',
				status: ''
			}
		}
	},

	methods: {
		showError() {

			const date = new Date(this.$store.state.freeTrial.free_trial_end)

			const options = { year: 'numeric', month: 'long', day: 'numeric' }
			const dateFormat = new Intl.DateTimeFormat('fr-FR', options)

			const formattedDate = dateFormat.format(date)
			this.error.status = 'error'
			this.error.text = `Votre essai gratuit a pris fin le ${formattedDate}`
		},
		checkDate() {
			if (this.$store.state.freeTrial.free_trial_end) {
				const freeTrialEndDate = new Date(this.$store.state.freeTrial.free_trial_end)

				// Obtenir la date actuelle
				const currentDate = new Date()

				// Comparer les dates
				if (freeTrialEndDate > currentDate) {
					return true
				}
			}
			else {
				return true
			}
		},
		resetError() {
			this.error = {
				text: '',
				status: ''
			}
		},
		close() {
			this.$store.state.isPopUpNotAllowedVisible = false
		},
		async freeTrial() {
			if (this.$store.state.freeTrial.free_trial_start == null) {
				const res = await authenticatedApiFetch('/get-free-trials/', { method: 'GET' })
					.then(res => res.json())
					.then(response => {
						return response[0]
					})
					.catch(err => {
						console.error(err)
					})

				this.$store.dispatch('getSubscription')
				this.subscription = res

				this.error.status = 'success'
				this.error.text = `Votre essai gratuit est activé. Il prendra fin le ${this.subscription.free_trial_end}`

				this.$emit('showPopUp', this.error)
				this.close()

			}
			else {
				this.error.status = 'error'
				this.error.text = `Tu as déjà utilisé ton essai gratuit`
				this.$emit('showPopUp', this.error)
			}


		},
		async checkSubscription() {
			const res = await authenticatedApiFetch('/subscriptions/', { method: 'GET' })
				.then(response => response.json())
				.then(response => {
					return response[0]
				})

			this.subscription = res
		}
	},

	created() {
		this.checkSubscription()
	},
}
</script>

<style>
</style>
